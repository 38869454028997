import { useMemo } from 'react'
import DynamicTable from '@atlaskit/dynamic-table'
import Pagination from '@atlaskit/pagination'
import { RowType } from '@atlaskit/dynamic-table/dist/types/types'
import { getPagesCount } from 'utils/helpers/get-pages-count'
import { tableHead } from './table-head'
import { getTableRows } from './table-rows'
import { ITimeOffLogTableProps } from './types'
import { TimeOffLogTableStyles } from './styles'
import { TimeOffLogGetListOrderByEnum, OrderEnum } from 'shared'

export const TimeOffLogTable = (props: ITimeOffLogTableProps) => {
  const { sourceData, total, loading, tableState, showPagination, onUpdateTableState } = props
  const { page, limit, sort, order } = tableState

  const pages: Array<number> = useMemo(() => getPagesCount(total, limit), [total, limit])

  const onSetTablePage = (pageNumber: number) => {
    onUpdateTableState({ ...tableState, page: pageNumber })
  }

  const onSetTableSort = (key: TimeOffLogGetListOrderByEnum, sortOrder: OrderEnum) => {
    onUpdateTableState({ ...tableState, sort: key, order: OrderEnum[sortOrder] })
  }

  const tableRows: Array<RowType> = useMemo(
    () =>
      getTableRows({
        sourceData,
      }),
    [sourceData],
  )

  return (
    <TimeOffLogTableStyles>
      <DynamicTable
        head={tableHead}
        rows={tableRows}
        sortKey={sort}
        sortOrder={order}
        loadingSpinnerSize="large"
        isLoading={loading}
        onSort={({ key, sortOrder }: { key: TimeOffLogGetListOrderByEnum; sortOrder: OrderEnum }) =>
          onSetTableSort(key, sortOrder)
        }
      />
      {showPagination && (
        <Pagination
          nextLabel="Next"
          label="Page"
          pageLabel="Page"
          selectedIndex={page - 1}
          pages={pages}
          previousLabel="Previous"
          onChange={(_e, pageNumber: number) => onSetTablePage(pageNumber)}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        />
      )}
    </TimeOffLogTableStyles>
  )
}
