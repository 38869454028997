// TODO: fix eslint errors
/* eslint-disable */
import Pagination from '@atlaskit/pagination'
import { xcss, Box } from '@atlaskit/primitives'
import { token } from '@atlaskit/tokens'
import styled from 'styled-components'
import React, { FC, ReactNode, useEffect, useState, useRef } from 'react'
import InfoIcon from '@atlaskit/icon/glyph/info'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning'
import EmojiPeopleIcon from '@atlaskit/icon/glyph/emoji/people'
import * as DOMPurify from 'dompurify'

function getBgColor(info: string) {
  switch (info) {
    case 'info':
      return token('color.background.information')
    case 'error':
      return token('color.background.danger')
    case 'warning':
      return token('color.background.warning')
    case 'other':
      return token('color.background.discovery')
  }
}

function getIconColor(info: string) {
  switch (info) {
    case 'info':
      return token('color.icon.brand')
    case 'error':
      return token('color.icon.danger')
    case 'warning':
      return token('color.icon.warning')
    case 'other':
      return token('color.icon.discovery')
  }
}

const AnnouncementWrapper: FC<{
  type: keyof typeof icons
  children: ReactNode
}> = styled.div`
  border-radius: 3px;
  width: 100%;
  min-height: 140px;
  overflow: hidden;
  padding: ${token('space.200')};
  background-color: ${(props) => getBgColor(props.type)};
  margin-bottom: ${token('space.300')};
  div[type=${(props) => props.type}] svg > path {
    fill: ${(props) => getIconColor(props.type)};
  }
  div[type=${(props) => props.type}] svg g > path:first-child {
    fill: ${(props) => getIconColor(props.type)};
  }
  button[aria-current='page'] {
    background: transparent;
  }
`

const AnnouncementIcon: FC<{ type: keyof typeof icons; children: ReactNode }> = styled.div`
  width: 24px;
  height: 24px;
`

const icons = {
  info: <InfoIcon label="InfoIcon" />,
  error: <ErrorIcon label="ErrorIcon" />,
  warning: <EditorWarningIcon label="EditorWarningIcon" />,
  other: <EmojiPeopleIcon label="PeopleIcon" />,
}

function CustomIcon({ type }: { type: keyof typeof icons }) {
  return <AnnouncementIcon type={type}>{icons[type]}</AnnouncementIcon>
}

export default function Announcement({
  announcements,
}: {
  announcements: {
    priority: string
    text: string
  }[]
}) {
  const [page, setPage] = useState(1)

  const alertType = matchType(announcements[page - 1]?.priority)

  function matchType(type: string) {
    switch (type) {
      case 'low':
        return 'other'

      case 'medium':
        return 'info'

      case 'high':
        return 'warning'

      case 'critical':
        return 'error'

      default:
        return 'info'
    }
  }

  return announcements[page - 1] ? (
    <AnnouncementWrapper type={alertType as keyof typeof icons}>
      <Box
        xcss={xcss({
          display: 'flex',
        })}
      >
        <Box
          xcss={xcss({
            marginRight: 'space.200',
          })}
        >
          <CustomIcon type={alertType as keyof typeof icons} />
        </Box>
        <Box>
          <Box
            xcss={xcss({
              color: 'color.text',
              fontFamily: 'inherit',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              marginBottom: 'space.100',
            })}
          >
            Announcement
          </Box>
          <Box
            xcss={xcss({
              marginBottom: 'space.100',
            })}
          >
            <div
              style={{
                overflow: 'hidden',
                width: '268px',
                minHeight: '80px',
                textOverflow: 'ellipsis',
                color: 'color.text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(announcements[page - 1].text),
              }}
            />
          </Box>
        </Box>
      </Box>

      {announcements.length > 1 && (
        <Box
          xcss={xcss({
            display: 'flex',
            justifyContent: 'flex-end',
          })}
        >
          <Pagination
            onChange={(e, page) => {
              setPage(page)
            }}
            nextLabel="Next"
            label="Page"
            pageLabel="Page"
            pages={Array.from({ length: announcements.length }, (_, index) => index + 1)}
            previousLabel="Previous"
          />
        </Box>
      )}
    </AnnouncementWrapper>
  ) : null
}
