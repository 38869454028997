import { Form, Modal, Space } from 'antd'
import { useEffect, useMemo } from 'react'
import { Button, FormInputAmount, FormDatepicker, FormSelect } from 'components'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from 'hooks'
import {
  useGetSystemCurrencyQuery,
  useCreateEmployeeMgpHistoryMutation,
  useUpdateEmployeeMgpHistoryMutation,
  useDeleteEmployeeMgpHistoryMutation,
  CreateEmployeeMGPHistoryPayload,
  UpdateEmployeeMGPHistoryPayload,
  employeeCompensationApi,
} from 'services/api'
import { closeMGPModal, triggerNotification } from 'store'
import { useProfile } from '../../../../../../context'
import { mergeDataBySourceFields } from 'utils/helpers/merge-data-by-source-fields'
import { DeleteInfo } from './components'
import { schema } from './schema'
import { MgpFormModalTitle, MgpFormStyles } from './styles'
import { MgpFormModalProps, MgpFormValues } from './types'
import { defaultValues, mgpModalTitleMap, MgpModalTypeEnum, mgpModalNotificationMap, mgpModalBtnMap } from './constants'

export const MgpFormModal = ({ mode, payload }: MgpFormModalProps) => {
  const { profile } = useProfile()

  const dispatch = useAppDispatch()

  const { data: currencyList, isLoading: isLoadingSystemCurrencies } = useGetSystemCurrencyQuery(undefined, {
    skip: mode === MgpModalTypeEnum.Delete,
  })

  const [createMgp, { isLoading: isCreatingMgp }] = useCreateEmployeeMgpHistoryMutation()
  const [updateMgp, { isLoading: isUpdatingMgp }] = useUpdateEmployeeMgpHistoryMutation()
  const [deleteMgp, { isLoading: isDeletingMgp }] = useDeleteEmployeeMgpHistoryMutation()
  const isLoading = isCreatingMgp || isUpdatingMgp || isDeletingMgp

  const { handleSubmit, control, reset } = useForm<MgpFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const currencySelectOptions = useMemo(() => {
    if (!currencyList) return []

    return currencyList.map(({ currencyId, code, name }) => ({
      label: `${code} - ${name}`,
      value: currencyId,
    }))
  }, [currencyList])

  const handleClose = () => {
    reset()
    dispatch(closeMGPModal())
  }

  const onSubmit: SubmitHandler<MgpFormValues> = async (data) => {
    try {
      // TODO - Fix the type of data
      if (mode === MgpModalTypeEnum.Create) {
        await createMgp({ ...data, employeeId: profile.id } as CreateEmployeeMGPHistoryPayload).unwrap()
      }

      if (mode === MgpModalTypeEnum.Edit && payload) {
        await updateMgp({ ...data, id: payload.id } as UpdateEmployeeMGPHistoryPayload).unwrap()
      }

      dispatch(employeeCompensationApi.util.invalidateTags(['employeeCompensation']))
      dispatch(triggerNotification({ type: 'success', message: mgpModalNotificationMap[mode] }))
      handleClose()
    } catch (error) {
      console.error('Error creating incentive', error)
    }
  }

  const handleDelete = async () => {
    if (payload) {
      try {
        await deleteMgp(payload.id).unwrap()

        dispatch(employeeCompensationApi.util.invalidateTags(['employeeCompensation']))
        dispatch(triggerNotification({ type: 'success', message: mgpModalNotificationMap[mode] }))
        handleClose()
      } catch (error) {
        console.error('Error creating incentive', error)
      }
    } else {
      console.error({ payload })
    }
  }

  useEffect(() => {
    if (mode === MgpModalTypeEnum.Edit && payload) {
      const formResetData = mergeDataBySourceFields(defaultValues, {
        ...payload,
        currencyId: payload.currency.id,
      })
      reset(formResetData)
    }
  }, [])

  return (
    <Modal
      title={<MgpFormModalTitle>{mgpModalTitleMap[mode]}</MgpFormModalTitle>}
      open
      footer={null}
      onCancel={handleClose}
      destroyOnClose
      width={400}
      maskClosable={false}
    >
      <MgpFormStyles>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          {mode === MgpModalTypeEnum.Delete && payload ? (
            <DeleteInfo
              amount={payload.amount}
              date={payload.date}
              currency={payload.currency}
              employeeFullName={`${profile.firstNameEn} ${profile.lastNameEn} ${profile.nickname ? `(${profile.nickname})` : ''}`}
            />
          ) : (
            <>
              <FormSelect<MgpFormValues>
                showSearch
                control={control}
                name="currencyId"
                loading={isLoadingSystemCurrencies}
                label="Currency"
                placeholder="Select currency"
                optionFilterProp="label"
                options={currencySelectOptions}
              />
              <FormInputAmount<MgpFormValues> control={control} name="amount" />
              <FormDatepicker<MgpFormValues>
                control={control}
                name="date"
                placeholder="Select date"
                label="Start date"
              />
            </>
          )}
          <Space className="modal-footer" size={8}>
            <Button type="default" onClick={handleClose}>
              Close
            </Button>
            <Button
              type="primary"
              danger={mode === MgpModalTypeEnum.Delete}
              htmlType={mode === MgpModalTypeEnum.Delete ? 'button' : 'submit'}
              loading={isLoading}
              onClick={mode === MgpModalTypeEnum.Delete ? handleDelete : undefined}
            >
              {mgpModalBtnMap[mode]}
            </Button>
          </Space>
        </Form>
      </MgpFormStyles>
    </Modal>
  )
}
