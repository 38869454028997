import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const StyledEventBlock = styled.section`
  width: 100%;
  position: relative;
  border-radius: 3px;
  border: 1px solid ${token("color.background.accent.gray.subtlest")};
  background: ${token("elevation.surface")};
  padding: ${token("space.200")};
  container-type: inline-size;
  margin-bottom: ${token("space.300")};

  @media (max-width: 1440px) {
    padding: ${token("space.200")} ${token("space.100")};
  }

  @media (max-width: 480px) {
    padding: ${token("space.200")};
    margin-bottom: ${token("space.200")};
  }
`;

export const StyledEventHeader = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${token("space.100")};

  @media (max-width: 1440px) {
    padding: 0 ${token("space.100")} ${token("space.100")} ${token("space.100")};
  }

  @media (max-width: 480px) {
    padding: 0 0 ${token("space.100")};
  }

  & > h3 {
    color: ${token("color.text")};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: 1440px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const StyledCalendar = styled.div`
  @media (min-width: 1920px) {
    padding-bottom: ${token("space.400")};
  }

  [data-testid="calendar--calendar"] {
    width: 100%;
  }

  [data-testid="calendar--week"] {
    margin-bottom: 0px;
  }

  [data-selected] {
    &.state_holiday {
      color: ${token("color.text.warning")};
      background-color: ${token("color.background.accent.orange.subtlest")};

      &:hover {
        color: ${token("color.text.warning")};
        background-color: ${token("color.background.accent.orange.subtlest")};
      }
    }

    &.corporate_event {
      color: ${token("color.text.accent.purple")};
      background-color: ${token("color.background.accent.purple.subtlest")};

      &:hover {
        color: ${token("color.text.accent.purple")};
        background-color: ${token("color.background.accent.purple.subtlest")};
      }
    }

    &.professional_holiday {
      color: ${token("color.text.accent.lime")};
      background-color: ${token("color.background.accent.lime.subtlest")};

      &:hover {
        color: ${token("color.text.accent.lime")};
        background-color: ${token("color.background.accent.lime.subtlest")};
      }
    }
  }

  button[data-testid="calendar--day"] {
    cursor: default;

    &:hover {
      background-color: #fff;
    }

    &[data-sibling] {
      color: ${token("color.text.disabled")} !important;
      background-color: transparent !important;
    }
  }

  button[data-testid="calendar--selected-day"] {
    cursor: default;

    &:hover {
      background-color: #fff;
    }

    &[data-sibling] {
      color: ${token("color.text.disabled")} !important;
      background-color: transparent !important;
    }
  }

  button[data-today="true"]:hover {
    color: ${token("color.text.selected")};
  }
`;

export const StyledEventsList = styled.div`
  display: grid;
  gap: ${token("space.150")};

  @media (max-width: 1440px) {
    padding: 0 ${token("space.100")};
  }
`;

export const StyledEventsListIcon = styled.div<{ $eventType: string }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  flex-shrink: 0;
  font-size: 0;

  ${(props) => {
    switch (props.$eventType) {
      case "state_holiday":
        return `& {
          background-color: ${token("color.background.accent.orange.subtlest")};
          color: ${token("color.background.accent.orange.subtler.pressed")};
        }`;

      case "corporate_event":
        return `& {
              background-color: ${token(
                "color.background.accent.purple.subtlest"
              )};
              color: ${token("color.background.accent.purple.subtlest.pressed")};
            }`;

      case "professional_holiday":
        return `& {
          background-color: ${token("color.background.accent.lime.subtlest")};
          color: ${token("color.icon.accent.lime")};
        }`;
      default:
        break;
    }
  }}
`;

export const StyledEventsListText = styled.div`
  flex-grow: 1;
  margin-left: ${token("space.150")};

  p {
    width: 55cqw;
    color: ${token("color.text.subtle")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (min-width: 1920px) {
      width: 60cqw;
      font-size: 16px;
    }
  }

  span {
    color: ${token("color.text.subtlest")};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    @media (min-width: 1920px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const StyledEventsListDate = styled.span`
  width: 64px;
  color: ${token("color.text.subtlest")};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  white-space: nowrap;

  @media (min-width: 1920px) {
    width: 82px;
    font-size: 14px;
    line-height: 20px;
  }
`;
