// TODO: fix eslint errors
/* eslint-disable */
import { useEffect, useState } from 'react'
import { xcss, Box } from '@atlaskit/primitives'
import PreferencesIcon from '@atlaskit/icon/glyph/preferences'
import { IconButton } from '@atlaskit/button/new'
import MultiSelectCustom from 'components/UI/MultiSelectCustom/MultiSelectCustom'
import styled from 'styled-components'

const MultiSelectCustomAlone = styled.div`
  padding: 4px;
  width: 216px;
  height: 300px;
  background-color: white;
  overflow: hidden;
  height: 360px;
  border-radius: 3px;
  box-shadow:
    0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 8px 12px 0px rgba(9, 30, 66, 0.15);

  .dropdown-content {
    border: none !important;
    box-shadow: none !important;
    width: 208px;
    .search {
      margin: 0px 4px 4px 5px;
    }
  }

  .panel-content {
    margin-left: -5px !important;
  }
`

type Option = {
  label: string
  value: string
  company?: string
}

export default function FilterToggle({
  options,
  value,
  onChange,
  onSelectClick,
}: {
  options: Option[]
  value: Option[]
  onChange: (arg: any) => void
  onSelectClick?: () => void
}) {
  const [openFilterByOrg, setOpenFilterByOrg] = useState(false)

  useEffect(() => {
    function closeFilter() {
      setOpenFilterByOrg(false)
    }

    window.addEventListener('click', closeFilter)

    return () => {
      window.removeEventListener('click', closeFilter)
    }
  })

  return (
    <>
      <Box>
        <IconButton
          label="button"
          appearance="subtle"
          isSelected={openFilterByOrg}
          icon={(iconProps) => (
            <PreferencesIcon {...iconProps} primaryColor={openFilterByOrg ? undefined : 'rgba(68, 84, 111, 1)'} />
          )}
          onClick={(e) => {
            if (onSelectClick) {
              onSelectClick()
            }
            if (openFilterByOrg === true) {
              e.stopPropagation()
              setOpenFilterByOrg((val) => !val)
            } else {
              setTimeout(() => {
                setOpenFilterByOrg((val) => !val)
              }, 1)
            }
          }}
        />
      </Box>
      {openFilterByOrg && (
        <Box
          xcss={xcss({
            position: 'absolute',
            inset: 'space.0',
            left: 'calc(100% - 216px)',
            top: '40px',
            zIndex: 'modal',
          })}
        >
          <MultiSelectCustomAlone className="multiSelectCustomAlone">
            <MultiSelectCustom
              hasSelectAll={false}
              isOpen
              options={options}
              onChange={onChange}
              value={value}
              placeholder="Org Unit"
              labelledBy="All Org Units"
            />
          </MultiSelectCustomAlone>
        </Box>
      )}
    </>
  )
}
