// TODO: Fix TS
import { createSlice } from '@reduxjs/toolkit'
import { TriggerNotificationDataType } from './types'

export const notificationsHandlerSlice = createSlice({
  name: 'notificationsHandler',
  initialState: [] as TriggerNotificationDataType[],
  reducers: {
    triggerNotification: (state, action) => {
      state.push(action.payload)
    },
    resetNotifications: () => [],
  },
})

export const { triggerNotification, resetNotifications } = notificationsHandlerSlice.actions
export const notificationsHandlerReducer = notificationsHandlerSlice.reducer
