import { DirectManagerResponseProps, DirectManagerProps } from './directManager.types'
import { ACTIVE_EMPLOYEES } from '../../../../constants'

const transformToADSSelectOptions = (options: Array<DirectManagerResponseProps>): Array<DirectManagerProps> =>
  options.map((option) => ({
    ...option,
    label: `${option.firstNameEn} ${option.lastNameEn}${option.nickname ? ` (${option.nickname})` : ''}`,
    value: option.id,
  }))

export const fetchDirectManagerAPI = async (idOpenProfile: string) => {
  try {
    const response = await fetch(ACTIVE_EMPLOYEES, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })

    const data = (await response.json()) as Array<DirectManagerResponseProps>

    return transformToADSSelectOptions(data.filter((directManager) => directManager.id !== idOpenProfile))
  } catch (error) {
    console.error(error)
    throw new Error()
  }
}

export const fetchActiveAPI = async () => {
  try {
    const response = await fetch(ACTIVE_EMPLOYEES, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })

    return (await response.json()) as Array<DirectManagerResponseProps>
  } catch (error) {
    console.error(error)
    throw new Error()
  }
}
