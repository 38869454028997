import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { ISupportModalProps, ISupportFormValues } from './types'
import { defaultValues } from './constants'
import { schema } from './schema'
import { Button, Flex, Form, Input, Modal, Row } from 'antd'
import { SUPPORT } from 'utils/constants/api'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SupportModalStyles } from './styles'
import { ErrorIcon, SuccessIcon } from 'components'
import { useEmployee } from 'contexts/EmployeeContext'

const { TextArea } = Input

export const SupportFormModal = ({ isOpen, onCancel }: ISupportModalProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const { employee } = useEmployee()

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<ISupportFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      email: employee?.email || '',
    },
  })

  const onSubmit: SubmitHandler<ISupportFormValues> = async (data) => {
    setLoading(true)
    try {
      const response = await fetch(SUPPORT, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          email: data.email,
          message: data.message,
        }),
      })
      if (response.status === 200) {
        setIsSuccess(true)
      } else {
        setIsError(true)
      }
    } catch (error) {
      Sentry.captureException(error)
      setIsError(true)
    } finally {
      reset()
      setLoading(false)
    }
  }

  const handleClose = () => {
    reset()
    setIsError(false)
    setIsSuccess(false)
    onCancel()
  }

  return (
    <Modal
      title="Support"
      open={isOpen}
      footer={null}
      onCancel={handleClose}
      destroyOnClose
      width={400}
      maskClosable={false}
    >
      <SupportModalStyles>
        {(isSuccess || isError) && (
          <>
            <Flex gap={8} vertical align="center">
              {isSuccess ? <SuccessIcon /> : <ErrorIcon />}
              <h3 className="response-title">{isSuccess ? 'Thanks!' : 'Oops! Something went wrong'}</h3>
              <p className="response-text">
                {isSuccess
                  ? 'Your feedback helps us continually improve our products. We’ll respond as soon as possible.'
                  : "Your message wasn't sent, please try again later."}
              </p>
            </Flex>
            <Row justify="end" className="response-footer">
              <Button type="primary" onClick={handleClose}>
                Got it
              </Button>
            </Row>
          </>
        )}

        {!isSuccess && !isError && (
          <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
            <Controller
              name="email"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <Form.Item
                  {...rest}
                  label="Email"
                  layout="vertical"
                  validateStatus={error ? 'error' : ''}
                  help={errors.email?.message}
                  initialValue={employee?.email}
                >
                  <Input placeholder="Enter your email" value={value} />
                </Form.Item>
              )}
            />
            <Controller
              name="message"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <Form.Item
                  {...rest}
                  label="Your message"
                  layout="vertical"
                  validateStatus={error ? 'error' : ''}
                  help={errors.message?.message}
                >
                  <TextArea rows={8} maxLength={1000} showCount value={value} />
                </Form.Item>
              )}
            />

            <Form.Item className="modal-footer">
              <Button type="default" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Send
              </Button>
            </Form.Item>
          </Form>
        )}
      </SupportModalStyles>
    </Modal>
  )
}
