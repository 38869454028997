// TODO: fix eslint errors
/* eslint-disable */
import { PROFESSIONAL_HOLIDAYS } from '../../../../../../utils/constants/api'
import { ProfessionalHolidayFromApi } from '../../../../../../shared/Holiday/ProfessionalHoliday/data/ProfessionalHolidayFromApi'
import ConfirmDeleteModal from '../../../../Layer/Modal/ConfirmDeleteModal'

type DeleteProfessionalHoliday = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  professionalHoliday: ProfessionalHolidayFromApi
}

function DeleteProfessionalHoliday(props: DeleteProfessionalHoliday) {
  function deleteProfessionalHoliday(): void {
    fetch(`${PROFESSIONAL_HOLIDAYS}/${props.professionalHoliday.id}`, {
      method: 'DELETE',
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else {
        response.json().then()
      }
    })
  }

  return (
    <ConfirmDeleteModal
      opened={props.opened}
      modalTitle="Delete Professional Holiday"
      cancelText="Cancel"
      deleteText={`<span class="what-to-delete">&laquo;${props.professionalHoliday.value.name}&raquo;</span>`}
      okText="Delete"
      onCancel={props.onClose}
      onOk={deleteProfessionalHoliday}
    />
  )
}

export default DeleteProfessionalHoliday
