// TODO: fix eslint errors
/* eslint-disable */
import {
  type ProviderProps,
  type EmployeeProps,
  type EmployeeType,
  type EmployeeContextType,
  type EmployeeContextProps,
  type RolesType,
} from './EmployeeContext.types'
import { FC, createContext, useState, useContext, useEffect } from 'react'
import { useEvent } from 'hooks/useEvent'
import { ROLE_UNAUTHORIZED } from './EmployeeContext.constants'
import { CALENDAR } from '../../utils/constants/api'

const EmployeeContext = createContext<EmployeeContextType>(null)

export const EmployeeContextProvider: FC<ProviderProps> = ({ children }) => {
  const [employee, setEmployee] = useState<EmployeeType>(null)
  const [roles, setRoles] = useState<RolesType>([])
  const [requestCounter, setRequestCounter] = useState<number>(0)

  const signIn = useEvent((employee: EmployeeType) => {
    if (employee) {
      setEmployee(employee)
      setRoles(employee.roles)
    } else {
      setRoles([ROLE_UNAUTHORIZED])
    }
  })

  const signOut = useEvent(() => {
    setEmployee(null)
    setRoles([])
  })

  const setAvatar = useEvent((url: string) => {
    setEmployee({
      ...employee!,
      imageUrl: url,
    })
  })

  function getRequests() {
    fetch(`${CALENDAR}/subordinate-requests-counter`)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
      })
      .then((data) => {
        if (Number.isInteger(data)) {
          setRequestCounter(data)
        }
      })
  }

  useEffect(() => {
    getRequests()
  }, [])

  return (
    <EmployeeContext.Provider
      value={{
        employee,
        roles,
        signIn,
        signOut,
        setAvatar,
        requestCounter,
        getRequests,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  )
}

export const useEmployee = () => useContext(EmployeeContext) as EmployeeContextProps

export const assertEmployee: (employee: EmployeeType) => asserts employee is EmployeeProps = (
  employee: EmployeeType,
): asserts employee is EmployeeProps => {
  if (!employee) throw new Error('Employee is null')
}
