import { useEffect, useRef, useState } from 'react'
import { NavProps } from '../Layout.types'
import { ReactComponent as LogoDesktop } from '../../../assets/images/logo/logo_core.svg'
import { ReactComponent as LogoMob } from '../../../assets/images/logo/logo_core_mob.svg'
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search'
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close'
import { AIStatusButton } from './ai-status-button'
import { CheckInOut } from './check-in-out'
import { User } from './User'
import MenuIcon from '@atlaskit/icon/glyph/menu'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import { IconButton } from '@atlaskit/button/new'
import { useNavigate } from 'react-router-dom'
import { MAIN } from 'navigation'
import SearchBar from 'components/Search/SearchBar/SearchBar'
import { Flex } from 'antd'
import { assertEmployee, useEmployee } from 'contexts/EmployeeContext'
import { useCheckInOut } from 'API/checkInOut/useCheckInOut'
import { StyledHeader, StyledHeaderMenuBtn, StyledHeaderSearch } from './styles'

export const Header = ({ navIsOpen, navSwitchStatus }: NavProps) => {
  const navigate = useNavigate()
  const { employee } = useEmployee()
  assertEmployee(employee)
  const { status } = useCheckInOut(employee.id)

  const toMain = () => {
    navigate(MAIN)
  }

  const input = useRef<HTMLInputElement>(null)
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false)

  useEffect(() => {
    searchIsOpen && input.current?.focus()
  })

  return (
    <StyledHeader $navIsOpen={navIsOpen}>
      <Flex className="header__left">
        <StyledHeaderMenuBtn onClick={navSwitchStatus}>
          <IconButton appearance="subtle" shape="circle" icon={navIsOpen ? CrossIcon : MenuIcon} label={navIsOpen ? 'Close Menu' : 'Open Menu'} />
        </StyledHeaderMenuBtn>

        <LogoDesktop className="logo" onClick={toMain} />
        <LogoMob className="logo--mob" onClick={toMain} />

        <StyledHeaderSearch $searchIsOpen={searchIsOpen}>
          <SearchBar $searchIsOpen={searchIsOpen} />

          <IconButton
            appearance="subtle"
            shape="circle"
            icon={searchIsOpen ? EditorCloseIcon : EditorSearchIcon}
            label="Search"
            onClick={() => {
              setSearchIsOpen(!searchIsOpen)
            }}
          />
        </StyledHeaderSearch>
      </Flex>

      <Flex className="header__right">
        <div className="header-actions">
          <AIStatusButton />
          {status && <CheckInOut />}
        </div>

        <div className="header-divider" />

        <div className="header-user">
          <User />
        </div>
      </Flex>
    </StyledHeader>
  )
}
