// TODO: fix eslint errors
/* eslint-disable */
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button/new'
import { Link } from 'react-router-dom'
import { Checkbox } from '@atlaskit/checkbox'
import { xcss, Box } from '@atlaskit/primitives'
import { ReactComponent as Stars } from '../../../../../assets/images/ProfileIcons/Stars.svg'
import styled from 'styled-components'
import { token } from '@atlaskit/tokens'

import { ReactElement, useEffect, useState, type FC, type ReactNode } from 'react'

const IconWrapper: FC<{
  children: ReactNode
}> = styled.div`
  padding-right: ${token('space.100')};
  svg path {
    fill: ${token('color.text.discovery')};
  }
`

export default function ModalContent3({
  setContent,
  setIsOpen,
  interviesID,
}: {
  setContent: (arg: number) => void
  setIsOpen: (arg: boolean) => void
  interviesID: string
}) {
  return (
    <div>
      <ModalHeader>
        <ModalTitle>
          <Box
            xcss={xcss({
              color: 'color.text',
              fontFamily: 'inherit',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            })}
          >
            AI аналіз особистості
          </Box>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Box
          xcss={xcss({
            color: 'color.text',
            fontFamily: 'inherit',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          })}
        >
          {' '}
          Ваше завдання — записати коротке відео, у якому ви відповісте на низку запитань, спрямованих на оцінку ваших
          особистісних якостей. <br />{' '}
          <Box
            xcss={xcss({
              paddingTop: 'space.200',
            })}
          >
            Рекомендації:
          </Box>
          <Box
            xcss={xcss({
              paddingTop: 'space.200',
            })}
          >
            <Box
              xcss={xcss({
                display: 'flex',
                paddingTop: 'space.100',
              })}
            >
              <Box>
                <IconWrapper>
                  <Stars />
                </IconWrapper>
              </Box>
              <Box>Знайдіть тихе та добре освітлене місце для запису відео.</Box>
            </Box>

            <Box
              xcss={xcss({
                display: 'flex',
                paddingTop: 'space.100',
              })}
            >
              <Box>
                <IconWrapper>
                  <Stars />
                </IconWrapper>
              </Box>
              <Box>Переконайтеся, що ваше обличчя чітко видно на відео.</Box>
            </Box>

            <Box
              xcss={xcss({
                display: 'flex',
                paddingTop: 'space.100',
              })}
            >
              <Box>
                <IconWrapper>
                  <Stars />
                </IconWrapper>
              </Box>
              <Box>Говоріть чітко та впевнено.</Box>
            </Box>

            <Box
              xcss={xcss({
                display: 'flex',
                paddingTop: 'space.100',
              })}
            >
              <Box>
                <IconWrapper>
                  <Stars />
                </IconWrapper>
              </Box>
              <Box>Відповідайте на питання чесно.</Box>
            </Box>

            <Box
              xcss={xcss({
                display: 'flex',
                paddingTop: 'space.100',
              })}
            >
              <Box>
                <IconWrapper>
                  <Stars />
                </IconWrapper>
              </Box>
              <Box>Перед відповіддю прочитайте кожне питання вголос.</Box>
            </Box>
          </Box>
          <Box>
            <Box
              xcss={xcss({
                paddingTop: 'space.200',
              })}
            >
              Приклад:
            </Box>
            <Box
              xcss={xcss({
                marginTop: 'space.150',
                height: '254px',
                overflow: 'hidden',
              })}
            >
              {/* <video controls width="466" height="254">
                <source
                  src="https://www.loom.com/share/ed0e89aecab54f3d98c4e9b080e6b37d"
                  type="video/mp4"
                />
              </video> */}
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '62.391681109185434%',
                  height: 0,
                }}
              >
                <iframe
                  src="https://www.loom.com/embed/ed0e89aecab54f3d98c4e9b080e6b37d?sid=d580e4f0-60be-442f-acbd-8731c889e050"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '254px',
                  }}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button
          appearance="subtle"
          onClick={() => {
            setIsOpen(false)
            setContent(1)
          }}
        >
          Відмінити
        </Button>
        <Button
          appearance="primary"
          onClick={() => {
            setContent(4)
          }}
        >
          Почати
        </Button>
      </ModalFooter>
    </div>
  )
}
