import styled from 'styled-components'

export const AIStatusButtonStyles = styled.div`
  .ai-btn {
    color: #531dab;
    border-color: #531dab;

    &:hover {
      color: #b37feb !important;
      border-color: #b37feb !important;
    }

    &.ant-popover-open {
      color: #22075e;
      border-color: #22075e;

      &:hover {
        color: #22075e !important;
        border-color: #22075e !important;
      }
    }
  }
`

export const AIPopupStyles = styled.div`
  display: grid;
  gap: 16px 0;
  padding: 4px;

  .ai-popup-title {
    color: var(--Components-Popover-Global-colorTextHeading, rgba(0, 0, 0, 0.88));
    font-size: var(--Components-Popover-Global-fontSize, 14px);
    font-style: normal;
    font-weight: var(--Components-Popover-Global-fontWeightStrong, 600);
    line-height: var(--Components-Popover-Global-lineHeight, 22px);
  }
`
