const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const EMPLOYEE = `${BASE_URL}/employee`
export const EMPLOYEES = `${BASE_URL}/employees`
export const ACCESS_LEVELS = `${BASE_URL}/employee/access-levels`
export const ACTIVE_EMPLOYEES = `${EMPLOYEES}/type/active`
export const JOB_TITLES = `${BASE_URL}/job-titles`
export const ORG_CHART = `${BASE_URL}/orgchart`
export const LEVELS = `${BASE_URL}/levels`
export const LOCATIONS = `${BASE_URL}/locations`

export const TIME_OFF_POLICIES = `${BASE_URL}/time-off-policies`
export const GENDERS = `${BASE_URL}/genders`
export const COUNTRIES = `${BASE_URL}/countries`
export const CALENDAR = `${BASE_URL}/calendar`

export const ACCRUED_DAYS_BALANCES = `${BASE_URL}/accrued-days-balances`

export const ATTENDANCE = `${BASE_URL}/attendance`
export const ATTENDANCE_EVENT = `${BASE_URL}/attendance/event`
export const WORK_TIME_CORRECTION = `${BASE_URL}/attendance/work-time-correction`

export const baseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}
