// TODO: fix eslint errors
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Modal from '../../../Layer/Modal/Modal'
import TextField, { Type } from 'components/old-ui/Input/TextField/TextField'
import Checkbox from 'components/old-ui/Input/Checkbox/Checkbox'
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError'
import { OrgUnitFromApi } from '../../../../../shared/OrgChart/data/OrgUnitFromApi'
import Select from 'components/old-ui/Input/SelectInput/SelectInput'
import { SelectValue } from '../../../../../shared/Common/data/SelectValue'

type BaseModal = {
  opened: boolean
  modalTitle: string
  name: string | null
  url: string | null
  selectedOrgUnits: OrgUnitFromApi[]
  orgUnits: SelectValue[]
  visible: boolean
  okText: string
  onCancel: () => any
  onOk: (
    text: string,
    url: string,
    orgUnits: string[],
    visible: boolean,
    validate: (errors: ValidationError[]) => void,
  ) => void
}

function BaseModal(props: BaseModal) {
  const orgUnitOptions = props.selectedOrgUnits.map(
    (orgUnitValue) =>
      ({
        label: orgUnitValue.name,
        value: orgUnitValue.id,
      }) as SelectValue,
  )
  const [name, setName] = useState<string>(props.name ?? '')
  const [url, setUrl] = useState<string>(props.url ?? '')
  const [nameError, setNameError] = useState<string | null>(null)
  const [visible, setVisible] = useState<boolean>(props.visible ?? true)
  const [urlError, setUrlError] = useState<string | null>(null)
  const [orgUnits, setOrgUnits] = useState<SelectValue[]>(props.orgUnits ?? orgUnitOptions)
  const [orgUnitsError, setOrgUnitsError] = useState<string | null>(null)

  function resetAll(): void {
    setName('')
    setUrl('')
    setVisible(true)
    setNameError(null)
    setUrlError(null)
    setOrgUnits(orgUnitOptions)
    setOrgUnitsError(null)
  }

  useEffect(() => {
    if (props.modalTitle === 'Create Link') {
      resetAll()
    }
    if (props.orgUnits.length === 0) {
      setOrgUnits(orgUnitOptions)
    }
  }, [props.opened])

  function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
      if (error.property === 'link.name') {
        setNameError(error.message)
      } else if (error.property === 'link.url') {
        setUrlError(error.message)
      } else if (error.property === 'link.orgUnits') {
        setOrgUnitsError(error.message)
      }
    })
  }

  function changeUrl(url: string) {
    const urlRegex = new RegExp(/https?:\/\//)
    if (urlRegex.test(url)) {
      setUrl(url)
    } else {
      const urlProtocolRegex = new RegExp(/^ht|^htt|^http|^https|^https?:|^https?:\/\//i)
      if (urlProtocolRegex.test(url)) {
        setUrl('https://')
      } else {
        setUrl(`https://${url}`)
      }
    }
  }

  return (
    <Modal
      opened={props.opened}
      modalTitle={props.modalTitle}
      cancelText="Cancel"
      okText={props.okText}
      onCancel={() => {
        props.onCancel()
        resetAll()
      }}
      onOk={() =>
        props.onOk(
          name,
          url,
          orgUnits.map((orgUnit) => orgUnit.value),
          visible,
          validate,
        )
      }
    >
      <TextField
        label="Name"
        placeholder="Name..."
        required
        value={name}
        type={Type.Textarea}
        horizontal
        error={nameError}
        onChange={(newName) => {
          setName(newName)
          setNameError(null)
          setOrgUnitsError(null)
        }}
        maxLength={50}
      />
      <Select
        label="Org Unit"
        required
        isMulti
        value={orgUnits}
        options={orgUnitOptions}
        error={orgUnitsError}
        onChange={(newOrgUnits: SelectValue[]) => {
          setOrgUnits(newOrgUnits)
          setOrgUnitsError(null)
        }}
        isSearchable={false}
      />
      <TextField
        label="URL"
        placeholder="Url..."
        required
        value={url}
        type={Type.Textarea}
        horizontal
        error={urlError}
        onChange={(newUrl) => {
          changeUrl(newUrl)
          setUrlError(null)
          setOrgUnitsError(null)
        }}
        maxLength={2083}
      />
      <Checkbox
        disabled={false}
        label="Visibility"
        required={null}
        checked={visible}
        checkBoxLabel="Shown"
        onChange={(newVisible) => setVisible(newVisible)}
        switch
      />
    </Modal>
  )
}

export default BaseModal
