import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultEmployeeRateHistoryTableFilters, defaultEmployeeRateHistoryTableState } from './constants'
import { EmployeeRateHistoryTableFilters, EmployeeRateHistoryTableState } from './types'

export const employeeRateHistoryTableFiltersSlice = createSlice({
  name: 'employeeRateHistoryTableFilters',
  initialState: {
    tableState: defaultEmployeeRateHistoryTableState,
    tableFilters: defaultEmployeeRateHistoryTableFilters,
  },
  reducers: {
    setEmployeeRateHistoryTableState: (state, action: PayloadAction<EmployeeRateHistoryTableState>) => ({
      ...state,
      tableState: action.payload,
    }),
    setEmployeeRateHistoryFilters: (state, action: PayloadAction<EmployeeRateHistoryTableFilters>) => ({
      tableFilters: { ...state.tableFilters, ...action.payload },
      tableState: defaultEmployeeRateHistoryTableState,
    }),
    resetEmployeeRateHistoryTableState: (state) => ({
      ...state,
      tableState: defaultEmployeeRateHistoryTableState,
    }),
    resetEmployeeRateHistoryFilters: () => ({
      tableFilters: defaultEmployeeRateHistoryTableFilters,
      tableState: defaultEmployeeRateHistoryTableState,
    }),
  },
})

export const {
  setEmployeeRateHistoryTableState,
  setEmployeeRateHistoryFilters,
  resetEmployeeRateHistoryTableState,
  resetEmployeeRateHistoryFilters,
} = employeeRateHistoryTableFiltersSlice.actions

export const employeeRateHistoryTableFiltersReducer = employeeRateHistoryTableFiltersSlice.reducer
