import { currencyFormatter } from 'utils/helpers/currency-formatter'
import { TooltipInfo } from './components'
import { EmployeeCompensationData } from 'services/api'
import { CompensationListConfig } from './types'

export const compensationListConfig = (data: EmployeeCompensationData): CompensationListConfig[] => [
  {
    label: 'Current rate',
    value: data.rate ? `${data.rate.currency} ${currencyFormatter(data.rate.value)}` : '-',
  },
  {
    label: 'Current MGP',
    value: data.mgp ? `${data.mgp.currency} ${currencyFormatter(data.mgp.value)}` : '-',
    action: <TooltipInfo title="Minimum guaranteed payment" />,
  },
]
