import styled from 'styled-components'

export const BonusPenaltyFormModalStyles = styled.div`
  form {
    display: grid;
  }

  .employee-card-wrap {
    margin: 16px 0;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-select .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }

  .ant-radio-group.invalid {
    .ant-radio-button-wrapper {
      border: 1px solid #ff4d4f;
    }
  }

  .modal-footer {
    padding: 12px 0 0;
  }
`
