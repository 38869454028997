import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Box, xcss } from '@atlaskit/primitives'

import CalendarIcon from '@atlaskit/icon/glyph/calendar'
import Button from '@atlaskit/button'
import styled from 'styled-components'
import { DatePicker } from '@atlaskit/datetime-picker'

import { addDays } from 'date-fns'

const Wrapper: FC<{
  children: ReactElement
}> = styled.div`
  ${() => `
   .customSelect>div[class$='control']{
   display:none !important;
   }
  
  `}
`

export default function CustomDatePicker({
  selectedDate,
  onChange,
}: {
  selectedDate: Date
  onChange: (_value: string) => void
}) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    function onBlur() {
      setOpen(false)
    }

    window.addEventListener('click', onBlur)

    return () => {
      window.removeEventListener('click', onBlur)
    }
  }, [])

  function showDateTitle() {
    let title = ''

    if (
      selectedDate.toLocaleString('en-us', { month: 'short' }) ===
      addDays(selectedDate, 30).toLocaleString('en-us', {
        month: 'short',
      })
    ) {
      title = `${selectedDate.toLocaleString('en-us', { month: 'long' })} ${selectedDate.getFullYear()}`
    } else if (selectedDate.getFullYear() === addDays(selectedDate, 30).getFullYear()) {
      title = `${selectedDate.toLocaleString('en-us', { month: 'short' })} - ${addDays(selectedDate, 30).toLocaleString(
        'en-us',
        {
          month: 'short',
        },
      )} ${addDays(selectedDate, 30).getFullYear()}`
    } else {
      title = `${selectedDate.toLocaleString('en-us', { month: 'short' })} ${selectedDate.getFullYear()} - ${addDays(
        selectedDate,
        30,
      ).toLocaleString('en-us', {
        month: 'short',
      })} ${addDays(selectedDate, 30).getFullYear()}`
    }
    return title
  }

  return (
    <Wrapper>
      <>
        <Button
          appearance="subtle"
          onClick={(e) => {
            e.stopPropagation()
            setOpen((val) => !val)
          }}
          style={
            open
              ? {
                  backgroundColor: '#dcdfe4',
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                }
              : { display: 'flex', alignItems: 'center', padding: 0 }
          }
        >
          <Box
            xcss={xcss({
              color: 'color.text.subtle',
              fontFamily: 'inherit',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '10px',
              paddingRight: 'space.150',
            })}
          >
            <CalendarIcon label="calendar" primaryColor="#44546F" />
            <Box xcss={xcss({ marginLeft: 'space.050' })}>{showDateTitle()}</Box>
          </Box>
        </Button>
        <Box
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation()
          }}
        >
          {open ? (
            <DatePicker
              weekStartDay={1}
              value={selectedDate ? selectedDate.toISOString() : new Date().toISOString()}
              isOpen={open}
              selectProps={{ className: 'customSelect' }}
              onChange={(val) => {
                setOpen(false)
                onChange(val)
              }}
            />
          ) : null}
        </Box>{' '}
      </>
    </Wrapper>
  )
}
