import { useState } from 'react'
import { useEvent } from 'hooks/useEvent'
import { signIn as fetchSignIn } from './signIn'
import * as Sentry from '@sentry/react'

export const useSignIn = () => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const [isError, setError] = useState<boolean>(false)

  const resetStatuses = useEvent(() => {
    setLoading(false)
    setSuccess(false)
    setError(false)
  })

  const signIn = useEvent(async (email: string, password: string) => {
    setLoading(true)
    try {
      const response = await fetchSignIn(email, password)
      switch (response.status) {
        case 401:
          if (response.data.error === 'Employee is not allowed to sign in') {
            return 403
          }
          if (response.data.error === 'Wrong email or password') {
            return 400
          }
          break

        case 500:
          break

        default:
          setSuccess(true)
          return response.data
      }
      setError(true)
    } catch (error) {
      Sentry.captureException(error)
      setError(true)
    } finally {
      setLoading(false)
    }
  })

  return {
    isLoading,
    isSuccess,
    isError,
    signIn,
    resetStatuses,
  }
}
