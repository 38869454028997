import styled from 'styled-components'
import backgroundImg from '../../assets/images/landing/background.svg'

export const AuthPageStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 48px 16px;
  background: url(${backgroundImg}) center no-repeat;
  background-color: rgba(233, 242, 255, 1);
  background-size: cover;
  width: 100%;
  min-height: 100%;
  z-index: 1;

  .content {
    max-width: 100%;
    position: relative;
    z-index: 3;
    background-color: #fff;
    padding: 24px;
    border-radius: 3px;
    width: 450px;
    box-shadow:
      0 8px 12px 0 rgba(9, 30, 66, 0.15),
      0 0 1px 0 rgba(9, 30, 66, 0.31);

    @media (max-width: 480px) {
      box-shadow:
        0 1px 1px 0 rgba(9, 30, 66, 0.25),
        0 0 1px 0 rgba(9, 30, 66, 0.31);
    }
  }

  .logWrapper {
    margin-bottom: 11px;

    > img {
      display: block;
      width: 168px;
      margin: auto;
    }
  }

  .sendWrapper {
    margin-top: 16px;
  }

  .sendBtn {
    min-height: 36px;
    align-items: center !important;
    width: 100% !important;
  }

  .googleBtn {
    min-height: 36px;
    align-items: center !important;
    border: 1px solid #dcdfe4 !important;
  }

  .signInWithGoogleText {
    color: #626f86;
  }

  .googleWrapContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .googleIcon {
    line-height: 0;

    > svg {
      width: 18px;
      height: 18px;
    }
  }

  .linkBtn {
    align-items: center !important;

    > span:first-child {
      margin: 0 !important;
    }
  }

  .labelContainer {
    --ds-font-weight-semibold: 590;
  }

  .inputContainer {
    margin-top: 8px;
  }

  .support-btn {
    position: absolute;
    inset: auto 64px 84px auto;
    width: 164px;
    color: rgba(0, 0, 0, 0.88);
    background-color: #fff;
    border-radius: 3px;
    box-shadow:
      0px 0px 1px 0px rgba(9, 30, 66, 0.12),
      0px 0px 8px 0px rgba(9, 30, 66, 0.16);

    &:hover {
      background-color: #f0f0f0 !important;
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        var(--Colors-Neutral-Fill-colorFilledHandleBg, #f0f0f0) !important;
    }

    &__icon {
      font-size: 20px;
      color: #44546f;
    }

    @media (max-width: 1024px) {
      inset: auto auto 5% 16px;
    }
  }
`
