// TODO: fix eslint errors
/* eslint-disable */
import React, { useState, useEffect, FC } from 'react'
import './Holidays.scss'
import SubHeaderFilterBlock from './SubHeaderFilterBlock'
import { ProfessionalHolidays } from './ProfessionalHolidays/ProfessionalHolidays'
import { CorporateEvents } from './CorporateEvents/CorporateEvents'
import { StateHolidays } from './StateHolidays/StateHolidays'
import { COUNTRIES, LOCATIONS } from '../../../../utils/constants/api'
import { LocationFromApi } from '../Locations/Locations'
import { CountryFromApi } from '../../../../shared/Location/data/CountryFromApi'
import { SelectValue } from '../../../../shared/Common/data/SelectValue'
import Select from 'components/old-ui/Input/SelectInput/SelectInput'
import { useLocation } from 'react-router-dom'

export const Holidays: FC = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  let yearParam = searchParams.get('year')
  if (yearParam && parseInt(yearParam) > new Date().getFullYear() + 1) {
    yearParam = (new Date().getFullYear() + 1).toString()
    searchParams.set('year', yearParam)
    const updatedURL = `${location.pathname}?${searchParams.toString()}`
    window.history.replaceState(null, '', updatedURL)
  } else if (yearParam && parseInt(yearParam) < new Date().getFullYear() - 1) {
    yearParam = (new Date().getFullYear() - 1).toString()
    searchParams.set('year', yearParam)
    const updatedURL = `${location.pathname}?${searchParams.toString()}`
    window.history.replaceState(null, '', updatedURL)
  }

  const emptySelectValue = { label: '', value: '' } as SelectValue
  const [activeIndex, setActiveIndex] = useState(0)
  const [selectedYear, setSelectedYear] = useState<string>(yearParam || new Date().getFullYear().toString())
  const [locations, setLocations] = useState<LocationFromApi[]>([])
  const [countries, setCountries] = useState<SelectValue[]>([])
  const [country, setCountry] = useState<SelectValue>(emptySelectValue)

  const handleLiClick = (index: React.SetStateAction<number>) => {
    setActiveIndex(index)
    getContentComponent()
  }

  const handleYearChange = (newYear: string) => {
    setSelectedYear(newYear)
    getContentComponent()
  }

  const getActiveClass = (index: number) => (index === activeIndex ? 'active' : '')

  function getLocations() {
    fetch(LOCATIONS, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((locations: LocationFromApi[]) => setLocations(locations))
  }

  function getCountries() {
    fetch(COUNTRIES, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((countries: CountryFromApi[]) =>
        setCountries(
          countries
            .filter((country) => locations.some((location) => location.value.countryCode === country.code))
            .map((country, index) => {
              if (country.code === 'UA' || index === 0) {
                setCountry({
                  label: country.name,
                  value: country.code,
                })
              }
              return {
                label: country.name,
                value: country.code,
              }
            }),
        ),
      )
  }

  useEffect(getLocations, [])

  useEffect(() => {
    getCountries()
  }, [locations])

  const getContentComponent = () => {
    switch (activeIndex) {
      case 0:
        return <StateHolidays year={selectedYear} country={country.value} />
      case 1:
        return <ProfessionalHolidays year={selectedYear} />
      case 2:
        return <CorporateEvents year={selectedYear} />
      default:
        return null
    }
  }
  return (
    <div className="sub-content-block holidays-main-block d-flex">
      <div className="sub-content-header d-flex">
        <div className="sub-title-block d-flex">
          <h3>Holidays {countries && activeIndex === 0 ? 'in' : ''}</h3>
          {countries && activeIndex === 0 && (
            <div className="country-filter">
              <Select
                label=""
                required={false}
                error=""
                isMulti={false}
                value={country}
                options={countries}
                onChange={(e: any) => {
                  setCountry(e)
                }}
                isSearchable={false}
              />
            </div>
          )}
        </div>
        <SubHeaderFilterBlock selectedYear={selectedYear} onYearChange={handleYearChange} />
      </div>
      <div className="sub-content-main pad65">
        <ul className="holiday-menu">
          <li className={getActiveClass(0)} onClick={() => handleLiClick(0)}>
            State Holidays
          </li>
          <li className={getActiveClass(1)} onClick={() => handleLiClick(1)}>
            Professional Holidays
          </li>
          <li className={getActiveClass(2)} onClick={() => handleLiClick(2)}>
            Corporate Events
          </li>
        </ul>
        <div className="content holidays-content">{getContentComponent()}</div>
      </div>
    </div>
  )
}
