// TODO: fix eslint errors
/* eslint-disable */
import { useState } from 'react'
import { AccessLevelOptionsProps } from './accessLevel.types'
import { useQuery } from '../../../../useQuery'
import { fetchAccessLevelAPI, fetchCurrentAccessLevelAPI, addAccessLevelAPI, deleteAccessLevelAPI } from './accessLevel'
import { useEvent } from 'hooks/useEvent'

export const useAccessLevel = (idCurrentProfile: string) => {
  const [accessLevel, setAccessLevel] = useState<Array<AccessLevelOptionsProps>>([])
  const [getAllAccessLevel, isLoading, isSuccess, isError] = useQuery(fetchAccessLevelAPI)

  const [currentAccessLevel, setCurrentAccessLevel] = useState<Array<AccessLevelOptionsProps>>()
  const [getCurrentAccessLevel, isLoadingCurrent, isSuccessCurrent, isErrorCurrent] =
    useQuery(fetchCurrentAccessLevelAPI)

  const [addAccessLevelA, isLoadingAdd, isSuccessAdd, isErrorAdd] = useQuery(addAccessLevelAPI)
  const [deleteAccessLevelA, isLoadingDelete, isSuccessDelete, isErrorDelete] = useQuery(deleteAccessLevelAPI)

  const fetchAccessLevel = useEvent(async () =>
    getAllAccessLevel().then((response) => {
      setAccessLevel(response)
      return response
    }),
  )

  const fetchCurrentAccessLevel = useEvent(() =>
    getCurrentAccessLevel(idCurrentProfile).then((response) => {
      setCurrentAccessLevel(response)
      return response
    }),
  )

  const addAccessLevel = useEvent((addIds: Array<string>) => addAccessLevelA(idCurrentProfile, addIds))

  const deleteAccessLevel = useEvent((deleteIds: Array<string>) => deleteAccessLevelA(idCurrentProfile, deleteIds))

  return {
    accessLevel,
    fetchAccessLevel,
    isLoading,
    isSuccess,
    isError,

    currentAccessLevel,
    fetchCurrentAccessLevel,

    addAccessLevel,
    deleteAccessLevel,
  }
}
