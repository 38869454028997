// TODO: fix eslint errors
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Modal from '../../../Layer/Modal/Modal'
import TextField, { Type } from 'components/old-ui/Input/TextField/TextField'
import SelectInput from 'components/old-ui/Input/SelectInput/SelectInput'
import Checkbox from 'components/old-ui/Input/Checkbox/Checkbox'
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError'
import { LocationFromApi } from '../../Locations/Locations'
import { SelectValue } from '../../../../../shared/Common/data/SelectValue'
import { getGMTOffsetByTimezoneName } from '../../../../Common/Timezones/Services'

type BaseModal = {
  opened: boolean
  modalTitle: string
  text: string | null
  locations: SelectValue[]
  selectedLocations: LocationFromApi[]
  visible: boolean
  okText: string
  onCancel: () => any

  onOk: (text: string, locations: string[], visible: boolean, validate: (errors: ValidationError[]) => void) => void
}

function BaseModal(props: BaseModal) {
  const locationOptions = props.selectedLocations.map(
    (locationValue) =>
      ({
        label: `${locationValue.name} ${getGMTOffsetByTimezoneName(locationValue.value.timezone)}`,
        value: locationValue.id,
      }) as SelectValue,
  )

  const [text, setText] = useState<string>(props.text ?? '')
  const [textError, setTextError] = useState<string | null>(null)

  const [locations, setLocations] = useState<SelectValue[]>(props.locations ?? locationOptions)
  const [locationsError, setLocationsError] = useState<string | null>(null)

  const [visible, setVisible] = useState<boolean>(props.visible ?? true)

  function resetAll(): void {
    setText('')
    setLocations(locationOptions)
    setLocationsError(null)
    setVisible(true)
    setTextError(null)
  }

  function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
      if (error.property === 'randomPhrase.text') {
        setTextError(error.message)
      } else if (error.property === 'randomPhrase.locations' || error.property === 'randomPhrase.dateAndLocation') {
        setLocationsError(error.message)
      }
    })
  }

  useEffect(() => {
    if (props.modalTitle === 'Create Phrase') {
      resetAll()
    }
    if (props.locations.length === 0) {
      setLocations(locationOptions)
    }
  }, [props.opened])

  return (
    <Modal
      opened={props.opened}
      modalTitle={props.modalTitle}
      cancelText="Cancel"
      okText={props.okText}
      onCancel={() => {
        props.onCancel()
        resetAll()
      }}
      onOk={() =>
        props.onOk(
          text,
          locations.map((location) => location.value),
          visible,
          validate,
        )
      }
    >
      <TextField
        label="Text"
        placeholder="Text..."
        required
        value={text}
        type={Type.Textarea}
        horizontal
        error={textError}
        onChange={(newText) => {
          setText(newText)
          setTextError(null)
          setLocationsError(null)
        }}
        maxLength={300}
      />
      <SelectInput
        label="Location"
        required
        isMulti
        value={locations}
        options={locationOptions}
        error={locationsError}
        onChange={(newLocations: SelectValue[]) => {
          setLocations(newLocations)
          setLocationsError(null)
        }}
        isSearchable={false}
      />
      <Checkbox
        disabled={false}
        label="Visibility"
        required={null}
        checked={visible}
        onChange={(newVisible) => setVisible(newVisible)}
        checkBoxLabel="Shown"
        switch
      />
    </Modal>
  )
}

export default BaseModal
