import { Box, xcss } from '@atlaskit/primitives'
import ManagerLinkButton from './ManagerLinkButton'
import { TDirectManager } from './types'

export default function ModalContentNoAIButton({ directManager }: { directManager: TDirectManager }) {
  return (
    <>
      <Box
        xcss={xcss({
          color: 'color.text.subtle',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          width: '348px',
        })}
      >
        {`Your analysis status will appear here as soon as it is assigned by the ${directManager.id ? 'manager' : 'HR manager'}.`}
      </Box>
      <Box>{directManager.id && <ManagerLinkButton directManager={directManager} />}</Box>
    </>
  )
}
