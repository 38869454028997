// TODO: fix eslint errors
/* eslint-disable */
import ConfirmDeleteModal from '../../../../../Layer/Modal/ConfirmDeleteModal'
import { EmployeeFromApi } from '../../../../../../../shared/Employee/data/EmployeeFromApi'
import { EMPLOYEES } from '../../../../../../../utils/constants/api'

type RehireEmployee = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  employee: EmployeeFromApi
}

function RehireEmployee(props: RehireEmployee) {
  function rehireEmployee(): void {
    fetch(`${EMPLOYEES}/employee/${props.employee.id}/rehired`, {
      method: 'POST',
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else {
        response.json().then((jsonData) => alert(jsonData.error))
      }
    })
  }

  return (
    <ConfirmDeleteModal
      opened={props.opened}
      modalTitle="Rehire Employee"
      cancelText="Cancel"
      deleteText={`${props.employee.firstNameEn} ${props.employee.lastNameEn}`}
      okText="Rehire"
      onCancel={props.onClose}
      onOk={rehireEmployee}
      isRehire
    />
  )
}

export default RehireEmployee
