// TODO: fix eslint errors
/* eslint-disable */
import { useEffect, useState } from 'react'
import Modal from '../../../../Layer/Modal/Modal'
import TextField, { Type } from 'components/old-ui/Input/TextField/TextField'
import SelectInput from 'components/old-ui/Input/SelectInput/SelectInput'
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError'
import { SelectValue } from '../../../../../../shared/Common/data/SelectValue'
import DatePicker from 'components/old-ui/Input/DatePicker/DatePicker'
import Radio from 'components/old-ui/Input/Radio/Radio'
import Checkbox from 'components/old-ui/Input/Checkbox/Checkbox'
import { OrgUnitFromApi } from '../../../../../../shared/OrgChart/data/OrgUnitFromApi'
import { LocationFromApi } from '../../../Locations/Locations'
import { getGMTOffsetByTimezoneName } from '../../../../../Common/Timezones/Services'
import dayjs from 'dayjs'

type BaseModal = {
  opened: boolean
  modalTitle: string
  name: string | null
  locations: SelectValue[]
  orgUnits: SelectValue[]
  locationValues: LocationFromApi[]
  orgUnitValues: OrgUnitFromApi[]
  date: Date | null
  dayOff: string
  paidTimeOff: string
  text: string | null
  visible: boolean
  archiveAfterFinish: boolean
  okText: string
  onCancel: () => any
  onOk: (
    name: string,
    orgUnits: string[],
    locations: string[],
    date: Date,
    dayOff: boolean,
    paidTimeOff: boolean,
    text: string,
    visible: boolean,
    archiveAfterFinish: boolean,
    validate: (errors: ValidationError[]) => void,
  ) => void
  isSent?: boolean
}

function BaseModal(props: BaseModal) {
  const orgUnitOptions = props.orgUnitValues.map(
    (orgUnitValue) =>
      ({
        label: orgUnitValue.name,
        value: orgUnitValue.id,
      }) as SelectValue,
  )
  const locationOptions = props.locationValues.map(
    (locationValue) =>
      ({
        label: `${locationValue.name} ${getGMTOffsetByTimezoneName(locationValue.value.timezone)}`,
        value: locationValue.id,
      }) as SelectValue,
  )
  const [name, setName] = useState<string>(props.name || '')
  const [orgUnits, setOrgUnits] = useState<SelectValue[]>(props.okText == 'Edit' ? props.orgUnits : orgUnitOptions)
  const [locations, setLocations] = useState<SelectValue[]>(props.okText == 'Edit' ? props.locations : locationOptions)
  const [date, setDate] = useState<Date | null>(props.date ? dayjs(props.date).toDate() : null)
  const [dayOff, setDayOff] = useState<string>(props.dayOff)
  const [paidTimeOff, setPaidTimeOff] = useState<string>(props.paidTimeOff)
  const [text, setText] = useState<string>(props.text || '')
  const [visible, setVisible] = useState<boolean>(props.visible)
  const [archiveAfterFinish, setArchiveAfterFinish] = useState<boolean>(props.archiveAfterFinish)

  const [nameError, setNameError] = useState<string | null>(null)
  const [orgUnitError, setOrgUnitError] = useState<string | null>(null)
  const [locationError, setLocationError] = useState<string | null>(null)
  const [dateError, setDateError] = useState<string | null>(null)
  const [textError, setTextError] = useState<string | null>(null)

  useEffect(() => {
    if (props.modalTitle === 'Create Corporate Event') {
      resetAll()
    }
    if (props.orgUnits.length === 0 && props.okText != 'Edit') {
      setOrgUnits(orgUnitOptions)
    }
    if (props.locations.length === 0 && props.okText != 'Edit') {
      setLocations(locationOptions)
    }
  }, [props.opened])

  function resetAll(): void {
    setName('')
    setOrgUnits([])
    setLocations([])
    setDate(null)
    setDayOff('false')
    setPaidTimeOff('false')
    setText('')
    setVisible(true)
    setArchiveAfterFinish(false)
    setNameError(null)
    setLocationError(null)
    setOrgUnitError(null)
    setDateError(null)
    setTextError(null)
  }

  function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
      if (!date) {
        console.log('Hey, date is absent!')
      }
      if (error.property === 'corporateEvent.name') {
        setNameError(error.message)
      } else if (error.property === 'corporateEvent.locations') {
        setLocationError(error.message)
      } else if (error.property === 'corporateEvent.orgUnits') {
        setOrgUnitError(error.message)
      } else if (error.property === 'corporateEvent.text') {
        setTextError(error.message)
      } else if (error.property === 'corporateEvent.date') {
        setDateError(error.message)
      }
    })
  }

  function clearErrors() {
    setNameError(null)
    setLocationError(null)
    setOrgUnitError(null)
    setTextError(null)
    setDateError(null)
  }

  function handleNameChange(newName: string): void {
    setName(newName)
    setNameError(null)
  }

  function handleOrgUnitsChange(newOrgUnits: SelectValue[]): void {
    setOrgUnits(newOrgUnits)
    setOrgUnitError(null)
  }

  function handleLocationsChange(newLocations: SelectValue[]): void {
    setLocations(newLocations)
    setLocationError(null)
  }

  function handleDateChange(newDate: Date): void {
    setDate(newDate)
    setDateError(null)
  }

  function handleDayOffChange(newDayOff: string): void {
    setDayOff(newDayOff)
  }

  function handlePaidTimeOffChange(newPaidTimeOff: string): void {
    setPaidTimeOff(newPaidTimeOff)
  }

  function handleTextChange(newText: string): void {
    setText(newText)
    setTextError(null)
  }

  function handleVisibleChange(newVisible: boolean): void {
    setVisible(newVisible)
  }

  function handleArchiveAfterFinish(newValue: boolean): void {
    setArchiveAfterFinish(newValue)
  }

  function addOneDay(): Date {
    const newDate = new Date()
    newDate.setDate(newDate.getDate() + 1)
    return newDate
  }

  function checkDate(date: Date | null): Date {
    if (!date) {
      setDateError('Date is required')
    }
    return date as Date
  }

  return (
    <Modal
      isDisabled={date ? date <= new Date() : false}
      opened={props.opened}
      modalTitle={props.modalTitle}
      cancelText="Cancel"
      okText={props.okText}
      onCancel={() => {
        props.onCancel()
        resetAll()
      }}
      onOk={() =>
        props.onOk(
          name,
          orgUnits.map((orgUnit) => orgUnit.value),
          locations.map((location) => location.value),
          checkDate(date),
          dayOff === 'true',
          paidTimeOff === 'true',
          text,
          visible,
          archiveAfterFinish,
          validate,
        )
      }
    >
      <TextField
        label="Name"
        placeholder="Please enter event's name"
        required
        value={name}
        type={Type.Textarea}
        horizontal
        error={nameError}
        onChange={(newName) => {
          handleNameChange(newName)
        }}
        onClick={() => clearErrors()}
        maxLength={100}
      />
      <SelectInput
        label="Org Units"
        isSearchable
        required
        isMulti
        value={orgUnits}
        options={orgUnitOptions}
        error={orgUnitError}
        onChange={(newOrgUnits) => {
          handleOrgUnitsChange(newOrgUnits)
        }}
        onFocus={() => {
          clearErrors()
        }}
      />
      <SelectInput
        label="Locations"
        isSearchable
        required
        isMulti
        value={locations}
        options={locationOptions}
        error={locationError}
        onChange={(newLocations) => {
          handleLocationsChange(newLocations)
        }}
        onFocus={() => {
          clearErrors()
        }}
      />
      <div>
        <DatePicker
          date={date}
          minDate={addOneDay()}
          maxDate={null}
          label="Date"
          onChange={(newDate) => {
            handleDateChange(newDate)
          }}
          onClick={() => clearErrors()}
          horizontal
          required
          error={dateError}
          disabled={date ? date < new Date() : false}
        />
      </div>
      <Radio
        label="Day Off"
        required={false}
        name={`dayOff${props.okText}`}
        value={dayOff}
        onChange={(newDayOff) => handleDayOffChange(newDayOff)}
        radios={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
      />
      <div style={{ display: dayOff == 'true' ? 'block' : 'none' }}>
        <Radio
          label="Paid Time Off"
          required={false}
          name={`paidTimeOff${props.okText}`}
          value={paidTimeOff}
          onChange={(newPaidTimeOff) => handlePaidTimeOffChange(newPaidTimeOff)}
          radios={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
        />
      </div>
      <TextField
        label="Text"
        placeholder="Please enter text"
        required={false}
        value={text}
        type={Type.Textarea}
        horizontal
        error={textError}
        onChange={(newText) => {
          handleTextChange(newText)
        }}
        onClick={() => clearErrors()}
        maxLength={150}
      />
      <Checkbox
        disabled={false}
        label="Visibility"
        required={false}
        checked={visible}
        checkBoxLabel="Shown"
        onChange={(newShow) => handleVisibleChange(newShow)}
        switch
      />
      <Checkbox
        disabled={false}
        label=""
        required={false}
        checked={archiveAfterFinish}
        checkBoxLabel="Move to archive after finished"
        onChange={(newValue) => handleArchiveAfterFinish(newValue)}
        switch={false}
      />
    </Modal>
  )
}

export default BaseModal
