import { getFormattedDate } from 'utils/helpers/formatted-date'
import { currencyFormatter } from 'utils/helpers/currency-formatter'
import { EmployeeMgpHistory } from 'models'
import { tableColumnsMap } from './constants'
import { EmployeeMGPHistoryTableConfig, GetTableColumnsProps } from './types'
import { TableAction } from './components'
import { SmileOutlined } from '@ant-design/icons'

export const getTableColumns = ({ isVisibleAmountValue }: GetTableColumnsProps): EmployeeMGPHistoryTableConfig => {
  const config: EmployeeMGPHistoryTableConfig = [
    {
      title: 'Start date',
      width: '40%',
      dataIndex: tableColumnsMap.date,
      render: (startDate: EmployeeMgpHistory['date']) => <span>{getFormattedDate(startDate, 'coreHRDateFormat')}</span>,
      sorter: true,
    },
    {
      title: 'Amount',
      width: '40%',
      dataIndex: tableColumnsMap.amount,
      render: (amount: EmployeeMgpHistory['amount'], { currency }) => (
        <>{isVisibleAmountValue ? `${currency.symbol} ${currencyFormatter(amount)}` : <SmileOutlined />}</>
      ),
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: unknown, record: EmployeeMgpHistory) => <TableAction record={record} />,
    },
  ]
  return config
}
