import styled from 'styled-components'

export const RequestInfoStyles = styled.div`
  display: flex;
  justify-content: space-between;

  .anticon {
    svg {
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .anticon.active-icon {
    svg {
      color: #579dff;
    }
  }

  .ant-popover-content {
    .ant-popover-inner {
      padding: 16px;
      border-radius: 3px;
      box-shadow: 0px 2px 4px 0px rgba(0, 35, 11, 0.2);
    }

    .content {
      display: flex;
      flex-direction: column;
      color: #172b4d;
      gap: 8px;

      .content-row {
        display: grid;
        grid-template-columns: 98px 1fr;
        grid-column-gap: 18px;
      }

      .label {
        font-weight: 600;
      }
    }
  }
`
