// TODO: fix eslint errors
/* eslint-disable */
import { MultiSelect } from 'react-multi-select-component'
import styled from 'styled-components'
import { Box, xcss } from '@atlaskit/primitives'
import Tag from '@atlaskit/tag'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import { Checkbox } from '@atlaskit/checkbox'
import Avatar from '@atlaskit/avatar'
import { FC, ReactElement } from 'react'
import Spinner from '@atlaskit/spinner'

const Wrapper: FC<{
  children: ReactElement
  $contentWidth: number | undefined
  $selectWidth: number | undefined
  $selectHeight: number | undefined
  $dropdownHorizontalDisplacement: string | undefined
  onClick: (arg: any) => void
  $hideSearch: boolean
}> = styled.div`
  .clear-selected-button {
    display: none !important;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(102, 102, 102, 0.4);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .multi-select {
    width: ${(props) => (props.$selectWidth ? `${props.$selectWidth}px` : '204px')};
  }

  .dropdown-container:focus-within {
    box-shadow: none;
    border: 2px solid #388bff !important;
  }

  .dropdown-container {
    border: 2px solid rgba(9, 30, 66, 0.14);
    box-sizing: content-box;
  }

  .dropdown-container,
  .dropdown-heading {
    width: ${(props) => (props.$selectWidth ? `${props.$selectWidth}px` : '204px')};
    height: ${(props) => (props.$selectHeight ? `${props.$selectHeight}px` : '28px')};
  }

  .select-item {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .select-item.selected {
    background: transparent !important;
  }

  .select-item.selected:hover {
    background: #f1f3f5 !important;
  }

  .select-item:hover {
    box-shadow: inset 2px 0px 0px #0c66e4;
  }
  .select-item:active {
    background: #091e4224;
  }
  .select-item.selected:active {
    background: #091e4224 !important;
  }

  .select-item:focus-visible {
    border: 2px solid #0c66e4 !important;
    outline: none !important;
    box-shadow: none;
  }

  .item-textline {
    text-overflow: ellipsis;
    overflow: hidden;
    width: ${(props) => (props.$contentWidth ? `${props.$contentWidth - 40}px` : '164px')};
    height: 20px;
    white-space: nowrap;
  }

  .item-renderer {
    display: block;
    text-align: left;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .dropdown-heading {
    padding: 0 8px 0 4px;
  }

  .options {
    overflow-x: hidden;
  }

  .dropdown-content {
    border-radius: 4px;
    background: #ffffff;
    box-shadow:
      0px 0px 1px 0px rgba(9, 30, 66, 0.31),
      0px 8px 12px 0px rgba(9, 30, 66, 0.15);
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 8px;
    width: ${(props) => (props.$contentWidth ? `${props.$contentWidth}px` : '204px')};
    left: ${(props) => (props.$dropdownHorizontalDisplacement ? props.$dropdownHorizontalDisplacement : '0')};

    .search {
      ${(props) => (props.$hideSearch ? 'display: none !important;' : '')}
      border-radius: 4px;
      border: 2px solid rgba(9, 30, 66, 0.14);
      background: white !important;
      margin-bottom: 4px;
      width: auto;
      margin: 0px 4px 4px 4px;
      display: flex;
      height: 32px;
      input {
        height: 28px;
        padding: 6px 8px 6px 12px;
        background: transparent;
      }
    }

    .search::before {
      content: url('/image/SearchIcon_.svg');
      display: inline-block; /* Ensures the added text appears on a new line */
      color: blue; /* Example styling */
      margin-top: 4px;
      margin-left: 8px;
    }

    .search-clear-button {
      top: 2px;
    }

    .panel-content {
      box-shadow: none;
    }

    .search:focus-within {
      border: 2px solid #388bff !important;
    }
  }
`

interface Option {
  value: any
  label: string
  key?: string
  company?: string
  avatar?: string
  email?: string
  disabled?: boolean
}

interface IDefaultItemRendererProps {
  checked: boolean
  option: Option
  disabled?: boolean
  onClick: React.ChangeEventHandler<HTMLInputElement>
  contentWidth?: number
  selectWidth?: number
  selectHeight?: number
  onChange: (arg: Option[]) => void
  value: Option[]
  permanentPlaceholder?: boolean
}

const ItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
  contentWidth,
  selectWidth,
  selectHeight,
  onChange,
  value,
  permanentPlaceholder,
}: IDefaultItemRendererProps) =>
  !(option.label === 'Clear Button') ? (
    <div className={`item-renderer ${disabled ? 'disabled' : ''}`} style={{ display: 'flex', alignItems: 'center' }}>
      {' '}
      <>
        {' '}
        <Box
          xcss={xcss({
            marginRight: 'avatar' in option ? '2px' : '13px',
          })}
        >
          <Checkbox
            isChecked={checked}
            onChange={(val) => {
              onClick(val)

              const cssElement = document.querySelector('.select-panel .search input')

              if (cssElement instanceof HTMLElement) {
                cssElement.focus()
              }
            }}
            isDisabled={disabled}
          />
        </Box>
        {'avatar' in option ? (
          <Box
            xcss={xcss({
              marginRight: '8px',
            })}
          >
            <Avatar size="small" name={option.avatar ? option.avatar : ''} src={option.avatar ? option.avatar : ''} />
          </Box>
        ) : null}
        <Box>
          <div
            className="item-textline"
            style={
              'avatar' in option
                ? {
                    width: contentWidth ? `${contentWidth - 70}px` : '134px',
                  }
                : {}
            }
          >
            {option.label}{' '}
          </div>
          {option.email || option.company ? (
            <Box
              xcss={xcss({
                fontFamily: 'inherit',
                fontSize: '11px',
                fontStyle: 'normal',
                fontWeight: 400,
              })}
            >
              <div
                style={{
                  color: '#626F86',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: contentWidth ? `${contentWidth - 50}px` : '154px',
                }}
              >
                {option.email || option.company}
              </div>
            </Box>
          ) : null}
        </Box>{' '}
      </>
    </div>
  ) : (
    <div
      className={`item-renderer ${disabled ? 'disabled' : ''}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        onClick={(e: { stopPropagation: () => void }) => {
          e.stopPropagation()
          onChange([])
        }}
      >
        <Tag removeButtonLabel="Remove" text={String(value.length)} />
      </Box>
    </div>
  )

function valueRenderer(
  selectedOptions: Option[],
  fullList: Option[],
  placeholder: string,
  onChange: (arg: Option[]) => void,
  contentWidth?: number,
  selectWidth?: number,
  selectHeight?: number,
  permanentPlaceholder?: boolean,
) {
  return selectedOptions.length > 0 ? (
    <Box
      xcss={xcss({
        display: 'flex',
        alignItems: 'center',
      })}
    >
      <Box
        onClick={(e: { stopPropagation: () => void }) => {
          e.stopPropagation()
          onChange([])
        }}
      >
        <Tag removeButtonLabel="Remove" text={String(selectedOptions.length)} />
      </Box>

      <Box
        xcss={xcss({
          fontFamily: 'inherit',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px',
          textAlign: 'left',
        })}
      >
        {!permanentPlaceholder ? (
          <div
            style={{
              color: '#626F86',
              width: !contentWidth ? '120px' : `${contentWidth - 80}px`,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {selectedOptions[0].label}
            {selectedOptions.length > 1 ? `, ${selectedOptions[1].label}` : ''}
            {selectedOptions.length > 2 ? `, ${selectedOptions[2].label}` : ''}
            {selectedOptions.length > 3 ? `, ${selectedOptions[3].label}` : ''}
          </div>
        ) : (
          <div style={{ color: '#626F86' }}>{placeholder}</div>
        )}
      </Box>
    </Box>
  ) : (
    <Box
      xcss={xcss({
        width: '100px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontFamily: 'inherit',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        textAlign: 'left',
        paddingLeft: '4px',
      })}
    >
      <div style={{ color: '#626F86' }}>{placeholder}</div>
    </Box>
  )
}

interface IMultiSelect {
  contentWidth?: number
  selectWidth?: number
  selectHeight?: number
  dropdownHorizontalDisplacement?: string
  options: Option[]
  value: Option[]
  onChange: (arg: Option[]) => void
  onSelectClick?: () => void
  placeholder: string
  labelledBy: string
  isOpen?: boolean
  hideSearch?: boolean
  permanentPlaceholder?: boolean
  loading?: boolean
  onClosed?: () => void
  [key: string]: any
}

function MultiSelectCustom({
  options,
  value,
  onChange,
  placeholder,
  labelledBy,
  onSelectClick,
  contentWidth,
  selectWidth,
  selectHeight,
  dropdownHorizontalDisplacement,
  isOpen,
  hideSearch,
  permanentPlaceholder,
  loading,
  onClosed,
  ...props
}: IMultiSelect) {
  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation()
      }}
      $contentWidth={contentWidth}
      $selectWidth={selectWidth}
      $selectHeight={selectHeight}
      $hideSearch={hideSearch || false}
      $dropdownHorizontalDisplacement={dropdownHorizontalDisplacement}
    >
      <>
        <MultiSelect
          isOpen={isOpen}
          onMenuToggle={(isOpen: boolean) => {
            if (onSelectClick) {
              onSelectClick()
            }
            if (!isOpen && onClosed) {
              onClosed()
            }
          }}
          //  disableSearch={hideSearch}
          labelledBy={labelledBy}
          options={options}
          value={value}
          onChange={onChange}
          ArrowRenderer={({ expanded }) =>
            loading ? <Spinner size="small" /> : <ChevronDownIcon label="dropdown_arrow" />
          }
          ItemRenderer={(obj: IDefaultItemRendererProps) =>
            ItemRenderer({
              ...obj,
              contentWidth,
              selectWidth,
              onChange,
              value,
              permanentPlaceholder,
            })
          }
          valueRenderer={(selectedOptions, fullList) =>
            valueRenderer(
              selectedOptions,
              fullList,
              placeholder,
              onChange,
              contentWidth,
              selectWidth,
              selectHeight,
              permanentPlaceholder,
            )
          }
          {...props}
        />
      </>
    </Wrapper>
  )
}

export default MultiSelectCustom
