// TODO: fix eslint errors
/* eslint-disable */
import { xcss, Box } from '@atlaskit/primitives'
import { token } from '@atlaskit/tokens'
import Button from '@atlaskit/button/new'
import styled from 'styled-components'
import { ReactComponent as Stars } from '../../assets/images/ProfileIcons/Stars.svg'
import { FC, useState, useEffect, ReactNode } from 'react'
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large'

const CustomList = styled.ul`
  padding-left: 20px;
  ::marker {
    font-size: 8px;
  }
`

const TitleBar: FC<{
  children: ReactNode
  open: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}> = styled.div`
  border-radius: 3px;
  border: 1px solid ${token('color.background.accent.purple.subtlest')};
  background-color: ${(props) =>
    props.open ? token('color.background.accent.purple.subtlest.hovered') : token('color.border.inverse')};
  height: 48px;
  color: ${token('color.text')};
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${token('space.200')};
  padding-right: ${token('space.200')};
  padding-top: ${token('space.100')};
  padding-bottom: ${token('space.100')};
  &:hover {
    background-color: ${token('color.background.accent.purple.subtlest')};
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .stars-icon svg path {
    fill: ${token('color.icon.discovery')};
  }
  .stars-icon {
    margin-right: ${token('space.150')};
  }
`

export default function Spoiler({ title, text }: { title: string; text: string[] }) {
  const [open, setOpen] = useState(false)

  return (
    <Box>
      <TitleBar
        open={open}
        onClick={() => {
          setOpen((val) => !val)
        }}
      >
        <div>
          <div className="stars-icon">
            <Stars />
          </div>
          {title}
        </div>
        <div style={open ? { transform: 'rotate(90deg)' } : {}}>
          <ChevronRightLargeIcon label="ChevronRightLargeIcon" />
        </div>
      </TitleBar>
      {open && (
        <Box
          xcss={xcss({
            marginTop: 'space.200',
            color: 'color.text',
            fontFamily: 'inherit',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          })}
        >
          <CustomList>
            {text.map((line) => (
              <li>{line}</li>
            ))}
          </CustomList>
        </Box>
      )}
    </Box>
  )
}
