import { object, string, ObjectSchema, number } from 'yup'
import { PolicyBalanceEditFormValues } from './types'

export const schema: ObjectSchema<PolicyBalanceEditFormValues> = object({
  policyId: string().required(),
  sign: string().required(),
  amount: number()
    .transform((value) => (isNaN(value) ? null : value))
    .min(1, 'The number of days must not be less than 1')
    .max(366, 'The number of days must not exceed 366 days')
    .required('Enter the number to add or subtract'),
  comment: string().required('Enter comment'),
}).required()
