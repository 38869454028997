// TODO: fix eslint errors
/* eslint-disable */
import { FC } from 'react'
import cn from 'classnames'
import style from './User.module.scss'
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'
import { Button } from '../../../UI/Button'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import { ButtonItem } from '@atlaskit/side-navigation'
import { assertEmployee, useEmployee } from '../../../../contexts/EmployeeContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { ProfileAvatar } from '../../../Profile/ProfileAvatar'

export const User: FC = () => {
  const { employee } = useEmployee()
  assertEmployee(employee)
  const navigate = useNavigate()
  const location = useLocation()

  const profileLink = `/employee/${employee?.id}`
  const isInProfile = location.pathname === profileLink

  const toProfile = () => {
    navigate(profileLink)
  }

  return (
    <div className={style.user}>
      <div className={style.sideBarUserAvatarScope}>
        <ButtonItem
          isSelected={isInProfile}
          iconBefore={
            <ProfileAvatar
              size={32}
              imgAvatar={employee.imageUrl}
              firstName={employee.firstNameEn}
              lastName={employee.lastNameEn}
            />
          }
          onClick={toProfile}
        >
          {`${employee?.firstNameEn} ${employee?.lastNameEn}`}
        </ButtonItem>
      </div>

      <ButtonItem iconBefore={<SignOutIcon label="Sign Out" />}>Sign Out</ButtonItem>
    </div>
  )
}
