// TODO: fix eslint errors
/* eslint-disable */
import { LINKS } from '../../../../../utils/constants/api'
import { LinkFromApi } from '../../../../../shared/Link/data/LinkFromApi'
import ConfirmDeleteModal from '../../../Layer/Modal/ConfirmDeleteModal'

type DeleteLink = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  link: LinkFromApi
}

function DeleteLink(props: DeleteLink) {
  function deleteLink(): void {
    fetch(`${LINKS}/${props.link.id}`, {
      method: 'DELETE',
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else {
        response.json().then()
      }
    })
  }

  const linkName = `link <span><a href="${props.link.value.url}" target="_blank">${props.link.value.name}</a></span>`

  return (
    <ConfirmDeleteModal
      opened={props.opened}
      modalTitle="Delete Link"
      cancelText="Cancel"
      deleteText={linkName}
      okText="Delete"
      onCancel={props.onClose}
      onOk={deleteLink}
    />
  )
}

export default DeleteLink
