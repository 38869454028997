import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants'
import {
  CreateBonusesPenaltiesPayload,
  BonusesPenaltiesData,
  GetBonusesPenaltiesQuery,
  UpdateBonusesPenaltiesPayload,
  EmployeeBonusPenaltyData,
} from './types'

export const bonusPenaltyApi = createApi({
  reducerPath: 'bonusPenaltyApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/employees` }),
  tagTypes: ['bonusPenalty'],
  endpoints: (build) => ({
    // Getting list of employees who can create bonuses and penalties
    getEmployeesBonusPenalty: build.query<EmployeeBonusPenaltyData, void>({
      query: () => ({
        url: '/bonuses-penalties-employees',
      }),
    }),
    getBonusPenaltyDataByEmployeeId: build.query<BonusesPenaltiesData, GetBonusesPenaltiesQuery>({
      query: ({ employeeId, ...params }: GetBonusesPenaltiesQuery) => ({
        url: `${employeeId}/bonuses-penalties`,
        params,
      }),
      providesTags: [{ type: 'bonusPenalty', id: 'LIST' }],
    }),
    getBonusPenaltyData: build.query<BonusesPenaltiesData, GetBonusesPenaltiesQuery>({
      query: (params: GetBonusesPenaltiesQuery) => ({
        url: '/bonuses-penalties',
        params,
      }),
      providesTags: [{ type: 'bonusPenalty', id: 'LIST' }],
    }),
    createBonusPenalty: build.mutation<string, CreateBonusesPenaltiesPayload>({
      query: ({ employeeId, ...body }) => ({
        url: `${employeeId}/bonuses-penalties`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'bonusPenalty', id: 'LIST' }]
        }
        return []
      },
    }),
    updateBonusPenalty: build.mutation<string, UpdateBonusesPenaltiesPayload>({
      query: ({ employeeId, id, status }) => ({
        url: `${employeeId}/bonuses-penalties/${id}`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'bonusPenalty', id: 'LIST' }]
        }
        return []
      },
    }),
  }),
})

export const {
  useCreateBonusPenaltyMutation,
  useGetBonusPenaltyDataQuery,
  useUpdateBonusPenaltyMutation,
  useGetEmployeesBonusPenaltyQuery,
  useGetBonusPenaltyDataByEmployeeIdQuery,
} = bonusPenaltyApi
