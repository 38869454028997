// TODO: fix eslint errors
/* eslint-disable */
import React, { useState } from 'react'
import Select from 'react-select'
import '../index.scss'
import './SelectInput.scss'

type SelectInput = {
  label?: string
  required: boolean
  isMulti: boolean
  isSearchable: boolean
  value: object | object[] | null
  options: object[]
  error: string | null
  onChange: (value: any) => void
  onFocus?: () => void
  disabled?: boolean | null
  onMenuOpen?: () => void
  className?: string
  placeholder?: string
}

function SelectInput(props: SelectInput) {
  const [clicked, setClicked] = useState<boolean>(false)

  return (
    <div className="input horizontal-input">
      {props.label ? (
        <label>
          {props.label}
          {props.required ? <span>*</span> : ''}
        </label>
      ) : null}
      <div className="input-container">
        <Select
          className={props.className ? props.className : ''}
          isMulti={props.isMulti}
          value={props.value}
          options={props.options}
          isDisabled={props.disabled ? props.disabled : false}
          isClearable={false}
          classNamePrefix="react-select"
          onMenuOpen={() => {
            setClicked(true)
            if (props.onMenuOpen) {
              props.onMenuOpen()
            }
          }}
          onChange={(e: any) => {
            props.onChange(e)
            setClicked(true)
          }}
          onFocus={() => {
            if (props.onFocus) {
              props.onFocus()
            }
          }}
          onBlur={() => {
            setClicked(false)
            if (props.error) {
              props.error = null
            }
          }}
          isSearchable={props.isSearchable}
          placeholder={props.placeholder ? props.placeholder : 'Select...'}
        />
        {(() => {
          if (props.error && !clicked) {
            return (
              <div className="error">
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.83317 6.00002H8.1665V4.33335H9.83317M9.83317 12.6667H8.1665V7.66669H9.83317M8.99984 0.166687C7.90549 0.166687 6.82185 0.382235 5.81081 0.801024C4.79976 1.21981 3.8811 1.83364 3.10728 2.60746C1.54448 4.17027 0.666504 6.28988 0.666504 8.50002C0.666504 10.7102 1.54448 12.8298 3.10728 14.3926C3.8811 15.1664 4.79976 15.7802 5.81081 16.199C6.82185 16.6178 7.90549 16.8334 8.99984 16.8334C11.21 16.8334 13.3296 15.9554 14.8924 14.3926C16.4552 12.8298 17.3332 10.7102 17.3332 8.50002C17.3332 7.40567 17.1176 6.32204 16.6988 5.31099C16.28 4.29994 15.6662 3.38128 14.8924 2.60746C14.1186 1.83364 13.1999 1.21981 12.1889 0.801024C11.1778 0.382235 10.0942 0.166687 8.99984 0.166687Z"
                    fill="#E05D44"
                  />
                </svg>
                <span>{props.error}</span>
              </div>
            )
          }
        })()}
      </div>
    </div>
  )
}

export default SelectInput
