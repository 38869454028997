import type {LeaveStatusResponseProps} from './leaveStatus.types';
import {CALENDAR} from "../../constants";

export const getLeaveStatusAPI = async (id: string) => {
    const now = new Date();
    const toDayString = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;

    try {
        const response = await fetch(`${CALENDAR}/requests?start-date=${toDayString}&end-date=${toDayString}`, {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        });

        const data = await response.json() as Array<LeaveStatusResponseProps>;
        return data.find((res) => res.requesterId === id);
    } catch (error) {
        console.error(error);
        throw new Error();
    }
};
