import {type JobInfoProps} from './jobInfo.types';
import {useQuery} from "../../useQuery";
import {getCurrentJobInfo} from './getCurrentJobInfo';
import {useState} from "react";
import {useEvent} from "hooks/useEvent";

export const useJobInfo = (idProfile: string) => {
    const [fetchCurrentJobInfoAPI, isLoading, isSuccess, isError] = useQuery(getCurrentJobInfo);
    const [jobInfo, setJobInfo] = useState<JobInfoProps>();

    const fetchCurrentJobInfo = useEvent(() => fetchCurrentJobInfoAPI(idProfile).then((response) => {
            setJobInfo(response);
            return response;
        }));

    return {
        fetchCurrentJobInfo,
        jobInfo,
        isLoading,
        isSuccess,
        isError
    }
};
