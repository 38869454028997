// TODO: fix eslint errors
/* eslint-disable */
import { EMPLOYEE_PROFILE } from 'utils/constants/routes'
import { TypeIconsCollection } from 'shared/TimeOffPolicy/constants/NewTypeIconsCollection'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import CalendarTooltip from './calendar-tooltip'
import { createPortal } from 'react-dom'

function isDateAWeekend(dateString: string) {
  const date = new Date(dateString)
  return date.getDay() === 6 || date.getDay() === 0
}

interface IHoliday {
  name: string
  dayOff: boolean
  holiday: string
}
interface IDayOff {
  dayOff: string
  email: string
  endDate: string
  firstColumn: boolean
  lastColumn?: boolean
  fullName: string
  holiday: string
  name: string
  orgUnit: string
  startDate: string
  status: string
  substitute: string
  substituteAvatarUrl: string
  substituteId: string
  type: string
  typeIcon: string
  url: string
  workingHours?: string
}

interface IEmployee {
  email: string
  employeeId: string
  firstNameEn: string
  hireDate: string
  lastNameEn: string
  nickname: string
  orgUnitId: string
  url: string
  workLocationId: string
  workSchedule: string
}

interface ICalendarDate {
  [key: string]: {
    [key: string]: [] | IHoliday | IDayOff | IEmployee
  }
}

function buildLine(
  value: [] | IHoliday | IDayOff | IEmployee | IEmployee,
  tooltipClass: string,
  employeeDataArray: [string, [] | IHoliday | IDayOff | IEmployee][],
) {
  let dateCounter = new Date()
  const arr = Object.entries(value)
  const result = []

  for (let index = 0; index < arr.length; index++) {
    const [key, value] = arr[index]

    if (index === 0) {
      dateCounter = new Date(key)

      result.push(
        <div
          key={`${tooltipClass}_${index}${1}`}
          style={{ width: '30px', height: '16px' }}
          data-tooltip-id={`${tooltipClass}_${index}${1}`}
        >
          {!value.noIcons ? (
            <div className="event-icon">
              {TypeIconsCollection[value.typeIcon as keyof typeof TypeIconsCollection](
                'without-bg-icon',
                'withouthover',
              )}
            </div>
          ) : null}
        </div>,
      )
      result.push(
        createPortal(
          <CalendarTooltip
            key={`${tooltipClass}_${index}${2}`}
            issetPopup={false}
            tooltipClass={`${tooltipClass}_${index}${1}`}
            value={value}
            objKey={key}
            isDateAWeekend={isDateAWeekend}
          />,
          document.querySelector('.calendar') as HTMLTableElement,
        ),
      )
    } else {
      dateCounter.setDate(dateCounter.getDate() + 1)

      const dateKey = `${dateCounter.getFullYear()}-${(dateCounter.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${dateCounter.getDate().toString().padStart(2, '0')}`

      const currentDate = employeeDataArray.find((item) => item[0] === dateKey)
      const date = currentDate || ['no', {}]
      const transfer =
        'holiday' in date[1]
          ? (date[1] as IHoliday).holiday === 'weekend' || (date[1] as IHoliday).holiday === 'stateHoliday'
          : false

      if (transfer) {
        const shiftDate: { [key: string]: any } = {}
        for (let i = index; i < arr.length; i++) {
          const [lkey, value] = arr[i]
          shiftDate[lkey] = { ...value, noIcons: true }
        }
        const currentDate = employeeDataArray.find((item) => item[0] === Object.keys(shiftDate)[0])
        const dateNext = currentDate || ['no', {}]

        dateNext[1] = shiftDate as IDayOff

        break
      } else {
        result.push(
          <div
            key={`${tooltipClass}_${index}${3}`}
            style={{ width: '30px', height: '16px' }}
            data-tooltip-id={`${tooltipClass}_${index}${1}`}
          />,
        )
        result.push(
          createPortal(
            <CalendarTooltip
              key={`${tooltipClass}_${index}${4}`}
              issetPopup={false}
              tooltipClass={`${tooltipClass}_${index}${1}`}
              value={value}
              objKey={key}
              isDateAWeekend={isDateAWeekend}
            />,
            document.querySelector('.calendar') as HTMLTableElement,
          ),
        )
      }
    }
  }

  return result
}

function showIconBar(
  value: [] | IHoliday | IDayOff | IEmployee | IEmployee,
  combinedObj: {
    holiday: any
    length?: 0 | null
    status?: string | null
    typeIcon: any
    type?: string | null
    workingHours?: string | null | undefined
    lastColumn?: boolean | null | undefined
    startDate?: string | null
    url?: string | null
    firstNameEn?: string | null
    lastNameEn?: string | null
    nickname?: string | null
  },
  tooltipClass: string,
  key: string,
  employeeDataArray: [string, [] | IHoliday | IDayOff | IEmployee][],
) {
  if (
    value &&
    Object.keys(value).length > 0 &&
    combinedObj.holiday !== 'weekend' &&
    typeof Object.values(value)[0] !== 'object'
  ) {
    return (
      <div
        data-tooltip-id={tooltipClass}
        tabIndex={0}
        className={`${combinedObj.status === 'New' ? 'NoConfirm ' : `${combinedObj.typeIcon}-event`} event-style`}
        style={{
          position: 'absolute',
          width: '30px',
          margin: '-8px 4px 0 0px',
          borderRadius: '4px',
        }}
      >
        <div className="event-icon">
          {TypeIconsCollection[combinedObj.typeIcon as keyof typeof TypeIconsCollection](
            'without-bg-icon',
            'withouthover',
          )}
        </div>
        {combinedObj.holiday || combinedObj.workingHours
          ? createPortal(
              <CalendarTooltip
                issetPopup={false}
                tooltipClass={tooltipClass}
                value={value}
                objKey={key}
                isDateAWeekend={isDateAWeekend}
              />,
              document.querySelector('.calendar') as HTMLTableElement,
            )
          : null}
      </div>
    )
  }
  if (
    value &&
    Object.keys(value).length > 0 &&
    combinedObj.holiday != 'weekend' &&
    typeof Object.values(value)[0] === 'object'
  ) {
    return (
      <div
        onMouseEnter={() => {
          document.querySelectorAll(`.${tooltipClass}`).forEach((userItem) => {
            userItem.classList.add('hover-effect')
          })
        }}
        onMouseLeave={() => {
          document.querySelectorAll(`.${tooltipClass}`).forEach((userItem) => {
            userItem.classList.remove('hover-effect')
          })
        }}
        onFocus={() => {
          document.querySelectorAll(`.${tooltipClass}`).forEach((userItem) => {
            userItem.classList.add('focus-effect')
          })
        }}
        onBlur={() => {
          document.querySelectorAll(`.${tooltipClass}`).forEach((userItem) => {
            userItem.classList.remove('focus-effect')
          })
        }}
        tabIndex={0}
        className={`${
          combinedObj.status === 'New' ? 'NoConfirm ' : `${combinedObj.typeIcon}-event`
        } event-style ${tooltipClass}`}
        style={{
          position: 'absolute',
          margin: '-8px 4px 0 0px',
          borderRadius: '4px',
          zIndex: 1,
        }}
      >
        {buildLine(value, tooltipClass, employeeDataArray)}
      </div>
    )
  }
  return ''
}

function renderCalendar(calendarData: ICalendarDate) {
  if (calendarData) {
    return Object.entries(calendarData).map(([employeeKey, employeeData], mainIndex) => {
      const employeeDataArray: [string, [] | IHoliday | IDayOff | IEmployee][] = Object.entries(employeeData as object)

      return (
        <tr key={employeeKey}>
          {employeeDataArray.map(([key, value], index) => {
            const combinedObj = {
              holiday: 'holiday' in value ? value.holiday : null,
              length: 'length' in value ? value.length : null,
              status:
                'status' in value
                  ? value.status
                  : typeof Object.values(value)[0] === 'object'
                    ? Object.entries(value)[0][1].status
                    : null,
              typeIcon:
                'typeIcon' in value
                  ? value.typeIcon
                  : typeof Object.values(value)[0] === 'object'
                    ? Object.entries(value)[0][1].typeIcon
                    : null,
              type: 'type' in value ? value.type : null,
              workingHours: 'workingHours' in value ? value.workingHours : null,
              lastColumn: 'lastColumn' in value ? value.lastColumn : null,
              startDate:
                'startDate' in value
                  ? value.startDate
                  : typeof Object.values(value)[0] === 'object'
                    ? Object.entries(value)[0][1].startDate
                    : null,
              url: 'url' in value ? value.url : null,
              firstNameEn: 'firstNameEn' in value ? value.firstNameEn : null,
              lastNameEn: 'lastNameEn' in value ? value.lastNameEn : null,
              nickname: 'nickname' in value ? value.nickname : null,
            }
            const tooltipClass = combinedObj.startDate
              ? `c_${employeeKey}-${combinedObj.startDate.replace(/[ ,]/g, '')}`
              : `c_${employeeKey}-${Math.random().toString(36).substring(2, 7)}`

            if (index === 0) {
              return (
                <td key={index}>
                  {combinedObj.url ? (
                    <div className="with-avatar">
                      <LazyLoad overflow>
                        <img src={combinedObj.url} alt="cropped" />
                      </LazyLoad>
                    </div>
                  ) : (
                    <div className="without-avatar">
                      <p>{combinedObj.firstNameEn ? combinedObj.firstNameEn.charAt(0).toUpperCase() : '-'}</p>
                    </div>
                  )}
                  <Link
                    data-tooltip-id={`tooltip-${employeeKey}`}
                    to={`${EMPLOYEE_PROFILE}/${employeeKey}`}
                    target="_blank"
                  >
                    <h4>{`${combinedObj.firstNameEn} ${combinedObj.lastNameEn}`}</h4>
                    {combinedObj.nickname ? <span className="nickname">{combinedObj.nickname}</span> : null}
                  </Link>
                </td>
              )
            }
            return (
              <td
                key={index}
                className={`${combinedObj.holiday === 'weekend' ? 'weekend ' : ''} 
                    ${combinedObj.length === 0 ? 'simple-day ' : ''} 
                    ${new Date(key).toDateString() === new Date().toDateString() ? 'today-border ' : ''}`}
              >
                {showIconBar(value, combinedObj, tooltipClass, key, employeeDataArray)}
              </td>
            )
          })}
        </tr>
      )
    })
  }
  return null
}

export default renderCalendar
