import Icon from '@ant-design/icons'
import type { GetProps } from 'antd'
import {
  CheckSvg,
  CloseSvg,
  LinkSvg,
  InfoSvg,
  DropDownSvg,
  CalendarSvg,
  SuccessSvg,
  ErrorSvg,
  AddSvg,
  ShortcutSvg,
  NoDataSvg,
  NoResultsSvg,
} from './constants'

type CustomIconComponentProps = GetProps<typeof Icon>

export const CheckIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={CheckSvg} {...props} />
export const CloseIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={CloseSvg} {...props} />
export const LinkIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={LinkSvg} {...props} />
export const InfoIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={InfoSvg} {...props} />
export const DropDownIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={DropDownSvg} {...props} />
export const CalendarIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={CalendarSvg} {...props} />
export const SuccessIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={SuccessSvg} {...props} />
export const ErrorIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={ErrorSvg} {...props} />
export const AddIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={AddSvg} {...props} />
export const ShortcutIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={ShortcutSvg} {...props} />
export const NoDataIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={NoDataSvg} {...props} />
export const NoResultsIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={NoResultsSvg} {...props} />
