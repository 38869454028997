import styled from 'styled-components'

export const RateFormModalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .anticon.anticon-close-circle svg {
    width: 24px;
    height: 24px;

    path {
      fill: rgba(255, 77, 79, 1);
    }
  }
`

export const RateFormStyles = styled.div`
  .ant-form-item {
    margin-bottom: 16px;
  }

  .modal-footer {
    padding: 12px 0 0;
  }
`
