import {useState} from "react";
import {LevelProps} from "./level.types";
import {useQuery} from "../../../../useQuery";
import {fetchLevelAPI} from "./level";
import {useEvent} from "hooks/useEvent";

export const useLevel = () => {
    const [levels, setLevels] = useState<Array<LevelProps>>([]);
    const [getAllLevels, isLoading, isSuccess, isError] = useQuery(fetchLevelAPI);

    const fetchLevels = useEvent(async () => getAllLevels()
            .then((response) => {
                setLevels(response);
                return response;
            }));

    return {
        levels,
        fetchLevels,
        isLoading,
        isSuccess,
        isError
    };
};
