// TODO: fix eslint errors
/* eslint-disable */
import React, { createContext, useContext, useState } from 'react'

interface ModalContextValue {
  isModalOpen: boolean
  setModalOpen: (isOpen: boolean) => void
}

interface ModalProviderProps {
  children: React.ReactNode
}

export const ModalContext = createContext<ModalContextValue>({
  isModalOpen: false,
  setModalOpen: (isOpen: boolean) => {},
})

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  return <ModalContext.Provider value={{ isModalOpen, setModalOpen }}>{children}</ModalContext.Provider>
}
