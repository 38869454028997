import { LinkButton } from '@atlaskit/button/new'
import { SentryReportingErrorStyles } from './styles'

export const SentryReportingError = () => (
  <SentryReportingErrorStyles>
    <div className="wrapper">
      <div className="error-title">
        <h1>Oops! Something went wrong.</h1>
        <p>An unexpected error occurred. Please try again later.</p>
        <LinkButton appearance="primary" href="/">
          Back to Homepage
        </LinkButton>
      </div>

      <div className="error-text">
        <p>An unexpected error occurred.</p>
        <p>Please try again later.</p>
      </div>
    </div>
  </SentryReportingErrorStyles>
)
