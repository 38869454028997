import React, { FC } from "react";
import "./OrgUnits.scss";
import { InnerTab } from "./InnerTab";

export const OrgUnits: FC = () => (
    <div className="sub-content-block d-flex">
      <div className="sub-content-header d-flex z-index-fix">
        <div className="sub-title-block d-flex">
          <h3>Org chart settings</h3>
        </div>
      </div>
      <div className="sub-content-main orgUnits-z-index">
        <div className="orgUnits">
          <div className="vertical-tabs">
            <InnerTab />
          </div>
        </div>
      </div>
    </div>
  );
