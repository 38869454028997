// TODO: fix eslint errors
/* eslint-disable */
import { type Dispatch, type FC, type SetStateAction, useEffect } from 'react'
import style from './DirectManager.module.scss'
import styleParent from '../ShortInformationCard.module.scss'
import { ProfileAvatar } from '../../ProfileAvatar'
import { useDirectManager } from '../../../../API/profile/directManager'
import { useProfile } from '../../context'
import { Skeleton } from '@atlaskit/avatar'
import { LineSkeleton } from '../../../UI/LineSkeleton'
import { Link } from 'react-router-dom'
import cn from 'classnames'

interface DirectManagerProps {
  setIsNotEmptyDM: Dispatch<SetStateAction<boolean>>
}

export const DirectManager: FC<DirectManagerProps> = ({ setIsNotEmptyDM }) => {
  const { idOpenProfile, jobInfo } = useProfile()
  const { fetchDirectManager, directManager, isLoading } = useDirectManager(idOpenProfile)

  useEffect(() => {
    fetchDirectManager()
  }, [])

  useEffect(() => {
    if (
      jobInfo?.currentJobInfoValue.directManagerId &&
      jobInfo?.currentJobInfoValue.directManagerId !== directManager?.id
    ) {
      fetchDirectManager()
    }
  }, [jobInfo?.currentJobInfoValue.directManagerId])

  useEffect(() => {
    //@ts-ignore
    setIsNotEmptyDM(Boolean(typeof directManager === 'object' && directManager?.length !== 0) || isLoading)
  }, [directManager])

  //@ts-ignore
  if (directManager?.length === 0) return null

  return (
    <>
      <div className={cn(styleParent.otherInfoBlockTitle, styleParent.otherInfoBlockTitleM)}>Direct Manager:</div>
      <div className={styleParent.managerWrap}>
        {isLoading || !directManager ? (
          <div className={style.directManager}>
            <div className={style.avatar}>
              <Skeleton size="medium" />
            </div>
            <div className={style.left}>
              <div>
                <LineSkeleton height="16px" width="50%" />
              </div>
              <div>
                <LineSkeleton height="12px" width="35%" />
              </div>
            </div>
          </div>
        ) : (
          <Link to={`/employee/${directManager!.id}`} className={style.directManagerLink}>
            <div className={style.directManager}>
              <div className={style.avatar}>
                <ProfileAvatar
                  imgAvatar={directManager!.image}
                  size={32}
                  firstName={directManager!.firstNameEn}
                  lastName={directManager!.lastNameEn}
                />
              </div>
              <div>
                <div className={style.name}>
                  {directManager!.firstNameEn} {directManager!.lastNameEn}
                </div>
                {directManager!.nickname && <div className={style.nickname}>{directManager!.nickname}</div>}
              </div>
            </div>
          </Link>
        )}
      </div>
    </>
  )
}
