import { useEffect, useState } from 'react'
import { coreHRDateFormat, dateFormatDB } from 'utils/helpers/formatted-date'
import { DatePicker, Input } from 'antd'
import { CalendarOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'
import dayjs, { Dayjs } from 'dayjs'
import { FilterRangeDatesProps, RangeValuesState } from './types'
import { defaultRangeValueState } from './constants'
import { FilterRangeDatesStyles } from './styles'

export const FilterRangeDates = (props: FilterRangeDatesProps) => {
  const { onChange, value, placeholder } = props
  const [showRange, setShowRange] = useState(false)
  const [rangeData, setRangeData] = useState<string[]>()
  const [rangeValue, setRangeValue] = useState<RangeValuesState>(defaultRangeValueState)

  const handleClick = () => {
    setShowRange(!showRange)
  }

  const handleClear = () => {
    setRangeValue(defaultRangeValueState)
    setRangeData([])
    onChange([])
  }

  const handleRangeChange = (dates: NoUndefinedRangeValueType<Dayjs>, dateStrings: string[]) => {
    setRangeValue({
      label: dateStrings.join(' - '),
      value: dates,
    })

    setRangeData(dates.map((date) => dayjs(date).format(dateFormatDB)))
  }

  const handleOpenChange = (open: boolean) => {
    setShowRange(open)
  }

  useEffect(() => {
    if (!showRange && rangeData) {
      onChange(rangeData)
    }
  }, [showRange])

  useEffect(() => {
    if (value) {
      const startDay = dayjs(value[0])
      const endDay = dayjs(value[1])

      setRangeValue({
        label: `${startDay.format(coreHRDateFormat)} - ${endDay.format(coreHRDateFormat)}`,
        value: [startDay, endDay],
      })
    }
  }, [value])

  return (
    <FilterRangeDatesStyles>
      <Input
        placeholder="default size"
        value={rangeValue.label || placeholder}
        readOnly
        suffix={
          rangeValue.label ? <CloseCircleOutlined onClick={handleClear} /> : <CalendarOutlined onClick={handleClick} />
        }
        onClick={handleClick}
        width="264px"
      />
      {showRange && (
        <DatePicker.RangePicker
          onOpenChange={handleOpenChange}
          onCalendarChange={handleRangeChange}
          format={coreHRDateFormat}
          value={rangeValue.value}
          open={showRange}
          allowClear={false}
          placement="bottomRight"
        />
      )}
    </FilterRangeDatesStyles>
  )
}
