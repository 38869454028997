import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SystemCurrency } from 'models'
import { BASE_URL } from '../constants'

export const systemCurrencyApi = createApi({
  reducerPath: 'currency-api',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/currencies` }),
  tagTypes: ['system-currency'],
  endpoints: (build) => ({
    getSystemCurrency: build.query<SystemCurrency[], void>({
      query: () => ({
        url: '/system',
      }),
    }),
  }),
})

export const { useGetSystemCurrencyQuery } = systemCurrencyApi
