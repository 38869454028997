// TODO: fix eslint errors
/* eslint-disable */
import './ConfirmDeleteModal.scss'
import Button, { Size, Type } from 'components/old-ui/Button/Button'
import parse from 'html-react-parser'

type ConfirmDeleteModal = {
  opened: boolean
  modalTitle: string
  cancelText: string
  deleteText: string
  okText: string
  onCancel: () => any
  onOk: (...value: any) => any
  isRehire?: boolean
}

function ConfirmDeleteModal(props: ConfirmDeleteModal) {
  return (
    <div className={`delete-modal modal-${props.opened ? 'opened' : 'closed'}`}>
      <div className="modal-content">
        <div className="modal-header">
          <h2>{props.modalTitle}</h2>
          <i onClick={props.onCancel} className="material-icons">
            close
          </i>
        </div>
        <div className="modal-body">
          {props.isRehire ? (
            <p>
              Are you sure you want to rehire {parse(props.deleteText)} ? All the previous job information will be moved
              to archive.
            </p>
          ) : (
            <p>Are you sure you want to delete {parse(props.deleteText)} ? This process cannot be undone.</p>
          )}
        </div>
        <div className="modal-footer">
          <Button text={props.cancelText} size={Size.Medium} type={Type.Ghost} onClick={props.onCancel} />
          <Button text={props.okText} size={Size.Medium} type={Type.Delete} onClick={props.onOk} />
        </div>
      </div>
    </div>
  )
}

export default ConfirmDeleteModal
