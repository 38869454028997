import React, { FC } from "react";
import { SubMenu } from "../Layer/SubMenu/SubMenu";
import { Outlet } from "react-router-dom";

export const Admin: FC = () => (
    <div className="admin-panel-board">
      <div className="main-flex">
        <div className="main-content">
          <div className="main-title-block">
            <div className="d-flex align-items-center">
              <i className="material-icons">settings</i>
            </div>
            <h2>Admin Panel</h2>
          </div>
          <div className="d-flex">
            <SubMenu />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
