import { token } from '@atlaskit/tokens'
import styled from 'styled-components'

export const InactiveUserViewStyles = styled.div`
  display: grid;
  gap: 12px 0;
  text-align: center;
  color: ${token('color.text')};

  .container {
    &__icon {
      font-size: 77px;
      color: ${token('color.icon.danger')};
      margin: 0 auto;
    }

    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }

    &__text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }

    &__link {
      width: 128px;
      color: ${token('color.link')};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-decoration: none;
    }
  }
`
