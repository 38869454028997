import styled from 'styled-components'
import { token } from '@atlaskit/tokens'

export const EmptyTableStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  .title {
    color: ${token('color.text')};
    font-size: 29px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin: 0 0 ${token('space.200')};
  }

  .description {
    color: ${token('color.text.subtle')};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    white-space: pre-wrap;
    margin: 0;
  }

  &.size-small {
    svg {
      width: 250px;
      height: 250px;
    }

    .title {
      font-size: 20px;
    }
  }
`
