import { useState } from 'react'
import { NavProps } from './Layout.types'
import { Header } from './Header'
import { SideBar } from './SideBar'
import { useEvent } from 'hooks/useEvent'
import { Outlet } from 'react-router-dom'
import { StyledContentLayout, StyledMainLayout } from './LayoutStyles'

export const Layout = () => {
  const [navIsOpen, setNavIsOpen] = useState<boolean>(false)
  const switchStatus = useEvent(() => {
    setNavIsOpen(!navIsOpen)
  })

  const navProps: NavProps = {
    navIsOpen,
    navSwitchStatus: switchStatus,
  }

  return (
    <>
      <Header {...navProps} />
      <StyledContentLayout>
        <SideBar {...navProps} />
        <StyledMainLayout $navIsOpen={navIsOpen}>
          <Outlet />
        </StyledMainLayout>
      </StyledContentLayout>
    </>
  )
}
