import { type TimeOffBalanceResponseProps, type TimeOffBalanceProps } from './timeOffBalance.types'
import { ACCRUED_DAYS_BALANCES } from '../../constants'

const transformTimeOffBalanceResponse = (
  timeOffBalanceList: Array<TimeOffBalanceResponseProps>,
): Array<TimeOffBalanceProps> =>
  timeOffBalanceList.map((timeOffBalance) => ({
    timeOffPolicyId: timeOffBalance.timeOffPolicyId,
    timeOffPolicyName: timeOffBalance.timeOffPolicyName,
    quantity: timeOffBalance.quantity,
    typeIcon: timeOffBalance.typeIcon,
  }))

export const fetchTimeOffBalanceAPI = async (idProfile: string) => {
  try {
    const response = await fetch(`${ACCRUED_DAYS_BALANCES}/${idProfile}`, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })

    const data = (await response.json()) as Array<TimeOffBalanceResponseProps>
    return transformTimeOffBalanceResponse(data)
  } catch (error) {
    console.error(error)
    throw new Error()
  }
}
