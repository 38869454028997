
import {  EMPLOYEES } from "../../utils/constants/api";

function getEmployeesWithOrgUnit() {
    return fetch(`${EMPLOYEES  }/type/active`, {
       method: "GET",
       headers: {
         Accept: "application/json",
         "Content-type": "application/json",
       },
     });
   }

   export default getEmployeesWithOrgUnit;
