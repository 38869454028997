import { DayRange } from "components/UI/FilterCalendar";
import { EmployeeData } from "interfaces/attendance/employees.interface";

interface EmployeeSummary {
  expectedHours: { hours: string; minutes: string };
  workedHours: { hours: string; minutes: string };
  remainingHours: { hours: string; minutes: string };
}

const checkAndAggregateUserData = (
  data: EmployeeData
): EmployeeSummary | undefined => {
  const employeeIds = Object.keys(data);

  if (employeeIds.length !== 1) {
    return;
  }

  const employeeId = employeeIds[0];
  const employeeData = data[employeeId];

  const {expectedHours} = employeeData;
  const {workedHours} = employeeData;
  const {remainingHours} = employeeData;

  const firstEntryKey = Object.keys(employeeData).find(
    (key) =>
      key !== "expectedHours" &&
      key !== "workedHours" &&
      key !== "remainingHours"
  );

  if (!firstEntryKey) {
    return;
  }

  const parseTime = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    return {
      hours: String(hours),
      minutes: String(minutes).padStart(2, "0"),
    };
  };

  const expected = parseTime(expectedHours);
  const worked = parseTime(workedHours);
  const remaining = parseTime(remainingHours);

  return {
    expectedHours: expected,
    workedHours: worked,
    remainingHours: remaining,
  };
};

const useEmployeeSummary = (dateRange: DayRange, employee: EmployeeData) => {
  const result = checkAndAggregateUserData(employee);
  return result;
};

export default useEmployeeSummary;
