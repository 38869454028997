import {useQuery} from "../../useQuery";
import {getLeaveStatusAPI} from "./leaveStatus";
import {useState} from "react";
import type {LeaveStatusResponseProps} from "./leaveStatus.types";
import {useEvent} from "hooks/useEvent";

export const useLeaveStatus = (idProfile: string) => {
    const [getLeaveStatusAPIs, isLoading, isSuccess, isError] = useQuery(getLeaveStatusAPI);
    const [leaveStatus, setLeaveStatus] = useState<LeaveStatusResponseProps>();

    const getLeaveStatus = useEvent(async () => {
        try {
            const response = await getLeaveStatusAPIs(idProfile);
            setLeaveStatus(response);
            return response;
        } catch (error) {
            throw new Error();
        }
    });

    return {
        getLeaveStatus,
        leaveStatus,
        isLoading,
        isSuccess,
        isError
    }
};
