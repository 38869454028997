// TODO add generic types and remove ts-ignore

export const mergeDataBySourceFields = <T extends object, R extends object>(
  source: T,
  data: R,
  allowedDataEmptyValues?: (0 | '' | false)[],
) => {
  const sourceFieldsNames = Object.keys(source) as (keyof T)[]
  return sourceFieldsNames.reduce((result: T, fieldName: keyof T): T => {
    // the source and data objects may have different fields,
    // and we need to get only those that match the fields of the source object
    // @ts-ignore
    const dataValue: T[keyof T] = data[fieldName]

    result[fieldName] =
      // @ts-ignore
      dataValue || allowedDataEmptyValues?.includes(dataValue)
        ? dataValue
        : source[fieldName]

    return result
  }, {} as T)
}
