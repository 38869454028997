import { useMemo } from 'react'
import { DatePicker } from '@atlaskit/datetime-picker'
import { coreHRDateFormat, getFormattedDate } from 'utils/helpers/formatted-date'
import { ErrorMessage } from '@atlaskit/form'
import dayjs from 'dayjs'
import { Controller, useWatch, useFormContext } from 'react-hook-form'
import { DateRangeStyles } from './styles'
import { announcementFormErrorsMsgMap } from '../constants'

export const DateRange = () => {
  const { control, setValue } = useFormContext()

  const dateNow: string = getFormattedDate(dayjs(), 'dateFormatDB')

  const [startDate, endDate] = useWatch({
    control,
    name: ['startDate', 'endDate'],
  })

  const minEndDate: string = useMemo(
    () => (startDate ? getFormattedDate(startDate, 'dateFormatDB') : dateNow),
    [startDate, dateNow],
  )

  const onUpdateStartDate = (startDateValue: string) => {
    if (dayjs(startDateValue) > dayjs(endDate)) {
      setValue('endDate', '')
    }
    setValue('startDate', startDateValue)
  }

  return (
    <DateRangeStyles>
      <span className="date-range-label">
        Display dates
        {/* <span className='is-required'> *</span> */}
      </span>
      <div className="input-group">
        <Controller
          name="startDate"
          control={control}
          rules={{ required: true }}
          render={({ field: { onBlur, onChange, name, value }, fieldState: { error } }) => (
            <div>
              <DatePicker
                name={name}
                placeholder="Start date"
                value={value}
                dateFormat={coreHRDateFormat}
                minDate={dateNow}
                onBlur={onBlur}
                onChange={(date) => {
                  onChange(date)
                  onUpdateStartDate(date)
                }}
              />
              {error && <ErrorMessage>{announcementFormErrorsMsgMap.startDate.required}</ErrorMessage>}
            </div>
          )}
        />
        <Controller
          name="endDate"
          control={control}
          rules={{ required: true }}
          render={({ field: { onBlur, onChange, name, value }, fieldState: { error } }) => (
            <div>
              <DatePicker
                name={name}
                placeholder="End date"
                value={value}
                dateFormat={coreHRDateFormat}
                minDate={minEndDate}
                onBlur={onBlur}
                onChange={onChange}
              />
              {error && <ErrorMessage>{announcementFormErrorsMsgMap.endDate.required}</ErrorMessage>}
            </div>
          )}
        />
      </div>
    </DateRangeStyles>
  )
}
