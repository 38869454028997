import {FC} from "react";
import cn from 'classnames';
import style from './LineSkeleton.module.scss';
import {SkeletonHeadingItem} from "@atlaskit/menu";

interface LineSkeletonProps {
    height: `${number}${'px' | '%'}`;
    width?: `${number}${'px' | '%'}`;
    className?: string;
}

export const LineSkeleton: FC<LineSkeletonProps> = ({height, width, className}) => (<div className={cn(style.lineSkeleton, className)} style={{
        // @ts-ignore
        '--ds-space-100': height,
        width
    }}><SkeletonHeadingItem isShimmering /></div>);
