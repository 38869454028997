import { MgpFormValues } from './types'
import { CloseCircleFilled } from '@ant-design/icons'

export enum MgpModalTypeEnum {
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
}

export const mgpModalTitleMap: Record<MgpModalTypeEnum, string | JSX.Element> = {
  [MgpModalTypeEnum.Create]: 'Add new MGP',
  [MgpModalTypeEnum.Edit]: 'Edit MGP',
  [MgpModalTypeEnum.Delete]: (
    <>
      <CloseCircleFilled /> Delete MGP
    </>
  ),
}

export const mgpModalBtnMap: Record<MgpModalTypeEnum, string> = {
  [MgpModalTypeEnum.Create]: 'Add',
  [MgpModalTypeEnum.Edit]: 'Edit',
  [MgpModalTypeEnum.Delete]: 'Delete',
}

export const mgpModalNotificationMap: Record<MgpModalTypeEnum, string> = {
  [MgpModalTypeEnum.Create]: "You've added a new MPG.",
  [MgpModalTypeEnum.Edit]: "You've updated the MPG.",
  [MgpModalTypeEnum.Delete]: "You've deleted the MPG.",
}

export const defaultValues: MgpFormValues = {
  currencyId: null,
  amount: null,
  date: '',
}
