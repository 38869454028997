import { OrderEnum } from 'shared'
import { EmployeeMGPHistoryTableFilters, EmployeeMGPHistoryTableState } from './types'

export const defaultEmployeeMGPHistoryTableState: EmployeeMGPHistoryTableState = {
  page: 1,
  limit: 6,
  sort: 'date',
  order: OrderEnum.DESC,
}

export const defaultEmployeeMGPHistoryTableFilters: EmployeeMGPHistoryTableFilters = {
  currencies: [],
  dates: [],
}
