import { WorkLocationProps, WorkLocationResponseProps } from './workLocation.types'
import { LOCATIONS } from '../../../../constants'
import { getGMTOffsetByTimezoneName } from 'application/Common/Timezones/Services'

const transformToADSSelectOptions = (options: Array<WorkLocationResponseProps>): Array<WorkLocationProps> =>
  options.map((option) => ({
    ...option,
    dataValue: option.value,
    label: `${option.value.countryCode}, ${option.value.timezone} ${getGMTOffsetByTimezoneName(option.value.timezone)}`,
    value: option.id,
  }))

export const fetchWorkLocationAPI = async () => {
  try {
    const response = await fetch(LOCATIONS, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })

    const data = (await response.json()) as Array<WorkLocationResponseProps>

    return transformToADSSelectOptions(data)
  } catch (error) {
    console.error(error)
    throw new Error()
  }
}
