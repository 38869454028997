// TODO: fix eslint errors
/* eslint-disable */
import { useCallback, useState, ReactNode, FC, useEffect } from 'react'
import styled from 'styled-components'
import SearchField from 'components/Search/SearchField/SearchField'
import Button from '@atlaskit/button/new'
import { Box, xcss } from '@atlaskit/primitives'
import Avatar from '@atlaskit/avatar'
import { INTERVIEWS, FILTERS } from '../../utils/constants/api'
import { token } from '@atlaskit/tokens'
import HipchatChevronDoubleUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-double-up'
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large'
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'

function EmployeeList({
  height,
  availableEmployees,
  selectedEmployees,
  onClick,
  filter,
}: {
  height?: number
  availableEmployees: Employee[]
  selectedEmployees: Employee[]
  onClick: (arg: Employee) => void
  filter: (arg: Employee) => boolean
}) {
  return (
    <ScrollBlock style={{ maxHeight: height ? `${height}px` : '320px' }}>
      {availableEmployees.filter(filter).map((item) => (
        <ResultItem
          className={selectedEmployees.find((obj) => obj.id === item.id) ? 'selected' : ''}
          onClick={() => {
            onClick(item)
          }}
        >
          <Box
            xcss={xcss({
              display: 'flex',
              alignItems: 'center',
            })}
          >
            {' '}
            <Box>
              <Avatar size="small" name={item.nickname ? item.nickname : ''} src={item.avatar ? item.avatar : ''} />
            </Box>
            <Box
              xcss={xcss({
                display: 'flex',
                alignItems: 'center',
                marginLeft: '8px',
              })}
            >
              <Box>
                <Box
                  xcss={xcss({
                    display: 'flex',
                    fontFamily: 'inherit',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    color: 'color.text',
                    maxWidth: '280px',
                    minWidth: '200px',
                    overflow: 'auto',
                  })}
                >
                  {item.firstName} {item.lastName} {item.nickname ? `(${item.nickname})` : null}
                </Box>
                {item.email ? (
                  <Box
                    xcss={xcss({
                      display: 'flex',
                      fontFamily: 'inherit',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                    })}
                  >
                    <div style={{ color: '#626F86' }}> {item.email}</div>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </ResultItem>
      ))}
    </ScrollBlock>
  )
}

const ScrollBlock = styled.div`
  /* width */

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(102, 102, 102, 0.4);
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  overflow: auto;
`

const ResultItem = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 16px;
  padding-left: 4px;
  &:hover {
    box-shadow: inset 2px 0px 0px #0c66e4 !important;
    background: #f1f3f5;
    border: none !important;
  }
  &:active {
    box-shadow: inset 2px 0px 0px #0c66e4 !important;
    background: #091e4224;
    border: none !important;
  }
  &:focus {
    border: 2px solid #0c66e4;
    box-shadow: none;
  }
  &:focus-visible {
    box-shadow: none;
    border: 2px solid #0c66e4;
    outline: none;
  }

  &.selected {
    background-color: ${token('color.background.selected')};
  }
`

const ButtonsWrapper: FC<{
  children: ReactNode
}> = styled.div`
  button {
    width: 32px;
    height: 32px;
    padding-inline-start: 0;
    padding-inline-end: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

type Employee = {
  avatar: string
  email: string
  firstName: string
  id: string
  lastName: string
  nickname: string
}

export default function TeamAnalysisModal({
  isOpen,
  setIsOpen,
  onConfirm,
}: {
  isOpen: boolean
  setIsOpen: (arg: boolean) => void
  onConfirm: () => void
}) {
  const [search, setSearch] = useState('')
  const [availableEmployees, setAvailableEmployees] = useState<Employee[]>([])
  const [movedEmployees, setMovedEmployees] = useState<Employee[]>([])
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([])
  const [selectedMovedEmployees, setSelectedMovedEmployees] = useState<Employee[]>([])

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  useEffect(() => {
    fetch(`${FILTERS}/interviews/request/employees`)
      .then((res) => res.json())
      .then((data) => {
        setAvailableEmployees(data)
      })
  }, [])

  function sendEmployees(callback: () => void) {
    if (movedEmployees.length > 0) {
      fetch(`${INTERVIEWS}/request`, {
        method: 'POST',
        body: JSON.stringify(movedEmployees.map((item) => item.id)),
      }).then((res) => {
        onConfirm()
      })
    }
  }

  function filterByNameEmail(item: { firstName: any; lastName: any; nickname: string; email: string }) {
    const string = `${item.firstName} ${item.lastName} ${item.nickname ? `(${item.nickname})` : null}`
    return string.toLocaleLowerCase().indexOf(search) > -1 || item.email.toLocaleLowerCase().indexOf(search) > -1
  }

  return (
    <div>
      {isOpen && (
        <Modal onClose={closeModal} width="868px" height="628px">
          <ModalHeader>
            <ModalTitle>Assign AI personality analysis</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Box
              xcss={xcss({
                display: 'flex',
                height: '472px',
                marginTop: 'space.050',
              })}
            >
              <Box
                xcss={xcss({
                  width: '370px',
                  padding: 'space.200',
                  borderRadius: 'border.radius.050',
                  border: '1px solid',
                  borderColor: 'color.border.disabled',
                })}
              >
                <Box
                  xcss={xcss({
                    color: 'color.text',
                    fontFamily: 'inherit',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px',
                  })}
                >
                  Available employees
                </Box>
                <Box
                  xcss={xcss({
                    color: 'color.text.subtlest',
                    fontFamily: 'inherit',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                  })}
                >
                  The users who have test requests in the status New or Processing are not displayed in the list.
                </Box>
                <Box
                  xcss={xcss({
                    marginTop: 'space.250',
                    marginBottom: 'space.100',
                  })}
                >
                  <SearchField
                    onreset={1}
                    placeholder="Search"
                    value={search}
                    onChange={(value) => {
                      setSearch(value)
                      setSelectedMovedEmployees([])
                    }}
                    onFocus={() => {}}
                    onClick={(e) => {}}
                    onPressEnter={() => {}}
                  />
                </Box>
                <EmployeeList
                  filter={filterByNameEmail}
                  availableEmployees={availableEmployees}
                  selectedEmployees={selectedEmployees}
                  onClick={(item: Employee) => {
                    setSelectedEmployees((val) => {
                      if (val.find((obj) => obj.id === item.id)) {
                        return val.filter((obj) => obj.id !== item.id)
                      }
                      return [...val, item]
                    })
                  }}
                />
              </Box>
              <Box
                xcss={xcss({
                  padding: 'space.300',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                })}
              >
                {' '}
                <ButtonsWrapper>
                  <Box
                    xcss={xcss({
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 'space.100',
                      justifyContent: 'center',
                    })}
                  >
                    <Button
                      isDisabled={availableEmployees.length === 0}
                      onClick={() => {
                        if (availableEmployees.length > 0) {
                          const objToMove = availableEmployees.filter(filterByNameEmail)

                          setAvailableEmployees(
                            search.length > 0
                              ? (val) => val.filter((item) => objToMove.find((obj) => !(obj.id === item.id)))
                              : [],
                          )

                          setMovedEmployees((val) => [...val, ...objToMove])
                          setSelectedEmployees([])
                          setSelectedMovedEmployees([])
                        }
                      }}
                    >
                      <div style={{ transform: 'rotate(90deg)' }}>
                        <HipchatChevronDoubleUpIcon label="double arrow right" />
                      </div>
                    </Button>
                    <Button
                      isDisabled={selectedEmployees.length === 0}
                      onClick={() => {
                        setAvailableEmployees((val) =>
                          val.filter((item) => !selectedEmployees.find((obj) => obj.id === item.id)),
                        )
                        setMovedEmployees((val) => [...val, ...selectedEmployees])
                        setSelectedEmployees([])
                        setSelectedMovedEmployees([])
                      }}
                    >
                      <ChevronRightLargeIcon label="arrow right" />
                    </Button>
                    <Button
                      isDisabled={selectedMovedEmployees.length === 0}
                      onClick={() => {
                        setMovedEmployees((val) =>
                          val.filter((item) => !selectedMovedEmployees.find((obj) => obj.id === item.id)),
                        )
                        setAvailableEmployees((val) => [...val, ...selectedMovedEmployees])
                        setSelectedEmployees([])
                        setSelectedMovedEmployees([])
                      }}
                    >
                      <ChevronLeftLargeIcon label="arrow left" />
                    </Button>
                    <Button
                      isDisabled={movedEmployees.length === 0}
                      onClick={() => {
                        setAvailableEmployees((val) => [...val, ...movedEmployees])
                        setMovedEmployees([])
                        setSelectedEmployees([])
                        setSelectedMovedEmployees([])
                      }}
                    >
                      <div style={{ transform: 'rotate(-90deg)' }}>
                        <HipchatChevronDoubleUpIcon label="double arrow right" />
                      </div>
                    </Button>
                  </Box>
                </ButtonsWrapper>
              </Box>
              <Box
                xcss={xcss({
                  width: '370px',
                  padding: 'space.200',
                  borderRadius: 'border.radius.050',
                  border: '1px solid',
                  borderColor: 'color.border.disabled',
                })}
              >
                <Box
                  xcss={xcss({
                    color: 'color.text',

                    fontFamily: 'inherit',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px',
                  })}
                >
                  Selected employees
                </Box>
                {movedEmployees.length === 0 ? (
                  <Box
                    xcss={xcss({
                      marginTop: 'space.050',
                      color: 'color.text.subtlest',
                      fontFamily: 'inherit',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                    })}
                  >
                    You haven’t added any employees yet.
                  </Box>
                ) : (
                  <Box
                    xcss={xcss({
                      marginTop: 'space.200',
                    })}
                  />
                )}
                <EmployeeList
                  height={406}
                  filter={(item) => true}
                  availableEmployees={movedEmployees}
                  selectedEmployees={selectedMovedEmployees}
                  onClick={(item: Employee) => {
                    setSelectedMovedEmployees((val) => {
                      if (val.find((obj) => obj.id === item.id)) {
                        return val.filter((obj) => obj.id !== item.id)
                      }
                      return [...val, item]
                    })
                  }}
                />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              isDisabled={!movedEmployees.length}
              appearance="primary"
              onClick={() => {
                sendEmployees(onConfirm)
                closeModal()
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}
