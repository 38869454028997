// TODO: fix eslint errors
/* eslint-disable */
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { EMPLOYEE_PROFILE } from 'utils/constants/routes'
import { EmployeesForEmployeeStatusWidget } from 'shared/Main/EmployeesForEmployeeStatusWidget/data/EmployeesForEmployeeStatusWidget'
import { EMPLOYEES, FILTERS } from 'utils/constants/api'
import { xcss, Box, Inline } from '@atlaskit/primitives'
import Avatar from '@atlaskit/avatar'
import ShipIcon from '@atlaskit/icon/glyph/ship'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CustomCarousel from '../../CustomCarousel'
import CalendarIcon from '@atlaskit/icon/glyph/calendar'
import { formatDateToFriendly } from 'utils/helpers/DateTime/dateFromStringWithDefaultTimezone'
import FilterToggle from '../../FilterToggle'
import Tooltip from '@atlaskit/tooltip'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.43,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1440, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}

const AvatarWrapper: FC<{
  children: ReactElement
}> = styled.div`
  ${(props) => `
  height:96px;
  & > div>span{
    h
    svg{
    
    }
    margin:2px;

    
  }
  
  `}
`

type Option = {
  label: string
  value: string
  company: string
}

function OutgoingEmployeesWidget({ employee }: any) {
  const [orgUnitOptions, setOrgUnitOptions] = useState<Option[]>([])

  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<Option[]>([])

  const [outgoingEmployees, setOutgoingEmployees] = useState<EmployeesForEmployeeStatusWidget[]>([])
  const [inited, setInited] = useState(false)

  useEffect(() => {
    if (employee) {
      fetch(`${EMPLOYEES}/outgoing-widget`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const outgoing = data
          setOutgoingEmployees(outgoing)
        })

      fetch(`${FILTERS}/calendar/org-units-by-employees`)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          return {}
        })
        .then((data) => {
          if ('orgUnits' in data) {
            const companies = Object.values(
              (data as { orgUnits: any }).orgUnits as {
                name: string
                id: string
                companyName: string
              }[],
            ).map((item) => ({
              label: item.companyName,
              value: item.companyName,
            }))

            const uniqCompanies: {
              [key: string]: any
            } = {}

            companies.forEach((elm) => {
              if (elm.label) {
                uniqCompanies[elm.label] = elm
              }
            })

            const selectedItems = Object.values(uniqCompanies).filter((item) => item.value === employee.companyName)
            const unselectedItems = Object.values(uniqCompanies).filter((item) => !selectedItems.includes(item))

            setOrgUnitOptions([...selectedItems, ...unselectedItems])
            setSelectedOrgUnitOptions(selectedItems)
            setInited(true)
          }
        })
    }
  }, [employee])

  const outgoingEmployeesFiltered = inited
    ? outgoingEmployees.filter(
        (item) =>
          selectedOrgUnitOptions.length === 0 || selectedOrgUnitOptions.some((elm) => item.companyName === elm.value),
      )
    : []

  return (
    <Box
      xcss={xcss({
        marginTop: 'space.300',
        padding: 'space.200',
        backgroundColor: 'elevation.surface',
        color: 'color.text',
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        border: '1px solid',
        borderColor: 'color.border.disabled',
        borderRadius: 'border.radius.050',
        minHeight: '96px',
      })}
    >
      <Box
        xcss={xcss({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        })}
      >
        <Box>
          <Box
            xcss={xcss({
              fontFamily: 'inherit',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              color: 'color.text',
            })}
          >
            Say goodbye to
          </Box>
        </Box>
        <FilterToggle
          onSelectClick={() => {
            const unselectedItems = orgUnitOptions.filter((item) => !selectedOrgUnitOptions.includes(item))

            setOrgUnitOptions([...selectedOrgUnitOptions, ...unselectedItems])
          }}
          options={orgUnitOptions}
          onChange={(values) => {
            setSelectedOrgUnitOptions(values as Option[])
          }}
          value={selectedOrgUnitOptions}
        />
      </Box>

      <Box xcss={xcss({ marginTop: 'space.100' })}>
        {' '}
        {!(outgoingEmployeesFiltered.length > 0) ? (
          <Box
            xcss={xcss({
              color: 'color.text.disabled',
              display: 'flex',
              fontFamily: 'inherit',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              marginTop: '8px',
            })}
          >
            <ShipIcon label="ShipIcon" />
            <Box xcss={xcss({ marginLeft: 'space.100', marginTop: '2px' })}>
              Great news! No one is leaving us anytime soon. Let's keep up the awesome work together!
            </Box>
          </Box>
        ) : (
          <CustomCarousel responsiveParams={responsive}>
            {outgoingEmployeesFiltered.map((request, index) => (
              <Box
                key={request.id}
                xcss={xcss({
                  paddingTop: 'space.200',
                  paddingBottom: 'space.200',
                  paddingRight: 'space.200',
                  paddingLeft: 'space.200',
                  width: '290px',
                  minHeight: '148px',
                  overflow: 'hidden',
                  border: '1px solid red',
                  borderRadius: 'border.radius.100',
                  borderColor: 'color.border.disabled',
                  display: 'flex',
                })}
              >
                <Box
                  xcss={xcss({
                    position: 'relative',
                    marginRight: 'space.200',
                  })}
                >
                  <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`${EMPLOYEE_PROFILE}/${request.id}`}>
                    <AvatarWrapper>
                      <Avatar src={request.avatar} size="xlarge" />
                    </AvatarWrapper>
                  </Link>
                </Box>
                <Box>
                  <Box
                    xcss={xcss({
                      color: 'color.text',
                      fontFamily: 'inherit',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '20px',
                    })}
                  >
                    <Link style={{ color: 'inherit' }} to={`${EMPLOYEE_PROFILE}/${request.id}`}>
                      <Tooltip position="bottom" content={`${request.firstNameEn} ${request.lastNameEn}`}>
                        <Box
                          xcss={xcss({
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '140px',
                            height: '20px',
                            whiteSpace: 'nowrap',
                          })}
                        >
                          {request.firstNameEn} {request.lastNameEn}
                        </Box>{' '}
                      </Tooltip>{' '}
                    </Link>
                  </Box>
                  <Box
                    xcss={xcss({
                      color: 'color.text.subtlest',

                      fontFamily: 'inherit',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                    })}
                  >
                    {request.nickname}
                  </Box>
                  {request.companyName && (
                    <Box
                      xcss={xcss({
                        color: 'color.text.subtle',
                        fontFamily: 'inherit',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        marginTop: '4px',
                      })}
                    >
                      <Tooltip delay={0} position="bottom" content={request.companyName}>
                        {(tooltipProps) => (
                          <Box
                            {...tooltipProps}
                            xcss={xcss({
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              maxWidth: '146px',
                            })}
                          >
                            {request.companyName}
                          </Box>
                        )}
                      </Tooltip>
                    </Box>
                  )}
                  {request.jobTitle && (
                    <Box
                      xcss={xcss({
                        color: 'color.text.subtlest',
                        fontFamily: 'inherit',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        marginTop: '3px',
                      })}
                    >
                      <Tooltip delay={0} position="bottom" content={request.jobTitle}>
                        {(tooltipProps) => (
                          <Box
                            {...tooltipProps}
                            xcss={xcss({
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              maxWidth: '146px',
                            })}
                          >
                            {request.jobTitle}
                          </Box>
                        )}
                      </Tooltip>
                    </Box>
                  )}
                  {request.terminationDate && (
                    <Box
                      style={{
                        marginTop: '6px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box>
                        <CalendarIcon label="CalendarIcon" primaryColor="#758195" size="small" />
                      </Box>
                      <Box
                        xcss={xcss({
                          color: 'color.text.subtlest',
                          marginLeft: 'space.050',
                          fontFamily: 'inherit',
                          fontSize: '11px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '14px',
                        })}
                      >
                        {`${formatDateToFriendly(request.terminationDate)}, ${new Date(
                          request.terminationDate,
                        ).getFullYear()}`}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </CustomCarousel>
        )}
      </Box>
    </Box>
  )
}

export default OutgoingEmployeesWidget
