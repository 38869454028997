import styled from 'styled-components'

export const DeleteModalStyles = styled.div`
  color: var(--Components-Modal-Component-titleColor, rgba(0, 0, 0, 0.88));
  font-style: normal;

  .title {
    display: flex;
    font-size: var(--Components-Modal-Component-titleFontSize, 16px);
    font-weight: var(--Components-Modal-Global-fontWeightStrong, 600);
    line-height: var(--Typography-Line-Height-lineHeightLG, 24px);

    &__icon {
      font-size: 24px;
      color: #ff4d4f;
      margin-right: 10px;
    }
  }

  .currency {
    margin: 20px 0 12px;
    text-align: left;
    font-size: var(--Typography-Font-Size-fontSize, 14px);
    font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
    line-height: var(--Typography-Line-Height-lineHeight, 22px);
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 0 8px;
  }
`
