import styled from 'styled-components'

export const BonusesPenaltiesStyles = styled.div`
  width: 1144px;
  padding: 24px 0 0 24px;
  position: relative;

  .table-header-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 24px;
  }
`
