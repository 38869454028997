import { useId } from 'react'
import { Dropdown, Tag } from 'antd'
import { DropDownIcon } from 'components'
import { DropdownViewStyles, DropdownRenderStyles } from './styles'
import { IDropdownViewProps } from './types'

export const DropdownView = (props: IDropdownViewProps) => {
  const { label, children, selectedCounter, selectedLabel, onClear, hasSearch = false, dropdownHeight = 300 } = props

  const containerId: string = useId()

  const handleClear = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    onClear()
  }

  return (
    <DropdownViewStyles id={containerId}>
      <Dropdown
        trigger={['click']}
        getPopupContainer={() => document.getElementById(containerId) as HTMLElement}
        dropdownRender={() => (
          <DropdownRenderStyles $dropdownHeight={dropdownHeight} $hasSearch={hasSearch}>
            {children}
          </DropdownRenderStyles>
        )}
      >
        <div className="dropdown-label-content">
          {selectedCounter ? (
            <div className="selected-label">
              <Tag closable onClose={handleClear} bordered={false} color="default">
                {selectedCounter}
              </Tag>
              <span className="label">{selectedLabel}</span>
            </div>
          ) : (
            <>
              <span className="label">{label}</span>
              <DropDownIcon />
            </>
          )}
        </div>
      </Dropdown>
    </DropdownViewStyles>
  )
}
