import { Form, Modal, Radio, Select, Space } from 'antd'
import { useEffect, useMemo } from 'react'
import { Button, DropDownIcon, FormDatepicker, FormInputAmount } from 'components'
import type { SelectProps } from 'antd'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from 'hooks'
import { CreateBonusesPenaltiesPayload } from 'services/api/bonus-penalty/types'
import { RangePickerProps } from 'antd/es/date-picker'
import {
  useCreateBonusPenaltyMutation,
  useGetSystemCurrencyQuery,
  useGetEmployeesBonusPenaltyQuery,
} from 'services/api'
import { closeBonusPenaltyModal, triggerNotification } from 'store'
import { EmployeeCard } from './components'
import { schema } from './schema'
import { BonusPenaltyFormModalStyles } from './styles'
import { BonusPenaltyFormValues, BonusPenaltyFormModalProps } from './types'
import { defaultValues, bonusPenaltySelectOptions } from './constants'

export const BonusPenaltyFormModal = ({ createFromProfile = false, employee }: BonusPenaltyFormModalProps) => {
  const dispatch = useAppDispatch()

  const { data: currencyList, isLoading: isLoadingCurrencies } = useGetSystemCurrencyQuery()

  // eslint-disable-next-line max-len
  const { data: employeeList = { items: [], total: 0 }, isLoading: isLoadingBonusPenaltyEmployees } =
    useGetEmployeesBonusPenaltyQuery(undefined, {
      skip: createFromProfile,
    })

  const hasEmployeesList = Boolean(employeeList.items.length)

  const [createBonusPenalty, { isLoading: isCreatingBonusPenalty }] = useCreateBonusPenaltyMutation()

  const { handleSubmit, control, reset, setValue } = useForm<BonusPenaltyFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const currencySelectOptions: SelectProps['options'] = useMemo(() => {
    if (!currencyList) return []

    return currencyList.map(({ currencyId, code, name }) => ({
      label: `${code} - ${name}`,
      value: currencyId,
    }))
  }, [currencyList])

  const employeeSubordinateSelectOptions: JSX.Element[] = useMemo(() => {
    if (hasEmployeesList && employee) {
      const combineEmployeesList = [
        {
          id: employee.id,
          firstNameEn: employee.firstNameEn,
          lastNameEn: employee.lastNameEn,
          nickname: employee.nickname,
          imageUrl: employee.imageUrl,
          email: employee.email,
        },
        ...employeeList.items,
      ]

      return combineEmployeesList.map((employeeItem) => (
        <Select.Option
          key={`${employeeItem.id}`}
          value={employeeItem.id}
          label={`${employeeItem.firstNameEn} ${employeeItem.lastNameEn} ${employeeItem.nickname ? `(${employeeItem.nickname})` : ''}`}
        >
          <EmployeeCard employee={employeeItem} />
        </Select.Option>
      ))
    }

    return []
  }, [employeeList.items])

  const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current > dayjs().endOf('day')

  const handleClose = () => {
    reset()
    dispatch(closeBonusPenaltyModal())
  }

  const onSubmit: SubmitHandler<BonusPenaltyFormValues> = async (data) => {
    try {
      // TODO - Fix the type of data
      await createBonusPenalty(data as CreateBonusesPenaltiesPayload).unwrap()
      dispatch(triggerNotification({ type: 'success', message: "You've added a new incentive." }))
      handleClose()
    } catch (error) {
      console.error('Error creating incentive', error)
    }
  }

  useEffect(() => {
    if (employee && createFromProfile) {
      setValue('employeeId', employee.id)
    }
  }, [])

  return (
    <Modal
      title="Add bonus or penalty"
      open
      footer={null}
      onCancel={handleClose}
      destroyOnClose
      width={400}
      maskClosable={false}
    >
      <BonusPenaltyFormModalStyles>
        {createFromProfile && employee && (
          <div className="employee-card-wrap">
            <EmployeeCard employee={employee} size="large" />
          </div>
        )}
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          {hasEmployeesList && (
            <Controller
              name="employeeId"
              control={control}
              render={({ field: { name, ...rest }, fieldState: { error } }) => (
                <Form.Item
                  name={name}
                  label="Employee"
                  layout="vertical"
                  validateStatus={error && 'error'}
                  help={error?.message}
                >
                  <Select
                    {...rest}
                    showSearch
                    placeholder="Select employee"
                    optionLabelProp="label"
                    loading={isLoadingBonusPenaltyEmployees}
                    suffixIcon={<DropDownIcon />}
                  >
                    {employeeSubordinateSelectOptions}
                  </Select>
                </Form.Item>
              )}
            />
          )}
          <Controller
            name="type"
            control={control}
            render={({ field: { name, ...rest }, fieldState: { error } }) => (
              <Form.Item
                name={name}
                label="Choose incentive"
                layout="vertical"
                validateStatus={error && 'error'}
                help={error?.message}
              >
                <Radio.Group
                  {...rest}
                  className={clsx({ invalid: Boolean(error?.message) })}
                  options={bonusPenaltySelectOptions}
                  optionType="button"
                />
              </Form.Item>
            )}
          />
          <Controller
            name="currencyId"
            control={control}
            render={({ field: { name, ...rest }, fieldState: { error } }) => (
              <Form.Item
                name={name}
                label="Currency"
                layout="vertical"
                validateStatus={error && 'error'}
                help={error?.message}
              >
                <Select
                  {...rest}
                  showSearch
                  loading={isLoadingCurrencies}
                  placeholder="Select currency"
                  optionFilterProp="label"
                  options={currencySelectOptions}
                  suffixIcon={<DropDownIcon />}
                />
              </Form.Item>
            )}
          />
          <FormInputAmount<BonusPenaltyFormValues> control={control} name="amount" />
          <FormDatepicker<BonusPenaltyFormValues>
            control={control}
            name="effectiveDate"
            placeholder="Select date"
            label="Effective date"
            disabledDate={disabledDate}
          />
          <Space className="modal-footer" size={8}>
            <Button type="default" onClick={handleClose}>
              Close
            </Button>
            <Button type="primary" loading={isCreatingBonusPenalty} htmlType="submit">
              Add
            </Button>
          </Space>
        </Form>
      </BonusPenaltyFormModalStyles>
    </Modal>
  )
}
