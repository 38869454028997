import { ReactNode } from 'react'
import Tooltip, { PositionType } from '@atlaskit/tooltip'
import { TextTooltipStyles } from './styles'

interface LongTextTooltipProps {
  content: string
  maxContentLength: number
  children: ReactNode
  position?: PositionType
}

export const LongTextTooltip = ({
  content,
  maxContentLength,
  position = 'bottom-end',
  children,
}: LongTextTooltipProps) => (
  <TextTooltipStyles>
    {content.length > maxContentLength ? (
      <Tooltip content={content} position={position}>
        {(tooltipProps) => <p {...tooltipProps}>{children}</p>}
      </Tooltip>
    ) : (
      <p>{children}</p>
    )}
  </TextTooltipStyles>
)
