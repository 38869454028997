// TODO: fix eslint errors
/* eslint-disable */
import '../index.scss'
import './DateSelector.scss'
import React, { useState } from 'react'
import { SelectorData } from './data/SelectorData'
import Select from 'react-select'
import { SelectValue } from '../../../../shared/Common/data/SelectValue'

type DateSelector = {
  year: number | null
  data: SelectorData
  label: string
  onChange: (value: SelectorData) => void
  horizontal: boolean
  required: boolean
}

function DateSelector(props: DateSelector) {
  const monthOptions: SelectValue[] = [
    { label: 'January', value: '1' },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ]

  const weekdayOptions: SelectValue[] = [
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' },
    { label: 'Sunday', value: 'Sunday' },
  ]

  const [selectedMonth, setSelectedMonth] = useState(props.data ? props.data.month : '1')
  const [selectedWeek, setSelectedWeek] = useState(props.data ? props.data.weekNumber : '1')
  const [selectedWeekday, setSelectedWeekday] = useState(props.data ? props.data.dayOfWeek : 'Monday')

  const getDaysInMonth = (year: number, month: number) => new Date(year, month, 0).getDate()

  const handleMonthChange = (e: any) => {
    setSelectedMonth(e.value)
    if (Number(selectedWeek) > Math.ceil(getDaysInMonth(props.year ?? new Date().getFullYear(), e.value) / 7)) {
      setSelectedWeek('1')
    }
    const selectedValue = e.value
    const newData: SelectorData = {
      month: selectedValue,
      dayOfWeek: selectedWeekday,
      weekNumber: selectedWeek,
    }
    props.onChange(newData)
  }

  const handleWeekChange = (e: any) => {
    const week = e.target.value
    const maxWeeks = Math.ceil(getDaysInMonth(props.year ?? new Date().getFullYear(), parseInt(selectedMonth)) / 7)
    if (Number(week) > maxWeeks) {
      setSelectedWeek(maxWeeks.toString())
    } else {
      setSelectedWeek(week)
    }
    const selectedValue = e.target.value
    const newData: SelectorData = {
      month: selectedMonth,
      dayOfWeek: selectedWeekday,
      weekNumber: selectedValue,
    }
    props.onChange(newData)
  }

  const handleWeekdayChange = (e: any) => {
    setSelectedWeekday(e.value)
    const selectedValue = e.value
    const newData: SelectorData = {
      month: selectedMonth,
      dayOfWeek: selectedValue,
      weekNumber: selectedWeek,
    }
    props.onChange(newData)
  }

  return (
    <div className={`input data-selector${props.horizontal ? ' horizontal-input' : ''}`}>
      <label>
        {props.label}
        {props.required ? <span>*</span> : ''}
      </label>
      <div className="input-container date-selector">
        <i className="material-icons month-icon">event</i>
        <Select
          isMulti={false}
          id="month"
          className="month"
          value={monthOptions.find((monthOption) => monthOption.value === selectedMonth)}
          options={monthOptions}
          isClearable={false}
          classNamePrefix="react-select"
          onChange={(e: any) => {
            props.onChange(e)
            handleMonthChange(e)
          }}
          isSearchable={false}
        />
        <span className="label-week">Week</span>
        <input id="week" type="number" min={1} max={4} value={selectedWeek} onChange={handleWeekChange} />
        <Select
          isMulti={false}
          id="weekday"
          className="weekday"
          value={weekdayOptions.find((weekdayOption) => weekdayOption.value === selectedWeekday)}
          options={weekdayOptions}
          isClearable={false}
          classNamePrefix="react-select"
          onChange={(e: any) => {
            props.onChange(e)
            handleWeekdayChange(e)
          }}
          isSearchable={false}
        />
      </div>
    </div>
  )
}

export default DateSelector
