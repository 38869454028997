import { AUTH } from "../../utils/constants/api";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errors";
import * as Sentry from "@sentry/react";

export const checkAuth = async () => {
  try {
    const response = await fetch(AUTH);

    if (response.status === AUTHORIZATION_ERROR) return null;

    if (!response.ok)
      throw new Error(`Network response was not ok: ${  response.statusText}`);

    return await response.json();
  } catch (error) {
    Sentry.captureException(error);
    throw new Error();
  }
};
