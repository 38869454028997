import { RowType } from '@atlaskit/dynamic-table/dist/types/types'
import { Inline, Box, xcss } from '@atlaskit/primitives'
import Avatar from '@atlaskit/avatar'
import { Link } from 'react-router-dom'
import { GetTableRowsType } from './types'
import { ITimeOffLogProps } from 'models'
import { getFormattedDate } from 'utils/helpers/formatted-date'
import { tableColumnsMap } from './constants'
import classNames from 'classnames'
import { UserBlock, StyledUserBlockInfo } from './styles'

export const getTableRows = ({ sourceData }: GetTableRowsType): Array<RowType> =>
  sourceData.map((item: ITimeOffLogProps) => ({
    key: tableColumnsMap.employeeId,
    isHighlighted: false,
    cells: [
      {
        key: tableColumnsMap.date,
        content: getFormattedDate(item.date, 'coreHRDateFormat'),
      },
      {
        key: tableColumnsMap.quantity,
        content: (
          <div className={classNames('policy-amount', item.quantity > 0 ? 'is-positive' : 'is-negative')}>
            {item.quantity}
          </div>
        ),
      },
      {
        key: tableColumnsMap.changedByEmployeeId,
        content: (
          <Inline xcss={UserBlock}>
            {item.changedByName === 'Auto' ? (
              <span>{item.changedByName}</span>
            ) : (
              <>
                <Avatar name={item.changedByName} src={item.changedByAvatar} size="small" appearance="circle" />
                <StyledUserBlockInfo>
                  <Link to={`/employee/${item.changedByEmployeeId}`}>
                    <Box
                      xcss={xcss({
                        width: '160px',
                        textOverflow: 'ellipsis',
                        overflow: 'hemployeeIdden',
                        textWrap: 'nowrap',
                      })}
                    >
                      {item.changedByName}
                    </Box>
                  </Link>
                  <span>{item.changedByNickname}</span>
                </StyledUserBlockInfo>
              </>
            )}
          </Inline>
        ),
      },
      {
        key: tableColumnsMap.balance,
        content: item.balance,
      },
      {
        key: tableColumnsMap.comment,
        content: item.comment,
      },
    ],
  }))
