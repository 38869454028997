import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

export function getGMTOffsetByTimezoneName(location: string): string {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const offsetMinutes = dayjs().tz(location).utcOffset()
  const offsetSign = offsetMinutes < 0 ? '-' : '+'
  const absoluteOffsetMinutes = Math.abs(offsetMinutes)
  const hours = Math.floor(absoluteOffsetMinutes / 60)
    .toString()
    .padStart(2, '0')
  const minutes = (absoluteOffsetMinutes % 60).toString().padStart(2, '0')

  return `(GMT${offsetSign}${hours}:${minutes})`
}
