import { object, string, ObjectSchema, number } from 'yup'
import { RateFormValues } from './types'

export const schema: ObjectSchema<RateFormValues> = object({
  currencyId: string().required('Please choose currency'),
  rate: number()
    .min(0.01, 'Min rate is 0.01')
    .max(9999999.99, 'Max rate is 9,999,999.99')
    .required('Please enter an rate'),
  startDate: string().required('Please select effective date'),
}).required()
