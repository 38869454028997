
import { SelectValue } from "../../shared/Common/data/SelectValue";

function sortASC(options: SelectValue[]) {
    return options.sort((a, b) => {
      const labelA = (a.label || "").toUpperCase();
      const labelB = (b.label || "").toUpperCase();

      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  }

  function sortFilter(options: SelectValue[], selectedOptions: SelectValue[]) {

    const unselectedOptions = options.filter(
      (option) =>
        !selectedOptions.some((selected) => selected.value === option.value)
    );

    return [...selectedOptions, ...unselectedOptions] as SelectValue[];
  }


  export {sortASC, sortFilter};
