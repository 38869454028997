import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const TableFiltersStyles = styled.div`
  .filter {
    &-body {
      display: grid;
      grid-template-columns: 240px 160px 160px;
      gap: ${token("space.200")}; 

      .text-field .field, .select-field .select__control {
        border: 2px solid rgba(9, 30, 66, 0.14);
      }

      .text-field {
        .field {
          padding: 0px ${token("space.100")};
        }
      }
    }
  }
`
