import type {
  DayRange,
  Day,
  Locale,
} from "@hassanmojab/react-modern-calendar-datepicker";

export const monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const localeCalendar: Locale = {
  // months list by order
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  // week days by order
  weekDays: [
    {
      name: "Monday",
      short: "Mon",
    },
    {
      name: "Tuesday",
      short: "Tue",
    },
    {
      name: "Wednesday",
      short: "Wed",
    },
    {
      name: "Thursday",
      short: "Thu",
    },
    {
      name: "Friday",
      short: "Fri",
    },
    {
      name: "Saturday",
      short: "Sat",
      isWeekend: true,
    },
    {
      name: "Sunday", // used for accessibility
      short: "Sun", // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 6,

  // return a { year: number, month: number, day: number } object
  // eslint-disable-next-line
  getToday(gregorainTodayObject: any) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  // eslint-disable-next-line
  toNativeDate(date: any) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  // eslint-disable-next-line
  getMonthLength(date: any) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  // eslint-disable-next-line
  transformDigit(digit: any) {
    return digit;
  },

  // texts in the date picker
  nextMonth: "Next Month",
  previousMonth: "Previous Month",
  openMonthSelector: "Open Month Selector",
  openYearSelector: "Open Year Selector",
  closeMonthSelector: "Close Month Selector",
  closeYearSelector: "Close Year Selector",
  defaultPlaceholder: "Select...",

  // for input range value
  from: "from",
  to: "to",

  // used for input value when multi dates are selected
  digitSeparator: ",",

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
};

export const getWeekDayMondayFirst = (day: number): number => (day + 6) % 7;

export const oneDayInMilliseconds = 86400000;

export const todayNativeDate = new Date();
export const yesterdayNativeDate = new Date(
  todayNativeDate.getTime() - oneDayInMilliseconds
);
export const startThisWeekNativeDate = new Date(
  todayNativeDate.getTime() -
    oneDayInMilliseconds * getWeekDayMondayFirst(todayNativeDate.getDay())
);

export const startLastWeekNativeDate = new Date(
  todayNativeDate.getTime() -
    oneDayInMilliseconds * (getWeekDayMondayFirst(todayNativeDate.getDay()) + 7)
);
export const endLastWeekNativeDate = new Date(
  todayNativeDate.getTime() -
    oneDayInMilliseconds * (getWeekDayMondayFirst(todayNativeDate.getDay()) + 1)
);

export const startThisMonthNativeDate = new Date(
  todayNativeDate.getTime() -
    oneDayInMilliseconds * (todayNativeDate.getDate() - 1)
);

export const endLastMonthNativeDate = new Date(
  todayNativeDate.getTime() - oneDayInMilliseconds * todayNativeDate.getDate()
);
export const startLastMonthNativeDate = new Date(
  endLastMonthNativeDate.getTime() -
    oneDayInMilliseconds * (endLastMonthNativeDate.getDate() - 1)
);

export const todayObj: Day = {
  day: todayNativeDate.getDate(),
  month: todayNativeDate.getMonth() + 1,
  year: todayNativeDate.getFullYear(),
};

export const yesterdayObj: Day = {
  day: yesterdayNativeDate.getDate(),
  month: yesterdayNativeDate.getMonth() + 1,
  year: yesterdayNativeDate.getFullYear(),
};

export const startThisWeekObj: Day = {
  day: startThisWeekNativeDate.getDate(),
  month: startThisWeekNativeDate.getMonth() + 1,
  year: startThisWeekNativeDate.getFullYear(),
};

export const startLastWeekObj: Day = {
  day: startLastWeekNativeDate.getDate(),
  month: startLastWeekNativeDate.getMonth() + 1,
  year: startLastWeekNativeDate.getFullYear(),
};

export const endLastWeekObj: Day = {
  day: endLastWeekNativeDate.getDate(),
  month: endLastWeekNativeDate.getMonth() + 1,
  year: endLastWeekNativeDate.getFullYear(),
};

export const startThisMonthObj: Day = {
  day: startThisMonthNativeDate.getDate(),
  month: startThisMonthNativeDate.getMonth() + 1,
  year: startThisMonthNativeDate.getFullYear(),
};

export const startLastMonthObj: Day = {
  day: startLastMonthNativeDate.getDate(),
  month: startLastMonthNativeDate.getMonth() + 1,
  year: startLastMonthNativeDate.getFullYear(),
};

export const endLastMonthObj: Day = {
  day: endLastMonthNativeDate.getDate(),
  month: endLastMonthNativeDate.getMonth() + 1,
  year: endLastMonthNativeDate.getFullYear(),
};

export const yesterdayRangeObj: DayRange = {
  from: yesterdayObj,
  to: yesterdayObj,
};

export const thisWeekRangeObj: DayRange = {
  from: startThisWeekObj,
  to: yesterdayObj,
};

export const lastWeekRangeObj: DayRange = {
  from: startLastWeekObj,
  to: endLastWeekObj,
};

export const thisMonthRangeObj: DayRange = {
  from: startThisMonthObj,
  to: yesterdayObj,
};

export const lastMonthRangeObj: DayRange = {
  from: startLastMonthObj,
  to: endLastMonthObj,
};

export const defaultRangeAttendanceLog = {
  range: thisMonthRangeObj,
  name: "This Month",
};

export const intendedRanges = [
  {
    range: yesterdayRangeObj,
    name: "Yesterday",
  },
  {
    range: thisWeekRangeObj,
    name: "This Week",
  },
  {
    range: lastWeekRangeObj,
    name: "Last Week",
  },
  {
    range: thisMonthRangeObj,
    name: "This Month",
  },
  {
    range: lastMonthRangeObj,
    name: "Last Month",
  },
];
