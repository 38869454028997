import { useAppDispatch } from 'hooks'
import { FilterCheckboxGroup, FilterRangeDates } from 'components'
import { employeeRequestStatusSelectOptions } from 'shared/constants'
import { setEmployeeBonusPenaltyFilters } from 'store'
import { Space } from 'antd'

export const Filters = () => {
  const dispatch = useAppDispatch()

  const handleChangeCreationDates = (dates: string[]) => {
    dispatch(
      setEmployeeBonusPenaltyFilters({
        effectiveDate: dates,
      }),
    )
  }

  const handleChangeStatus = (statuses: string[]) => {
    dispatch(
      setEmployeeBonusPenaltyFilters({
        statuses,
      }),
    )
  }

  return (
    <Space size={16}>
      <FilterRangeDates placeholder="Effective date" onChange={handleChangeCreationDates} />
      <FilterCheckboxGroup
        label="Statuses"
        options={employeeRequestStatusSelectOptions}
        onChange={handleChangeStatus}
      />
    </Space>
  )
}
