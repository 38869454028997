// TODO: fix eslint errors
/* eslint-disable */
import { FC } from 'react'
import { StyledEmptyFiltersBlock } from './EmptyFiltersPlaceholderStyles'

interface EmptyFiltersPlaceholderProps {
  title: string
  info: string
}

export const EmptyFiltersPlaceholder: FC<EmptyFiltersPlaceholderProps> = ({ title, info }) => (
  <>
    <StyledEmptyFiltersBlock>
      <img src="/image/NoFiltersFound.svg" />
      <h3>{title}</h3>
      <p>{info}</p>
    </StyledEmptyFiltersBlock>
  </>
)
