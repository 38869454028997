import styled from 'styled-components'
import { Button } from 'antd'

export const StyledButton = styled(Button)`
  &&& {
    &.core-hr-secondary-btn {
      background-color: rgba(233, 242, 255, 1);
      border: none;
      color: #000;
      font-weight: 500;
      font-size: 14px;
      gap: 0;
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

      &:hover {
        background-color: rgba(186, 224, 255, 1);
        color: #000;
      }

      &:active {
        background: rgba(105, 177, 255, 1);
        color: #000;
      }
    }

    &.core-hr-purple-btn {
      background-color: rgba(114, 46, 209, 1);
      border: none;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      box-shadow: 0px 2px 0px 0px #f9f0ff;

      &:hover {
        background-color: rgba(146, 84, 222, 1);
        color: #fff;
      }

      &:active {
        background: rgba(114, 46, 209, 1);
        color: #fff;
      }
    }

    &.capitalize-text {
      text-transform: capitalize;
    }
  }
`
