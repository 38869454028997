import {AUTH} from "../../utils/constants/api";
import * as Sentry from "@sentry/react";

export const signOut = async () => {
    try {
        const response = await fetch(AUTH, { method: 'DELETE' });
        if(!response.ok) throw new Error();

        return await response.text();
    } catch (error) {
        Sentry.captureException(error);
        throw new Error();
    }
};
