import { useState } from 'react'
import { Button } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import { TableActionProps } from './types'
import { useDeleteSystemCurrencyMutation } from 'services/api'
import { DeleteModal } from '../delete-modal'
import { useAppDispatch } from 'hooks'
import { triggerNotification } from 'store'
import { TableActionsStyles } from './styles'

export const TableAction = (props: TableActionProps) => {
  const { data } = props
  const dispatch = useAppDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [deleteSystemCurrency, { isLoading }] = useDeleteSystemCurrencyMutation()

  const onCurrencyDelete = async () => {
    try {
      await deleteSystemCurrency({ currencyId: data.id }).unwrap()
      setIsModalOpen(false)
      dispatch(
        triggerNotification({
          type: 'success',
          message: "You've deleted a currency.",
        }),
      )
    } catch (error) {
      //TODO: fix TS and replace to notification from N1-823
      throw new Error('Something went wrong!')
    }
  }
  return (
    <TableActionsStyles>
      <Button size="small" icon={<DeleteFilled />} onClick={() => setIsModalOpen(true)} />
      <DeleteModal
        data={data}
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onSubmit={onCurrencyDelete}
        isLoading={isLoading}
      />
    </TableActionsStyles>
  )
}
