// TODO: Create common EmployeeItem component ( next task )
import { Avatar } from 'antd'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { getAbbr } from 'utils/helpers/get-abbr'
import clsx from 'clsx'
import { EmployeeCardTypeProps } from './types'
import { EmployeeCardStyles } from './styles'
import { EmployeeCardSize } from './constants'

export const EmployeeCard = (props: EmployeeCardTypeProps) => {
  const { employee, size = EmployeeCardSize.MEDIUM } = props
  const { firstNameEn, lastNameEn, nickname, email, imageUrl } = employee

  return (
    <EmployeeCardStyles className={clsx('employee-card', `size-${size}`)}>
      <Avatar
        alt="avatar"
        className="employee-avatar"
        src={imageUrl ? <LazyLoadImage src={imageUrl} effect="blur" /> : ''}
      >
        {getAbbr(`${firstNameEn} ${lastNameEn}`)}
      </Avatar>
      <div className="employee">
        <span className="employee-name">{`${firstNameEn} ${lastNameEn} ${nickname ? `( ${nickname} )` : ''}`}</span>
        <span className="employee-email">{email}</span>
      </div>
    </EmployeeCardStyles>
  )
}
