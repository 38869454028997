import styled from 'styled-components'

export const EmployeeCardStyles = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-avatar-image,
  .lazy-load-image-loaded {
    width: 24px;
    height: 24px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .employee {
    display: flex;
    flex-direction: column;
    line-height: 16px;
    font-size: 12px;

    &-name {
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.88);
    }

    &-email {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-avatar-string {
    font-size: 14px;
  }

  // Large size

  &.size-large {
    .ant-avatar-image,
    .lazy-load-image-loaded {
      width: 32px;
      height: 32px;

      img {
        width: 32px;
        height: 32px;
      }
    }

    .employee {
      display: flex;
      flex-direction: column;

      &-name {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
`
