import styled from 'styled-components'

export const CheckInOutStyles = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .time-info {
    line-height: 18px;
    color: #626f86;
    display: flex;
    flex-direction: column;

    &__point {
      display: flex;
      justify-content: space-between;
      gap: 8px;
    }
  }
`
