import styled from 'styled-components'

export const SupportModalStyles = styled.div`
  form {
    display: grid;
  }

  .ant-form-item {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .modal-footer {
    padding: 12px 0 0;

    .ant-form-item-control-input-content {
      display: flex;
      gap: 0 8px;
    }
  }

  .response {
    &-title {
      color: var(--Colors-Neutral-colorTextBase, #000);
      text-align: center;
      font-size: var(--Typography-Font-Size-fontSizeHeading4, 20px);
      font-style: normal;
      font-weight: var(--Typography-Font-Weight-fontWeightStrong, 700);
      line-height: var(--Typography-Line-Height-lineHeightHeading4, 28px);
      margin: 0;
    }

    &-text {
      color: var(--Colors-Neutral-Text-colorTextSecondary, rgba(0, 0, 0, 0.65));
      text-align: center;
      font-size: var(--Typography-Font-Size-fontSize, 14px);
      font-style: normal;
      font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
      line-height: var(--Typography-Line-Height-lineHeight, 22px);
      margin: 0;
    }

    &-footer {
      margin-top: 12px;
    }
  }
`
