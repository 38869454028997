import { EMPLOYEES } from "../../utils/constants/api";

const baseHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const statusAPI = {
  getUserAllowedStatusesTransitions(id: string) {
    return fetch(`${EMPLOYEES}/${id}/allowed_statuses_transitions`, {
      method: "GET",
      headers: baseHeaders,
    });
  },
  getUserStatuses(id: string) {
    return fetch(`${EMPLOYEES}/${id}/statuses`, {
      method: "GET",
      headers: baseHeaders,
    });
  },
  setUserStatus(id: string, statusId: string | number, dateStart: string) {
    return fetch(`${EMPLOYEES}/${id}/statuses`, {
      method: "POST",
      headers: baseHeaders,
      body: JSON.stringify({
        statusId: statusId.toString(),
        dateStart,
      }),
    });
  },
  updateUserStatus(id: string, statusId: string | number, dateStart: string) {
    return fetch(`${EMPLOYEES}/${id}/statuses/${statusId}`, {
      method: "PUT",
      headers: baseHeaders,
      body: JSON.stringify({ dateStart }),
    });
  },
  deleteUserStatus(id: string, statusId: string) {
    return fetch(`${EMPLOYEES}/${id}/statuses/${statusId}`, {
      method: "DELETE",
      headers: baseHeaders,
    });
  },
};
