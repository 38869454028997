import styled from 'styled-components'

export const LoaderStyles = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);

  .anticon {
    font-size: 24px;
    color: #1677ff;
  }
`
