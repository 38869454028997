// TODO: fix eslint errors
/* eslint-disable */
import { JOB_TITLES } from '../../../../../../utils/constants/api'
import ConfirmDeleteModal from '../../../../Layer/Modal/ConfirmDeleteModal'
import { JobTitleFromApi } from '../../../../../../shared/JobTitle/data/JobTitleFromApi'

type DeleteJobTitle = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  jobTitle: JobTitleFromApi
}

function DeleteJobTitle(props: DeleteJobTitle) {
  function deleteTitle(): void {
    fetch(`${JOB_TITLES}/${props.jobTitle.id}`, {
      method: 'DELETE',
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else {
        response.json().then((jsonData) => alert(jsonData.error))
      }
    })
  }

  return (
    <ConfirmDeleteModal
      opened={props.opened}
      modalTitle="Delete Job Title"
      cancelText="Cancel"
      deleteText={`<span>&laquo;${props.jobTitle.name}&raquo;</span>`}
      okText="Delete"
      onCancel={props.onClose}
      onOk={deleteTitle}
    />
  )
}

export default DeleteJobTitle
