import type { TableColumnsType } from 'antd'
import { Currency } from 'models'
import { CurrenciesTableRowProps } from 'pages/currencies/types'
import { TableAction } from '../table-action'

export const tableColumns: TableColumnsType<CurrenciesTableRowProps> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'ISO code',
    dataIndex: 'code',
    key: 'code',
    sorter: true,
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    key: 'symbol',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (_: unknown, record: Currency) => <TableAction data={record} />,
  },
]
