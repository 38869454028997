// TODO: fix eslint errors
/* eslint-disable */
import { ATTENDANCE, WORK_TIME_CORRECTION, ATTENDANCE_EVENT } from '../constants'
import { CorrectionTotalTimeProps, GetAttendanceLogProps, UpdateTimeAPIProps } from './attendanceLog.types'
import * as Sentry from '@sentry/react'

export const attendanceLog = async ({ employeeIds, dayTypes, startDate, endDate }: GetAttendanceLogProps) => {
  try {
    const response = await fetch(ATTENDANCE, {
      method: 'POST',
      body: JSON.stringify({
        employeeIds,
        dayTypes,
        startDate,
        endDate,
      }),
    })

    if (!response.ok || response.status !== 200) {
      const error = await response.json()
      throw new Error(error[0].message)
    }

    return await response.json()
  } catch (error: any) {
    Sentry.captureException(error)
    return {
      error: String(error.message),
    }
  }
}

export const correctionTotalTime = async ({
  employeeId,
  actionEmployeeId,
  date,
  timeCorrection,
}: CorrectionTotalTimeProps) => {
  try {
    const response = await fetch(WORK_TIME_CORRECTION, {
      method: 'POST',
      body: JSON.stringify({
        employeeId,
        actionEmployeeId,
        date,
        timeCorrection,
      }),
    })

    if (!response.ok || response.status !== 200) {
      const error = await response.json()
      throw new Error(error[0].message)
    }

    return await response.json()
  } catch (error: any) {
    Sentry.captureException(error)
    return {
      error: String(error.message),
    }
  }
}

export const updateTimeAPIs = async ({ eventId, time }: UpdateTimeAPIProps) => {
  try {
    const response = await fetch(`${ATTENDANCE_EVENT}/${eventId}`, {
      method: 'PUT',
      body: JSON.stringify({
        time,
      }),
    })

    if (!response.ok || response.status !== 200) {
      const error = await response.json()
      throw new Error(error[0].message)
    }

    return response
  } catch (error: any) {
    Sentry.captureException(error)
    return {
      error: String(error.message),
    }
  }
}
