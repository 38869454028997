import { Button, Space, Tooltip } from 'antd'
import { useUpdateBonusPenaltyMutation } from 'services/api'
import { CheckIcon, CloseIcon } from 'components'
import { TableActionProps } from './types'

export const TableAction = (props: TableActionProps) => {
  // const dispatch = useAppDispatch()
  const [updateBonusPenalty, { isLoading: isUpdatingStatus }] = useUpdateBonusPenaltyMutation()

  const {
    data: { employeeId, lastPayrollClosedDate, id, actions },
  } = props

  const { canApprove, canReject, canCancel } = actions

  // const onCopy = () => {
  //   dispatch(triggerNotification({ type: 'info', message: 'Link for the request is copied' }))
  // }

  const handleUpdateStatus = (status: TableActionProps['data']['status']) => {
    updateBonusPenalty({ employeeId, id, status })
  }

  return (
    <Space size={12} align="center">
      {/* <CopyToClipboard
        text={`${window.location.origin}${REQUESTS}?employee=${employeeId}&type=${type}&effectivedate=${effectiveDate}`}
        onCopy={onCopy}
      >
        <Button size="small" icon={<LinkIcon />} />
      </CopyToClipboard> */}
      {canApprove && (
        <Button type="primary" size="small" icon={<CheckIcon />} loading={isUpdatingStatus} onClick={() => handleUpdateStatus('approved')} />
      )}
      {canReject && <Button size="small" icon={<CloseIcon />} loading={isUpdatingStatus} onClick={() => handleUpdateStatus('rejected')} />}
      {canCancel && (
        <Tooltip
          placement="bottomRight"
          title={lastPayrollClosedDate ? `You can't cancel this request \n because it is in closed payroll period` : null}
          overlayStyle={{ maxWidth: '258px' }}
        >
          <Button size="small" disabled={Boolean(lastPayrollClosedDate)} loading={isUpdatingStatus} onClick={() => handleUpdateStatus('canceled')}>
            Cancel
          </Button>
        </Tooltip>
      )}
    </Space>
  )
}
