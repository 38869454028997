import { BonusPenaltyTableColumns } from './types'

export const tableColumnsMap: {
  [key in keyof BonusPenaltyTableColumns]: keyof BonusPenaltyTableColumns
} = {
  id: 'id',
  amount: 'amount',
  creationDate: 'creationDate',
  status: 'status',
  type: 'type',
  effectiveDate: 'effectiveDate',
}
