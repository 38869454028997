import { Tag } from 'components'
import { getFormattedDate } from 'utils/helpers/formatted-date'
import { currencyFormatter } from 'utils/helpers/currency-formatter'
import { bonusPenaltyColorMap, bonusPenaltyLabelMap } from 'shared/constants/employee-bonus-penalty'
import { BonusPenalty } from 'models'
import { SmileOutlined } from '@ant-design/icons'
import { tableColumnsMap } from './constants'
import { BonusPenaltyTableConfig, GetTableColumnsProps } from './types'
import { TableAction, RequestInfo } from './components'

export const getTableColumns = ({ isVisibleAmountValue }: GetTableColumnsProps): BonusPenaltyTableConfig => {
  const config: BonusPenaltyTableConfig = [
    {
      title: 'Type',
      dataIndex: tableColumnsMap.type,
      sorter: true,
      render: (type: BonusPenalty['type']) => (
        <Tag bordered={false} color={bonusPenaltyColorMap[type]}>
          {bonusPenaltyLabelMap[type]}
        </Tag>
      ),
      width: '94px',
    },
    {
      title: 'Creation date',
      dataIndex: tableColumnsMap.creationDate,
      render: (creationDate: BonusPenalty['creationDate']) => (
        <span>{getFormattedDate(creationDate, 'coreHRDateFormat')}</span>
      ),
      sorter: true,
    },
    {
      title: 'Amount',
      width: '96',
      dataIndex: tableColumnsMap.amount,
      render: (amount: BonusPenalty['amount'], { currencySymbol }) => (
        <>{isVisibleAmountValue ? `${currencySymbol} ${currencyFormatter(amount)}` : <SmileOutlined />}</>
      ),
      sorter: true,
    },
    {
      title: 'Effective date',
      dataIndex: tableColumnsMap.effectiveDate,
      sorter: true,
      render: (effectiveDate: BonusPenalty['effectiveDate']) => (
        <span>{getFormattedDate(effectiveDate, 'coreHRDateFormat')}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: tableColumnsMap.status,
      width: '124px',
      sorter: true,
      render: (_: unknown, { status, actionEmployeeFullName, actionEmployeeId, actionEmployeeNickname }) => (
        <RequestInfo
          status={status}
          actionEmployeeFullName={actionEmployeeFullName}
          actionEmployeeId={actionEmployeeId}
          actionEmployeeNickname={actionEmployeeNickname}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: unknown, record: BonusPenalty) => <TableAction data={record} />,
    },
  ]

  return config
}
