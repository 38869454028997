import React, { useEffect, useRef, useState } from 'react'

type PaginationProps = {
  currentPage: number
  totalPages: number
  setPage: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, setPage }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const buttonWidth = 100
  const [maxVisiblePages, setMaxVisiblePages] = useState<number>(5)

  useEffect(() => {
    const updateMaxVisiblePages = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.getBoundingClientRect().width
        const newMaxVisiblePages = Math.floor(containerWidth / buttonWidth)
        setMaxVisiblePages(newMaxVisiblePages)
      }
    }

    window.addEventListener('resize', updateMaxVisiblePages)
    updateMaxVisiblePages()

    return () => {
      window.removeEventListener('resize', updateMaxVisiblePages)
    }
  }, [buttonWidth])

  const renderPageNumbers = () => {
    const pageNumbers = []
    let startPage = 1
    let endPage = totalPages

    if (totalPages > maxVisiblePages) {
      const halfVisible = Math.floor(maxVisiblePages / 2)

      if (currentPage <= halfVisible) {
        endPage = maxVisiblePages
      } else if (currentPage >= totalPages - halfVisible) {
        startPage = totalPages - maxVisiblePages + 1
      } else {
        startPage = currentPage - halfVisible
        endPage = currentPage + halfVisible
      }
    }

    // TODO: Fix the eslint-disable-next-line
    // eslint-disable-next-line no-plusplus
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          onClick={() => setPage(i)}
          className={currentPage === i ? 'pagination-page active' : 'pagination-page'}
        >
          {i}
        </span>,
      )
    }

    if (startPage > 1) {
      if (startPage > 2) {
        pageNumbers.unshift(<span key="ellipsis-start">...</span>)
      }
      pageNumbers.unshift(
        <span key="page-1" onClick={() => setPage(1)} className="pagination-page">
          1
        </span>,
      )
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<span key="ellipsis-end">...</span>)
      }
      pageNumbers.push(
        <span key={`page-${totalPages}`} onClick={() => setPage(totalPages)} className="pagination-page">
          {totalPages}
        </span>,
      )
    }

    return pageNumbers
  }

  return (
    <div className="pagination-pages" ref={containerRef}>
      {renderPageNumbers()}
    </div>
  )
}

export default Pagination
