// TODO: fix eslint error
/* eslint-disable */
import { useEffect, useState } from 'react'
import { checkAuth } from './checkAuth'
import { useEmployee } from '../../contexts/EmployeeContext'
import * as Sentry from '@sentry/react'

export const useCheckAuth = () => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const [isError, setError] = useState<boolean>(false)

  const { employee, signIn } = useEmployee()

  useEffect(() => {
    if (employee) return
    setLoading(true)
    checkAuth()
      .then((response) => {
        setSuccess(true)
        signIn(response)
      })
      .catch((error) => {
        Sentry.captureException(error)
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [employee])

  return {
    isLoading,
    isSuccess,
    isError,
  }
}
