// TODO: fix eslint errors
/* eslint-disable */
import { xcss, Box } from '@atlaskit/primitives'
import Button from '@atlaskit/button/new'
import { useCallback, useState } from 'react'
import Modal from '@atlaskit/modal-dialog'
import ModalContent1 from './ModalContent1'
import ModalContent2 from './ModalContent2'
import ModalContent3 from './ModalContent3'
import ModalContent4 from './ModalContent4'
import ModalContent5 from './ModalContent5'

function getContent(
  content: number,
  setContent: (arg: number) => void,
  setIsOpen: (arg: boolean) => void,
  interviesID: string,
) {
  switch (content) {
    case 1:
      return <ModalContent1 setContent={setContent} setIsOpen={setIsOpen} interviesID={interviesID} />
    case 2:
      return <ModalContent2 setContent={setContent} setIsOpen={setIsOpen} interviesID={interviesID} />
    case 3:
      return <ModalContent3 setContent={setContent} setIsOpen={setIsOpen} interviesID={interviesID} />
    case 4:
      return <ModalContent4 setContent={setContent} setIsOpen={setIsOpen} interviesID={interviesID} />
    case 5:
      return <ModalContent5 setContent={setContent} setIsOpen={setIsOpen} interviesID={interviesID} />
  }
}

export default function AIModal({
  isOpen,
  setIsOpen,
  content,
  setContent,
  interviesID,
}: {
  isOpen: boolean
  setIsOpen: (arg: boolean) => void
  content: number
  setContent: (arg: number) => void
  interviesID: string
}) {
  const closeModal = useCallback(() => setIsOpen(false), [])

  return isOpen ? (
    <Modal
      shouldCloseOnOverlayClick={false}
      onClose={closeModal}
      width={514}
      {...(content === 3 ? { height: 722 } : content === 4 ? { width: 554 } : {})}
    >
      <Box
        xcss={xcss({
          overflow: 'auto',
        })}
      >
        {getContent(content, setContent, setIsOpen, interviesID)}
      </Box>
    </Modal>
  ) : null
}
