import { createSlice } from '@reduxjs/toolkit'
import { defaultBonusPenaltyModalState } from './constants'

export const bonusPenaltyModalSlice = createSlice({
  name: 'bonusPenaltyModal',
  initialState: defaultBonusPenaltyModalState,
  reducers: {
    openBonusPenaltyModal: (state) => {
      state.isOpen = true
    },
    closeBonusPenaltyModal: (state) => {
      state.isOpen = false
    },
  },
})

export const { openBonusPenaltyModal, closeBonusPenaltyModal } = bonusPenaltyModalSlice.actions
export const bonusPenaltyModalReducer = bonusPenaltyModalSlice.reducer
