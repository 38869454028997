import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InfoIcon from '../../../assets/images/resetPassword/info.svg'
import { ReactComponent as GoogleIcon } from '../../../assets/images/logo/google-logo.svg'
import { LoadingButton } from '@atlaskit/button'
import { ErrorMessage, HelperMessage } from '@atlaskit/form'
import { useSignIn } from '../../../API/auth/useSignIn'
import { useLoginWithGoogle } from '../../../API/auth/useLoginWithGoogle'
import { AUTH_FORGOT_PASSWORD, MAIN } from 'navigation'
import { useEmployee } from '../../../contexts/EmployeeContext'
import { AuthLayout } from '../AuthLayout'
import { Input } from '../../UI/Input'
import { PasswordInput } from '../../UI/PasswordInput'
import { Button } from '../../UI/Button'
import { InactiveUserView } from './components/inactive-user-view'
import { schema } from './schema'
import { SignInFormValues } from './types'
import { defaultValues } from './constants'
import styleAuth from '../Auth.module.scss'
import style from './SignIn.module.scss'

export const SignIn = () => {
  const { signIn: signInEmployee } = useEmployee()
  const navigate = useNavigate()
  const [inactiveUserView, setInactiveUserView] = useState<boolean>(false)
  const [isCapsLockOn, setIsCapsLockOn] = useState(false)
  const [focusedField, setFocusedField] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useForm<SignInFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const toForgotPassword = () => {
    const email = getValues('email')
    navigate(AUTH_FORGOT_PASSWORD, { state: { email } })
  }

  const toMain = () => {
    navigate(MAIN, { replace: true })
  }

  const { isLoading, signIn, resetStatuses } = useSignIn()
  const { loginWithGoogle } = useLoginWithGoogle()
  const onSubmit: SubmitHandler<SignInFormValues> = (values) => {
    resetStatuses()
    signIn(values.email, values.password).then((userData) => {
      switch (userData) {
        case 400:
          setError('root', { type: 'custom', message: 'Wrong email or password' })
          break
        case 403:
          setInactiveUserView(true)
          break
        default:
          signInEmployee(userData)
          toMain()
      }
    })
  }

  const handleKeyUp = (fieldName: string, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (errors.root?.message) {
      clearErrors('root')
    }
    if (event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true)
    } else {
      setIsCapsLockOn(false)
    }
  }

  return inactiveUserView ? (
    <InactiveUserView
      onClose={() => {
        setInactiveUserView(false)
      }}
    />
  ) : (
    <AuthLayout>
      <div className={style.greeting}>Welcome to CoreHR! We’re glad you’re here</div>
      <h1 className={style.title}>Sign in to your account</h1>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className={styleAuth.inputContainer}>
          <Input
            {...register('email')}
            label="Email"
            type="email"
            id="email"
            placeholder="Enter your email"
            isInvalid={!!errors.email?.message}
            onKeyUp={(event) => handleKeyUp('email', event)}
            onFocus={() => setFocusedField('email')}
          />
          {isCapsLockOn && focusedField === 'email' && (
            <div className={style.infoWrapper}>
              <img src={InfoIcon} width={16} height={16} alt="info" className={style.infoImage} />
              <HelperMessage>Caps Lock is on</HelperMessage>
            </div>
          )}
          {!!errors.email?.message && <ErrorMessage>{errors.email?.message}</ErrorMessage>}
        </div>

        <div className={styleAuth.inputContainer}>
          <PasswordInput
            {...register('password')}
            label="Password"
            placeholder="Enter your password"
            isInvalid={!!errors.password?.message}
            onKeyUp={(event) => handleKeyUp('password', event)}
            onFocus={() => setFocusedField('password')}
          />
          {isCapsLockOn && focusedField === 'password' && (
            <div className={style.infoWrapper}>
              <img src={InfoIcon} width={16} height={16} alt="info" className={style.infoImage} />
              <HelperMessage>Caps Lock is on</HelperMessage>
            </div>
          )}
          {!!errors.password?.message && <ErrorMessage>{errors.password?.message}</ErrorMessage>}
        </div>

        {!!errors.root?.message && <ErrorMessage>{errors.root?.message}</ErrorMessage>}

        <div className={style.fagotWrapper}>
          <Button spacing="none" onClick={toForgotPassword} className={style.fagotBtn} appearance="link">
            Forgot password?
          </Button>
        </div>

        <div className={styleAuth.sendWrapper}>
          {isLoading ? (
            <LoadingButton appearance="primary" isLoading className={styleAuth.sendBtn}>
              Loading...
            </LoadingButton>
          ) : (
            <Button appearance="primary" type="submit" className={styleAuth.sendBtn}>
              Sign In
            </Button>
          )}
        </div>

        <div className={styleAuth.sendWrapper}>
          <Button onClick={loginWithGoogle} appearance="subtle" className={`${styleAuth.sendBtn} ${styleAuth.googleBtn}`}>
            <span className={styleAuth.googleWrapContent}>
              <span className={styleAuth.googleIcon}>
                <GoogleIcon />
              </span>
              <span className={styleAuth.signInWithGoogleText}>Sign in with Google</span>
            </span>
          </Button>
        </div>
      </form>
    </AuthLayout>
  )
}
