// TODO: fix eslint errors
/* eslint-disable */
import { xcss, Box } from '@atlaskit/primitives'
import { token } from '@atlaskit/tokens'
import Button from '@atlaskit/button/new'
import styled from 'styled-components'
import { ReactComponent as Stars } from '../../../assets/images/ProfileIcons/Stars.svg'
import { FC, useState, useEffect, ReactNode } from 'react'

const ButtonWrapper: FC<{
  children: ReactNode
  width?: number
}> = styled.div`
  button {
    margin: auto;
    width: ${(props) => (props.width ? `${props.width}px` : '308px')};
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  button span {
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  button svg path {
    fill: ${token('color.icon.subtle')};
  }
`

export default function AIResultsButton({
  onClick,
  width,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  width?: number
}) {
  return (
    <Box>
      <ButtonWrapper width={width}>
        <Button
          onClick={(e) => {
            onClick(e)
          }}
        >
          <Stars style={{ marginRight: '4px' }} />
          Open AI Analysis Results
        </Button>
      </ButtonWrapper>
    </Box>
  )
}
