// TODO: fix eslint errors
/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { Inline } from '@atlaskit/primitives'
import { token } from '@atlaskit/tokens'
import Calendar from '@atlaskit/calendar'
import PreferencesIcon from '@atlaskit/icon/glyph/preferences'
import { IconButton } from '@atlaskit/button/new'
import { formatDateToFriendlyShort } from 'utils/helpers/DateTime/dateFromStringWithDefaultTimezone'
import { ReactSVG } from 'react-svg'
import { CALENDAR_WIDGET, FILTERS } from 'utils/constants/api'
import calendar_check from 'assets/images/mainPageIcons/calendar_check.svg'
import { SelectValue } from 'shared/Common/data/SelectValue'
import FilterModal from './Filter/FilterModal'
import { LongTextTooltip } from 'components/UI/LongTextTooltip'
import {
  StyledCalendar,
  StyledEventBlock,
  StyledEventHeader,
  StyledEventsList,
  StyledEventsListDate,
  StyledEventsListIcon,
  StyledEventsListText,
} from './EventCalendarStyles'

function convertDateFormat(dateStr: string) {
  // Split the input date string into parts
  const [day, weekday, month, year] = dateStr.split(' ')

  // Convert the month name to month number
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const monthNumber = monthNames.indexOf(month) + 1

  // Format the month number with leading zero if necessary
  const formattedMonth = String(monthNumber).padStart(2, '0')

  // Format the day with leading zero if necessary
  const formattedDay = day.replace(',', '').padStart(2, '0')

  // Return the date in "YYYY-MM-DD" format
  return `${year}-${formattedMonth}-${formattedDay}`
}

export default function EventCalendar({ employee }: any) {
  const [defaultSelectedDates, setDefaultSelectedDates] = useState<{
    [key: string]: any
  }>({})
  const [month, setMonth] = useState<{ [key: string]: any }>({})
  const calendarRef = useRef(null)
  const [openFilterByOrg, setOpenFilterByOrg] = useState(false)

  const [orgUnitOptions, setOrgUnitOptions] = useState<SelectValue[]>([])
  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<SelectValue[]>([])
  const [selectedOrgUnitOptionsApplied, setSelectedOrgUnitOptionsApplied] = useState<SelectValue[]>([])

  const [countryOptions, setCountryOptions] = useState<SelectValue[]>([])
  const [selectedCountryOptions, setSelectedCountryOptions] = useState<SelectValue[]>([])
  const [selectedCountryOptionsApplied, setSelectedCountryOptionsApplied] = useState<SelectValue[]>([])

  const [inited, setInited] = useState(false)

  const holidayTypes = ['state_holiday', 'professional_holiday', 'corporate_event']

  const [holidaysTypesFilter, setHolidaysTypesFilter] = useState([...holidayTypes])
  const [holidaysTypesFilterApplied, setHolidaysTypesFilterApplied] = useState([...holidayTypes])

  function fetchEventsWithFilters() {
    const queryParams = new URLSearchParams({
      show_corporate_events: holidaysTypesFilterApplied.includes('corporate_event').toString(),
      show_professional_holidays: holidaysTypesFilterApplied.includes('professional_holiday').toString(),
      show_state_holidays: holidaysTypesFilterApplied.includes('state_holiday').toString(),
      location_ids: JSON.stringify(selectedCountryOptionsApplied.map((item) => item.value)),
      org_unit_ids: JSON.stringify(selectedOrgUnitOptionsApplied.map((item) => item.value)),
    })

    return fetch(`${CALENDAR_WIDGET}?${queryParams}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    }).then((response) => response.json())
  }

  function getHolidays() {
    fetchEventsWithFilters()
      .then((newEvents) => {
        const dateObj: { [key: string]: string } = {}

        newEvents.forEach((event: any) => {
          dateObj[event.date] = event
        })
        setDefaultSelectedDates(dateObj)
      })
      .catch((error) => {
        console.error('An error occurred fetching events:', error)
      })
  }

  useEffect(() => {
    fetch(`${FILTERS}/widget/calendar/locations-and-org-units`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if ('locations' in data) {
          const countries = data.locations.map((country: { name: string; id: string }) => ({
            label: country.name,
            value: country.id,
          }))

          setCountryOptions(countries)

          const selectedCountries = countries.filter(
            (item: { label: string; value: string }) => item.value === employee.workLocationId,
          )

          setSelectedCountryOptions(selectedCountries)
          setSelectedCountryOptionsApplied(selectedCountries)
        }

        if ('orgUnits' in data) {
          const orgUnits = Object.values(
            (data as { orgUnits: any }).orgUnits as {
              name: string
              id: string
              companyName: string
            }[],
          ).map((item) => ({
            label: item.name,
            value: item.id,
            company: item.companyName,
          }))
          const selectedItems = orgUnits.filter((item) => item.value === employee.orgUnitId)
          const unselectedItems = orgUnits.filter((item) => !selectedItems.includes(item))
          setOrgUnitOptions([...selectedItems, ...unselectedItems])
          setSelectedOrgUnitOptions(selectedItems)
          setSelectedOrgUnitOptionsApplied(selectedItems)
        }

        setInited(true)
      })
      .catch((error) => console.error('An error occurred fetching countries:', error))
  }, [])

  useEffect(() => {
    if (inited) {
      getHolidays()
    }
  }, [selectedCountryOptionsApplied, selectedOrgUnitOptionsApplied, holidaysTypesFilterApplied])

  useEffect(() => {
    colorDays()
  }, [defaultSelectedDates, month])

  function colorDays() {
    if (calendarRef.current) {
      const dateCells = (calendarRef.current as HTMLDivElement).querySelectorAll('div[role=gridcell] button')

      dateCells.forEach((cell) => {
        cell.classList.remove(holidayTypes[0])
        cell.classList.remove(holidayTypes[1])
        cell.classList.remove(holidayTypes[2])
      })

      dateCells.forEach((cell) => {
        const date = cell.getAttribute('aria-label')

        if (date) {
          if (Object.keys(defaultSelectedDates).includes(convertDateFormat(date as string))) {
            cell.classList.add(defaultSelectedDates[convertDateFormat(date as string)].type)
          }
        }
      })
    }
  }

  return (
    <StyledEventBlock>
      <StyledEventHeader>
        <h3>Holidays and Events</h3>
        <IconButton
          label="button"
          appearance="subtle"
          isSelected={openFilterByOrg}
          icon={(iconProps) => (
            <PreferencesIcon
              {...iconProps}
              primaryColor={openFilterByOrg ? undefined : token('color.icon.accent.gray')}
            />
          )}
          onClick={(e) => {
            if (openFilterByOrg) {
              e.stopPropagation()
              setOpenFilterByOrg((val) => !val)
            } else {
              setTimeout(() => {
                setOpenFilterByOrg((val) => !val)
              }, 1)
            }
          }}
        />
      </StyledEventHeader>

      <StyledCalendar ref={calendarRef}>
        <Calendar
          onChange={(date, e) => {
            const dateArr = date.iso.split('-')

            const correctDate = `${dateArr[0]}-${dateArr[1]}-01`

            setMonth({ ...date, iso: correctDate })
          }}
          selected={Object.keys(defaultSelectedDates)}
          testId="calendar"
          weekStartDay={1}
        />
      </StyledCalendar>

      <StyledEventsList>
        {Object.keys(defaultSelectedDates)
          .sort()
          .filter((item) => {
            if ('iso' in month) {
              return (
                new Date(item).getFullYear() === new Date(month.iso as string).getFullYear() &&
                new Date(item).getMonth() === new Date(month.iso as string).getMonth()
              )
            }
            return (
              new Date(item).getFullYear() === new Date().getFullYear() &&
              new Date(item).getMonth() === new Date().getMonth()
            )
          })
          .map((elm, i) => (
            <Inline alignBlock="center" key={String(new Date(defaultSelectedDates[elm].date).getTime())}>
              <StyledEventsListIcon $eventType={defaultSelectedDates[elm].type}>
                <ReactSVG src={calendar_check} />
              </StyledEventsListIcon>

              <StyledEventsListText>
                <LongTextTooltip content={defaultSelectedDates[elm].name} maxContentLength={30}>
                  {defaultSelectedDates[elm].name}
                </LongTextTooltip>

                {defaultSelectedDates[elm].dayOff && <Inline as="span">Day off</Inline>}
              </StyledEventsListText>

              <StyledEventsListDate>
                {`${formatDateToFriendlyShort(defaultSelectedDates[elm].date)}, ${new Date(
                  defaultSelectedDates[elm].date,
                ).getFullYear()}`}
              </StyledEventsListDate>
            </Inline>
          ))}
      </StyledEventsList>

      {openFilterByOrg && (
        <FilterModal
          holidaysTypesFilter={holidaysTypesFilter}
          setHolidaysTypesFilter={setHolidaysTypesFilter}
          countryOptions={countryOptions}
          selectedCountryOptions={selectedCountryOptions}
          setSelectedCountryOptions={setSelectedCountryOptions}
          orgUnitOptions={orgUnitOptions}
          setSelectedOrgUnitOptions={setSelectedOrgUnitOptions}
          selectedOrgUnitOptions={selectedOrgUnitOptions}
          onCancel={() => {
            setHolidaysTypesFilter(holidaysTypesFilterApplied)
            setSelectedCountryOptions(selectedCountryOptionsApplied)
            setSelectedOrgUnitOptions(selectedOrgUnitOptionsApplied)
            setOpenFilterByOrg(false)
          }}
          onApply={() => {
            setHolidaysTypesFilterApplied(holidaysTypesFilter)
            setSelectedCountryOptionsApplied(selectedCountryOptions)
            setSelectedOrgUnitOptionsApplied(selectedOrgUnitOptions)
            setOpenFilterByOrg(false)
          }}
        />
      )}
    </StyledEventBlock>
  )
}
