import { Tooltip } from 'antd'
import { useId, useState } from 'react'
import clsx from 'clsx'
import { InfoIcon } from 'components'
import { TooltipInfoStyles } from './styles'
import { TooltipInfoProps } from './types'

export const TooltipInfo = ({ title }: TooltipInfoProps) => {
  const [isActive, setIsActive] = useState(false)
  const id = useId()

  return (
    <TooltipInfoStyles>
      <Tooltip
        title={title}
        placement="bottom"
        trigger="click"
        onOpenChange={(open) => setIsActive(open)}
        getPopupContainer={() => document.getElementById(id) as HTMLElement}
      >
        <InfoIcon className={clsx({ 'active-icon': isActive })} />
      </Tooltip>
    </TooltipInfoStyles>
  )
}
