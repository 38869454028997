import { createSlice } from '@reduxjs/toolkit'
import { locationsInitialState } from './constants'

export const locationsSlice = createSlice({
  name: 'announcements',
  initialState: locationsInitialState,
  reducers: {},
})

// export const { } = locationsSlice.actions
export const locationsReducer = locationsSlice.reducer
