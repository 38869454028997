import { Form } from 'antd'
import { NumericFormat } from 'react-number-format'
import clsx from 'clsx'
import { FieldValues, useController } from 'react-hook-form'
import { FormInputAmountProps } from './types'
import { FormInputNumberStyles } from './styles'

export const FormInputAmount = <FormDataType extends FieldValues>(props: FormInputAmountProps<FormDataType>) => {
  const { name, label = 'Amount', tooltip, control } = props

  const {
    field: { onChange, ...fieldProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  return (
    <FormInputNumberStyles>
      <Form.Item label={label} tooltip={tooltip} validateStatus={error && 'error'} help={error?.message}>
        <NumericFormat
          {...fieldProps}
          className={clsx('input-number', { invalid: Boolean(error?.message) })}
          getInputRef={fieldProps.ref}
          placeholder="e.g. 1,000.00"
          fixedDecimalScale
          allowNegative={false}
          decimalScale={2}
          onValueChange={({ value }) => onChange(value ? parseFloat(value) : null)}
          thousandSeparator=","
        />
      </Form.Item>
    </FormInputNumberStyles>
  )
}
