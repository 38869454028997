import { useId, useState } from 'react'
import { Popover, Tag } from 'antd'
import clsx from 'clsx'
import { employeeRequestStatusColorMap, employeeRequestStatusLabelMap } from 'shared/constants/employee-requests'
import { InfoIcon } from 'components'
import { renderRequestInfoLabelMap } from './constants'
import { RequestInfoProps } from './types'
import { RequestInfoStyles } from './styles'

export const RequestInfo = (props: RequestInfoProps) => {
  const { status, actionEmployeeFullName, actionEmployeeNickname } = props
  const [isActive, setIsActive] = useState(false)
  const id = useId()

  const renderContent = (
    <div className="content">
      <div className="content-row">
        <span className="label">{`${renderRequestInfoLabelMap[status]}:`}</span>
        <span>
          {actionEmployeeFullName} {actionEmployeeNickname ? `(${actionEmployeeNickname})` : ''}
        </span>
      </div>
      {/* Ready for category task */}
      {/* <div className="content-row">
        <span className="label">Category:</span>
        <span>{'Netpeak Core R&D (IT) > R&D Support services'}</span>
      </div> */}
    </div>
  )

  return (
    <RequestInfoStyles id={id}>
      <Tag bordered={false} color={employeeRequestStatusColorMap[status]}>
        {employeeRequestStatusLabelMap[status]}
      </Tag>
      <Popover
        placement="bottomRight"
        content={renderContent}
        trigger="click"
        arrow={false}
        onOpenChange={(open) => setIsActive(open)}
        getPopupContainer={() => document.getElementById(id) as HTMLElement}
      >
        <InfoIcon className={clsx({ 'active-icon': isActive })} />
      </Popover>
    </RequestInfoStyles>
  )
}
