import { CompensationPanel, MgpTable, BonusPenaltyTable, RateTable } from './components'
import { CompensationStyles } from './styles'

export const Compensation = () => (
  <CompensationStyles>
    <CompensationPanel />
    <BonusPenaltyTable />
    <RateTable />
    <MgpTable />
  </CompensationStyles>
)
