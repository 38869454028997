// TODO: fix eslint errors
/* eslint-disable */
import '../index.scss'
import './Checkbox.scss'

type Checkbox = {
  label: string | JSX.Element | null
  required: boolean | null
  checked: boolean
  checkBoxLabel: string
  onChange: (value: any) => void
  switch: boolean
  disabled: boolean
}

function Checkbox(props: Checkbox) {
  return (
    <div className="input horizontal-input">
      <label>
        {props.label}
        {props.required ? <span>*</span> : ''}
      </label>
      {!props.switch ? (
        <div className="checkbox-container">
          <input
            disabled={props.disabled}
            type="checkbox"
            onChange={() => props.onChange(!props.checked)}
            checked={props.checked}
          />
          <span>{props.checkBoxLabel}</span>
        </div>
      ) : (
        <div className="checkbox-container">
          <label className="switch">
            <input
              disabled={props.disabled}
              type="checkbox"
              onChange={() => props.onChange(!props.checked)}
              checked={props.checked}
              id="checkbox"
            />
            <div className={`slider round ${props.checked ? 'clicked' : 'unclicked'}`} />
            <span className="label-text">{props.checkBoxLabel}</span>
          </label>
        </div>
      )}
    </div>
  )
}

export default Checkbox
