import { createSlice } from '@reduxjs/toolkit'
import { announcementsInitialState } from './constants'

export const announcementsSlice = createSlice({
  name: 'announcements',
  initialState: announcementsInitialState,
  reducers: {},
})

// export const { } = announcementsSlice.actions
export const announcementsReducer = announcementsSlice.reducer
