import {EMPLOYEES} from "../../utils/constants/api";
import {StatusProps} from "./checkInOut.types";
import * as Sentry from "@sentry/react";

export const checkStatus = async (employeeId: string)=> {
    try {
        const response = await fetch(`${EMPLOYEES  }/${  employeeId  }/attendance/status`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        });

        return await response.json() as StatusProps;
    } catch (error) {
        Sentry.captureException(error);
        throw new Error();
    }
};
