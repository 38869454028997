import { token } from '@atlaskit/tokens'
import styled from 'styled-components'

export const SentryReportingErrorStyles = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: url('/image/error-page-bg.svg') center no-repeat;
  background-size: cover;

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 300px;

    @media (max-width: 1440px) {
      padding: 0 100px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }

    @media (max-width: 480px) {
      padding: 0 ${token('space.300')};
    }
  }

  .error-title {
    max-width: 494px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${token('space.500')} 0;

    h1 {
      color: #000;
      font-size: 62px;
      font-style: normal;
      font-weight: 500;
      line-height: 89%;

      @media (max-width: 480px) {
        font-size: 54px;
      }
    }

    p {
      display: none;
      max-width: 420px;
      color: #000;
      font-size: 32px;
      font-style: italic;
      font-weight: 400;
      line-height: 32px;
      margin: 0;

      @media (max-width: 1024px) {
        display: block;
      }

      @media (max-width: 480px) {
        font-size: 24px;
      }
    }
  }

  .error-text {
    max-width: 420px;
    color: #000;
    text-align: center;
    font-size: 32px;
    font-style: italic;
    font-weight: 400;
    line-height: 32px;
    padding: ${token('space.300')} 0;

    & > p {
      margin: 0;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
`
