export const SearchSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6965 12.5708L16.9798 15.9125C17.1269 16.0715 17.2063 16.2815 17.2012 16.498C17.1961 16.7145 17.1069 16.9206 16.9525 17.0725C16.7982 17.2244 16.5907 17.3102 16.3741 17.3117C16.1575 17.3133 15.9489 17.2305 15.7923 17.0808L12.5106 13.7425C11.2444 14.6964 9.66304 15.1334 8.08669 14.9652C6.51035 14.7969 5.05684 14.036 4.02048 12.8363C2.98411 11.6367 2.44234 10.0881 2.50487 8.50399C2.56739 6.91993 3.22953 5.41881 4.35722 4.3046C5.48491 3.19038 6.99388 2.54632 8.57858 2.50284C10.1633 2.45936 11.7053 3.0197 12.8924 4.07041C14.0795 5.12111 14.8229 6.58366 14.9722 8.16192C15.1215 9.74017 14.6655 11.3162 13.6965 12.5708ZM8.7498 13.3333C9.96537 13.3333 11.1312 12.8505 11.9907 11.9909C12.8502 11.1314 13.3331 9.96558 13.3331 8.75C13.3331 7.53443 12.8502 6.36864 11.9907 5.5091C11.1312 4.64956 9.96537 4.16667 8.7498 4.16667C7.53422 4.16667 6.36843 4.64956 5.50889 5.5091C4.64935 6.36864 4.16646 7.53443 4.16646 8.75C4.16646 9.96558 4.64935 11.1314 5.50889 11.9909C6.36843 12.8505 7.53422 13.3333 8.7498 13.3333Z"
      fill="#626F86"
    />
  </svg>
)
