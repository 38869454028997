import { useAppDispatch, useAppSelector } from 'hooks'
import { useCallback, useMemo } from 'react'
import { useProfile } from '../../../../context'
import { openRateModal } from 'store'
import { TableBox } from '../table-box'
import { Table, Filters, RateFormModal } from './components'
import { useGetEmployeeRateHistoryDataQuery } from 'services/api'

export const RateTable = () => {
  const { idOpenProfile } = useProfile()

  const dispatch = useAppDispatch()

  const { mode, isOpen, payload } = useAppSelector((state) => state.rateModal)

  const { tableState, tableFilters } = useAppSelector((state) => state.employeeRateHistoryTableFilters)

  const {
    data: employeeRateHistoryDate = { items: [], total: 0 },
    isFetching,
    isLoading,
  } = useGetEmployeeRateHistoryDataQuery({
    ...tableState,
    ...tableFilters,
    employeeId: idOpenProfile,
  })

  const handleCreateRateModal = useCallback(() => dispatch(openRateModal({ mode: 'create' })), [])

  // TODO: Implement hasActiveFiltersFn
  const hasActiveFilters = useMemo(() => {
    const { dates, currencies } = tableFilters

    return !!(currencies?.length || dates?.length)
  }, [tableFilters.dates, tableFilters.currencies])

  return (
    <>
      <TableBox
        isLoadingTable={isLoading}
        tableTitle="Rate history"
        skeleton={{ filtersCount: 2, colCount: 3, rowCount: 6 }}
        onClickAddBtn={handleCreateRateModal}
        showTable={Boolean(employeeRateHistoryDate.total)}
        tableComponent={<Table isLoading={isFetching} sourceData={employeeRateHistoryDate} />}
        tableFiltersComponent={<Filters />}
        showTableFilters={hasActiveFilters}
      />
      {isOpen && <RateFormModal mode={mode} payload={payload} />}
    </>
  )
}
