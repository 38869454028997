// TODO: fix eslint errors
/* eslint-disable */
import React, { useEffect, useState } from 'react'

import Modal from '../../../Layer/Modal/Modal'
import Select from 'components/old-ui/Input/SelectInput/SelectInput'
import Checkbox from 'components/old-ui/Input/Checkbox/Checkbox'

import { CountryFromApi } from '../../../../../shared/Location/data/CountryFromApi'
import { CityFromApi } from '../../../../../shared/Location/data/CityFromApi'
import { TimezoneFromApi } from '../../../../../shared/Location/data/TimezoneFromApi'
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError'
import { getGMTOffsetByTimezoneName } from '../../../../Common/Timezones/Services'

import { COUNTRIES } from '../../../../../utils/constants/api'

type BaseModal = {
  opened: boolean
  modalTitle: string
  country: SelectValue
  city: SelectValue
  timezone: SelectValue
  showOnMainPage: boolean
  countries: CountryFromApi[]
  okText: string
  onCancel: () => any
  onOk: (
    country: string,
    city: string,
    timezone: string,
    showOnMainPage: boolean,
    validate: (errors: ValidationError[]) => void,
  ) => void
}

type SelectValue = {
  value: string
  label: string
}

function BaseModal(props: BaseModal) {
  const emptySelectValue = { label: '', value: '' } as SelectValue

  const countryOptions = props.countries.map((country) => ({ label: country.name, value: country.code }) as SelectValue)

  const [country, setCountry] = useState<SelectValue>(props.country)
  const [city, setCity] = useState<SelectValue>(props.city)
  const [timezone, setTimezone] = useState<SelectValue>(props.timezone)
  const [showOnMainPage, setShowOnMainPage] = useState<boolean>(props.showOnMainPage)

  const [countryError, setCountryError] = useState<string | null>(null)
  const [cityError, setCityError] = useState<string | null>(null)
  const [timezoneError, setTimezoneError] = useState<string | null>(null)

  const [citiesFromApi, setCitiesFromApi] = useState<CityFromApi[]>([])
  const [timezonesFromApi, setTimezonesFromApi] = useState<TimezoneFromApi[]>([])

  const [cityOptions, setCityOptions] = useState<SelectValue[]>([])
  const [timezoneOptions, setTimezoneOptions] = useState<SelectValue[]>([])

  useEffect(() => {
    if (props.modalTitle === 'Create Location') {
      resetAll()
    }
    if (country.value !== '') {
      getCitiesFromApi(country.value)
      if (city.value == null) {
        getTimezonesFromApi(country.value)
      }
    }
  }, [props.opened])

  useEffect(() => {
    if (citiesFromApi.length > 0) {
      setCityOptions(citiesFromApi.map((city) => ({ label: city.name, value: city.name }) as SelectValue))
    } else {
      setCityOptions([])
    }
  }, [citiesFromApi])

  useEffect(() => {
    if (timezonesFromApi.length > 0) {
      setTimezoneOptions(
        timezonesFromApi.map(
          (timezone) =>
            ({
              label: `${timezone.name} ${getGMTOffsetByTimezoneName(timezone.name)}`,
              value: timezone.name,
            }) as SelectValue,
        ),
      )
    }
  }, [timezonesFromApi])

  // This is the way to set timezone by city if city is selected
  useEffect(() => {
    if (citiesFromApi.length > 0) {
      const cityTimezone = citiesFromApi.find((cityToFind) => cityToFind.name === city.label)?.timezone
      setTimezone({
        label: cityTimezone ? `${cityTimezone} ${getGMTOffsetByTimezoneName(cityTimezone)}` : undefined,
        value: cityTimezone,
      } as SelectValue)
      setTimezoneOptions([
        {
          label: cityTimezone ? `${cityTimezone} ${getGMTOffsetByTimezoneName(cityTimezone)}` : undefined,
          value: cityTimezone,
        } as SelectValue,
      ])
    }
  }, [city])

  function getCitiesFromApi(countryCode: string): void {
    fetch(`${COUNTRIES}/${countryCode}/cities`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((cities: CityFromApi[]) => {
        if (countryCode === 'UA') {
          cities = cities.map((city) => {
            if (city.name === 'Odessa') {
              city.name = 'Odesa'
            }
            return city
          })
        }
        setCitiesFromApi(cities.length > 0 ? cities : [])
      })
  }

  function getTimezonesFromApi(countryCode: string): void {
    fetch(`${COUNTRIES}/${countryCode}/timezones`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((timezones: TimezoneFromApi[]) => setTimezonesFromApi(timezones))
  }

  function handleCountryChange(newCountry: SelectValue) {
    setCountry(newCountry)
    getCitiesFromApi(newCountry.value)
    getTimezonesFromApi(newCountry.value)
    setCity(emptySelectValue)
    setTimezone(emptySelectValue)
    setCountryError(null)
  }

  function handleTimezoneChange(newTimezone: SelectValue) {
    setTimezone(newTimezone)
    setTimezoneError(null)
  }

  function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
      if (error.property === 'location.countryCode') {
        setCountryError(error.message)
      } else if (error.property === 'location.timezone') {
        setTimezoneError(error.message)
      } else if (error.property === 'location.name') {
        setCountryError(error.message)
      }
    })
  }

  function resetAll(): void {
    setCountry(emptySelectValue)
    setCity(emptySelectValue)
    setTimezone(emptySelectValue)
    setCountryError(null)
    setCityError(null)
    setTimezoneError(null)
  }

  return (
    <Modal
      opened={props.opened}
      modalTitle={props.modalTitle}
      cancelText="Cancel"
      okText={props.okText}
      onCancel={() => {
        props.onCancel()
        resetAll()
      }}
      onOk={() =>
        props.onOk(
          country.value ? country.value : '',
          city.value ? city.value : '',
          timezone.value ? timezone.value : '',
          showOnMainPage,
          validate,
        )
      }
    >
      <Select
        label="Country"
        isSearchable
        required
        isMulti={false}
        value={country}
        options={countryOptions}
        error={countryError}
        onChange={(newCountry) => handleCountryChange(newCountry)}
      />
      <Select
        label="City"
        isSearchable
        required={false}
        isMulti={false}
        value={city}
        options={cityOptions}
        error={cityError}
        onChange={(newCity) => setCity(newCity)}
      />
      <Select
        label="Timezone"
        isSearchable
        required
        isMulti={false}
        value={timezone}
        options={timezoneOptions}
        error={timezoneError}
        onChange={(newTimezone) => handleTimezoneChange(newTimezone)}
      />
      <Checkbox
        disabled={false}
        label=""
        required={false}
        checked={showOnMainPage}
        checkBoxLabel="Show Timezone on the main page"
        onChange={(newChecked) => setShowOnMainPage(newChecked)}
        switch={false}
      />
    </Modal>
  )
}

export default BaseModal
