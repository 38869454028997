import {useState} from "react";
import type {ProfileResponseProps} from "./profile.types";
import {useQuery} from "../useQuery";
import {fetchProfileAPI} from "./profile";
import {useEvent} from "hooks/useEvent";

export const useProfile = (id: string) => {
    const [profile, setProfile] = useState<ProfileResponseProps>();
    const [getProfile, isLoading, isSuccess, isError] = useQuery(fetchProfileAPI);

    const fetchProfile =  useEvent(async () => {
        try {
            const response = await getProfile(id);
            if (response.id) {
                setProfile(response);
                return response;
            }
        } catch (error) {
            throw new Error();
        }
    });

    return {
        profile,
        fetchProfile,
        isLoading,
        isSuccess,
        isError
    };
};
