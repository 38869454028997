import { LEVELS } from '../../../../constants'
import { LevelResponseProps, LevelProps } from './level.types'

const transformToADSSelectOptions = (options: Array<LevelResponseProps>): Array<LevelProps> =>
  options.map((option) => ({
    ...option,
    label: option.name,
    value: option.id,
  }))

export const fetchLevelAPI = async () => {
  try {
    const response = await fetch(LEVELS, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })

    const data = (await response.json()) as Array<LevelResponseProps>

    return transformToADSSelectOptions(data)
  } catch (error) {
    console.error(error)
    throw new Error()
  }
}
