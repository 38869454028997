import { useMemo, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { schema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Input, InputNumber, Modal, Select, Button, Radio, Tooltip } from 'antd'
import { PolicyBalanceEditFormValues, TimeOffLogModalProps } from './types'
import { defaultValues } from './constants'
import * as Sentry from '@sentry/react'
import { ITimeOffLogPoliciesProps } from 'models'
import { LongTextTooltip } from 'components/UI/LongTextTooltip'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { PoliciesPayloadType, useUpdatePolicyBalanceMutation } from 'services/api'
import { TypeIconsCollection } from 'shared/TimeOffPolicy/constants/NewTypeIconsCollection'
import { useProfile } from 'components/Profile/context'
import { DropDownIcon } from '../icons'
import { PolicyItemStyles, TimeOffLogModalStyles } from './styles'

const { TextArea } = Input

export const TimeOffLogModal = ({ onClose, policies, activePolicyId }: TimeOffLogModalProps) => {
  const { profile } = useProfile()
  const [policyId, setPolicyId] = useState<string>(activePolicyId)
  const policiesSelectOptions = useMemo(
    () =>
      policies.map((item: ITimeOffLogPoliciesProps) => ({
        label: (
          <PolicyItemStyles>
            {TypeIconsCollection[item.type_icon as keyof typeof TypeIconsCollection]('small-icon', 'withouthover')}
            <LongTextTooltip content={item.name} maxContentLength={45}>
              {item.name}
            </LongTextTooltip>
          </PolicyItemStyles>
        ),
        value: item.id,
      })),
    [policies],
  )

  const { control, handleSubmit, reset } = useForm<PolicyBalanceEditFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      policyId,
    },
  })

  const [updatePolicyBalance, { isLoading }] = useUpdatePolicyBalanceMutation()

  const onPolicyChange = (value: string) => {
    setPolicyId(value)
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  const onSubmit: SubmitHandler<PolicyBalanceEditFormValues> = async (data) => {
    const body: PoliciesPayloadType = {
      employeeId: profile.id,
      policyId,
      daysToAllocate: data.sign === '-' ? -data.amount : data.amount,
      comment: data.comment,
    }

    try {
      await updatePolicyBalance(body).unwrap()
      handleClose()
    } catch (error: any) {
      Sentry.captureException(error)
      //TODO: fix TS and replace to notification from N1-823
      throw new Error(error?.message || 'Something went wrong!')
    }
  }

  return (
    <Modal
      open
      title="Edit Balance"
      footer={null}
      onCancel={handleClose}
      destroyOnClose
      width={400}
      maskClosable={false}
      closeIcon={false}
    >
      <TimeOffLogModalStyles>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <Controller
            name="policyId"
            control={control}
            render={({ field: { value, ...rest }, fieldState: { error } }) => (
              <Form.Item
                {...rest}
                label="Policy"
                layout="vertical"
                validateStatus={error && 'error'}
                help={error?.message}
                initialValue={value}
              >
                <Select options={policiesSelectOptions} suffixIcon={<DropDownIcon />} onChange={onPolicyChange} />
              </Form.Item>
            )}
          />
          <div className="amount-item-wrap">
            <Controller
              name="amount"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <Form.Item
                  {...rest}
                  label="Amount"
                  layout="vertical"
                  validateStatus={error && 'error'}
                  help={error?.message}
                  extra="Select an action and enter the number of days"
                >
                  <InputNumber<number> min={1} max={366} value={value} controls={false} />
                </Form.Item>
              )}
            />
            <Controller
              name="sign"
              control={control}
              render={({ field: { ...rest }, fieldState: { error } }) => (
                <Form.Item {...rest} layout="vertical" className="amount-item" validateStatus={error && 'error'}>
                  <Radio.Group>
                    <Tooltip placement="bottom" title="Add">
                      <Radio.Button value="+" className={error && 'is-error'}>
                        <PlusOutlined className="radio-icon" />
                      </Radio.Button>
                    </Tooltip>
                    <Tooltip placement="bottom" title="Subtract">
                      <Radio.Button value="-" className={error && 'is-error'}>
                        <MinusOutlined className="radio-icon" />
                      </Radio.Button>
                    </Tooltip>
                  </Radio.Group>
                </Form.Item>
              )}
            />
          </div>

          <Controller
            name="comment"
            control={control}
            render={({ field: { value, ...rest }, fieldState: { error } }) => (
              <Form.Item
                {...rest}
                label="Add a comment"
                layout="vertical"
                validateStatus={error && 'error'}
                help={error?.message}
              >
                <TextArea rows={3} maxLength={107} showCount value={value} />
              </Form.Item>
            )}
          />

          <Form.Item className="modal-footer">
            <Button type="default" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </TimeOffLogModalStyles>
    </Modal>
  )
}
