import { Input } from 'antd'
import { FilterItemSearchTypeProps } from './types'
import { FilterItemSearchStyles } from './styles'
import { SearchOutlined } from '@ant-design/icons'

export const FilterItemSearch = (props: FilterItemSearchTypeProps) => (
  <FilterItemSearchStyles>
    <Input {...props} prefix={<SearchOutlined />} />
  </FilterItemSearchStyles>
)
