// TODO: fix eslint errors
/* eslint-disable */
import { FC, useEffect, useState } from 'react'
import { BlockInfoItem } from '../../../BlockInfo'
import cn from 'classnames'
import style from '../../../Profile.module.scss'
import { useProfile } from '../../../context'
import { type OnChangeValue, Select } from '../../../../UI/Select'
import { DirectManagerProps, useDirectManager } from '../../../../../API/profile/fields/jobInfo/directManager'
import { InputText } from '../../../ViewingModes/InputText'
import InlineEdit from '@atlaskit/inline-edit'
import { useSetField } from '../../../../../API/profile/setField/useSetField'

export const DirectManager: FC = () => {
  const { isAccessEditRole, jobInfo, idOpenProfile, setDirectManager, showErrorNotify } = useProfile()
  const { fetchDirectManagers, directManager, isLoading } = useDirectManager(idOpenProfile)

  const [picked, setPicked] = useState<DirectManagerProps>()

  const { setField } = useSetField(idOpenProfile)

  const [mainErrorStateServ, setMainErrorStateServ] = useState<{
    clearError?: () => void | undefined
    error?: string | undefined
  }>({
    clearError: undefined,
    error: undefined,
  })

  useEffect(() => {
    fetchDirectManagers().then((response) => {
      if (jobInfo?.currentJobInfoValue.directManagerId) {
        const directManager = response.find(
          (directManager) => directManager.value === jobInfo.currentJobInfoValue.directManagerId,
        )

        setPicked(directManager)
      }
    })
  }, [])

  return (
    <BlockInfoItem title="Direct manager">
      <div className={cn(style.inputWrapper, isAccessEditRole && style.profileInputTextIsEditing)}>
        <InlineEdit
          {...(!isAccessEditRole && { isEditing: false })}
          {...(mainErrorStateServ.error && { isEditing: true })}
          hideActionButtons
          defaultValue={picked}
          editView={({ errorMessage, onChange, ...fieldProps }) => (
            <Select
              {...fieldProps}
              appearance="subtle"
              spacing="compact"
              inputId="single-select-example-clearable1"
              isSearchable
              isClearable
              onChange={(newValue: OnChangeValue<DirectManagerProps, false>) => {
                mainErrorStateServ.clearError && mainErrorStateServ.clearError()
                setMainErrorStateServ({
                  error: undefined,
                  clearError: undefined,
                })
                onChange(newValue as any)

                setField(['current-job-info', 'direct_manager_id', newValue?.id || 'null'].join('/'))
                  .then(() => {
                    setDirectManager(newValue?.id || '')
                  })
                  .catch((error) => {
                    setMainErrorStateServ({
                      clearError: showErrorNotify(String(error.message)),
                      error: String(error.message),
                    })
                  })
              }}
              className={style.profileSelect}
              autoFocus={!mainErrorStateServ.error}
              isInvalid={!!errorMessage || !!mainErrorStateServ.error}
              openMenuOnFocus
              isLoading={isLoading}
              options={directManager}
              placeholder="Choose a Direct Manager"
            />
          )}
          readView={() => <InputText value={picked && picked.label} />}
          onConfirm={(value) => setPicked(value)}
        />
      </div>
    </BlockInfoItem>
  )
}
