/* eslint-disable */
import Icon from '@ant-design/icons'
import type { GetProps } from 'antd'

type CustomIconComponentProps = GetProps<typeof Icon>

const SignInSvg = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g id="Vector">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 2.51406e-07V2H16V16H9V18H16.006C17.106 18 18 17.107 18 16.005V1.995C18.0003 1.73302 17.9489 1.47357 17.8488 1.23147C17.7487 0.989369 17.6018 0.769382 17.4166 0.58409C17.2314 0.398797 17.0115 0.251836 16.7695 0.151612C16.5274 0.0513882 16.268 -0.000131329 16.006 2.51406e-07H9Z"
        fill="black"
        fill-opacity="0.88"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.977001 8C0.71478 8.00343 0.464632 8.11075 0.281462 8.29842C0.0982911 8.48609 -0.00293511 8.73877 1.10321e-06 9.001C1.10321e-06 9.552 0.437001 10 0.977001 10L10.5299 9.97898C10.7267 9.78037 10.817 9.68732 11 9.5C11.183 9.31268 11.5 9 11.5 9C11.5 9 11.183 8.68764 11 8.5C10.817 8.31236 10.7012 8.1939 10.509 8H0.977001Z"
        fill="black"
        fill-opacity="0.88"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.303 5.305C9.10892 5.502 9.00013 5.76745 9.00013 6.044C9.00013 6.32055 9.10892 6.586 9.303 6.783L11.5 9L9.303 11.217C9.1088 11.4135 8.9999 11.6787 8.9999 11.955C8.9999 12.2313 9.1088 12.4965 9.303 12.693C9.707 13.102 10.363 13.102 10.768 12.693L13.698 9.738C13.8913 9.54079 13.9996 9.27565 13.9996 8.9995C13.9996 8.72335 13.8913 8.45821 13.698 8.261L10.768 5.305C10.6719 5.20858 10.5578 5.13207 10.4321 5.07987C10.3064 5.02767 10.1716 5.00079 10.0355 5.00079C9.89939 5.00079 9.76462 5.02767 9.63892 5.07987C9.51322 5.13207 9.39907 5.20858 9.303 5.305Z"
        fill="black"
        fill-opacity="0.88"
      />
    </g>
  </svg>
)

export const SignInIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={SignInSvg} {...props} />
