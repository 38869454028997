// TODO: fix eslint errors
/* eslint-disable */
import BaseModal from './BaseModal'
import { getGMTOffsetByTimezoneName } from '../../../../Common/Timezones/Services'
import { CountryFromApi } from '../../../../../shared/Location/data/CountryFromApi'
import { LocationFromApi } from '../Locations'
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError'
import { SelectValue } from '../../../../../shared/Common/data/SelectValue'

import { LOCATIONS } from '../../../../../utils/constants/api'
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors'

type EditLocation = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  locationToChange: LocationFromApi
  countries: CountryFromApi[]
}

function EditLocation(props: EditLocation) {
  function edit(
    country: string,
    city: string,
    timezone: string,
    showOnMainPage: boolean,
    validate: (errors: ValidationError[]) => void,
  ): void {
    fetch(`${LOCATIONS}/${props.locationToChange.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        countryCode: country,
        city,
        timezone,
        showOnMainPage,
      }),
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors))
      }
    })
  }

  // way to get country name by country code
  const countryName = props.countries.find((country) => country.code === props.locationToChange.value.countryCode)?.name

  const country = {
    label: countryName,
    value: props.locationToChange.value.countryCode,
  } as SelectValue
  const city = {
    label: props.locationToChange.value.city,
    value: props.locationToChange.value.city,
  } as SelectValue
  const timezone = {
    label: `${props.locationToChange.value.timezone} ${getGMTOffsetByTimezoneName(props.locationToChange.value.timezone)}`,
    value: props.locationToChange.value.timezone,
  } as SelectValue

  return (
    <BaseModal
      opened={props.opened}
      modalTitle="Edit Location"
      country={country}
      city={city}
      timezone={timezone}
      showOnMainPage={props.locationToChange.value.showOnMainPage}
      countries={props.countries}
      okText="Edit"
      onCancel={props.onClose}
      onOk={edit}
    />
  )
}

export default EditLocation
