import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Spinner from '@atlaskit/spinner';
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";

import { AuthLayout } from '../../AuthLayout';

import { Button } from "../../../UI/Button";

import { AUTH_SIGN_IN } from 'navigation';

import { useSendLetterLink } from "../../../../API/auth/useSendLetterLink";

import styles from './SuccessForgotPassword.module.scss';
import styleAuth from "../../Auth.module.scss";

interface SuccessForgotPasswordProps {
    email: string;
}

export const SuccessForgotPassword: FC<SuccessForgotPasswordProps> = ({ email }) => {
    const navigate = useNavigate();
    const { isLoading, sendLetterLink } = useSendLetterLink();
    const [waitTime, setWaitTime] = useState(30);


    const navigateToLogin = () => {
        navigate(AUTH_SIGN_IN, { replace: true });
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (waitTime > 0) {
            intervalId = setInterval(() => {
                setWaitTime((prevWaitTime) => prevWaitTime - 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
     }, [waitTime]);

    const handleSendEmailAgain = () => {
        if (waitTime === 0) {
            sendLetterLink(email);
            setWaitTime(30);
        }
    };

    return(
        <AuthLayout>
            <h2 className={styles.title}>Check your email</h2>

            <p className={styles.paragraph}>We’ve sent password reset instructions to {email}</p>

            <div className={styles.reSendText}>If it doesn’t arrive soon, check your spam folder or</div>

            <div className={styles.wrapReSend}>
                {isLoading ? (
                    <Spinner delay={0} size={20} />
                    ) : (
                    <Button
                        appearance="link"
                        spacing="none"
                        className={styles.linkBtn}
                        onClick={handleSendEmailAgain}
                        isDisabled={!!waitTime}
                    >
                        {waitTime > 0 ? `Send the email again in ${waitTime} sec` : 'Send the email again'}
                    </Button>
                )}
            </div>

            <div className={styles.returnButtonWrapper}>
                    <Button
                        appearance="link"
                        spacing="none"
                        iconBefore={<ArrowLeftIcon label="Return to login"/>}
                        className={styleAuth.linkBtn}
                        onClick={navigateToLogin}
                    >
                        Return to login
                    </Button>
                </div>
        </AuthLayout>
    );
};
