import { object, string, ObjectSchema } from 'yup'
import { ISupportFormValues } from './types'

export const schema: ObjectSchema<ISupportFormValues> = object({
  email: string()
    .email('Email is invalid')
    .required('Please enter an email')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is invalid'),
  message: string().trim().min(10, 'Message should have more than 10 symbols').required('Please fill in the field'),
}).required()
