// TODO: fix eslint errors
/* eslint-disable */
import { JOB_TITLES_CATEGORIES } from '../../../../../../utils/constants/api'
import { JobTitlesCategoryFromApi } from '../../../../../../shared/JobTitle/data/JobTitlesCategoryFromApi'
import ConfirmDeleteModal from '../../../../Layer/Modal/ConfirmDeleteModal'

type DeleteCategory = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  category: JobTitlesCategoryFromApi
}

function DeleteCategory(props: DeleteCategory) {
  function deleteLink(): void {
    fetch(`${JOB_TITLES_CATEGORIES}/${props.category.id}`, {
      method: 'DELETE',
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else {
        response.json().then((jsonData) => alert(jsonData.error))
      }
    })
  }

  return (
    <ConfirmDeleteModal
      opened={props.opened}
      modalTitle="Delete Category"
      cancelText="Cancel"
      deleteText={`<span>&laquo;${props.category.name}&raquo;</span>`}
      okText="Delete"
      onCancel={props.onClose}
      onOk={deleteLink}
    />
  )
}

export default DeleteCategory
