import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultRateModalState } from './constants'
import { RateModalState } from './types'

export const rateModalSlice = createSlice({
  name: 'rateModal',
  initialState: defaultRateModalState,
  reducers: {
    openRateModal: (_state, action: PayloadAction<RateModalState>) => ({
      isOpen: true,
      ...action.payload,
    }),
    closeRateModal: () => defaultRateModalState,
  },
})

export const { openRateModal, closeRateModal } = rateModalSlice.actions
export const rateModalReducer = rateModalSlice.reducer
