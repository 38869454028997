import FlagFilledIcon from '@atlaskit/icon/glyph/flag-filled'
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play'
import { useEmployee, assertEmployee } from '../../../../contexts/EmployeeContext'
import { useCheckInOut } from '../../../../API/checkInOut/useCheckInOut'
import Spinner from '@atlaskit/spinner'
import { useAppDispatch } from 'hooks'
import { triggerNotification } from 'store'
import { Button } from 'antd'
import { PauseOutlined } from '@ant-design/icons'
import { CheckInOutStyles } from './styles'

export const CheckInOut = () => {
  const dispatch = useAppDispatch()
  const { employee } = useEmployee()
  assertEmployee(employee)

  const {
    status,
    isInitLoading,
    isInitError,
    isSyncLoading,
    onWork,
    onBreak,
    checkIn,
    isCheckInLoading,
    pause,
    isPauseLoading,
    unpause,
    isUnpauseLoading,
    checkOut,
    isCheckOutLoading,
  } = useCheckInOut(employee.id)

  const onClickHandler = (variant: string) => {
    switch (variant) {
      case 'start':
        !isSyncLoading &&
          !isCheckInLoading &&
          checkIn().then(() => {
            dispatch(
              triggerNotification({
                type: 'info',
                message: 'Hi!',
                description: 'You have started your workday. Have a good day!',
              }),
            )
          })
        break

      case 'pause':
        !isCheckOutLoading &&
          !isSyncLoading &&
          !isPauseLoading &&
          pause().then(() => {
            dispatch(
              triggerNotification({
                type: 'info',
                message: 'I hope to see you soon!',
                description: 'You have paused your workday.',
              }),
            )
          })
        break

      case 'unpause':
        !isSyncLoading &&
          !isUnpauseLoading &&
          unpause().then(() => {
            dispatch(
              triggerNotification({
                type: 'info',
                message: 'Glad to see you back!',
                description: 'Welcome back after your pause.',
              }),
            )
          })
        break

      case 'finish':
        !isPauseLoading &&
          !isSyncLoading &&
          !isCheckOutLoading &&
          checkOut().then(() => {
            dispatch(
              triggerNotification({
                type: 'info',
                message: 'See you soon. Bye!',
                description: 'You have completed your workday. Have a good one!',
              }),
            )
          })
        break

      default:
        break
    }
  }

  if (isInitError) return <div>Ops...</div>

  return (
    <CheckInOutStyles>
      {isInitLoading ? (
        <Spinner size={36} />
      ) : (
        <div className="content">
          {onWork && (
            <div className="time-info">
              <div className="time-info__point">
                <div>On work:</div>
                <div>{onWork}</div>
              </div>
              {onBreak && (
                <div className="time-info__point">
                  <div>On break:</div>
                  <div>{onBreak}</div>
                </div>
              )}
            </div>
          )}

          {(status === 'init' || status === 'check_out') && (
            <Button
              variant="solid"
              color="primary"
              onClick={() => onClickHandler('start')}
              loading={isCheckInLoading}
              icon={<VidPlayIcon label="Start" />}
            >
              Start
            </Button>
          )}
          {(status === 'check_in' || status === 'unpause') && (
            <>
              <Button
                variant="solid"
                onClick={() => onClickHandler('pause')}
                loading={isPauseLoading}
                icon={<PauseOutlined />}
              >
                Pause
              </Button>
              <Button
                variant="solid"
                color="primary"
                onClick={() => onClickHandler('finish')}
                loading={isCheckOutLoading}
                icon={<FlagFilledIcon label="Finish" />}
              >
                Finish
              </Button>
            </>
          )}
          {status === 'pause' && (
            <Button
              variant="solid"
              onClick={() => onClickHandler('unpause')}
              loading={isUnpauseLoading}
              icon={<VidPlayIcon size="small" label="Unpause" />}
            >
              Unpause
            </Button>
          )}
        </div>
      )}
    </CheckInOutStyles>
  )
}
