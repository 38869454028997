// TODO: fix eslint errors
/* eslint-disable */
import { useQuery } from '../useQuery'
import { attendanceLog, correctionTotalTime as correctionTotalTimeA, updateTimeAPIs } from './attendanceLog'
import { useEvent } from 'hooks/useEvent'
import { GetAttendanceLogProps, CorrectionTotalTimeProps, UpdateTimeAPIProps } from './attendanceLog.types'
import { useState } from 'react'

export const useAttendanceLog = () => {
  const [getAttendanceLogAPI, isLoading, isSuccess, isError] = useQuery(attendanceLog)

  const getAttendanceLog = useEvent(async (data: GetAttendanceLogProps) => {
    try {
      const res = await getAttendanceLogAPI(data)

      if (res.error) {
        throw new Error(String(res.error))
      }
      return res
    } catch (error: any) {
      throw new Error(String(error.message))
    }
  })

  return {
    getAttendanceLog,
    isLoading,
    isSuccess,
    isError,
  }
}

export const useAttendanceLogEditTotal = () => {
  const [correctionTotalTimeAPI, isLoading, isSuccess, isError] = useQuery(correctionTotalTimeA)

  const correctionTotalTime = useEvent(async (data: CorrectionTotalTimeProps) => {
    try {
      const res = await correctionTotalTimeAPI(data)
      //@ts-ignore
      if (res.error) {
        //@ts-ignore
        throw new Error(String(res.error))
      }
      return res
    } catch (error: any) {
      throw new Error(String(error.message))
    }
  })

  return {
    correctionTotalTime,
    isLoading,
    isSuccess,
    isError,
  }
}

export const useAttendanceLogEditEvent = () => {
  const [updateTimeAPI, isLoading, isSuccess, isError] = useQuery(updateTimeAPIs)

  const updateTime = useEvent(async (data: UpdateTimeAPIProps) => {
    try {
      data.time += ':00'
      const res = await updateTimeAPI(data)
      //@ts-ignore
      if (res.error) {
        //@ts-ignore
        throw new Error(String(res.error))
      }
      return res
    } catch (error: any) {
      throw new Error(String(error.message))
    }
  })

  return {
    updateTime,
    isLoading,
    isSuccess,
    isError,
  }
}
