import { OrderEnum } from 'shared'
import { EmployeeRateHistoryTableFilters, EmployeeRateHistoryTableState } from './types'

export const defaultEmployeeRateHistoryTableState: EmployeeRateHistoryTableState = {
  page: 1,
  limit: 6,
  sort: 'startDate',
  order: OrderEnum.DESC,
}

export const defaultEmployeeRateHistoryTableFilters: EmployeeRateHistoryTableFilters = {
  currencies: [],
  dates: [],
}
