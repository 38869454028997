import { CheckboxOptionType } from 'antd'
import { getEnumValues } from 'utils/helpers/enum-values'
import { EmployeeRequestStatusEnum, employeeRequestStatusLabelMap } from './employee-requests'

export const employeeRequestStatusSelectOptions: CheckboxOptionType[] = getEnumValues(EmployeeRequestStatusEnum).map(
  (status: EmployeeRequestStatusEnum) => ({
    label: employeeRequestStatusLabelMap[status],
    value: status,
    title: employeeRequestStatusLabelMap[status],
  }),
)
