import { useEmployee, assertEmployee } from '../../../../contexts/EmployeeContext'
import { useSignOut } from '../../../../API/auth/useSignOut'
import { useNavigate } from 'react-router-dom'
import { Avatar, Tooltip, Button } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { SignInIcon } from './icons'
import { UserStyles } from './styles'

export const User = () => {
  const { employee } = useEmployee()
  const navigate = useNavigate()
  const { signOut } = useSignOut()
  assertEmployee(employee)
  const profileLink = `/employee/${employee.id}`

  const toProfile = () => {
    navigate(profileLink)
  }

  return (
    <UserStyles>
      <Tooltip placement="bottom" title="Your profile">
        <a
          onClick={(event) => {
            event.preventDefault()
            toProfile()
          }}
          href={profileLink}
        >
          <Avatar src={employee.imageUrl} icon={<UserOutlined />} size={32} className="avatar" />
        </a>
      </Tooltip>

      <Tooltip placement="bottom" title="Sign out">
        <Button shape="circle" icon={<SignInIcon />} type="text" onClick={signOut} />
      </Tooltip>
    </UserStyles>
  )
}
