import { getEnumValues } from 'utils/helpers/enum-values'
import { BonusPenaltyEnum, bonusPenaltyLabelMap } from 'shared/constants/employee-bonus-penalty'
import { BonusPenaltyFormValues, SelectBonusPenaltyOptions } from './types'

export const defaultValues: BonusPenaltyFormValues = {
  employeeId: '',
  type: null,
  currencyId: '',
  amount: null,
  effectiveDate: '',
}

export const bonusPenaltySelectOptions: SelectBonusPenaltyOptions[] = getEnumValues(BonusPenaltyEnum).map(
  (type: BonusPenaltyEnum) => ({
    label: bonusPenaltyLabelMap[type],
    value: type,
  }),
)
