// TODO: fix eslint errors
/* eslint-disable */
import { Tooltip } from 'react-tooltip'
import { TypeIconsCollection } from 'shared/TimeOffPolicy/constants/NewTypeIconsCollection'
import { formatDateToFriendly } from 'utils/helpers/DateTime/dateFromStringWithDefaultTimezone'
import { xcss, Box } from '@atlaskit/primitives'
import { Link } from 'react-router-dom'
import Avatar from '@atlaskit/avatar'
import CalendarIcon from '@atlaskit/icon/glyph/calendar'
import styled from 'styled-components'

const TooltipWrapper = styled.div`
  ${(props) => `
  .react-tooltip {
    background: #fff !important;
    box-shadow:
      0 2.4px 7.4px rgb(0 0 0 / 18%),
      0 12.8px 28.8px rgb(0 0 0 / 22%);
    color: #38434c !important;
    font-family: Roboto, sans-serif;
  
    margin-top: -5px;
    max-width: 300px;
    min-width: 260px;
   
    padding: 12px 12px !important;
    z-index: 5;
  }
  .react-tooltip__show {
    opacity: 1 !important;
  }
`}
`

export default function MainTooltip({
  id,
  requesterName,
  requesterId,
  requesterAvatar,
  typeIcon,
  typeName,
  startDate,
  endDate,
  substituteName,
  substituteId,
  substituteAvatar,
}: {
  id: string
  requesterName: string
  requesterId: string
  requesterAvatar?: string
  typeIcon: string
  typeName: string
  startDate: string
  endDate: string
  substituteName: string
  substituteId: string
  substituteAvatar?: string
}) {
  return (
    <TooltipWrapper>
      <Tooltip id={id} place="bottom" noArrow={false} clickable delayShow={150}>
        <Box
          xcss={xcss({
            display: 'flex',
            alignItems: 'center',
            height: '24px',
          })}
        >
          <Box
            xcss={xcss({
              marginLeft: '-2px',
              marginTop: '3px',
              marginRight: '-2px',
            })}
          >
            <Avatar src={requesterAvatar} size="small" />
          </Box>{' '}
          <Box
            xcss={xcss({
              marginLeft: 'space.100',
              color: 'color.link',
              fontFamily: 'inherit',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              width: '204px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            })}
          >
            <Link to={`/employee/${requesterId}`}>{requesterName}</Link>
          </Box>
        </Box>

        <Box
          xcss={xcss({
            marginTop: '10px',
            marginLeft: '4px',
            display: 'flex',
            alignItems: 'center',
          })}
        >
          {' '}
          {TypeIconsCollection[typeIcon as keyof typeof TypeIconsCollection]('small-icon', 'withouthover')}
          <Box
            xcss={xcss({
              color: 'color.text.subtle',
              marginLeft: 'space.100',
              fontFamily: 'inherit',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '14px',
            })}
          >
            {typeName}
          </Box>
        </Box>

        <Box
          style={{
            marginTop: '6px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <CalendarIcon label="CalendarIcon" primaryColor="#44546F" />
          </Box>
          <Box
            xcss={xcss({
              color: 'color.text.subtlest',
              marginLeft: 'space.100',
              fontFamily: 'inherit',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
            })}
          >
            {' '}
            {`${formatDateToFriendly(startDate)}, ${new Date(startDate).getFullYear()}`}{' '}
            {endDate ? ` — ${formatDateToFriendly(endDate)}, ${new Date(endDate).getFullYear()}` : ''}
          </Box>
        </Box>

        {substituteName && (
          <Box xcss={xcss({})}>
            {' '}
            <Box
              xcss={xcss({
                color: 'color.text.subtlest',
                fontFamily: 'inherit',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              })}
            >
              Replacing:
            </Box>
            <Box
              xcss={xcss({
                color: 'color.link',
                fontFamily: 'inherit',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                display: 'flex',
                alignItems: 'center',
              })}
            >
              <Box
                xcss={xcss({
                  marginTop: '1px',
                  marginLeft: '-2px',
                  marginRight: '-2px',
                })}
              >
                <Avatar src={substituteAvatar} size="small" />
              </Box>
              <Box
                xcss={xcss({
                  marginLeft: 'space.100',
                  width: '204px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                })}
              >
                <Link to={`/employee/${substituteId}`}>{substituteName}</Link>
              </Box>
            </Box>
          </Box>
        )}
      </Tooltip>
    </TooltipWrapper>
  )
}
