// TODO: fix eslint errors
/* eslint-disable */
import { FC, useEffect, useState } from 'react'
import { useProfile } from '../../../context'
import { BlockInfoItem } from '../../../BlockInfo'
import cn from 'classnames'
import style from '../../../Profile.module.scss'
import InlineEdit from '@atlaskit/inline-edit'
import { Select } from '../../../../UI/Select'
import { InputText } from '../../../ViewingModes/InputText'
import { CountryOptionsProps, useCountry } from '../../../../../API/profile/fields/generalInfo/country'
import { useSetField } from '../../../../../API/profile/setField/useSetField'

export const Country: FC = () => {
  const { isAccessEdit, profile, idOpenProfile, setCountry } = useProfile()

  const { country, fetchCountry, isLoading } = useCountry()

  const [picked, setPicked] = useState<CountryOptionsProps>()

  const { setField } = useSetField(idOpenProfile, true)

  useEffect(() => {
    fetchCountry().then((response) => {
      if (profile.country) {
        const currentCountry = response.find((country) => country.code === profile.country)

        setPicked(currentCountry)
      }
    })
  }, [])

  return (
    <BlockInfoItem title="Country">
      <div className={cn(style.inputWrapper, isAccessEdit && style.profileInputTextIsEditing)}>
        <InlineEdit
          {...(!isAccessEdit && { isEditing: false })}
          hideActionButtons
          defaultValue={picked}
          editView={({ errorMessage, onChange, ...fieldProps }, ref) => (
            <Select
              {...fieldProps}
              onChange={(event: any) => {
                onChange(event)

                setField(['country', event?.value || 'null'].join('/')).then(() => {
                  setCountry(event?.value || null)
                })
              }}
              appearance="subtle"
              spacing="compact"
              inputId="single-select-example-clearable1"
              isSearchable
              isClearable
              className={style.profileSelect}
              autoFocus
              openMenuOnFocus
              ref={ref}
              isLoading={isLoading}
              options={country}
              placeholder="Choose a Gender"
            />
          )}
          readView={() => <InputText value={picked?.label} />}
          onConfirm={(value) => setPicked(value)}
        />
      </div>
    </BlockInfoItem>
  )
}
