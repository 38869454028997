import { CreateAnnouncementModal, DeleteAnnouncementModal, EditAnnouncementModal } from './components'
import { AnnouncementModalTypeEnum } from './constants'
import { IGetAnnouncementModalProps, GetAnnouncementModalType } from './types'

export const getAnnouncementModalType = (props: IGetAnnouncementModalProps): GetAnnouncementModalType => {
  const { onClose, payload, onDeleteAnnouncement } = props

  return {
    [AnnouncementModalTypeEnum.CREATE]: <CreateAnnouncementModal onClose={onClose} payload={payload} />,
    [AnnouncementModalTypeEnum.DELETE]: (
      <DeleteAnnouncementModal onClose={onClose} onDeleted={onDeleteAnnouncement} payload={payload} />
    ),
    [AnnouncementModalTypeEnum.EDIT]: <EditAnnouncementModal onClose={onClose} payload={payload} />,
  }
}
