// TODO: fix eslint errors
/* eslint-disable */
import { GOOGLE_AUTH } from '../../utils/constants/api'
import * as Sentry from '@sentry/react'

export const loginWithGoogle = async (data: any) => {
  try {
    const formData = new FormData()
    formData.append('code', data.code)
    formData.append('scope', data.scope)

    const response = await fetch(GOOGLE_AUTH, {
      method: 'POST',
      body: formData,
    })

    if (!response.ok || response.status !== 200) {
      alert('Employee not found')
      throw new Error()
    }

    return await response.json()
  } catch (error) {
    Sentry.captureException(error)
    alert('Employee not found')
    throw new Error()
  }
}
