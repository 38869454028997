// TODO: fix eslint errors
/* eslint-disable */
import React, { useEffect, useState, FC, ReactElement } from 'react'
import { LINKS_WIDGET } from 'utils/constants/api'
import { LinkForWidget } from 'shared/Link/data/LinkForWidget'
import { xcss, Box } from '@atlaskit/primitives'
import { token } from '@atlaskit/tokens'

import styled from 'styled-components'

const CustomUL: FC<{
  children: ReactElement<any, any>[]
  style: any
}> = styled.ul`
  ${(props) => `
  li::marker {
    color: ${token('color.icon.accent.gray')};
    font-size:14px;
    
  }
  margin-top: ${token('space.100')};

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;

  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #66666666;
  }

  &::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: transparent;
    max-width:200px;
  }
`}
`

function LinksWidget() {
  const [links, setLinks] = useState<LinkForWidget[]>([])

  useEffect(() => {
    fetch(LINKS_WIDGET, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((links: LinkForWidget[]) => {
        const newLinks: any[] = []
        links.forEach((link: LinkForWidget) => newLinks.push(link))
        setLinks(newLinks)
      })
  }, [])
  if (links.length > 0) {
    return (
      <Box
        xcss={xcss({
          backgroundColor: 'elevation.surface',
          border: '1px solid',
          borderColor: 'color.border.disabled',
          borderRadius: 'border.radius.050',
          paddingTop: 'space.200',
          paddingBottom: 'space.200',
          paddingLeft: 'space.075',
          width: '100%',
        })}
      >
        <Box>
          <h2
            style={{
              color: 'color.text',
              fontFamily: 'inherit',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px' /* 125% */,
              margin: '0 10px',
            }}
          >
            Useful links
          </h2>
        </Box>
        <CustomUL
          style={{
            padding: '0px',
            paddingLeft: token('space.300'),
            maxHeight: '348px',
            overflow: 'auto',
          }}
        >
          {links.map((link) => (
            <li key={link.name} style={{ marginBottom: token('space.200') }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={link.url}
                style={{
                  fontFamily: 'inherit',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#44546F',
                }}
              >
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '288px',
                  }}
                >
                  {link.name}
                </div>
              </a>
            </li>
          ))}
        </CustomUL>
      </Box>
    )
  }
  return <div />
}

export default LinksWidget
