import { useState } from 'react'
import { adminStatusAPI } from 'API/common/status'
import * as Sentry from '@sentry/react'

interface AddStatusProps {
  name: string
  onSuccess: () => void
}

const useAddStatus = () => {
  const [error, setError] = useState<string | null>(null)

  const addStatus = async ({ name, onSuccess }: AddStatusProps) => {
    try {
      const updateStatusResponse = await adminStatusAPI.addStatus(name, false)
      if (!updateStatusResponse.ok) {
        throw new Error(`HTTP error! status: ${updateStatusResponse.status}`)
      }
      onSuccess()
    } catch (error) {
      Sentry.captureException(error)
      setError((error as Error).message)
    }
  }

  return { addStatus, error }
}

export default useAddStatus
