// TODO: fix eslint errors
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add'
import { token } from '@atlaskit/tokens'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage, Field } from '@atlaskit/form'
import Select, { ValueType } from '@atlaskit/select'
import { ReactComponent as EditorRemoveIconNew } from '../../../../../assets/images/ProfileIcons/trash.svg'
import { ReactComponent as CalculatorIcon } from '../../../../../assets/images/policy/calculator.svg'
import { IconButton } from '@atlaskit/button/new'
import WarningIcon from '@atlaskit/icon/glyph/warning'
import InlineDialog from '@atlaskit/inline-dialog'

import {
  StyledRuleBlock,
  StyledRuleTop,
  StyledRuleHeader,
  StyledTrashButton,
  StyledRuleAccruals,
  StyledRuleSelect,
  StyledRuleAllocate,
  StyledCalculator,
  StyledAddButton,
  StyledFlexColumn,
  StyledWarningIcon,
  StyledErrorDialog,
} from '../StylesCreatePolicy'
import { AccrualRule } from '../../../../../interfaces/timeOffPolicy/accrualRule.interface'
import { AccrualRulesProps } from '../../../../../interfaces/timeOffPolicy/Steps/accrualRulesProps.interface'
import { Option } from '../../../../../interfaces/timeOffPolicy/Select/option.interface'
import { daysOfMonthOptionsMonthly, monthsOptions } from '../../../../../shared/TimeOffPolicy/constants/AccrualSchedule'

const AccrualRules: React.FC<AccrualRulesProps> = ({
  accrualRules,
  setAccrualRules,
  setRuleError,
  accrualRulesError,
  setAccrualRulesError,
  onValidate,
  statuses,
  burnoutAccrualRule,
  setBurnoutAccrualRule,
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen)
  }

  const validateRules = () => {
    const errors = accrualRules.map((rule) => ({
      allocationDays:
        rule.allocationDays === null
          ? 'Please, enter allocation days'
          : rule.allocationDays > 366
            ? 'The number of days must not exceed 366 days'
            : '',
      intervalType: rule.intervalType != 'yearly' && !rule.intervalType ? 'Please, choose an interval type' : '',
      intervalCount:
        rule.intervalCount === null
          ? 'Please, enter interval'
          : rule.intervalCount > 99
            ? 'The number of interval must not exceed 99'
            : '',
      yearlyAllocationDateType:
        rule.intervalType === 'yearly' && !rule.yearlyAllocationDateType
          ? 'Please, choose a yearly allocation type'
          : '',
      allocationMonth:
        rule.yearlyAllocationDateType === 'custom' && rule.allocationMonth === null ? 'Please, choose a month' : '',
      allocationDay:
        rule.yearlyAllocationDateType === 'custom' && rule.allocationDay === null ? 'Please, choose a day' : '',
      allocationStatusId:
        rule.yearlyAllocationDateType === 'status' && !rule.allocationStatusId ? 'Please, choose a status' : '',
    }))

    setAccrualRulesError(errors)

    return errors.every((error) => Object.values(error).every((msg) => msg === ''))
  }

  useEffect(() => {
    if (onValidate) {
      onValidate(validateRules)
    }
  }, [onValidate, validateRules])

  const addAccrualRule = () => {
    const newRule: AccrualRule = {
      allocationDays: null,
      intervalType: null,
      intervalCount: null,
      yearlyAllocationDateType: null,
      allocationMonth: null,
      allocationDay: null,
      allocationStatusId: null,
    }
    setAccrualRules([...accrualRules, newRule])
  }

  const updateAccrualRule = (index: number, field: keyof AccrualRule, value: string | number | null) => {
    const updatedRules = accrualRules.map((rule, i) => {
      if (i !== index) return rule

      const updatedRule: AccrualRule = { ...rule, [field]: value }

      if (field === 'intervalType') {
        if (value === 'yearly') {
          updatedRule.intervalCount = 1
        } else {
          updatedRule.intervalCount = null
          updatedRule.allocationDay = null
          updatedRule.allocationMonth = null
          updatedRule.allocationStatusId = null
          updatedRule.yearlyAllocationDateType = null
        }
      }

      return updatedRule
    })

    const updatedErrors = accrualRulesError.map((error, i) => {
      if (i === index) {
        return {
          ...error,
          ...(field === 'intervalType'
            ? {
                allocationDays: '',
                intervalCount: '',
                yearlyAllocationDateType: '',
                allocationMonth: '',
                allocationDay: '',
                allocationStatusId: '',
              }
            : {}),
          [field]: '',
        }
      }
      return error
    })

    setAccrualRules(updatedRules)
    setAccrualRulesError(updatedErrors)
  }

  const intervalTypes: Option[] = [
    { label: 'once a year', value: 'yearly' },
    { label: 'per months', value: 'monthly' },
    { label: 'per days', value: 'daily' },
  ]

  const yearlyAllocationDateTypes: Option[] = [
    { label: 'status starts date', value: 'status' },
    { label: 'birthday date', value: 'birthday' },
    { label: 'hire date', value: 'hire' },
    { label: 'custom date', value: 'custom' },
  ]

  return (
    <div>
      {accrualRules.map((rule, index) => (
        <StyledRuleBlock key={index}>
          <StyledRuleTop>
            <StyledRuleHeader>
              <h4>{index + 1} Rule</h4>
              <StyledTrashButton
                onClick={() => {
                  if (index === burnoutAccrualRule) {
                    setBurnoutAccrualRule(null)
                  }
                  setAccrualRules(accrualRules.filter((_, i) => i !== index))
                }}
              >
                <IconButton
                  appearance="subtle"
                  shape="default"
                  // @ts-ignore
                  icon={EditorRemoveIconNew}
                  label="Delete"
                />
              </StyledTrashButton>
            </StyledRuleHeader>

            <StyledRuleAccruals>
              <p>Accrual occurs</p>
              <StyledRuleSelect>
                <Field<ValueType<Option>> name={`accrual-rules-${index}`}>
                  {({ fieldProps: { id, value: _value, onChange: _onChange, ...rest }, error }) => (
                    <>
                      <Select<Option>
                        options={intervalTypes}
                        maxMenuHeight={204}
                        placeholder="select type"
                        onChange={(selectedOption) => {
                          updateAccrualRule(index, 'intervalType', selectedOption ? selectedOption.value : null)
                        }}
                        value={intervalTypes.find((option) => option.value === rule.intervalType)}
                        {...rest}
                      />
                      {accrualRulesError[index]?.intervalType && (
                        <ErrorMessage>{accrualRulesError[index]?.intervalType}</ErrorMessage>
                      )}
                    </>
                  )}
                </Field>
              </StyledRuleSelect>
            </StyledRuleAccruals>

            <StyledRuleAllocate>
              Allocate
              <Textfield
                name="allocationDays"
                placeholder=""
                value={rule.allocationDays ? rule.allocationDays.toString() : ''}
                onChange={(e) => {
                  if (parseInt(e.currentTarget.value) <= 365 || e.currentTarget.value === '') {
                    updateAccrualRule(
                      index,
                      'allocationDays',
                      e.currentTarget.value && e.currentTarget.value != '' ? parseInt(e.currentTarget.value, 10) : null,
                    )
                  }
                }}
              />
              {accrualRulesError[index]?.allocationDays && (
                <StyledErrorDialog>
                  <InlineDialog
                    content={accrualRulesError[index]?.allocationDays}
                    isOpen={accrualRulesError[index]?.allocationDays != null}
                    placement="right-end"
                  >
                    <StyledWarningIcon onClick={toggleDialog}>
                      <WarningIcon label="" primaryColor={token('color.icon.warning')} />
                    </StyledWarningIcon>
                  </InlineDialog>
                </StyledErrorDialog>
              )}
              {accrualRules[index] && <div>business day(s) </div>}
              {accrualRules[index].intervalType && rule.intervalType === 'yearly' && <>per each year on</>}
              {accrualRules[index].intervalType && rule.intervalType === 'daily' && <>per each</>}
              {accrualRules[index].intervalType != 'yearly' && (
                <>
                  {accrualRules[index].intervalType && rule.intervalType === 'monthly' && <>every</>}
                  <Textfield
                    name="intervalCount"
                    placeholder=""
                    value={rule.intervalCount ? rule.intervalCount.toString() : ''}
                    onChange={(e) => {
                      if (parseInt(e.currentTarget.value) <= 365 || e.currentTarget.value === '') {
                        updateAccrualRule(
                          index,
                          'intervalCount',
                          e.currentTarget.value && e.currentTarget.value != ''
                            ? parseInt(e.currentTarget.value, 10)
                            : null,
                        )
                      }
                    }}
                  />
                </>
              )}
              {accrualRulesError[index]?.intervalCount && (
                <StyledErrorDialog>
                  <InlineDialog
                    content={accrualRulesError[index]?.intervalCount}
                    isOpen={accrualRulesError[index]?.allocationDays != null}
                    placement="right-end"
                  >
                    <StyledWarningIcon onClick={toggleDialog}>
                      <WarningIcon label="" primaryColor={token('color.icon.warning')} />
                    </StyledWarningIcon>
                  </InlineDialog>
                </StyledErrorDialog>
              )}
            </StyledRuleAllocate>
            <StyledRuleAllocate>
              {accrualRules[index].intervalType === 'yearly' && (
                <StyledRuleSelect>
                  <Field<ValueType<Option>> name={`yearly-allocation-day-type-${index}`}>
                    {({ fieldProps: { id, value: _value, onChange: _onChange, ...rest }, error }) => (
                      <>
                        <Select<Option>
                          options={yearlyAllocationDateTypes}
                          maxMenuHeight={204}
                          placeholder="type"
                          onChange={(selectedOption) =>
                            updateAccrualRule(
                              index,
                              'yearlyAllocationDateType',
                              selectedOption ? selectedOption.value : null,
                            )
                          }
                          value={yearlyAllocationDateTypes.find(
                            (option) => option.value === rule.yearlyAllocationDateType,
                          )}
                          {...rest}
                        />
                        {accrualRulesError[index]?.yearlyAllocationDateType && (
                          <ErrorMessage>{accrualRulesError[index]?.yearlyAllocationDateType}</ErrorMessage>
                        )}
                      </>
                    )}
                  </Field>
                </StyledRuleSelect>
              )}
              {accrualRules[index].intervalType === 'yearly' &&
                accrualRules[index].yearlyAllocationDateType === 'status' && (
                  <>
                    namely
                    <StyledRuleSelect>
                      <Field<ValueType<Option>> name={`yearly-allocation-day-type-${index}`}>
                        {({ fieldProps: { id, value: _value, onChange: _onChange, ...rest }, error }) => (
                          <>
                            <Select<Option>
                              options={statuses}
                              maxMenuHeight={204}
                              placeholder="status"
                              onChange={(selectedOption) =>
                                updateAccrualRule(
                                  index,
                                  'allocationStatusId',
                                  selectedOption ? selectedOption.value : null,
                                )
                              }
                              value={statuses.find((option) => option.value === rule.allocationStatusId)}
                              {...rest}
                            />
                            {accrualRulesError[index]?.allocationStatusId && (
                              <ErrorMessage>{accrualRulesError[index]?.allocationStatusId}</ErrorMessage>
                            )}
                          </>
                        )}
                      </Field>
                    </StyledRuleSelect>
                  </>
                )}
              {accrualRules[index].intervalType === 'yearly' &&
                accrualRules[index].yearlyAllocationDateType === 'custom' && (
                  <>
                    namely
                    <StyledRuleSelect>
                      <Field<ValueType<Option>> name={`accrual-rules-month-${index}`}>
                        {({ fieldProps: { id, value: _value, onChange: _onChange, ...rest }, error }) => (
                          <>
                            <Select<Option>
                              options={monthsOptions}
                              maxMenuHeight={204}
                              placeholder="month"
                              onChange={(selectedOption) =>
                                updateAccrualRule(
                                  index,
                                  'allocationMonth',
                                  selectedOption ? parseInt(selectedOption.value) : null,
                                )
                              }
                              value={monthsOptions.find((option) => parseInt(option.value) === rule.allocationMonth)}
                              {...rest}
                            />
                            {accrualRulesError[index]?.allocationMonth && (
                              <ErrorMessage>{accrualRulesError[index]?.allocationMonth}</ErrorMessage>
                            )}
                          </>
                        )}
                      </Field>
                    </StyledRuleSelect>
                    on
                  </>
                )}
            </StyledRuleAllocate>
            <StyledRuleAllocate>
              {(accrualRules[index].intervalType === 'monthly' ||
                (accrualRules[index].intervalType === 'yearly' &&
                  accrualRules[index].yearlyAllocationDateType === 'custom')) && (
                <>
                  <StyledRuleSelect>
                    <Field<ValueType<Option>> name={`accrual-rules-days-${index}`}>
                      {({ fieldProps: { id, value: _value, onChange: _onChange, ...rest }, error }) => (
                        <>
                          <Select<Option>
                            options={daysOfMonthOptionsMonthly}
                            maxMenuHeight={204}
                            placeholder="day"
                            onChange={(selectedOption) =>
                              updateAccrualRule(
                                index,
                                'allocationDay',
                                selectedOption ? parseInt(selectedOption.value) : null,
                              )
                            }
                            value={daysOfMonthOptionsMonthly.find(
                              (option) => parseInt(option.value) === rule.allocationDay,
                            )}
                            {...rest}
                          />
                          {accrualRulesError[index]?.allocationDay && (
                            <ErrorMessage>{accrualRulesError[index]?.allocationDay}</ErrorMessage>
                          )}
                        </>
                      )}
                    </Field>
                  </StyledRuleSelect>
                  {accrualRules[index].intervalType === 'monthly' ? `day. Accruals start from the hire date.` : `day`}
                </>
              )}
              {accrualRules[index].intervalType === 'daily' ? 'days. Accruals start from the hire date' : ''}
            </StyledRuleAllocate>
          </StyledRuleTop>
          <StyledFlexColumn>
            <StyledCalculator>
              {typeof rule.allocationDays === 'number' && !isNaN(rule.allocationDays) ? (
                <>
                  <IconButton
                    appearance="subtle"
                    shape="default"
                    // @ts-ignore
                    icon={CalculatorIcon}
                    label="Delete"
                  />
                  {`This comes out to ${rule.allocationDays} day(s)`}
                </>
              ) : (
                ' '
              )}
              {accrualRules[index].intervalType === 'daily' && <> per each {accrualRules[index].intervalCount} days</>}

              {accrualRules[index].intervalType === 'monthly' && (
                <>
                  {' '}
                  every {accrualRules[index].intervalCount} month(s){' '}
                  {accrualRules[index].allocationDay != null && (
                    <>
                      on{' '}
                      {accrualRules[index].allocationDay !== 0
                        ? `${accrualRules[index].allocationDay} day`
                        : 'last day of month'}
                    </>
                  )}
                </>
              )}
              {accrualRules[index].intervalType === 'yearly' &&
                accrualRules[index].yearlyAllocationDateType === 'hire' && <> per each year on hire date</>}

              {accrualRules[index].intervalType === 'yearly' &&
                accrualRules[index].yearlyAllocationDateType === 'birthday' && <> per each year on birth date</>}

              {accrualRules[index].intervalType === 'yearly' &&
                accrualRules[index].yearlyAllocationDateType === 'status' && (
                  <>
                    {' '}
                    per each year on status starts date, namely{' '}
                    {statuses.find((option) => option.value === accrualRules[index].allocationStatusId)?.label || ''}
                  </>
                )}

              {accrualRules[index].intervalType === 'yearly' &&
                accrualRules[index].yearlyAllocationDateType === 'custom' && (
                  <>
                    {' '}
                    per each year on custom date, namely{' '}
                    {monthsOptions.find((option) => parseInt(option.value) === accrualRules[index].allocationMonth)
                      ?.label || ''}{' '}
                    on{' '}
                    {accrualRules[index].allocationDay !== 0
                      ? `${accrualRules[index].allocationDay} day`
                      : 'last day of month'}
                  </>
                )}
            </StyledCalculator>
          </StyledFlexColumn>
        </StyledRuleBlock>
      ))}

      <StyledAddButton
        onClick={() => {
          addAccrualRule()
          setRuleError(false)
        }}
      >
        <EditorAddIcon label="add" primaryColor={token('color.icon.accent.gray')} />
        Add accrual rule
      </StyledAddButton>
    </div>
  )
}

export default AccrualRules
