import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const showEmployeeCompensationSlice = createSlice({
  name: 'howEmployeeCompensation',
  initialState: {
    isVisible: true,
  },
  reducers: {
    triggerEmployeeCompensationView: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload
    },
  },
})

export const { triggerEmployeeCompensationView } = showEmployeeCompensationSlice.actions
export const showEmployeeCompensationReducer = showEmployeeCompensationSlice.reducer
