import styled from 'styled-components'

export const StyledContentLayout = styled.div`
  margin-top: 56px;
  display: grid;
  grid-template-columns: 240px 1fr;
  max-width: 2560px;

  @media (max-width: 1024px) {
    display: block;
  }
`

export const StyledMainLayout = styled.div<{ $navIsOpen: boolean }>`
  height: 100%;
  overflow-y: auto;

  @media (max-width: 1024px) {
    transition: transform 0.25s ease-out;
    ${(props) =>
      props.$navIsOpen
        ? `
      overflow: hidden;
      transform: translate(240px);
      width: calc(100vw - 240px);
      `
        : null}
  }
`
