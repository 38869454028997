import {forwardRef} from "react";
import cn from "classnames";
import style from './Select.module.scss';
import SelectADS, {type SelectProps as SelectADSProps} from '@atlaskit/select';

interface SelectProps<Option = [], IsMulti extends boolean = false> extends SelectADSProps<Option, IsMulti> {}

export const Select = forwardRef<any, SelectProps>(({className, ...restProps}, ref) => (
        <div>
            <SelectADS {...restProps} ref={ref} classNamePrefix="react-select" className={cn(className, style.select)} />
        </div>
    ));
