// TODO: fix eslint errors
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Modal from '../../../../Layer/Modal/Modal'
import TextField, { Type } from 'components/old-ui/Input/TextField/TextField'
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError'
import { JobTitlesCategoryFromApi } from '../../../../../../shared/JobTitle/data/JobTitlesCategoryFromApi'
import Button, { Size, Type as ButtonType } from 'components/old-ui/Button/Button'

type BaseModal = {
  opened: boolean
  modalTitle: string
  name: string | null
  categories: JobTitlesCategoryFromApi[]
  okText: string
  onCancel: () => any
  onOk: (text: string, validate: (errors: ValidationError[]) => void) => void
}

function BaseModal(props: BaseModal) {
  const [name, setName] = useState<string>(props.name ?? '')
  const [textError, setTextError] = useState<string | null>(null)

  function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
      if (error.property === 'category.name') {
        setTextError(error.message)
      }
    })
  }

  function resetAll(): void {
    setName('')
    setTextError(null)
  }

  function handleNameChange(newName: string): void {
    setName(newName)
    setTextError(null)
  }

  useEffect(() => {
    if (props.modalTitle === 'Create Category') {
      resetAll()
    }
  }, [props.opened])

  return (
    <Modal
      opened={props.opened}
      modalTitle={props.modalTitle}
      cancelText="Cancel"
      okText={props.okText}
      onCancel={() => {
        props.onCancel()
        resetAll()
      }}
      onOk={() => props.onOk(name, validate)}
    >
      <TextField
        label="Category"
        placeholder="Name..."
        required
        value={name}
        type={Type.Textarea}
        horizontal
        error={textError}
        onChange={(newName) => handleNameChange(newName)}
        maxLength={100}
      />
      <div className="all-categories">
        <p className="all-categories-title">ALL CATEGORIES</p>
        <div className="add-button-block">
          {props.categories.map((category) => (
            <Button text={category.name} size={Size.Medium} type={ButtonType.Grey} onClick={() => setName(name)} />
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default BaseModal
