// TODO: fix eslint errors
/* eslint-disable */
import './Button.scss'
import parse from 'html-react-parser'
import Spinner from '@atlaskit/spinner'

export enum Size {
  Big = 'big',
  Medium = 'medium',
}

export enum Type {
  Accent = 'accent',
  Ghost = 'ghost',
  Delete = 'delete',
  White = 'white',
  Grey = 'grey',
  Disabled = 'disabled',
  LightBlue = 'light-blue',
}

type Button = {
  text: string
  size: Size
  type: Type
  onClick: () => any
  isDisabled?: boolean
  icon?: string
  loading?: boolean
}

function Button(props: Button) {
  const classes = `button ${props.size}-button ${props.type}${props.isDisabled ? ' disabled-btn' : ''}`

  return (
    <button className={classes} onClick={!props.loading ? props.onClick : undefined} disabled={props.isDisabled}>
      {props.loading ? (
        <span className="spinner-icon">
          <Spinner size="small" />
        </span>
      ) : (
        <>
          {props.icon && <img src={props.icon} alt="" className="button-icon" />}
          {parse(props.text)}
        </>
      )}
    </button>
  )
}

export default Button
