import { useMemo } from 'react'
import { TablePaginationData } from 'types'
import { BonusesPenaltiesSortBy } from 'services/api'
import { BonusPenalty } from 'models'
import { Table as CodeHRTable } from 'components'
import { setEmployeeBonusPenaltyTableState, defaultEmployeeBonusPenaltyTableState } from 'store'
import { useAppDispatch, useAppSelector } from 'hooks'
import { getTableColumns } from './table-columns'
import { BonusPenaltyTableConfig, BonusPenaltyTableProps } from './types'

export const Table = (props: BonusPenaltyTableProps) => {
  const { isLoading, sourceData } = props

  const dispatch = useAppDispatch()

  const {
    tableState: { page, limit },
  } = useAppSelector((state) => state.employeeBonusPenaltyTable)

  const { isVisible: isVisibleAmountValue } = useAppSelector((state) => state.showEmployeeCompensation)

  // prettier-ignore
  const tableColumns: BonusPenaltyTableConfig = useMemo(
    () => getTableColumns({ isVisibleAmountValue }), [isVisibleAmountValue]
  )

  const onTableChange = (tableState: TablePaginationData<BonusesPenaltiesSortBy>) => {
    dispatch(setEmployeeBonusPenaltyTableState(tableState))
  }

  return (
    <CodeHRTable<BonusPenalty, BonusesPenaltiesSortBy>
      loading={isLoading}
      columns={tableColumns}
      recordIdKey="id"
      defaultTableState={defaultEmployeeBonusPenaltyTableState}
      dataSource={sourceData.items}
      onChange={onTableChange}
      pagination={{
        pageSize: limit,
        total: sourceData.total,
        current: page,
      }}
    />
  )
}
