import { Box } from '@atlaskit/primitives'
import { token } from '@atlaskit/tokens'
import Button from '@atlaskit/button/new'
import styled from 'styled-components'
import { ReactComponent as Stars } from '../../../assets/images/ProfileIcons/Stars.svg'
import { FC, ReactNode } from 'react'

const ButtonWrapper: FC<{
  $isActive: boolean
  children: ReactNode
  $width?: number
}> = styled.div`
  button {
    margin: auto;
    background-color: ${(props) =>
      props.$isActive ? token('color.background.discovery.bold.pressed') : token('color.background.discovery.bold')};
    color: ${token('color.text.inverse')};
    width: ${(props) => (props.$width ? `${props.$width}px` : '308px')};
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  button:hover {
    background-color: ${(props) =>
      props.$isActive
        ? token('color.background.discovery.bold.pressed')
        : token('color.background.discovery.bold.hovered')};
    color: ${token('color.text.inverse')};
  }

  button span {
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`

export default function AIButton({
  isActive,
  onClick,
  width,
}: {
  isActive: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  width?: number
}) {
  return (
    <Box>
      <ButtonWrapper $isActive={isActive} $width={width}>
        <Button
          onClick={(e) => {
            onClick(e)
          }}
        >
          <Stars style={{ marginRight: '4px' }} />
          Start personality analysis by AI
        </Button>
      </ButtonWrapper>
    </Box>
  )
}
