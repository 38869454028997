import { useEffect, useState } from 'react'
import { coreHRDateFormat, dateFormatDB } from 'utils/helpers/formatted-date'
import { DatePicker } from 'antd'
import { RangeValueType, NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'
import dayjs, { Dayjs } from 'dayjs'
import { CalendarIcon } from 'components'
import { getMatchingPreset } from './helpers'
import {
  dateRangePresets,
  DateRangePresetNameEnum,
  dateRangePresetNameLabelMap,
  defaultPresetName,
  defaultRangeState,
} from './constants'
import { IFilterRangeCalendarProps, IRangeState } from './types'
import { FilterRangeCalendarStyles } from './styles'

export const FilterRangeCalendar = (props: IFilterRangeCalendarProps) => {
  const { onChange, value = DateRangePresetNameEnum.LastWeek } = props

  const [showRange, setShowRange] = useState(false)
  const [rangeData, setRangeData] = useState<string[]>()
  const [range, setRange] = useState<IRangeState>(defaultRangeState)

  const handleClick = () => {
    setShowRange(!showRange)
  }

  const handleRangeChange = (dates: NoUndefinedRangeValueType<Dayjs>, dateStrings: string[]) => {
    const presetLabel = getMatchingPreset(dateStrings) || defaultPresetName

    setRange({
      presetLabel,
      valueLabel: dateStrings,
      value: dates,
    })

    setRangeData(dates.map((date) => dayjs(date).format(dateFormatDB)))
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setShowRange(false)
    }
  }

  useEffect(() => {
    if (!showRange && rangeData) {
      onChange(rangeData)
    }
  }, [showRange, rangeData])

  useEffect(() => {
    let startDay = dayjs()
    let endDay = dayjs()

    if (Array.isArray(value)) {
      startDay = dayjs(value[0])
      endDay = dayjs(value[1])

      setRange({
        presetLabel: getMatchingPreset(value) || defaultPresetName,
        valueLabel: [startDay.format(coreHRDateFormat), endDay.format(coreHRDateFormat)],
        value: [startDay, endDay],
      })
    } else {
      const preset = dateRangePresets.find(({ key }) => key === value)
      if (preset) {
        const datesArray = preset.value as RangeValueType<Dayjs>

        startDay = dayjs(datesArray[0])
        endDay = dayjs(datesArray[1])

        setRange({
          presetLabel: dateRangePresetNameLabelMap[preset.key],
          valueLabel: [startDay.format(coreHRDateFormat), endDay.format(coreHRDateFormat)],
          value: datesArray,
        })
      }
    }
  }, [value])

  return (
    <FilterRangeCalendarStyles>
      <CalendarIcon className="calendar-icon" />
      <div className="calendar" onClick={handleClick}>
        <span className="calendar-label">{range.presetLabel}</span>
        <span className="calendar-value">{`${range.valueLabel[0]} - ${range.valueLabel[1]}`}</span>
      </div>
      {showRange && (
        <DatePicker.RangePicker
          onOpenChange={handleOpenChange}
          presets={dateRangePresets}
          onCalendarChange={handleRangeChange}
          format={coreHRDateFormat}
          value={range.value}
          open={showRange}
          allowClear={false}
        />
      )}
    </FilterRangeCalendarStyles>
  )
}
