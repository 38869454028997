// TODO: fix eslint errors
/* eslint-disable */
import { FC, useState } from 'react'
import { useProfile } from '../../../context'
import { BlockInfoItem } from '../../../BlockInfo'
import cn from 'classnames'
import style from '../../../Profile.module.scss'
import InlineEdit from '@atlaskit/inline-edit'
import { Input } from '../../../../UI/Input'
import { InputText } from '../../../ViewingModes/InputText'
import { useSetField } from '../../../../../API/profile/setField/useSetField'
import { Tooltip } from '../../../../UI/Tooltip'
import { IconButton } from '@atlaskit/button/new'
import { ReactComponent as EditorRemoveIconNew } from '../../../../../assets/images/ProfileIcons/trash.svg'

interface NameProps {
  id: string
  employeeId: string
  person: string | null
  name: string | null
  removeContactPerson: (id: string) => void
  editContactPerson: (id: string, name: string, person: string) => void
}

export const Name: FC<NameProps> = ({ name, id, person, employeeId, removeContactPerson, editContactPerson }) => {
  const { isAccessEdit, emergencyContact, idOpenProfile } = useProfile()

  return (
    <BlockInfoItem title="Name">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <div className={cn(style.inputWrapper, isAccessEdit && style.profileInputTextIsEditing)}>
          <InlineEdit
            {...(!isAccessEdit && { isEditing: false })}
            defaultValue={name}
            editView={({ errorMessage, onChange, ...fieldProps }, ref) => (
              <Input
                {...fieldProps}
                onChange={(event) => {
                  onChange(event)
                }}
                ref={ref}
                autoFocus
                placeholder=""
                className={style.profileInput}
              />
            )}
            readView={() => <InputText value={name} />}
            onConfirm={(value) => {
              editContactPerson(id, value, person || '')
            }}
          />
        </div>
        {isAccessEdit && (
          <div className={style.contactBtnGroup}>
            <div>
              <Tooltip position="bottom" delay={0} content="Delete contact">
                {(tooltipProps) => (
                  <IconButton
                    {...tooltipProps}
                    onClick={() => {
                      removeContactPerson(id)
                    }}
                    appearance="subtle"
                    shape="default"
                    // @ts-ignore
                    icon={EditorRemoveIconNew}
                    //overlay={isRemoveLoading ? <Spinner size="medium" /> : null}
                    label="Delete"
                  />
                )}
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </BlockInfoItem>
  )
}
