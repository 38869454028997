import { PoliciesQueryType, TimeOffLogQueryType } from 'services/api/time-off-log/types'
import { TimeOffLogGetListOrderByEnum, OrderEnum } from 'shared'
import { IDefaultTimeOffLogPoliciesProps, IDefaultTimeOffLogTableProps } from './timeoff-log-table/types'

export const defaultTimeOffLogTableDate: IDefaultTimeOffLogTableProps = {
  total: 0,
  items: [],
}

export const defaultTimeOffLogPolicies: IDefaultTimeOffLogPoliciesProps = {
  total: 0,
  items: [],
}

export const defaultTimeOffLogQuery: TimeOffLogQueryType = {
  limit: 10,
  page: 1,
  sort: TimeOffLogGetListOrderByEnum.Date,
  order: OrderEnum.DESC,
}

export const defaultPolicyQuery: PoliciesQueryType = {
  page: 1,
  limit: 100,
}
