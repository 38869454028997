// TODO: fix eslint errors
/* eslint-disable */
import { useState } from 'react'

export interface Rule {
  text: string
  status: 'default' | 'error' | 'success' | 'errorBeforeCheck'
}

export interface ValidationRuleSet {
  name: string
  rules: Rule[]
}

export const useValidationRules = (resetPasswordValidationRules: ValidationRuleSet[]) => {
  const [ruleSets, setRuleSets] = useState<ValidationRuleSet[]>(resetPasswordValidationRules)
  const [isValid, setIsValid] = useState<boolean>(true)

  const validateFields = (fieldName: string, fieldValue: string, isBeforeCheck?: boolean) => {
    let isError = false
    const newRuleSets = ruleSets.map((ruleSet) => {
      if (ruleSet.name === fieldName) {
        const updatedRules = ruleSet.rules.map((rule) => {
          let newStatus: 'default' | 'error' | 'success' | 'errorBeforeCheck' = 'default'

          switch (rule.text) {
            case 'Only latin letters':
              newStatus = isBeforeCheck
                ? /^[\u0020-\u007F]+$/.test(fieldValue)
                  ? 'success'
                  : 'errorBeforeCheck'
                : /^[\u0020-\u007F]+$/.test(fieldValue)
                  ? 'success'
                  : 'error'
              break
            case 'At least 8 characters':
              newStatus = isBeforeCheck
                ? fieldValue.length >= 8
                  ? 'success'
                  : 'errorBeforeCheck'
                : fieldValue.length >= 8
                  ? 'success'
                  : 'error'
              break
            case 'At least 1 uppercase':
              newStatus = isBeforeCheck
                ? /[A-Z]/.test(fieldValue)
                  ? 'success'
                  : 'errorBeforeCheck'
                : /[A-Z]/.test(fieldValue)
                  ? 'success'
                  : 'error'
              break
            case 'At least 1 lowercase':
              newStatus = isBeforeCheck
                ? /[a-z]/.test(fieldValue)
                  ? 'success'
                  : 'errorBeforeCheck'
                : /[a-z]/.test(fieldValue)
                  ? 'success'
                  : 'error'
              break
            case 'At least 1 number':
              newStatus = isBeforeCheck
                ? /\d/.test(fieldValue)
                  ? 'success'
                  : 'errorBeforeCheck'
                : /\d/.test(fieldValue)
                  ? 'success'
                  : 'error'
              break
            case 'At least 1 special character':
              newStatus = isBeforeCheck
                ? /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(fieldValue)
                  ? 'success'
                  : 'errorBeforeCheck'
                : /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(fieldValue)
                  ? 'success'
                  : 'error'
              break
            default:
              break
          }

          if (newStatus === 'error') {
            isError = true
          }

          return { ...rule, status: newStatus }
        })

        return { ...ruleSet, rules: updatedRules }
      }

      return ruleSet
    })

    setRuleSets(newRuleSets)

    setIsValid(!isError)
  }

  return { ruleSets, validateFields, isValid }
}
