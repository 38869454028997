// TODO: fix eslint errors
/* eslint-disable */
import styled from 'styled-components'

export const StyledText = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 14px;
  p {
    margin: 0 0 0 10px;
    font-weight: 600;
    font-size: 14px;
    color: #172b4d;
  }
`

export const StyledExitButton = styled.div`
  button {
    width: 71px;
  }
`

export const StyledCancelButton = styled.div`
  button {
    width: 71px;
  }
`
