import { token } from '@atlaskit/tokens'
import styled from 'styled-components'
import { xcss } from '@atlaskit/primitives'

export const TimeOffLogTableStyles = styled.div`
  color: ${token('color.text.subtlest')};

  [aria-roledescription*='Sort'] {
    color: ${token('color.text.subtle')};
  }

  table {
    border-spacing: 0 ${token('space.100')};

    [class*='TableBodyCell'] {
      padding: ${token('space.100')};
    }

    td:first-child,
    th:first-child {
      padding-left: ${token('space.100')};
    }

    td:last-child,
    th:last-child {
      padding-right: ${token('space.100')};
    }
  }

  .policy-amount {
    display: inline-block;
    padding: 0 6px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    color: #172b4d;
    background-color: rgba(9, 30, 66, 0.06);

    &.is-positive {
      color: #216e4e;
      background-color: #dcfff1;
    }

    &.is-negative {
      color: #ae2e24;
      background-color: #ffeceb;
    }
  }
`

export const UserBlock = xcss({
  height: '40px',
  gap: 'space.100',
  alignItems: 'center',
  color: 'color.text.subtlest',
})

export const StyledUserBlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;

  & > a {
    color: ${token('color.text')};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  & > span {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
`
