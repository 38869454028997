import { type Dispatch, type SetStateAction, useEffect } from 'react'
import { useTimeOffBalance } from '../../../../API/profile/timeOffBalance/useTimeOffBalance'
import { useProfile } from '../../context'
import { TimeOffBalance } from './TimeOffBalance'
import cn from 'classnames'
import style from './TimeOffBalance.module.scss'
import styleParent from '../ShortInformationCard.module.scss'

interface TimeOffBalanceListProps {
  setIsNotEmptyTB: Dispatch<SetStateAction<boolean>>
}

export const TimeOffBalanceList = ({ setIsNotEmptyTB }: TimeOffBalanceListProps) => {
  const { idOpenProfile, setTimeOffBalance, updateTimeOffBalance } = useProfile()
  const { fetchTimeOffBalance, reFetchTimeOffBalance, timeOffBalance, isLoading } = useTimeOffBalance(idOpenProfile)

  useEffect(() => {
    fetchTimeOffBalance().then((response) => {
      setTimeOffBalance(response)
    })
  }, [fetchTimeOffBalance, setTimeOffBalance])

  useEffect(() => {
    if (updateTimeOffBalance) {
      reFetchTimeOffBalance().then((response) => {
        setTimeOffBalance(response)
      })
    }
  }, [reFetchTimeOffBalance, setTimeOffBalance, updateTimeOffBalance])

  useEffect(() => {
    setIsNotEmptyTB(Boolean(timeOffBalance?.length) || isLoading)
  }, [isLoading, setIsNotEmptyTB, timeOffBalance])

  if (!timeOffBalance || isLoading)
    return (
      <div>
        <div className={cn(styleParent.otherInfoBlockTitle, styleParent.otherInfoBlockTitleM)}>Balance:</div>

        <div>Loading...</div>
      </div>
    )

  if (!timeOffBalance.length) {
    return null
  }

  return (
    <div>
      <div className={cn(styleParent.otherInfoBlockTitle, styleParent.otherInfoBlockTitleM)}>Balance:</div>

      <div>
        <ul className={style.timeOffBalanceList}>
          {timeOffBalance.map((value) => (
            <li key={value.timeOffPolicyId}>
              <TimeOffBalance type={value.typeIcon} title={value.timeOffPolicyName} count={value.quantity} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
