import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultEmployeeBonusPenaltyTableState } from './constants'
import { EmployeeBonusPenaltyTableFilters, EmployeeBonusPenaltyTableState } from './types'

export const employeeBonusPenaltyTableSlice = createSlice({
  name: 'employeeBonusPenaltyTable',
  initialState: {
    tableState: defaultEmployeeBonusPenaltyTableState,
    tableFilters: {} as EmployeeBonusPenaltyTableFilters,
  },
  reducers: {
    setEmployeeBonusPenaltyTableState: (state, action: PayloadAction<EmployeeBonusPenaltyTableState>) => ({
      ...state,
      tableState: action.payload,
    }),
    setEmployeeBonusPenaltyFilters: (state, action: PayloadAction<EmployeeBonusPenaltyTableFilters>) => ({
      tableFilters: { ...state.tableFilters, ...action.payload },
      tableState: defaultEmployeeBonusPenaltyTableState,
    }),
  },
})

// prettier-ignore
export const { setEmployeeBonusPenaltyFilters, setEmployeeBonusPenaltyTableState } = employeeBonusPenaltyTableSlice.actions
export const employeeBonusPenaltyTableReducer = employeeBonusPenaltyTableSlice.reducer
