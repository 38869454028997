import {EMPLOYEES} from '../constants';
import {ProfileResponseProps} from "./profile.types";

export const fetchProfileAPI = async (id: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${id}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' }
        });

        return await response.json() as ProfileResponseProps;
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};
