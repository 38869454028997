import { useState } from 'react'
import { Form, Select } from 'antd'
import { FieldValues, useController } from 'react-hook-form'
import { FormSelectProps } from './types'
import { DropDownIcon } from 'components/icons'
import { SearchOutlined } from '@ant-design/icons'
import { FormSelectStyles } from './styles'

export const FormSelect = <FormDataType extends FieldValues, SelectOptionDataType = undefined>(
  props: FormSelectProps<FormDataType, SelectOptionDataType>,
) => {
  const { name, label, control, tooltip, mode, showSearch, placeholder, ...selectProps } = props

  const {
    field: fieldProps,
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const [open, setOpen] = useState(false)

  let suffixIcon: JSX.Element

  if (open && (mode === 'multiple' || showSearch)) {
    suffixIcon = <SearchOutlined />
  } else {
    suffixIcon = <DropDownIcon />
  }

  const onDropdownChange = (opened: boolean) => {
    setOpen(opened)
  }

  return (
    <FormSelectStyles>
      <Form.Item label={label} tooltip={tooltip} validateStatus={error && 'error'} help={error?.message}>
        <Select
          suffixIcon={suffixIcon}
          mode={mode}
          showSearch={showSearch}
          placeholder={placeholder}
          {...fieldProps}
          {...selectProps}
          onDropdownVisibleChange={onDropdownChange}
        />
      </Form.Item>
    </FormSelectStyles>
  )
}
