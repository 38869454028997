import { ValueDate } from 'rc-picker/lib/interface'
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'
import dayjs, { Dayjs } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { IRangeState } from './types'

dayjs.extend(isoWeek)

export const defaultPresetName = 'Dates'

export enum DateRangePresetNameEnum {
  Today = 'today',
  ThisWeek = 'thisWeek',
  LastWeek = 'lastWeek',
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
}

export const dateRangePresetNameLabelMap: Record<DateRangePresetNameEnum, string> = {
  [DateRangePresetNameEnum.Today]: 'Today',
  [DateRangePresetNameEnum.ThisWeek]: 'This Week',
  [DateRangePresetNameEnum.LastWeek]: 'Last Week',
  [DateRangePresetNameEnum.ThisMonth]: 'This Month',
  [DateRangePresetNameEnum.LastMonth]: 'Last Month',
}

type DateRangeValueType = ValueDate<RangeValueType<Dayjs>> & { key: DateRangePresetNameEnum }

export const dateRangePresets: DateRangeValueType[] = [
  {
    key: DateRangePresetNameEnum.Today,
    label: dateRangePresetNameLabelMap[DateRangePresetNameEnum.Today],
    value: [dayjs().startOf('day'), dayjs().endOf('day')],
  },
  {
    key: DateRangePresetNameEnum.ThisWeek,
    label: dateRangePresetNameLabelMap[DateRangePresetNameEnum.ThisWeek],
    value: [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')],
  },
  {
    key: DateRangePresetNameEnum.LastWeek,
    label: dateRangePresetNameLabelMap[DateRangePresetNameEnum.LastWeek],
    value: [dayjs().startOf('isoWeek').subtract(1, 'week'), dayjs().endOf('isoWeek').subtract(1, 'week')],
  },
  {
    key: DateRangePresetNameEnum.ThisMonth,
    label: dateRangePresetNameLabelMap[DateRangePresetNameEnum.ThisMonth],
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
  {
    key: DateRangePresetNameEnum.LastMonth,
    label: dateRangePresetNameLabelMap[DateRangePresetNameEnum.LastMonth],
    value: [dayjs().startOf('month').subtract(1, 'month'), dayjs().endOf('month').subtract(1, 'month')],
  },
]

export const defaultRangeState: IRangeState = {
  presetLabel: defaultPresetName,
  valueLabel: [],
  value: [null, null],
}
