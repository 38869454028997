// TODO: fix eslint errors
/* eslint-disable */
import BaseModal from './BaseModal'
import { CORPORATE_EVENTS } from '../../../../../../utils/constants/api'
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError'
import { NOT_FOUND_ERROR, VALIDATION_ERROR } from '../../../../../../utils/constants/errors'
import { OrgUnitFromApi } from '../../../../../../shared/OrgChart/data/OrgUnitFromApi'
import { LocationFromApi } from '../../../Locations/Locations'

type CreateCorporateEvent = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  orgUnits: OrgUnitFromApi[]
  locations: LocationFromApi[]
}

function CreateCorporateEvent(props: CreateCorporateEvent) {
  function create(
    name: string,
    orgUnits: string[],
    locations: string[],
    date: Date,
    dayOff: boolean,
    paidTimeOff: boolean,
    text: string,
    visible: boolean,
    archiveAfterFinish: boolean,
    validate: (errors: ValidationError[]) => void,
  ): void {
    const formData = new FormData()
    formData.append(
      'corporateEvent',
      JSON.stringify({
        name,
        orgUnitIds: orgUnits,
        locationIds: locations,
        date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        dayOff,
        paidTimeOff,
        text,
        visible,
        archiveAfterFinish,
      }),
    )

    fetch(CORPORATE_EVENTS, { method: 'POST', body: formData }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors))
      } else if (response.status === NOT_FOUND_ERROR) {
        validate([
          {
            property: 'title.categoryId',
            message: 'Category is required',
          },
        ])
      }
    })
  }

  function addOneDay(): Date {
    const newDate = new Date()
    newDate.setDate(newDate.getDate() + 1)
    return newDate
  }

  return (
    <BaseModal
      opened={props.opened}
      modalTitle="Create Corporate Event"
      name={null}
      orgUnitValues={props.orgUnits}
      orgUnits={[]}
      locationValues={props.locations}
      locations={[]}
      date={addOneDay()}
      dayOff="false"
      paidTimeOff="false"
      text={null}
      visible
      archiveAfterFinish={false}
      okText="Create"
      onCancel={props.onClose}
      onOk={create}
    />
  )
}

export default CreateCorporateEvent
