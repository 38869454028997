// TODO: fix eslint errors
/* eslint-disable */
import { FC } from 'react'
import { StyledEmptyDataBlock } from './EmptyDataPlaceholderStyles'

interface EmptyDataPlaceholderProps {
  title: string
}

export const EmptyDataPlaceholder: FC<EmptyDataPlaceholderProps> = ({ title }) => (
  <>
    <StyledEmptyDataBlock>
      <img src="/image/NoResultsFound.svg" />
      <h3>{title}</h3>
    </StyledEmptyDataBlock>
  </>
)
