import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Button } from 'antd'
import { QuestionCircleFilled } from '@ant-design/icons'
import { SupportFormModal } from 'components'
import { AuthPageStyles } from './styles'

export const Auth = () => {
  const [supportOpen, setSupportOpen] = useState(false)

  return (
    <AuthPageStyles>
      <div className="content">
        <Outlet />
      </div>

      <Button
        color="default"
        variant="filled"
        size="large"
        className="support-btn"
        icon={<QuestionCircleFilled className="support-btn__icon" />}
        onClick={() => setSupportOpen(true)}
      >
        Support
      </Button>

      <SupportFormModal
        isOpen={supportOpen}
        onCancel={() => {
          setSupportOpen(false)
        }}
      />
    </AuthPageStyles>
  )
}
