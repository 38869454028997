import { createGlobalStyle } from 'styled-components'

export const Globals = createGlobalStyle`
  /* Notifications */

  .ant-notification-notice-with-icon {
    display: grid;
    gap: 8px 0;

      .ant-notification-notice-message {
        margin-bottom: 0 !important;
      }

      .ant-notification-notice-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 4px !important;
      }
  }


  // TODO: Remove after clear  ADS reset styles file

  td, th {
    text-align: inherit;
  }

  .ant-pagination {
    .ant-pagination-item, .ant-pagination-next {
      margin-top: 0;
    }
  }
`
