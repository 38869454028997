import styled from 'styled-components'

export const FilterCheckboxGroupStyles = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper {
    width: 100%;
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 4px;

    &:hover {
      background: #e6f4ff;
    }
  }

  .ant-checkbox .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }

  .ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }

  .ant-checkbox + span {
    padding-inline-start: 8px;
    color: rgba(0, 0, 0, 0.88);
  }

  .ant-checkbox .ant-checkbox-inner:after {
    inset-inline-start: 20%;
  }
`
