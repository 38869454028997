import { forwardRef } from "react";
import style from "./Tag.module.scss";
import Group, { type TagGroupProps } from "@atlaskit/tag-group";

export const TagGroup = forwardRef<HTMLDivElement, TagGroupProps>(
  ({ children, ...restProps }, ref) => (
      <div className={style.tagGroup}>
        <Group {...restProps} ref={ref}>
          {children}
        </Group>
      </div>
    )
);
