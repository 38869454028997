// TODO: fix eslint errors
/* eslint-disable */
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { EMPLOYEE_PROFILE } from 'utils/constants/routes'
import { BirthdayFromApi } from 'shared/Main/Celebrations/data/BirthdayFromApi'
import { EMPLOYEES, FILTERS } from 'utils/constants/api'
import { xcss, Box } from '@atlaskit/primitives'
import FeedbackIcon from '@atlaskit/icon/glyph/feedback'
import Avatar from '@atlaskit/avatar'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CustomCarousel from '../CustomCarousel'
import { TypeIconsCollection } from 'shared/TimeOffPolicy/constants/NewTypeIconsCollection'
import { formatDateToFriendly } from 'utils/helpers/DateTime/dateFromStringWithDefaultTimezone'
import FilterToggle from '../FilterToggle'
import Tooltip from '@atlaskit/tooltip'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4.3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1440, min: 464 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
}

const AvatarWrapper: FC<{
  children: ReactElement
}> = styled.div`
  ${(props) => `
     
    & > div>span{
      width:54px;
      height:54px;
      svg{
        width:54px;
        height:54px;
      }
      margin:0px;
    }
    
    `}
`

type Option = {
  label: string
  value: string
  company: string
}

function Birthdays({ employee }: any) {
  const [orgUnitOptions, setOrgUnitOptions] = useState<Option[]>([])

  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<Option[]>([])

  const [birthdays, setBirthdays] = useState<BirthdayFromApi[]>([])
  const [inited, setInited] = useState(false)

  useEffect(() => {
    if (employee) {
      fetch(`${EMPLOYEES}/birthdays`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((birthdays) => {
          setBirthdays(birthdays)
        })

      fetch(`${FILTERS}/calendar/org-units-by-employees`)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          return {}
        })
        .then((data) => {
          if ('orgUnits' in data) {
            const companies = Object.values(
              (data as { orgUnits: any }).orgUnits as {
                name: string
                id: string
                companyName: string
              }[],
            ).map((item) => ({
              label: item.companyName,
              value: item.companyName,
            }))

            const uniqCompanies: {
              [key: string]: any
            } = {}

            companies.forEach((elm) => {
              if (elm.label) {
                uniqCompanies[elm.label] = elm
              }
            })

            const selectedItems = Object.values(uniqCompanies).filter((item) => item.value === employee.companyName)
            const unselectedItems = Object.values(uniqCompanies).filter((item) => !selectedItems.includes(item))

            setOrgUnitOptions([...selectedItems, ...unselectedItems])
            setSelectedOrgUnitOptions(selectedItems)
            setInited(true)
          }
        })
    }
  }, [employee])

  const birthdaysFiltered = inited
    ? birthdays.filter(
        (item) =>
          selectedOrgUnitOptions.length === 0 ||
          selectedOrgUnitOptions.some((elm) => item.value.companyName === elm.value),
      )
    : []

  return (
    <Box
      xcss={xcss({
        marginTop: 'space.300',
        padding: 'space.200',
        backgroundColor: 'elevation.surface',
        color: 'color.text',
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        border: '1px solid',
        borderColor: 'color.border.disabled',
        borderRadius: 'border.radius.050',
        minHeight: '96px',
      })}
    >
      <Box
        xcss={xcss({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        })}
      >
        <Box>
          <Box
            xcss={xcss({
              fontFamily: 'inherit',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              color: 'color.text',
            })}
          >
            Birthdays
          </Box>
        </Box>
        <FilterToggle
          onSelectClick={() => {
            const unselectedItems = orgUnitOptions.filter((item) => !selectedOrgUnitOptions.includes(item))

            setOrgUnitOptions([...selectedOrgUnitOptions, ...unselectedItems])
          }}
          options={orgUnitOptions}
          onChange={(values) => {
            setSelectedOrgUnitOptions(values as Option[])
          }}
          value={selectedOrgUnitOptions}
        />
      </Box>

      <Box xcss={xcss({ marginTop: 'space.100' })}>
        {' '}
        {!(birthdaysFiltered.length > 0) ? (
          <Box
            xcss={xcss({
              color: 'color.text.disabled',
              display: 'flex',
              fontFamily: 'inherit',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              marginTop: '8px',
            })}
          >
            <FeedbackIcon label="FeedbackIcon" />
            <Box xcss={xcss({ marginLeft: 'space.100', marginTop: '2px' })}>Party alarm: no birthdays today!</Box>
          </Box>
        ) : (
          <CustomCarousel itemWidth={174} responsiveParams={responsive}>
            {birthdaysFiltered.map((request, index) => (
              <Box
                key={request.value.id}
                xcss={xcss({
                  paddingTop: 'space.150',
                  paddingBottom: 'space.150',
                  width: '158px',
                  height: '146px',
                  overflow: 'hidden',
                  border: '1px solid',
                  borderRadius: 'border.radius.100',
                  borderColor: 'color.border.disabled',
                  textAlign: 'center',
                })}
              >
                <Box
                  xcss={xcss({
                    position: 'relative',
                    marginRight: 'space.200',
                    width: '54px',
                    margin: 'auto',
                  })}
                >
                  <Link
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    to={`${EMPLOYEE_PROFILE}/${request.value.id}`}
                  >
                    <Box
                      xcss={xcss({
                        position: 'relative',
                        height: '54px',
                      })}
                    >
                      <AvatarWrapper>
                        <Avatar src={request.linkToTheImage} size="xlarge" />
                      </AvatarWrapper>

                      <Box
                        xcss={xcss({
                          position: 'absolute',
                          inset: '36px 0 0 36px',
                        })}
                      >
                        {TypeIconsCollection.birthday('circle-icon', 'withouthover')}
                      </Box>
                    </Box>
                  </Link>
                </Box>
                <Box>
                  <Link style={{ color: 'inherit' }} to={`${EMPLOYEE_PROFILE}/${request.value.id}`}>
                    {' '}
                    <Tooltip position="bottom" content={request.value.name}>
                      <Box
                        xcss={xcss({
                          margin: 'auto',
                          marginTop: 'space.100',
                          color: 'color.text.subtle',
                          fontFamily: 'inherit',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: '20px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '140px',
                          height: '20px',
                          whiteSpace: 'nowrap',
                        })}
                      >
                        {request.value.name}
                      </Box>{' '}
                    </Tooltip>
                  </Link>

                  {request.value.nickname && (
                    <Box
                      xcss={xcss({
                        color: 'color.text.subtlest',
                        fontFamily: 'inherit',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '14px',
                        marginTop: 'space.025',
                        marginBottom: 'space.025',
                      })}
                    >
                      {request.value.nickname}
                    </Box>
                  )}

                  <Box
                    xcss={xcss({
                      color: 'color.text.subtlest',
                      fontFamily: 'inherit',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                    })}
                  >
                    {' '}
                    {`${formatDateToFriendly(request.value.currentBirthdayDate)}, ${new Date().getFullYear()}`}
                  </Box>
                </Box>
              </Box>
            ))}
          </CustomCarousel>
        )}
      </Box>
    </Box>
  )
}

export default Birthdays
