export const formatTime = (time?: string) => {
  if (typeof time !== 'string' || time.startsWith('00:00')) {
    return '-'
  }

  const unsignedTime = time.replace(/^[-+]/, '')

  const [hours, minutes] = unsignedTime.split(':')
  const formattedHours = parseInt(hours, 10)
  return `${formattedHours}:${minutes}`
}
