export const CheckSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M4.492 7.54556C4.37166 7.41747 4.20566 7.34211 4.03009 7.33586C3.85452 7.32962 3.68358 7.39298 3.55445 7.51219C3.42532 7.6314 3.34844 7.79681 3.34052 7.97244C3.3326 8.14808 3.39429 8.31974 3.51217 8.4501L5.91976 11.0643C6.2777 11.4152 6.81094 11.4152 7.14222 11.0843L7.38484 10.8449C8.26105 9.9825 9.13623 9.11909 10.0104 8.25465L10.0371 8.22797C10.854 7.4222 11.6668 6.61214 12.4753 5.79785C12.5961 5.67124 12.6624 5.50216 12.6598 5.3271C12.6571 5.15203 12.5858 4.98502 12.4613 4.8621C12.3367 4.73918 12.1688 4.6702 11.9939 4.67004C11.8189 4.66989 11.6509 4.73858 11.5261 4.86129C10.7214 5.67133 9.91262 6.47738 9.09988 7.2794L9.07322 7.30608C8.23554 8.13477 7.3968 8.96238 6.55698 9.7889L4.492 7.54556Z"
      fill="currentColor"
    />
  </svg>
)
export const CloseSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2869 5.09995L8.16539 7.22213L6.04253 5.09928C5.97906 5.03721 5.8937 5.00262 5.80492 5.00299C5.71614 5.00336 5.63107 5.03867 5.56812 5.10128L5.10171 5.56836C5.06978 5.59899 5.04432 5.63572 5.02685 5.67637C5.00938 5.71701 5.00025 5.76076 5.00001 5.805C4.99976 5.84924 5.00839 5.89309 5.02541 5.93393C5.04242 5.97477 5.06746 6.01178 5.09904 6.04277L7.22123 8.16429L5.09971 10.2871C5.03772 10.3507 5.00325 10.4361 5.00375 10.5249C5.00425 10.6137 5.03968 10.6987 5.10237 10.7616L5.56879 11.228C5.70605 11.3659 5.9126 11.3612 6.0432 11.2306L8.16605 9.10845L10.2876 11.2306C10.3511 11.2926 10.4365 11.3271 10.5253 11.3266C10.6141 11.3261 10.6991 11.2907 10.762 11.228L11.2291 10.7616C11.261 10.7309 11.2864 10.6941 11.3038 10.6534C11.3212 10.6128 11.3302 10.569 11.3304 10.5248C11.3306 10.4805 11.3219 10.4367 11.3049 10.3959C11.2878 10.3551 11.2627 10.3181 11.2311 10.2871L9.10821 8.16429L11.2311 6.04277C11.2931 5.9793 11.3277 5.89394 11.3274 5.80515C11.327 5.71637 11.2917 5.63131 11.2291 5.56836L10.7627 5.10194C10.7314 5.06994 10.6941 5.04446 10.6529 5.02696C10.6117 5.00946 10.5675 5.0003 10.5228 5C10.4789 5.00036 10.4356 5.00937 10.3952 5.02652C10.3549 5.04367 10.3183 5.06863 10.2876 5.09995H10.2869Z"
      fill="currentColor"
      fillOpacity="0.88"
    />
  </svg>
)
export const LinkSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8.96904 10.3974C8.94555 10.3742 8.91382 10.3611 8.88076 10.3611C8.8477 10.3611 8.81597 10.3742 8.79248 10.3974L6.97686 12.213C6.13623 13.0537 4.71748 13.1427 3.78936 12.213C2.85967 11.2834 2.94873 9.86616 3.78936 9.02554L5.60498 7.20991C5.65342 7.16148 5.65342 7.08179 5.60498 7.03335L4.98311 6.41148C4.95961 6.38821 4.92789 6.37516 4.89482 6.37516C4.86176 6.37516 4.83004 6.38821 4.80654 6.41148L2.99092 8.2271C1.66904 9.54898 1.66904 11.688 2.99092 13.0084C4.31279 14.3287 6.45186 14.3302 7.77217 13.0084L9.58779 11.1927C9.63623 11.1443 9.63623 11.0646 9.58779 11.0162L8.96904 10.3974ZM13.0097 2.99116C11.6878 1.66929 9.54873 1.66929 8.22842 2.99116L6.41123 4.80679C6.38797 4.83028 6.37492 4.86201 6.37492 4.89507C6.37492 4.92813 6.38797 4.95986 6.41123 4.98335L7.03154 5.60366C7.07998 5.6521 7.15967 5.6521 7.20811 5.60366L9.02373 3.78804C9.86436 2.94741 11.2831 2.85835 12.2112 3.78804C13.1409 4.71772 13.0519 6.13491 12.2112 6.97554L10.3956 8.79116C10.3723 8.81466 10.3593 8.84638 10.3593 8.87944C10.3593 8.91251 10.3723 8.94423 10.3956 8.96773L11.0175 9.5896C11.0659 9.63804 11.1456 9.63804 11.194 9.5896L13.0097 7.77398C14.33 6.4521 14.33 4.31304 13.0097 2.99116V2.99116ZM9.53311 5.81772C9.50961 5.79446 9.47789 5.78141 9.44482 5.78141C9.41176 5.78141 9.38004 5.79446 9.35654 5.81772L5.81748 9.35523C5.79422 9.37872 5.78117 9.41044 5.78117 9.44351C5.78117 9.47657 5.79422 9.50829 5.81748 9.53179L6.43623 10.1505C6.48467 10.199 6.56436 10.199 6.61279 10.1505L10.1503 6.61304C10.1987 6.5646 10.1987 6.48491 10.1503 6.43648L9.53311 5.81772Z"
      fill="currentColor"
      fillOpacity="0.88"
    />
  </svg>
)
export const InfoSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
    <g clipPath="url(#clip0_14301_9800)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3999 14C6.48065 14 5.5704 13.8189 4.72112 13.4672C3.87184 13.1154 3.10016 12.5998 2.45015 11.9497C1.80014 11.2997 1.28453 10.5281 0.932745 9.67878C0.580963 8.8295 0.399902 7.91925 0.399902 7C0.399902 6.08075 0.580963 5.17049 0.932745 4.32122C1.28453 3.47194 1.80014 2.70026 2.45015 2.05025C3.10016 1.40024 3.87184 0.884626 4.72112 0.532843C5.5704 0.18106 6.48065 -1.36979e-08 7.3999 0C9.25642 2.76642e-08 11.0369 0.737498 12.3496 2.05025C13.6624 3.36301 14.3999 5.14348 14.3999 7C14.3999 8.85652 13.6624 10.637 12.3496 11.9497C11.0369 13.2625 9.25642 14 7.3999 14ZM7.3999 12.5C8.85859 12.5 10.2575 11.9205 11.289 10.8891C12.3204 9.85764 12.8999 8.45869 12.8999 7C12.8999 5.54131 12.3204 4.14236 11.289 3.11091C10.2575 2.07946 8.85859 1.5 7.3999 1.5C5.94121 1.5 4.54226 2.07946 3.51081 3.11091C2.47936 4.14236 1.8999 5.54131 1.8999 7C1.8999 8.45869 2.47936 9.85764 3.51081 10.8891C4.54226 11.9205 5.94121 12.5 7.3999 12.5ZM7.3999 6.5C7.66512 6.5 7.91947 6.60536 8.10701 6.79289C8.29455 6.98043 8.3999 7.23478 8.3999 7.5V9.5C8.3999 9.76522 8.29455 10.0196 8.10701 10.2071C7.91947 10.3946 7.66512 10.5 7.3999 10.5C7.13469 10.5 6.88033 10.3946 6.6928 10.2071C6.50526 10.0196 6.3999 9.76522 6.3999 9.5V7.5C6.3999 7.23478 6.50526 6.98043 6.6928 6.79289C6.88033 6.60536 7.13469 6.5 7.3999 6.5ZM7.3999 3.5C7.66512 3.5 7.91947 3.60536 8.10701 3.79289C8.29455 3.98043 8.3999 4.23478 8.3999 4.5C8.3999 4.76522 8.29455 5.01957 8.10701 5.20711C7.91947 5.39464 7.66512 5.5 7.3999 5.5C7.13469 5.5 6.88033 5.39464 6.6928 5.20711C6.50526 5.01957 6.3999 4.76522 6.3999 4.5C6.3999 4.23478 6.50526 3.98043 6.6928 3.79289C6.88033 3.60536 7.13469 3.5 7.3999 3.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_14301_9800">
        <rect width="14" height="14" fill="white" transform="translate(0.399902)" />
      </clipPath>
    </defs>
  </svg>
)
export const DropDownSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.29158 0.793429C0.104773 0.982322 0 1.23727 0 1.50293C0 1.76859 0.104773 2.02354 0.29158 2.21243L3.23058 5.17743C3.44858 5.39243 3.73058 5.49943 4.00958 5.49943C4.28858 5.49943 4.56558 5.39243 4.77858 5.17743L7.70858 2.22243C7.89515 2.03341 7.99976 1.77851 7.99976 1.51293C7.99976 1.24734 7.89515 0.992448 7.70858 0.80343C7.61675 0.710245 7.50731 0.636247 7.38662 0.585739C7.26593 0.535231 7.13641 0.509221 7.00558 0.509221C6.87475 0.509221 6.74523 0.535231 6.62454 0.585739C6.50385 0.636247 6.39441 0.710245 6.30258 0.80343L4.00458 3.12043L1.69758 0.793429C1.60554 0.700608 1.49604 0.626932 1.37538 0.576653C1.25472 0.526375 1.1253 0.500488 0.99458 0.500488C0.863865 0.500488 0.734443 0.526375 0.613784 0.576653C0.493125 0.626932 0.383617 0.700608 0.29158 0.793429Z"
      fill="currentColor"
    />
  </svg>
)
export const CalendarSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.995 5H19.005C20.107 5 21 5.895 21 6.994V19.006C21 19.2679 20.9484 19.5273 20.8481 19.7693C20.7478 20.0113 20.6009 20.2312 20.4156 20.4163C20.2304 20.6015 20.0104 20.7484 19.7684 20.8485C19.5263 20.9487 19.2669 21.0001 19.005 21H4.995C4.46607 21 3.95878 20.7899 3.58468 20.416C3.21057 20.0421 3.00027 19.5349 3 19.006V6.994C3 5.893 3.892 5 4.995 5ZM5 9V18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H18C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18V9H5ZM6 4C6 3.73478 6.10536 3.48043 6.29289 3.29289C6.48043 3.10536 6.73478 3 7 3C7.26522 3 7.51957 3.10536 7.70711 3.29289C7.89464 3.48043 8 3.73478 8 4V5H6V4ZM16 4C16 3.73478 16.1054 3.48043 16.2929 3.29289C16.4804 3.10536 16.7348 3 17 3C17.2652 3 17.5196 3.10536 17.7071 3.29289C17.8946 3.48043 18 3.73478 18 4V5H16V4ZM7 13V10.999H9V13H7ZM15 13V10.999H17V13H15ZM11 13V10.999H13.001V13H11ZM7 17V15H9V17H7ZM11 17V15H13.001V17H11ZM15 17V15H17V17H15Z"
      fill="currentColor"
    />
  </svg>
)
export const SuccessSvg = () => (
  <svg width="74" height="74" viewBox="0 0 74 74" fill="none">
    <g id="Icon / &#60;CheckCircleIcon&#62;">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.8332 37C67.8332 54.0287 54.0286 67.8333 36.9998 67.8333C19.9711 67.8333 6.1665 54.0287 6.1665 37C6.1665 19.9712 19.9711 6.16663 36.9998 6.16663C54.0286 6.16663 67.8332 19.9712 67.8332 37ZM28.9289 34.1291C29.3051 34.2907 29.6453 34.5256 29.9297 34.8201L33.9165 38.8069L44.0699 28.6534C44.3543 28.359 44.6946 28.1241 45.0708 27.9625C45.4469 27.8009 45.8515 27.7158 46.2609 27.7123C46.6703 27.7087 47.0763 27.7867 47.4553 27.9417C47.8342 28.0968 48.1785 28.3257 48.468 28.6152C48.7575 28.9047 48.9864 29.249 49.1414 29.6279C49.2965 30.0068 49.3745 30.4129 49.3709 30.8223C49.3674 31.2317 49.2823 31.6363 49.1207 32.0124C48.9591 32.3886 48.7242 32.7288 48.4297 33.0133L36.0964 45.3466C35.5182 45.9246 34.7341 46.2494 33.9165 46.2494C33.0989 46.2494 32.3148 45.9246 31.7366 45.3466L25.5699 39.1799C25.2754 38.8955 25.0405 38.5553 24.8789 38.1791C24.7173 37.8029 24.6323 37.3983 24.6287 36.9889C24.6252 36.5795 24.7032 36.1735 24.8582 35.7946C25.0132 35.4157 25.2422 35.0714 25.5317 34.7819C25.8212 34.4924 26.1655 34.2634 26.5444 34.1084C26.9233 33.9534 27.3293 33.8754 27.7387 33.8789C28.1481 33.8825 28.5527 33.9675 28.9289 34.1291Z"
        fill="#22A06B"
      />
    </g>
  </svg>
)
export const ErrorSvg = () => (
  <svg width="74" height="74" viewBox="0 0 74 74" fill="none">
    <g id="Icon / &#60;CrossCircleIcon&#62;">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9998 67.8333C54.0286 67.8333 67.8332 54.0287 67.8332 37C67.8332 19.9712 54.0286 6.16663 36.9998 6.16663C19.9711 6.16663 6.1665 19.9712 6.1665 37C6.1665 54.0287 19.9711 67.8333 36.9998 67.8333ZM32.6579 37.0474L28.1106 41.6218C27.817 41.9071 27.5832 42.248 27.4228 42.6246C27.2623 43.0012 27.1785 43.406 27.1761 43.8153C27.1738 44.2247 27.253 44.6304 27.4091 45.0088C27.5652 45.3873 27.7951 45.7308 28.0853 46.0195C28.3756 46.3081 28.7205 46.536 29.0998 46.69C29.4791 46.844 29.8852 46.9209 30.2946 46.9162C30.7039 46.9115 31.1082 46.8254 31.4839 46.6628C31.8596 46.5003 32.1991 46.2645 32.4828 45.9693L37.0083 41.4168L41.446 45.8738C42.0229 46.4536 42.8065 46.7805 43.6245 46.7825C44.4424 46.7845 45.2276 46.4616 45.8074 45.8846C46.3872 45.3077 46.714 44.5241 46.7161 43.7062C46.7181 42.8883 46.3951 42.1031 45.8182 41.5233L41.3557 37.0434L45.9261 32.4458C46.4863 31.8627 46.7951 31.0829 46.7859 30.2744C46.7766 29.4659 46.4502 28.6933 45.8768 28.1232C45.3034 27.553 44.529 27.2309 43.7204 27.2263C42.9119 27.2216 42.1338 27.5348 41.5539 28.0983L37.0043 32.675L32.3748 28.0275C31.7945 27.4645 31.0163 27.152 30.2078 27.1572C29.3993 27.1625 28.6252 27.4851 28.0522 28.0556C27.4793 28.6261 27.1534 29.3989 27.1447 30.2074C27.136 31.0159 27.4452 31.7954 28.0058 32.3781L32.6579 37.0474Z"
        fill="#C9372C"
      />
    </g>
  </svg>
)
export const AddSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 11V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H11V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11H13Z"
      fill="currentColor"
    />
  </svg>
)
export const ShortcutSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.67 12.6667C12.668 12.6667 12.6667 12.668 12.6667 12.668L12.67 12.6667ZM3.33333 12.6707C3.33333 12.668 3.332 12.6667 3.33 12.6667H3.33333V12.6707ZM12.6667 12.6667V8.66667H14V12.668C14.0002 12.8428 13.9659 13.0159 13.8992 13.1775C13.8324 13.339 13.7345 13.4859 13.611 13.6095C13.4875 13.7332 13.3408 13.8314 13.1793 13.8984C13.0179 13.9654 12.8448 13.9999 12.67 14H3.33C2.97743 13.9998 2.63934 13.8597 2.38998 13.6105C2.14061 13.3613 2.00035 13.0232 2 12.6707V3.32933C2 2.59533 2.59733 2 3.33133 2H7.33333V3.33333H3.33333V12.6667H12.6667ZM3.33333 12.6707C3.33333 12.668 3.332 12.6667 3.33 12.6667H3.33333V12.6707ZM7.33333 3.33333H3.33333V12.6667H12.6667V8.66667H14V12.668C14.0002 12.8428 13.9659 13.0159 13.8992 13.1775C13.8324 13.339 13.7345 13.4859 13.611 13.6095C13.4875 13.7332 13.3408 13.8314 13.1793 13.8984C13.0179 13.9654 12.8448 13.9999 12.67 14H3.33C2.97743 13.9998 2.63934 13.8597 2.38998 13.6105C2.14061 13.3613 2.00035 13.0232 2 12.6707V3.32933C2 2.59533 2.59733 2 3.33133 2H7.33333V3.33333ZM12.6667 3.33333V5.33333C12.6667 5.51014 12.7369 5.67971 12.8619 5.80474C12.987 5.92976 13.1565 6 13.3333 6C13.5101 6 13.6797 5.92976 13.8047 5.80474C13.9298 5.67971 14 5.51014 14 5.33333V2.66667C14 2.48986 13.9298 2.32029 13.8047 2.19526C13.6797 2.07024 13.5101 2 13.3333 2H10.6667C10.4899 2 10.3203 2.07024 10.1953 2.19526C10.0702 2.32029 10 2.48986 10 2.66667C10 2.84348 10.0702 3.01305 10.1953 3.13807C10.3203 3.2631 10.4899 3.33333 10.6667 3.33333H12.6667Z"
      fill="black"
      fillOpacity="0.45"
    />
    <path
      d="M7.3251 9.61758L12.6584 4.28425L12.6667 4.11328C12.6667 4.11328 12.6659 3.93227 12.6667 3.84375C12.6674 3.75523 12.6667 3.55078 12.6667 3.55078V3.33333H12.4609C12.4609 3.33333 12.3463 3.33256 12.2578 3.33333C12.1693 3.3341 12.1007 3.33194 12 3.33333L11.7158 3.34158L9.0491 6.00825L6.38243 8.67492C6.26099 8.80065 6.19379 8.96905 6.19531 9.14385C6.19683 9.31865 6.26694 9.48586 6.39055 9.60946C6.51415 9.73307 6.68136 9.80318 6.85616 9.8047C7.03096 9.80622 7.19936 9.73902 7.3251 9.61758Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
)
export const NoDataSvg = () => (
  <svg width="300" height="292" viewBox="0 0 300 292" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
      <rect width="300" height="292" fill="white" />
      <circle cx="149.644" cy="145.757" r="114.324" fill="#F0F0F0" />
      <rect x="92.0168" y="73.2589" width="118.971" height="139.42" rx="13.0125" fill="#FEFEFE" />
      <rect x="100.382" y="87.2008" width="48.3322" height="9.29465" rx="4.64732" fill="#DCDCDC" />
      <rect x="100.382" y="141.11" width="95.7349" height="9.29465" rx="4.64732" fill="#D7D7D7" />
      <rect x="100.382" y="168.064" width="95.7349" height="9.29465" rx="4.64732" fill="#D7D7D7" />
      <rect x="100.382" y="195.019" width="95.7349" height="9.29465" rx="4.64732" fill="#D7D7D7" />
      <rect x="219.353" y="49.0927" width="79.934" height="33.4607" rx="5.57679" fill="#FEFEFE" />
      <circle cx="234.225" cy="65.8231" r="5.57679" fill="#456184" />
      <rect x="248.167" y="60.2463" width="30.6723" height="11.1536" rx="5.57679" fill="#D5D5D5" />
      <path
        d="M126.529 119.692C123.208 119.692 119.947 117.44 117.337 115.247L114.195 112.64C112.238 111.04 109.925 110.151 107.553 110.151H80.7498C78.3185 110.151 76.3024 112.107 76.3024 114.536V119.692V213.32C76.3024 216.579 78.9708 219.246 82.2323 219.246H217.672C220.933 219.246 223.602 216.579 223.602 213.32V125.558C223.602 122.299 220.933 119.692 217.672 119.692H126.529Z"
        fill="#456184"
      />
      <path
        d="M212.809 138.595H174.502C172.782 138.595 171.418 137.232 171.418 135.514C171.418 133.795 172.782 132.432 174.502 132.432H212.809C214.528 132.432 215.892 133.795 215.892 135.514C215.892 137.232 214.528 138.595 212.809 138.595Z"
        stroke="white"
        strokeWidth="1.85893"
        strokeMiterlimit="10"
      />
      <path
        d="M92.8987 181.077V161.43H94.9001L112.273 179.974L109.659 178.19H110.639V161.43H112.682V181.077H110.68L93.3071 162.519L95.9076 164.303H94.941V181.077H92.8987Z"
        fill="white"
      />
      <path
        d="M123.724 181.363C122.081 181.363 120.628 181.063 119.367 180.464C118.114 179.856 117.129 178.998 116.412 177.891C115.704 176.784 115.35 175.481 115.35 173.983V173.956C115.35 172.458 115.704 171.16 116.412 170.062C117.129 168.955 118.114 168.102 119.367 167.502C120.628 166.894 122.081 166.59 123.724 166.59C125.366 166.59 126.819 166.894 128.08 167.502C129.342 168.102 130.327 168.955 131.035 170.062C131.752 171.16 132.11 172.458 132.11 173.956V173.983C132.11 175.49 131.752 176.797 131.035 177.905C130.327 179.003 129.342 179.856 128.08 180.464C126.828 181.063 125.375 181.363 123.724 181.363ZM123.724 179.661C124.967 179.661 126.061 179.434 127.005 178.98C127.958 178.526 128.702 177.877 129.238 177.033C129.782 176.18 130.055 175.163 130.055 173.983V173.956C130.055 172.776 129.782 171.764 129.238 170.92C128.702 170.076 127.958 169.431 127.005 168.987C126.061 168.533 124.967 168.306 123.724 168.306C122.489 168.306 121.395 168.533 120.442 168.987C119.489 169.431 118.745 170.076 118.209 170.92C117.674 171.764 117.406 172.776 117.406 173.956V173.983C117.406 175.163 117.674 176.18 118.209 177.033C118.745 177.877 119.489 178.526 120.442 178.98C121.395 179.434 122.489 179.661 123.724 179.661Z"
        fill="white"
      />
      <path
        d="M141.886 181.077V161.43H151.825C154.085 161.43 156.005 161.82 157.585 162.601C159.173 163.382 160.38 164.503 161.206 165.964C162.041 167.425 162.459 169.177 162.459 171.219V171.247C162.459 173.289 162.041 175.045 161.206 176.516C160.38 177.986 159.173 179.116 157.585 179.906C156.005 180.687 154.085 181.077 151.825 181.077H141.886ZM143.956 179.266H151.716C153.568 179.266 155.134 178.953 156.414 178.327C157.693 177.691 158.665 176.779 159.327 175.59C159.999 174.392 160.335 172.953 160.335 171.274V171.247C160.335 169.549 160.003 168.106 159.341 166.917C158.678 165.719 157.707 164.807 156.427 164.18C155.147 163.554 153.577 163.241 151.716 163.241H143.956V179.266Z"
        fill="white"
      />
      <path
        d="M170.764 181.349C169.484 181.349 168.368 181.181 167.415 180.845C166.471 180.5 165.735 180.006 165.209 179.361C164.692 178.708 164.433 177.914 164.433 176.979V176.965C164.433 176.157 164.642 175.458 165.059 174.868C165.477 174.278 166.117 173.82 166.979 173.493C167.85 173.157 168.962 172.971 170.315 172.935L178.361 172.731V174.201L170.533 174.419C169.144 174.455 168.118 174.687 167.456 175.113C166.793 175.54 166.462 176.139 166.462 176.911V176.938C166.462 177.836 166.884 178.522 167.728 178.994C168.581 179.466 169.702 179.702 171.091 179.702C172.334 179.702 173.424 179.516 174.358 179.143C175.302 178.771 176.038 178.249 176.564 177.578C177.091 176.906 177.354 176.125 177.354 175.236V172.118C177.354 170.884 176.909 169.935 176.02 169.272C175.13 168.61 173.873 168.279 172.248 168.279C170.814 168.279 169.648 168.533 168.749 169.041C167.85 169.54 167.279 170.235 167.033 171.124L166.993 171.26H165.046L165.059 171.111C165.223 170.221 165.613 169.436 166.23 168.755C166.856 168.074 167.678 167.543 168.695 167.162C169.72 166.781 170.909 166.59 172.262 166.59C173.75 166.59 175.026 166.804 176.088 167.23C177.15 167.657 177.962 168.274 178.525 169.082C179.087 169.881 179.369 170.843 179.369 171.968V181.077H177.354V178.177H177.313C176.986 178.812 176.501 179.37 175.856 179.851C175.212 180.323 174.454 180.691 173.582 180.954C172.711 181.218 171.772 181.349 170.764 181.349Z"
        fill="white"
      />
      <path
        d="M189.09 181.322C187.493 181.322 186.29 180.972 185.482 180.274C184.683 179.566 184.284 178.485 184.284 177.033V168.496H181.057V166.876H184.284V163.078H186.313V166.876H191.241V168.496H186.313V176.856C186.313 177.864 186.567 178.59 187.075 179.035C187.592 179.479 188.391 179.702 189.471 179.702C189.771 179.702 190.079 179.688 190.397 179.661C190.724 179.625 191.005 179.579 191.241 179.525V181.118C190.942 181.181 190.606 181.231 190.234 181.267C189.862 181.304 189.48 181.322 189.09 181.322Z"
        fill="white"
      />
      <path
        d="M199.084 181.349C197.804 181.349 196.687 181.181 195.734 180.845C194.79 180.5 194.055 180.006 193.529 179.361C193.011 178.708 192.753 177.914 192.753 176.979V176.965C192.753 176.157 192.961 175.458 193.379 174.868C193.796 174.278 194.436 173.82 195.299 173.493C196.17 173.157 197.282 172.971 198.634 172.935L206.681 172.731V174.201L198.852 174.419C197.463 174.455 196.438 174.687 195.775 175.113C195.113 175.54 194.781 176.139 194.781 176.911V176.938C194.781 177.836 195.203 178.522 196.047 178.994C196.901 179.466 198.022 179.702 199.41 179.702C200.654 179.702 201.743 179.516 202.678 179.143C203.622 178.771 204.357 178.249 204.884 177.578C205.41 176.906 205.673 176.125 205.673 175.236V172.118C205.673 170.884 205.229 169.935 204.339 169.272C203.45 168.61 202.192 168.279 200.568 168.279C199.134 168.279 197.967 168.533 197.069 169.041C196.17 169.54 195.598 170.235 195.353 171.124L195.312 171.26H193.365L193.379 171.111C193.542 170.221 193.933 169.436 194.55 168.755C195.176 168.074 195.998 167.543 197.014 167.162C198.04 166.781 199.229 166.59 200.581 166.59C202.07 166.59 203.345 166.804 204.407 167.23C205.469 167.657 206.282 168.274 206.844 169.082C207.407 169.881 207.688 170.843 207.688 171.968V181.077H205.673V178.177H205.633C205.306 178.812 204.82 179.37 204.176 179.851C203.531 180.323 202.773 180.691 201.902 180.954C201.031 181.218 200.091 181.349 199.084 181.349Z"
        fill="white"
      />
    </g>
  </svg>
)
export const NoResultsSvg = () => (
  <svg width="324" height="356" viewBox="0 0 324 356" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7" clipPath="url(#clip0_5715_79718)">
      <rect width="324" height="356" fill="white" />
      <circle cx="161" cy="174" r="123" fill="#F0F0F0" />
      <rect x="96" y="93" width="128" height="161" rx="14" fill="#FEFEFE" />
      <rect x="108" y="111" width="52" height="10" rx="5" fill="#070707" />
      <rect x="108" y="140" width="103" height="10" rx="5" fill="#D7D7D7" />
      <rect x="108" y="169" width="103" height="10" rx="5" fill="#D7D7D7" />
      <rect x="108" y="198" width="103" height="10" rx="5" fill="#D7D7D7" />
      <rect x="108" y="227" width="103" height="10" rx="5" fill="#D7D7D7" />
      <rect x="236" y="70" width="86" height="36" rx="6" fill="#FEFEFE" />
      <circle cx="252" cy="88" r="6" fill="#456184" />
      <rect x="267" y="82" width="33" height="12" rx="6" fill="#D5D5D5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M287.564 274.806C288.83 277.63 289.261 280.982 287.962 283.784C286.774 286.384 284.36 288.165 281.978 289.723C280.965 290.376 279.918 291.049 278.702 291.272C277.425 291.508 276.09 291.208 274.803 290.834C270.927 289.767 267.421 288.51 264.571 285.665C263.826 284.888 262.99 284.23 262.204 283.499C259.411 280.979 256.622 278.449 253.834 275.911C247.738 270.405 241.089 265.561 235.176 259.699C232.798 257.357 230.6 254.774 228.147 252.499C226.687 251.154 223.509 247.268 221.675 246.777C221.177 246.335 220.741 245.843 220.366 245.299C216.926 247.787 213.199 249.938 209.324 251.674C196.983 257.181 182.959 258.509 169.814 255.478C163.016 253.888 157.399 250.476 151.016 247.985C144.296 245.346 139.587 239.086 135.237 233.643C127.207 223.602 122.487 211.126 121.762 198.303C121.106 186.193 123.787 171.335 131.377 161.666C132.023 160.837 132.669 160.008 133.314 159.18C140.296 150.272 147.417 143.106 157.898 138.286C169.43 132.995 182.496 131.468 194.957 133.709C207.945 136.033 218.139 142.637 228.407 150.474C231.436 152.76 232.748 156.059 234.728 159.132C236.542 161.959 238.454 164.638 239.939 167.724C242.916 173.868 244.863 180.515 245.676 187.293C246.499 193.929 246.223 200.675 244.901 207.227C244.575 208.873 238.824 226.78 237.69 226.158C238.258 226.484 238.8 226.87 239.316 227.316C246.778 234.084 254.536 241.046 262.333 247.644C265.751 250.524 268.52 253.78 271.808 256.713C275.557 260.087 279.402 263.314 282.668 267.194C284.593 269.502 286.291 272.008 287.564 274.806ZM217.303 156.11C196.376 137.152 164.045 138.748 145.087 159.676C126.129 180.603 127.725 212.934 148.653 231.892C169.58 250.85 201.911 249.254 220.869 228.326C239.827 207.399 238.231 175.068 217.303 156.11Z"
        fill="#456184"
      />
      <path
        d="M254.849 240.524C254.706 240.485 254.568 240.458 254.438 240.442C251.211 240.3 248.407 246.773 246.572 248.767C243.364 252.328 240.116 255.821 236.841 259.307C235.263 260.994 236.312 261.391 237.647 262.557C238.661 263.446 239.684 264.336 240.716 265.229C243.684 267.816 246.651 270.403 249.618 272.989C256.416 278.895 263.214 284.801 270.011 290.706C273.435 293.675 276.825 297.416 280.609 299.903C283.428 301.786 287.525 301.699 290.826 300.705C297.155 298.76 302.31 290.2 299.588 283.749C298.631 281.499 296.887 279.702 295.19 277.947C286.319 268.779 277.116 259.985 267.563 251.53C265.241 249.482 262.905 247.45 260.556 245.434C259.308 244.377 256.641 241.061 254.849 240.524Z"
        fill="#2F4156"
      />
      <path
        d="M184.5 188.492L165.754 169.746C165.086 169.101 164.191 168.744 163.263 168.752C162.334 168.76 161.446 169.133 160.789 169.789C160.133 170.446 159.76 171.334 159.752 172.263C159.744 173.191 160.101 174.086 160.746 174.754L179.492 193.5L160.746 212.246C160.101 212.914 159.744 213.809 159.752 214.737C159.76 215.666 160.133 216.554 160.789 217.211C161.446 217.867 162.334 218.24 163.263 218.248C164.191 218.256 165.086 217.899 165.754 217.254L184.5 198.508L203.246 217.254C203.914 217.899 204.809 218.256 205.737 218.248C206.666 218.24 207.554 217.867 208.211 217.211C208.868 216.554 209.24 215.666 209.248 214.737C209.256 213.809 208.899 212.914 208.254 212.246L189.508 193.5L208.254 174.754C208.592 174.427 208.862 174.036 209.048 173.604C209.233 173.172 209.331 172.707 209.335 172.237C209.339 171.767 209.25 171.301 209.072 170.865C208.893 170.43 208.63 170.035 208.298 169.702C207.965 169.37 207.57 169.107 207.135 168.929C206.699 168.75 206.233 168.661 205.763 168.665C205.293 168.669 204.828 168.767 204.396 168.952C203.964 169.138 203.573 169.408 203.246 169.746L184.5 188.492Z"
        fill="#44546F"
      />
    </g>
    <defs>
      <clipPath id="clip0_5715_79718">
        <rect width="324" height="356" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
