// TODO: fix eslint errors
/* eslint-disable */
import MultiSelectCustom from './MultiSelectCustom'

export default function PolicySelect({
  placeholder,
  labelledBy,
  selected,
  setSelected,
  policiesOptions,
}: {
  placeholder: string
  labelledBy: string
  selected: { value: any; label: string }[]
  setSelected: (arg: any) => void
  policiesOptions: { value: any; label: string }[]
}) {
  return (
    <MultiSelectCustom
      options={policiesOptions}
      value={selected}
      onChange={(arg) => {
        setSelected(arg)
      }}
      placeholder={placeholder}
      labelledBy={labelledBy}
    />
  )
}
