import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const DateRangeStyles = styled.div`
  display: flex;
  flex-direction: column;

  .date-range-label {
    font-size: 12px;
    color: ${token("color.text.subtle")};
    margin-bottom: ${token("space.050")};
    font-weight: 600;

    .is-required {
      color: ${token("color.text.danger")};
    }
  }

  .input-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${token("space.200")};
  }
`
