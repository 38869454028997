import { useMemo } from 'react'
import { Table as CodeHRTable } from 'components'
import { TablePaginationData } from 'types'
import { BonusesPenaltiesSortBy } from 'services/api'
import { BonusPenalty } from 'models'
import { setBonusPenaltyTableState, defaultBonusPenaltyTableState } from 'store'
import { useAppDispatch, useAppSelector } from 'hooks'
import { getTableColumns } from './table-columns'
import { BonusPenaltyTableConfig, BonusPenaltyTableProps } from './types'
import { BonusesPenaltiesTableStyles } from './styles'

export const BonusesPenaltiesTable = (props: BonusPenaltyTableProps) => {
  const { isLoading, sourceData } = props
  const {
    tableState: { page, limit },
  } = useAppSelector((state) => state.bonusPenaltyTable)

  const dispatch = useAppDispatch()

  const tableColumns: BonusPenaltyTableConfig = useMemo(() => getTableColumns(), [])

  const onTableChange = (tableState: TablePaginationData<BonusesPenaltiesSortBy>) => {
    dispatch(setBonusPenaltyTableState(tableState))
  }

  return (
    <BonusesPenaltiesTableStyles>
      <CodeHRTable<BonusPenalty, BonusesPenaltiesSortBy>
        loading={isLoading}
        columns={tableColumns}
        recordIdKey="id"
        defaultTableState={defaultBonusPenaltyTableState}
        dataSource={sourceData.items}
        onChange={onTableChange}
        pagination={{
          pageSize: limit,
          total: sourceData.total,
          current: page,
        }}
      />
    </BonusesPenaltiesTableStyles>
  )
}
