import { createSlice } from '@reduxjs/toolkit'

export const aiButtonSlice = createSlice({
  name: 'root',
  initialState: {
    aiButtonCount: 0,
  },
  reducers: {
    increment: (state) => {
      state.aiButtonCount += 1
    },
    decrement: (state) => {
      state.aiButtonCount -= 1
    },
    incrementByAmount: (state, action) => {
      state.aiButtonCount += action.payload
    },
  },
})

export const aiButtonReducer = aiButtonSlice.reducer
export const { increment, decrement, incrementByAmount } = aiButtonSlice.actions
