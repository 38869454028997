import { FC, useEffect, useState, useCallback } from 'react'
import { useAppDispatch } from 'hooks'
import cn from 'classnames'
import style from '../../../Profile.module.scss'
import { Toggle } from 'components/UI/Toggle'
import { BlockInfoItem } from '../../../BlockInfo'
import { useProfile } from '../../../context'
import { useSetField } from 'API/profile/setField/useSetField'
import { triggerNotification } from 'store'

export const TimeTracking: FC = () => {
  const dispatch = useAppDispatch()
  const { jobInfo, idOpenProfile, isAccessEditRole } = useProfile()
  const [checked, setChecked] = useState(jobInfo?.currentJobInfoValue.timeTracking || false)

  const { setField, isSuccess } = useSetField(idOpenProfile)

  const openSuccessNotification = useCallback(() => {
    dispatch(
      triggerNotification({
        type: 'success',
        message: `You’ve ${checked ? 'enabled' : 'disabled'} time tracking`,
        description: `Time tracking will be ${checked ? 'enabled' : 'disabled'} starting from tomorrow.`,
      }),
    )
  }, [checked])

  useEffect(() => {
    if (isSuccess) {
      openSuccessNotification()
    }
  }, [isSuccess, openSuccessNotification])

  return (
    <BlockInfoItem title="Time tracking">
      <div className={cn(style.inputWrapper, style.toggle)}>
        <Toggle
          isDisabled={!isAccessEditRole}
          isChecked={checked}
          onChange={(event) => {
            setChecked(event.target.checked)
            setField(['current-job-info', 'time_tracking', event.target.checked.toString()].join('/'))
          }}
        />
      </div>
    </BlockInfoItem>
  )
}
