import styled from 'styled-components'

export const UserStyles = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .avatar {
    img {
      width: 32px;
      height: 32px;
    }
  }
`
