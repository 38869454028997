// TODO: fix eslint errors
/* eslint-disable */
import { type Dispatch, type FC, forwardRef, type SetStateAction, useEffect } from 'react'
import cn from 'classnames'
import style from './Subordinates.module.scss'
import styleParent from '../ShortInformationCard.module.scss'
import AvatarGroup, { type AvatarProps } from '@atlaskit/avatar-group'
import { Skeleton } from '@atlaskit/avatar'
import { ProfileAvatar } from '../../ProfileAvatar'
import { Link } from 'react-router-dom'
import { useSubordinates } from '../../../../API/profile/subordinates'
import { useProfile } from '../../context'

const Avatar = forwardRef<HTMLAnchorElement, AvatarProps>(({ src, name, href, stackIndex, ...rest }, ref) => (
  <Link
    to={href!}
    ref={ref}
    className={style.avatarLink}
    style={{
      zIndex: stackIndex,
      borderRadius: '50%',
      boxShadow: `0px 0px 0px 2px rgba(255,255,255,1)`,
      display: 'block',
    }}
  >
    <ProfileAvatar
      size={32}
      imgAvatar={src}
      //@ts-ignore
      firstName={rest.firstNameEn}
      //@ts-ignore
      lastName={rest.lastNameEn}
    />
  </Link>
))

const Overrides = forwardRef<HTMLAnchorElement, AvatarProps>(({ href, name, src, ...rest }, ref) => (
  <Link to={href!} ref={ref} className={style.linkOverrides}>
    <div className={style.avatarOverrides}>
      <ProfileAvatar
        size={24}
        imgAvatar={src}
        //@ts-ignore
        firstName={rest.firstNameEn}
        //@ts-ignore
        lastName={rest.lastNameEn}
      />
    </div>
    <div>{name}</div>
  </Link>
))

export interface SubordinatesProps {
  setIsNotEmptyS: Dispatch<SetStateAction<boolean>>
}

export const Subordinates: FC<SubordinatesProps> = ({ setIsNotEmptyS }) => {
  const { idOpenProfile } = useProfile()
  const { subordinates, fetchSubordinates, isLoading } = useSubordinates(idOpenProfile)

  useEffect(() => {
    fetchSubordinates()
  }, [])

  useEffect(() => {
    setIsNotEmptyS(Boolean(subordinates?.length))
  }, [subordinates])

  if (subordinates?.length === 0) return null

  return (
    <>
      <div className={cn(styleParent.otherInfoBlockTitle, styleParent.otherInfoBlockTitleM)}>Subordinates:</div>
      <div>
        {isLoading || !subordinates ? (
          <ul className={style.listSkeleton}>
            <li>
              <Skeleton size="medium" />
            </li>
            <li>
              <Skeleton size="medium" />
            </li>
            <li>
              <Skeleton size="medium" />
            </li>
            <li>
              <Skeleton size="medium" />
            </li>
            <li>
              <Skeleton size="medium" />
            </li>
          </ul>
        ) : subordinates.length === 1 ? (
          <Link to={`${subordinates[0].href}`} className={style.directManagerLink}>
            <div className={style.directManager}>
              <div className={style.avatar}>
                <ProfileAvatar
                  imgAvatar={subordinates[0].src}
                  size={32}
                  firstName={subordinates[0].firstNameEn}
                  lastName={subordinates[0].lastNameEn}
                />
              </div>
              <div>
                <div className={style.name}>
                  {subordinates[0].firstNameEn} {subordinates[0].lastNameEn}
                </div>
                {subordinates[0].nickname && <div className={style.nickname}>{subordinates[0].nickname}</div>}
              </div>
            </div>
          </Link>
        ) : (
          <AvatarGroup
            avatar={Avatar}
            overrides={{
              // @ts-ignore
              AvatarGroupItem: {
                render: (Ava, props) => <Overrides {...props.avatar} />,
              } /*,
                            Avatar: {
                                render: (Ava, props) => {
                                    return <Overrides {...props} />
                                }
                            }*/,
            }}
            size="medium"
            maxCount={5}
            tooltipPosition="bottom"
            data={subordinates}
          />
        )}
      </div>
    </>
  )
}
