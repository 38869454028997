import { AnnouncementsQueryType } from 'services/api'
import { AnnouncementGetListOrderByEnum, OrderEnum } from 'shared'
import { IAnnouncementsModalProps } from './types'

export const defaultAnnouncementsDate = { items: [], total: 0 }

export enum AnnouncementModalTypeEnum {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}
export const defaultAnnouncementQuery: AnnouncementsQueryType = {
  size: 5,
  page: 1,
  sort: AnnouncementGetListOrderByEnum.StartDate,
  order: OrderEnum.DESC,
  priority: [],
  search: '',
  locations: [],
}
export const announcementModalDefaultState: IAnnouncementsModalProps = {
  isOpen: false,
  type: AnnouncementModalTypeEnum.CREATE,
  payload: null,
}
