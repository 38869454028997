// TODO: fix eslint errors
/* eslint-disable */
import BaseModal from './BaseModal'
import { ORG_CHART } from '../../../../../utils/constants/api'
import { OrgUnitFromApi } from '../../../../../shared/OrgChart/data/OrgUnitFromApi'
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors'
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError'

type CreateOrgUnit = {
  opened: boolean
  orgUnits: OrgUnitFromApi[]
  onOk: () => void
  onClose: () => any
}

function CreateOrgUnit(props: CreateOrgUnit) {
  function create(
    name: string,
    parentId: string | null,
    leaderId: string | null,
    isCompany: boolean,
    validate: (errors: ValidationError[]) => void,
  ): void {
    fetch(`${ORG_CHART}?without-hierarchy=true`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        parentId,
        leaderId,
        isCompany,
      }),
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors))
      }
    })
  }

  return (
    <BaseModal
      opened={props.opened}
      modalTitle="Create Org Unit"
      orgUnits={props.orgUnits}
      orgUnitValue={null}
      parentOrgUnit={null}
      leaderOrgUnit={null}
      okText="Create"
      onCancel={props.onClose}
      onOk={create}
    />
  )
}

export default CreateOrgUnit
