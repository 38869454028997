// TODO: fix eslint errors
/* eslint-disable */
import { useState, useEffect, useRef, FC } from 'react'
import MultiSelectCustom from 'components/UI/MultiSelectCustom/MultiSelectCustom'
import SearchField from '../SearchField/SearchField'
import { Box, Inline, xcss } from '@atlaskit/primitives'
import SearchResults from './SearchResults'
import { createPortal } from 'react-dom'
import { OrgUnitFromApi, SelectValue, JobTitleFromApi, EmployeeDataFromApi, Option } from './types'
import { ORG_CHART, JOB_TITLES, SEARCH } from 'utils/constants/api'
import { StyledSearchResults, StyledSearchResultsSelect, StyledSearchWrap } from './SearchBarStyles'
import PreferencesIcon from '@atlaskit/icon/glyph/preferences'
import { token } from '@atlaskit/tokens'
import styled from 'styled-components'
import { Checkbox } from '@atlaskit/checkbox'
import Button from '@atlaskit/button/new'

const FilterButton = styled.button<{ $openResults: boolean }>`
  border: none;
  background-color: ${(props) => (props.$openResults ? token('color.background.selected') : 'transparent')};
  width: 30px;
  height: 24px;
  padding: 0;
  margin-right: ${token('space.100')};
  border-radius: 3px;
  cursor: pointer;

  span {
    margin: auto !important;
  }
`

const fetchOrgUnitOptions = async (): Promise<SelectValue[]> => {
  const response = await fetch(`${ORG_CHART}?without-hierarchy=true`, {
    method: 'GET',
    headers: { Accept: 'application/json', 'Content-type': 'application/json' },
  })

  const orgUnits: OrgUnitFromApi[] = response.ok ? await response.json() : []

  return orgUnits.map((orgUnit) => ({
    value: orgUnit.id,
    label: orgUnit.name,
    company: orgUnit.companyName,
  }))
}

const fetchJobTitleOptions = async (): Promise<SelectValue[]> => {
  const response = await fetch(JOB_TITLES, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  })
  const jobTitles: JobTitleFromApi[] = response.ok ? await response.json() : []

  return jobTitles.map((jobTitle) => ({
    value: jobTitle.id,
    label: jobTitle.name,
  }))
}

async function fetchSearchResults(
  searchWord: string,
  selectedOrgUnitOptions: SelectValue[],
  selectedJobTitleOptions: SelectValue[],
) {
  const url = `${SEARCH}/`
  let data: EmployeeDataFromApi[] = []
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        orgUnits: selectedOrgUnitOptions.map((c) => c.value).join(','),
        jobTitles: selectedJobTitleOptions.map((j) => j.value).join(','),
        searchWord,
      }),
    })
    if (!response.ok) {
      console.log(`Network response was not ok${response.statusText}`)
    }
    data = await response.json()
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error)
  }
  return data
}

interface SearchBarProps {
  $searchIsOpen: boolean
}

const SearchBar: FC<SearchBarProps> = ({ $searchIsOpen }) => {
  const [selectedEmployees, setSelectedEmployees] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])

  const [selectedEmployeesAll, setSelectedEmployeesAll] = useState<Option[]>([])
  const [selectedCompaniesAll, setSelectedCompaniesAll] = useState<Option[]>([])

  const [searchResults, setSearchResults] = useState<EmployeeDataFromApi[]>([])

  const [openResults, setOpenResults] = useState(false)

  const [openFilters, setOpenFilters] = useState(false)

  const [search, setSearch] = useState('')
  const [searchReset, setSearchReset] = useState(0)

  const [showInactive, setShowInactive] = useState(false)

  const seachInputRef = useRef<HTMLDivElement>(null)

  const [searchButtonLoading, setSearchButtonLoading] = useState(false)

  const onClose = useRef(() => {
    setOpenResults(false)
    setOpenFilters(false)
    setShowInactive(false)
    setSearch('')
    setSearchReset((val) => val + 1)
    setSelectedEmployees([])
    setSelectedCompanies([])
    setSearchResults([])
  }).current

  const handleGlobalKeypress = useRef((e: KeyboardEvent) => {
    if (
      document.activeElement &&
      (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA')
    ) {
      return
    }
    seachInputRef.current?.querySelector('input')?.focus()
  }).current

  function focusItem(index: number) {
    const items = document.querySelectorAll<HTMLDivElement>('.searchResultItem')
    if (items.length > 0) {
      items.forEach((item, i: number) => {
        if (i === index) {
          item.classList.add('focused')
          item.focus()
        } else {
          item.classList.remove('focused')
          item.blur()
        }
      })
    }
  }

  function handleFocusItem(event: { key: any }) {
    const items = document.querySelectorAll('.searchResultItem')
    const focusedItemIndex = [...Array.from(items)].findIndex((item) => item === document.activeElement)
    let nextIndex

    // Handle arrow keys
    switch (event.key) {
      case 'ArrowUp':
        nextIndex = focusedItemIndex > 0 ? focusedItemIndex - 1 : items.length - 1
        focusItem(nextIndex)
        break
      case 'ArrowDown':
        nextIndex = focusedItemIndex < items.length - 1 ? focusedItemIndex + 1 : 0
        focusItem(nextIndex)
        break
    }
  }

  useEffect(() => {
    if (search.length > 0) {
      setSearchResults([])
      fetchSearchResults(search, selectedCompanies, selectedEmployees).then((data) => {
        setSearchResults(data)
      })
    }
  }, [search])

  useEffect(() => {
    window.addEventListener('keydown', handleFocusItem)
    window.addEventListener('keypress', handleGlobalKeypress)
    window.addEventListener('click', onClose)

    return () => {
      window.removeEventListener('keydown', handleFocusItem)
      window.removeEventListener('keypress', handleGlobalKeypress)
      window.addEventListener('click', onClose)
    }
  }, [])

  useEffect(() => {
    if (openResults === true && selectedEmployeesAll.length === 0 && selectedCompaniesAll.length === 0) {
      fetchOrgUnitOptions().then((options) => {
        const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label))
        setSelectedCompaniesAll(sortedOptions)
      })
      fetchJobTitleOptions().then((options) => {
        const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label))
        setSelectedEmployeesAll(sortedOptions)
      })
    }
  }, [openResults])

  function getBlockHeight() {
    const height = 68 + searchResults.length * 42
    return height < 456 ? height : 456
  }

  return (
    <>
      <StyledSearchWrap $searchIsOpen={$searchIsOpen} $openResults={openResults} ref={seachInputRef}>
        <SearchField
          elemAfterInput={
            <FilterButton
              $openResults={openFilters}
              onClick={(e) => {
                e.stopPropagation()

                if (openResults === true && openFilters === false) {
                  setOpenFilters(true)
                }

                if (openResults === true && openFilters === true) {
                  setOpenResults(false)
                  setOpenFilters(false)
                }

                if (openResults === false) {
                  setOpenFilters(true)
                  setOpenResults(true)
                }
              }}
            >
              <PreferencesIcon label="preferences" />
            </FilterButton>
          }
          onreset={searchReset}
          placeholder="Search"
          value={search}
          onChange={(value) => {
            setSearch(value)
          }}
          onKeyDown={() => {
            setOpenResults(true)
          }}
          onClick={(e) => {
            e.stopPropagation()
            setOpenResults(true)
          }}
          onFocus={(e) => {
            e.stopPropagation()
          }}
          onPressEnter={() => {
            fetchSearchResults(search, selectedCompanies, selectedEmployees).then((data) => {
              setSearchResults(data)
            })
          }}
        />
      </StyledSearchWrap>

      {openResults
        ? createPortal(
            <StyledSearchResults
              style={{
                height: `${getBlockHeight()}px`,
                inset: '0',

                left: seachInputRef.current ? seachInputRef.current.getBoundingClientRect().x : 0,
                top: seachInputRef.current
                  ? seachInputRef.current.getBoundingClientRect().y + (window.innerWidth < 1024 ? 56 : 34)
                  : 0,
              }}
            >
              <Box
                xcss={xcss({
                  marginTop: 'space.050',
                  backgroundColor: 'elevation.surface.overlay',
                  boxShadow: 'elevation.shadow.overlay',
                  borderRadius: 'border.radius',
                  borderColor: 'color.border',
                  paddingBottom: searchResults.length > 0 ? 'space.200' : '',
                })}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                }}
              >
                {openFilters && (
                  <>
                    <StyledSearchResultsSelect>
                      <MultiSelectCustom
                        options={selectedCompaniesAll}
                        value={selectedCompanies}
                        onChange={setSelectedCompanies}
                        labelledBy="Select Org Unit"
                        placeholder="Org Unit"
                      />
                      <MultiSelectCustom
                        options={selectedEmployeesAll}
                        value={selectedEmployees}
                        onChange={setSelectedEmployees}
                        labelledBy="Select Job Title"
                        placeholder="Job Title"
                      />
                      <Button
                        isLoading={searchButtonLoading}
                        appearance="primary"
                        onClick={() => {
                          setSearchResults([])
                          setSearchButtonLoading(true)
                          fetchSearchResults(search, selectedCompanies, selectedEmployees).then((data) => {
                            setSearchButtonLoading(false)
                            setSearchResults(data)
                          })
                        }}
                      >
                        Search
                      </Button>
                    </StyledSearchResultsSelect>
                    <Box
                      xcss={xcss({
                        marginLeft: 'space.200',
                        marginBottom: 'space.200',
                      })}
                    >
                      <Checkbox
                        value="default checkbox"
                        label="Show inactive employees"
                        onChange={(event) => {
                          setShowInactive(event.target.checked)
                        }}
                        isChecked={showInactive}
                        name="checkbox-default"
                        testId="cb-default"
                      />
                    </Box>
                  </>
                )}
                <Box
                  xcss={xcss({
                    maxHeight: '400px',
                    overflow: 'auto',
                  })}
                >
                  {searchResults.length > 0 ? (
                    <SearchResults
                      showInactive={showInactive}
                      results={searchResults}
                      onSelect={(val) => {
                        onClose()
                      }}
                    />
                  ) : search.length > 0 ? (
                    <Box
                      xcss={xcss({
                        color: 'color.text.subtlest',
                        fontFamily: 'inherit',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        padding: 'space.200',
                        textAlign: 'center',
                      })}
                    >
                      No results found
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </StyledSearchResults>,
            document.body,
          )
        : null}
    </>
  )
}

export default SearchBar
