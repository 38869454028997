import { IAnnouncement } from 'models'

export const tableColumnsMap: {
  [key in keyof IAnnouncement]: keyof IAnnouncement
} = {
  id: 'id',
  createdAt: 'createdAt',
  startDate: 'startDate',
  endDate: 'endDate',
  priority: 'priority',
  text: 'text',
  locations: 'locations',
}
