import { Tag } from 'antd'
import styled from 'styled-components'

export const TagStyles = styled(Tag)`
  font-weight: 600;

  &.ant-tag-volcano {
    color: #cf1322;
  }

  &.ant-tag-blue {
    color: #1677ff;
  }

  &.ant-tag-red {
    color: #d4380d;
  }
`
