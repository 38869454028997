import { HeadType } from '@atlaskit/dynamic-table/dist/types/types';
import { tableColumnsMap } from './constants';

export const tableHead: HeadType = {
  cells: [
    {
      key: tableColumnsMap.startDate,
      content: 'Display dates',
      isSortable: true,
      width: 16,
    },
    {
      key: tableColumnsMap.priority,
      content: 'Priority',
      isSortable: true,
      width: 6,
    },
    {
      key: tableColumnsMap.text,
      content: 'Announcement ',
      width: 48,
    },
    {
      key: tableColumnsMap.locations,
      content: 'Locations',
      width: 20,
    },
    {
      key: 'actions',
      content: 'Actions',
      width: 6,
    },
  ],
};
