import {type FC, type ReactNode} from "react";
import style from './BlockInfo.module.scss';

interface BlockInfoItemProps {
    title: string;
    required?: boolean;
    children: ReactNode;
}

export const BlockInfoItem: FC<BlockInfoItemProps> = ({title, required = false, children}) => (
        <tr className={style.rowContent}>
            <td className={style.titleItem}>{title}{required ? (<span className={style.required}>*</span>) : ''}</td>
            <td className={style.contentItem}>{children}</td>
        </tr>
    );
