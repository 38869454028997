// TODO: fix eslint errors
/* eslint-disable */
import { GENDERS } from '../../../../../../utils/constants/api'
import { GenderFromApi } from '../../../../../../shared/Employee/Gender/data/GenderFromApi'
import ConfirmDeleteModal from '../../../../Layer/Modal/ConfirmDeleteModal'
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError'
import { VALIDATION_ERROR } from '../../../../../../utils/constants/errors'

type DeleteGender = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  gender: GenderFromApi
}

function DeleteGender(props: DeleteGender) {
  function deleteGender(): void {
    fetch(`${GENDERS}/${props.gender.id}`, {
      method: 'DELETE',
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors))
      }
    })
  }

  function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
      if (error.property === 'gender') {
        alert(error.message)
      }
    })
  }

  return (
    <ConfirmDeleteModal
      opened={props.opened}
      modalTitle="Delete Gender Pronouns"
      cancelText="Cancel"
      deleteText={`<span>&laquo;${props.gender.name}&raquo;</span>`}
      okText="Delete"
      onCancel={props.onClose}
      onOk={deleteGender}
    />
  )
}

export default DeleteGender
