import { Space } from 'antd'
import { useMemo } from 'react'
import { useAppDispatch } from 'hooks'
import type { CheckboxOptionType } from 'antd'
import { FilterCheckboxGroup, FilterRangeDates } from 'components'
import { setEmployeeMgpHistoryFilters } from 'store'
import { useGetSystemCurrencyQuery } from 'services/api'

export const Filters = () => {
  const dispatch = useAppDispatch()

  const { data: systemCurrencyList } = useGetSystemCurrencyQuery()

  const systemCurrencySelectOptions: CheckboxOptionType[] = useMemo(() => {
    if (!systemCurrencyList) return []

    return systemCurrencyList.map(({ currencyId, code, name }) => ({
      label: `${code} - ${name}`,
      value: currencyId,
      title: code,
    }))
  }, [systemCurrencyList])

  const handleChangeDates = (dates: string[]) => {
    dispatch(setEmployeeMgpHistoryFilters({ dates }))
  }

  const handleChangeCurrency = (currencies: string[]) => {
    dispatch(setEmployeeMgpHistoryFilters({ currencies }))
  }

  return (
    <Space size={16}>
      <FilterRangeDates placeholder="Date" onChange={handleChangeDates} />
      <FilterCheckboxGroup label="Currency" options={systemCurrencySelectOptions} onChange={handleChangeCurrency} />
    </Space>
  )
}
