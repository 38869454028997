export const MAIN = '/'

export const AUTH = '/auth'
export const SWAGGER = '/swagger'
export const SIGN_IN = 'sign-in'
export const SET_PASSWORD = 'set-password'
export const FORGOT_PASSWORD = 'forgot-password'
export const RESET_PASSWORD = 'reset-password'

export const AUTH_SIGN_IN = `${AUTH}/${SIGN_IN}`
export const AUTH_SET_PASSWORD = `${AUTH}/${SET_PASSWORD}`
export const AUTH_FORGOT_PASSWORD = `${AUTH}/${FORGOT_PASSWORD}`
export const AUTH_RESET_PASSWORD = `${AUTH}/${RESET_PASSWORD}`

export const EMPLOYEE = '/employee/:id'

export const CALENDAR = '/calendar'
export const REQUESTS = '/requests'
export const STATUSES = '/statuses'
export const ATTENDANCE = 'attendance'
export const ANNOUNCEMENTS = '/announcements'
export const BONUSES_PENALTIES = '/bonuses-penalties'

export const ATTENDANCE_LOG = '/attendance-log'
export const SUBORDINATES_ANALYSIS = '/subordinates-analysis'
export const AI_ANALYSIS = '/ai-analysis'
export const CURRENCIES = '/currencies'

// TODO: fix eslint errors
/* eslint-disable */
export const _ADMIN = 'admin'
export const RANDOM_PHRASES = 'random-phrases'
export const LINKS = 'links'
export const PUSH_NOTIFICATIONS = 'push-notifications'
export const HOLIDAYS = 'holidays'
export const ORGCHART = 'orgchart'
export const LOCATIONS = 'locations'
export const GENDER_PRONOUNS = 'gender-pronouns'
export const LEVELS = 'levels'
export const JOB_TITLES = 'job-titles'
export const EMPLOYEES = 'employees'
export const TIME_OFF_POLICIES = 'time-off-policies'

export const ADMIN = `${MAIN}${_ADMIN}`
export const ADMIN_RANDOM_PHRASES = `${ADMIN}/${RANDOM_PHRASES}`
export const ADMIN_LINKS = `${ADMIN}/${LINKS}`
export const ADMIN_HOLIDAYS = `${ADMIN}/${HOLIDAYS}`
export const ADMIN_ORGCHART = `${ADMIN}/${ORGCHART}`
export const ADMIN_LOCATIONS = `${ADMIN}/${LOCATIONS}`
export const ADMIN_STATUSES = `${ADMIN}/${STATUSES}`
export const ADMIN_GENDER_PRONOUNS = `${ADMIN}/${GENDER_PRONOUNS}`
export const ADMIN_LEVELS = `${ADMIN}/${LEVELS}`
export const ADMIN_JOB_TITLES = `${ADMIN}/${JOB_TITLES}`
export const ADMIN_TIME_OFF_POLICIES = `${ADMIN}/${TIME_OFF_POLICIES}`
export const ADMIN_EMPLOYEES = `${ADMIN}/${EMPLOYEES}`

export const FORBIDDEN = '/forbidden'
