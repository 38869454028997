import { ITimeOffLogProps } from 'models'

export const tableColumnsMap: {
  [key in keyof ITimeOffLogProps]: keyof ITimeOffLogProps
} = {
  employeeId: 'employeeId',
  changedByEmployeeId: 'changedByEmployeeId',
  changedByName: 'changedByName',
  changedByNickname: 'changedByNickname',
  changedByAvatar: 'changedByAvatar',
  timeOffPolicyId: 'timeOffPolicyId',
  date: 'date',
  quantity: 'quantity',
  balance: 'balance',
  comment: 'comment',
}
