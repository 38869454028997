import styled from 'styled-components'

export const BonusesPenaltiesFiltersStyles = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  .filter-body {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
`
