import { token } from '@atlaskit/tokens'
import styled from 'styled-components'

// TODO: Separate this layout styles to component
// prettier-ignore
export const StyledRequestPage = styled.main`
  position: relative;
  height: 100%;
  margin: ${token("space.300")} ${token("space.400")} ${token("space.600")} ${token("space.300")};

  @media (min-width: 1920px) {
    margin: ${token("space.300")} ${token("space.800")} ${token("space.600")};
  }

  @media (max-width: 1280px) {
    margin: ${token("space.300")} ${token("space.400")} ${token("space.600")} ${token("space.200")};
  }

  @media (max-width: 1024px) {
    width: 100vw;
    padding: ${token("space.300")} ${token("space.400")} ${token("space.600")};
  }

  @media (max-width: 768px) {
    padding: ${token("space.300")} ${token("space.200")} ${token("space.600")};
  }

  @media (max-width: 480px) {
    width: 100vw;
  }
`;

export const StyledMainTitle = styled.h1`
  color: ${token('color.text')};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`

export const StyledRequestButton = styled.div<{ selected: boolean }>`
  margin: 0 0 0 auto;

  // TODO: remove after create component button
  & > button {
    display: flex;
    width: 224px;
    min-height: 32px;
    padding: 0px ${token('space.150')};
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid ${token('color.border')};
    background: ${token('color.background.accent.blue.subtlest')};

    &:hover {
      background: ${token('color.background.accent.blue.subtlest.hovered')};
    }

    &:active {
      background: ${token('color.background.accent.blue.subtlest.pressed')};
    }

    ${(props) => (props.selected ? `background:${token('color.background.accent.blue.subtlest.pressed')};` : '')};

    & > span {
      color: ${token('color.text')};
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      flex-grow: 0;
    }
  }
`

export const StyledTableWrap = styled.div`
  table {
    border-spacing: 0 ${token('space.100')};

    thead {
      th {
        height: 30px;
        padding: ${token('space.050')} ${token('space.100')};
        vertical-align: middle;

        & > button {
          color: ${token('color.text.subtle')};
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
        }

        &:hover {
          background-color: transparent;
        }

        &:first-of-type {
          padding-left: ${token('space.100')};
        }

        &:last-of-type {
          padding-right: ${token('space.100')};
        }
      }
    }

    td[class*='TableBodyCell'] {
      padding: 0 ${token('space.050')};
    }
  }
`
