import { useMemo } from 'react'
import { Spin, Switch } from 'antd'
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons'
import { useGetEmployeeCompensationDataQuery } from 'services/api'
import { triggerEmployeeCompensationView } from 'store'
import { useAppSelector } from 'hooks'
import { useDispatch } from 'react-redux'
import { useProfile } from '../../../../context'
import { compensationListConfig } from './constants'
import { StatisticsPanelStyles } from './styles'
import { CompensationListConfig } from './types'

export const CompensationPanel = () => {
  const dispatch = useDispatch()
  const { idOpenProfile } = useProfile()

  const { isVisible } = useAppSelector((state) => state.showEmployeeCompensation)

  const { data, isLoading } = useGetEmployeeCompensationDataQuery(idOpenProfile)

  const statisticsList: CompensationListConfig[] = useMemo(() => {
    if (data) {
      return compensationListConfig(data)
    }
    return []
  }, [data])

  const onChange = (checked: boolean) => {
    dispatch(triggerEmployeeCompensationView(checked))
  }

  return (
    <StatisticsPanelStyles>
      <div className="switcher">
        Show compensation <Switch defaultChecked onChange={onChange} size="small" value={isVisible} />
      </div>
      <Spin spinning={isLoading} indicator={<LoadingOutlined spin />}>
        <ul className="statistics-list">
          {statisticsList.map((item, index) => (
            <li key={index} className="statistics-list-item">
              <span className="label">
                {item.label}
                {item.action}
              </span>
              <span className="value">{isVisible ? item.value : <SmileOutlined />}</span>
            </li>
          ))}
        </ul>
      </Spin>
    </StatisticsPanelStyles>
  )
}
