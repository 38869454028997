import "./newicons.scss";
import { ReactSVG } from "react-svg";
import React from "react";

export const TypeIconsCollection = {
  vacation: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`vacation icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/vacation.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),

  sickDay: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`sickDay icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/sickDay.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  dayOff: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`dayOff icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/dayOff.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  remoteWork: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`remoteWork icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/remoteWork.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  birthday: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`birthday icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/birthday.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  holiday: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`holiday icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/holiday.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  businessTrip: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`businessTrip icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/businessTrip.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  military: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`military icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/military.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  maternity: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`maternity icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/maternity.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  education: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`education icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/education.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  volunteer: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`volunteer icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/volunteer.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  parental: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`parental icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/parental.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  personal: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`personal icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/personal.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  familyCare: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`familyCare icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/familyCare.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  bereavement: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`bereavement icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/bereavement.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  sabbatical: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`sabbatical icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/sabbatical.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  carryOver: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`carryOver icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/carryOver.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
  anniversary: (className: string, withouthover = "") => (
    <div className={className}>
      <div className={`anniversary icon-block ${  withouthover}`}>
        <ReactSVG
          src="/image/TimeOffTypeIcons/anniversary.svg"
          className={withouthover}
        />
      </div>
    </div>
  ),
} as const;
