// TODO: fix eslint errors
/* eslint-disable */
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { EMPLOYEE_PROFILE } from 'utils/constants/routes'
import { TypeIconsCollection } from 'shared/TimeOffPolicy/constants/NewTypeIconsCollection'
import CalendarIcon from '@atlaskit/icon/glyph/calendar'

export default function CalendarTooltip({
  issetPopup,
  tooltipClass,
  value,
  objKey,
  isDateAWeekend,
}: {
  issetPopup: boolean
  tooltipClass: string
  value: any
  objKey: string
  isDateAWeekend: (dateString: string) => boolean
}) {
  return (
    <div className="tooltip-block">
      {!issetPopup && (
        <ReactTooltip
          delayShow={150}
          openOnClick
          id={tooltipClass}
          className="tooltip-data"
          place="bottom"
          clickable
          noArrow={false}
          closeEvents={{ click: true }}
          globalCloseEvents={{ scroll: true, clickOutsideAnchor: true }}
        >
          <div>
            {value.typeIcon === 'holiday' ? <span className="popup-main-holiday-text">{value.name}</span> : ''}
            {value.holiday === 'stateHoliday' &&
            value.paidTimeOff &&
            !isDateAWeekend(objKey) &&
            !value.additionalPaidTimeOff ? (
              <div className="holiday-text">Paid Time Off</div>
            ) : (
              ''
            )}
            {value.holiday === 'corporateEvent' && value.paidTimeOff && !isDateAWeekend(objKey) ? (
              <div className="holiday-text">Paid Time Off</div>
            ) : (
              ''
            )}
            {value.typeIcon === 'holiday' && value.additionalPaidTimeOff ? (
              <div className="holiday-text">Additional Paid Time Off</div>
            ) : (
              ''
            )}
            {value.typeIcon && value.typeIcon !== 'holiday' ? (
              <div className="type-block">
                {value && Object.keys(value).length > 0 && value.holiday !== 'weekend'
                  ? TypeIconsCollection[value.typeIcon as keyof typeof TypeIconsCollection](
                      'small-icon',
                      'withouthover',
                    )
                  : null}
                <span className="event-name">{value.name}</span>
              </div>
            ) : (
              ''
            )}

            {!value.workingHours ? (
              <div className={`${value.typeIcon === 'holiday' ? 'holiday-day-tool' : ''} tool-date`}>
                <CalendarIcon label="CalendarIcon" />
                <div className="date-inner-container">
                  {value.startDate} {value.endDate ? ` - ${value.endDate}` : ''}
                </div>
              </div>
            ) : (
              ''
            )}
            {(value.status === 'Approved' || value.status === 'New') && value.substitute ? (
              <div className="replacing">
                Replacing: <br />
                <div>
                  <a href={`${EMPLOYEE_PROFILE}/${value.substituteId}`} target="_blank" rel="noreferrer">
                    {value.substitute.length > 0 && value.substituteAvatarUrl.length > 0 ? (
                      <>
                        <div className="with-avatar">
                          <img src={value.substituteAvatarUrl} alt="cropped" />
                        </div>
                        <span className="replacing-name">{value.substitute}</span>
                      </>
                    ) : (
                      <>
                        <div className="without-avatar">
                          <p>{value.substitute.charAt(0).toUpperCase()}</p>
                        </div>
                        <span className="replacing-name">{value.substitute}</span>
                      </>
                    )}
                  </a>
                </div>
              </div>
            ) : (
              ''
            )}

            {value.status === 'New' ? <span className="not-confirmed">Request is not confirmed</span> : ''}
          </div>
        </ReactTooltip>
      )}
    </div>
  )
}
