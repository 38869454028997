import { DateFormatType } from './types'

export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
export const isoDateFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
export const dateFormatYear = 'YYYY'
export const coreHRDateFormat = 'DD.MM.YYYY'
export const dateTime24hFormat = 'MM.DD.YYYY, hh:mm A'
export const dateFormatDB = 'YYYY-MM-DD'

export const formats: DateFormatType = {
  dateFormatYear,
  dateTime24hFormat,
  dateTimeFormat,
  isoDateFormat,
  coreHRDateFormat,
  dateFormatDB,
}
