// TODO: Create svg sprite
// https://www.figma.com/design/Uyc0uL6J6VBO1uAbXhTPtH/Directory?node-id=1-21142&m=dev

import clsx from 'clsx'
import { EmptyTableStyles } from './styles'
import { EmptyTableTemplateProps } from './types'

export const EmptyTableTemplate = ({ children, size }: EmptyTableTemplateProps) => (
  <EmptyTableStyles className={clsx(`size-${size}`)}>{children}</EmptyTableStyles>
)
