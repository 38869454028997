import { memo } from 'react'
import { Table as AntTable, TableProps as AntdTableProps } from 'antd'
import { TableProps } from './types'
import { AnyObject } from 'antd/es/_util/type'
import { TablePaginationData } from 'types'
import isArray from 'lodash/isArray'
import { orderDirectionEnumLabel } from 'shared/constants'
import { TableStyles } from './styles'

const genericMemo: <T>(component: T) => T = memo

const GenericComponent = <ColumnDataType extends AnyObject, SortFieldsType>(
  props: TableProps<ColumnDataType, SortFieldsType>,
) => {
  const { defaultTableState, isLoading, onChange, dataSource, recordIdKey, ...tableProps } = props

  const onTableChange: AntdTableProps<ColumnDataType>['onChange'] = (pagination, _filters, sorter) => {
    if (!onChange) return

    const tableState: TablePaginationData<SortFieldsType> = {
      page: pagination.current || defaultTableState.page,
      limit: pagination.pageSize || defaultTableState.limit,
      order: !isArray(sorter) && sorter.order ? orderDirectionEnumLabel[sorter.order] : defaultTableState.order,
      // prettier-ignore
      // eslint-disable-next-line max-len
      sort: !isArray(sorter) && sorter.field && sorter.order ? (sorter.field as keyof SortFieldsType) : defaultTableState?.sort,
    }

    onChange(tableState)
  }

  return (
    <TableStyles>
      <AntTable
        loading={isLoading}
        showSorterTooltip={false}
        rowKey={recordIdKey}
        {...tableProps}
        dataSource={dataSource}
        pagination={{
          hideOnSinglePage: true,
          position: ['bottomCenter'],
          size: 'default',
          ...tableProps.pagination,
        }}
        onChange={onTableChange}
      />
    </TableStyles>
  )
}

export const Table = genericMemo(GenericComponent)
