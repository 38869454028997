import { RateFormValues } from './types'
import { CloseCircleFilled } from '@ant-design/icons'

export enum RateModalTypeEnum {
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
}

export const rateModalTitleMap: Record<RateModalTypeEnum, string | JSX.Element> = {
  [RateModalTypeEnum.Create]: 'Add new rate',
  [RateModalTypeEnum.Edit]: 'Edit rate',
  [RateModalTypeEnum.Delete]: (
    <>
      <CloseCircleFilled /> Delete rate
    </>
  ),
}

export const rateModalBtnMap: Record<RateModalTypeEnum, string> = {
  [RateModalTypeEnum.Create]: 'Add',
  [RateModalTypeEnum.Edit]: 'Edit',
  [RateModalTypeEnum.Delete]: 'Delete',
}

export const rateModalNotificationMap: Record<RateModalTypeEnum, string> = {
  [RateModalTypeEnum.Create]: "You've added a new Rate.",
  [RateModalTypeEnum.Edit]: "You've updated the Rate.",
  [RateModalTypeEnum.Delete]: "You've deleted the Rate.",
}

export const defaultValues: RateFormValues = {
  currencyId: null,
  rate: null,
  startDate: '',
}
