import { useState, useMemo, SetStateAction } from 'react'
import { Button } from 'antd'
import { Inline, Stack } from '@atlaskit/primitives'
import { FilterCalendar, DayRange, defaultRangeTimeOffLog } from 'components/UI/FilterCalendar'
import { TypeIconsCollection } from 'shared/TimeOffPolicy/constants/NewTypeIconsCollection'
import { EmptyDataPlaceholder } from 'components/UI/EmptyDataPlaceholder/EmptyDataPlaceholder'
import { EmptyFiltersPlaceholder } from 'components/UI/EmptyFiltersPlaceholder/EmptyFiltersPlaceholder'
import { EditIcon } from './icons'
import { SkeletonTable } from 'components/UI/skeleton-table'
import { TimeOffLogModal } from './timeoff-log-modal'
import { TimeOffLogTable } from './timeoff-log-table'
import {
  defaultPolicyQuery,
  defaultTimeOffLogPolicies,
  defaultTimeOffLogQuery,
  defaultTimeOffLogTableDate,
} from './constants'
import { PoliciesQueryType, TimeOffLogQueryType } from 'services/api/time-off-log/types'
import { useGetPoliciesQuery, useGetTimeOffLogQuery } from 'services/api'
import { TimeOffLogTableType } from './timeoff-log-table/types'
import { ITimeOffLogPoliciesProps } from 'models'
import { useProfile } from 'components/Profile/context'
import classNames from 'classnames'
import { TimeOffLogStyles } from './styles'

export const TimeOffLogDrawer = () => {
  const { isManagerOpenProfile, isAccessEditRole, profile } = useProfile()
  const [dateRange, setDateRange] = useState<DayRange>(defaultRangeTimeOffLog.range)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const policyQuery: PoliciesQueryType = { employeeId: profile.id, ...defaultPolicyQuery }
  const {
    data: policiesData = defaultTimeOffLogPolicies,
    isLoading: isPoliciesLoading,
    isSuccess,
  } = useGetPoliciesQuery(policyQuery)
  const [activePolicyId, setActivePolicyId] = useState<string>('')

  useMemo(() => {
    if (isSuccess && policiesData.total) {
      setActivePolicyId(policiesData.items[0].id)
    }
  }, [isSuccess, policiesData])

  const [tableQuery, setTableQuery] = useState<TimeOffLogQueryType>({
    employeeId: profile.id,
    dateStart: `${dateRange.from?.year}-${dateRange.from?.month}-${dateRange.from?.day}`,
    dateEnd: `${dateRange.to?.year}-${dateRange.to?.month}-${dateRange.to?.day}`,
    ...defaultTimeOffLogQuery,
  })
  const { page, limit, order, sort } = tableQuery
  const {
    data: tableData = defaultTimeOffLogTableDate,
    isLoading: isTableLoading,
    isFetching: isTableFetching,
  } = useGetTimeOffLogQuery({ policyId: activePolicyId, ...tableQuery }, { skip: !policiesData.total })

  const policyChangeHandler = (policyId: string) => {
    const newQuery: TimeOffLogQueryType = {
      ...tableQuery,
      page: 1,
      employeeId: profile.id,
      policyId,
    }
    setTableQuery(newQuery)
    setActivePolicyId(policyId)
  }

  const onUpdateTableState = (query: TimeOffLogTableType) => {
    const newQuery: TimeOffLogQueryType = {
      ...tableQuery,
      ...query,
      policyId: activePolicyId,
      dateStart: `${dateRange.from?.year}-${dateRange.from?.month}-${dateRange.from?.day}`,
      dateEnd: `${dateRange.to?.year}-${dateRange.to?.month}-${dateRange.to?.day}`,
    }
    setTableQuery(newQuery)
  }

  const onSetDateRange = (range: SetStateAction<DayRange>) => {
    setDateRange(range)
    const newQuery: TimeOffLogQueryType = {
      ...tableQuery,
      policyId: activePolicyId,
      dateStart: `${dateRange.from?.year}-${dateRange.from?.month}-${dateRange.from?.day}`,
      dateEnd: `${dateRange.to?.year}-${dateRange.to?.month}-${dateRange.to?.day}`,
    }
    setTableQuery(newQuery)
  }

  return (
    <TimeOffLogStyles>
      <div className="content">
        {isPoliciesLoading ? (
          <SkeletonTable isCalendar filtersCount={5} colCount={5} rowCount={10} />
        ) : (
          <Stack space="space.300">
            <Inline>
              <h1 className="content__title">Time off & special statuses log</h1>
            </Inline>
            <Stack space="space.200">
              <Inline alignBlock="center" spread="space-between">
                <FilterCalendar
                  setDateRange={(range) => onSetDateRange(range)}
                  defaultRange={{
                    range: dateRange,
                    name: defaultRangeTimeOffLog.name,
                  }}
                />

                {(isAccessEditRole || isManagerOpenProfile) && (
                  <Button icon={<EditIcon />} color="primary" variant="solid" onClick={openModal}>
                    Edit balance
                  </Button>
                )}
              </Inline>
              <Inline>
                <div className="content-tabs">
                  {policiesData &&
                    policiesData.items.map((item: ITimeOffLogPoliciesProps) => (
                      <Button
                        className={classNames('content-tabs__btn', activePolicyId === item.id ? 'isActive' : null)}
                        variant="filled"
                        color="default"
                        key={item.id}
                        onClick={() => policyChangeHandler(item.id)}
                        icon={TypeIconsCollection[item.type_icon as keyof typeof TypeIconsCollection](
                          'small-icon',
                          'withouthover',
                        )}
                      >
                        {item.name}
                      </Button>
                    ))}
                </div>
              </Inline>
            </Stack>
            {/* TODO: Rewrite ternary operations below */}
            {isTableLoading ? (
              <div className="skeleton-wrap">
                <SkeletonTable isTableOnly colCount={5} rowCount={10} />
              </div>
            ) : (
              (tableData.total && (
                <TimeOffLogTable
                  sourceData={tableData.items}
                  total={tableData.total}
                  loading={isTableFetching}
                  showPagination={tableData.total > limit}
                  onUpdateTableState={onUpdateTableState}
                  tableState={{
                    page,
                    limit,
                    sort,
                    order,
                  }}
                />
              )) ||
              (dateRange === defaultRangeTimeOffLog.range && (
                <EmptyDataPlaceholder title="There is no information here yet" />
              )) || (
                <EmptyFiltersPlaceholder
                  title="No results found"
                  info="Sorry, that filter combination has no results. Please try different criteria."
                />
              )
            )}
            {isModalOpen && (
              <TimeOffLogModal onClose={closeModal} policies={policiesData.items} activePolicyId={activePolicyId} />
            )}
          </Stack>
        )}
      </div>
    </TimeOffLogStyles>
  )
}
