// TODO: fix eslint errors
/* eslint-disable */
import { TransformJobTitles } from './jobTitles.types'
import { useState } from 'react'
import { fetchCurrentJobTitle as fetchCurrentJobTitleAPI, fetchJobTitles as fetchJobTitlesAPI } from './jobTitles'
import { useEvent } from 'hooks/useEvent'
import { useQuery } from '../../../../useQuery'

export const useJobTitles = () => {
  const [jobTitles, setJobTitles] = useState<Array<TransformJobTitles>>([])
  const [getAllJobTitles, isLoading, isSuccess, isError] = useQuery(fetchJobTitlesAPI)

  const [currentJobTitle, setCurrentJobTitle] = useState<TransformJobTitles>()
  const [getCurrentJobTitle] = useQuery(fetchCurrentJobTitleAPI)

  const fetchJobTitles = useEvent(async () =>
    getAllJobTitles().then((response) => {
      setJobTitles(response)
      return response
    }),
  )

  const fetchCurrentJobTitle = useEvent((id: string) =>
    getCurrentJobTitle(id).then((response) => {
      setCurrentJobTitle(response[0])
      return response[0]
    }),
  )

  return {
    jobTitles,
    fetchJobTitles,
    isLoading,
    isSuccess,
    isError,

    currentJobTitle,
    fetchCurrentJobTitle,
  }
}
