// TODO: fix eslint errors
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { RANDOM_PHRASES_WIDGET } from 'utils/constants/api'
import { RandomPhraseForWidget } from 'shared/RandomPhrase/data/RandomPhraseForWidget'
import Tooltip from '@atlaskit/tooltip'
import RefreshIcon from '@atlaskit/icon/glyph/refresh'
import { IconButton } from '@atlaskit/button/new'
import { xcss, Box } from '@atlaskit/primitives'

function RandomPhrasesWidget() {
  const [randomPhrase, setRandomPhrase] = useState('')

  function getRandomPhrase() {
    fetch(RANDOM_PHRASES_WIDGET, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((randomPhrases: RandomPhraseForWidget) => {
        setRandomPhrase(randomPhrases.text)
      })
  }

  useEffect(() => {
    getRandomPhrase()
  }, [])

  function handleIconClick() {
    getRandomPhrase()
  }

  return (
    <Box
      xcss={xcss({
        padding: 'space.200',
        backgroundColor: 'elevation.surface',
        color: 'color.text',
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'color.border.disabled',
        borderRadius: 'border.radius.050',
        minHeight: '94px',
      })}
    >
      <Tooltip position="bottom" content="Generate new phrase">
        {(tooltipProps) => (
          <Box {...tooltipProps}>
            <IconButton
              label="button"
              appearance="subtle"
              icon={(iconProps) => <RefreshIcon {...iconProps} primaryColor="rgba(68, 84, 111, 1)" />}
              onClick={handleIconClick}
            />
          </Box>
        )}
      </Tooltip>

      <Box
        xcss={xcss({
          paddingLeft: 'space.100',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '14px',
        })}
      >
        {randomPhrase}
      </Box>
    </Box>
  )
}

export default RandomPhrasesWidget
