import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SystemCurrency, Currency } from 'models'
import { BASE_URL } from '../constants'
import { DeleteCurrenciesPayload, GetCurrenciesPayload } from './types'

export const currenciesApi = createApi({
  reducerPath: 'currenciesApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/currencies` }),
  tagTypes: ['currencies'],
  endpoints: (build) => ({
    getCurrencies: build.query<Currency[], void>({
      query: () => ({
        url: '',
      }),
    }),
    getSystemCurrencies: build.query<SystemCurrency[], GetCurrenciesPayload>({
      query: (params: GetCurrenciesPayload) => ({
        url: '/system',
        params,
      }),
      providesTags: (_result) => [{ type: 'currencies', id: 'LIST' }],
    }),
    addSystemCurrency: build.mutation<string, GetCurrenciesPayload>({
      query: (body) => ({
        url: '/system',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'currencies', id: 'LIST' }]
        }
        return []
      },
    }),
    deleteSystemCurrency: build.mutation<string, DeleteCurrenciesPayload>({
      query: (body) => ({
        url: `/system/${body.currencyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'currencies', id: 'LIST' }]
        }
        return []
      },
    }),
  }),
})

export const {
  useGetCurrenciesQuery,
  useGetSystemCurrenciesQuery,
  useAddSystemCurrencyMutation,
  useDeleteSystemCurrencyMutation,
} = currenciesApi
