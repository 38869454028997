import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultMgpModalState } from './constants'
import { MgpModalState } from './types'

export const mgpModalSlice = createSlice({
  name: 'mgpModal',
  initialState: defaultMgpModalState,
  reducers: {
    openMGPModal: (_state, action: PayloadAction<MgpModalState>) => ({
      isOpen: true,
      ...action.payload,
    }),
    closeMGPModal: () => defaultMgpModalState,
  },
})

export const { openMGPModal, closeMGPModal } = mgpModalSlice.actions
export const mgpModalReducer = mgpModalSlice.reducer
