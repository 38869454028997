// TODO: fix eslint errors
/* eslint-disable */
import { useQuery } from '../../useQuery'
import {
  addEmergencyContactAPI,
  getCurrentEmergencyContact,
  removeEmergencyContactAPI,
  addPhoneEmergencyContactAPI,
  editPhoneEmergencyContactAPI,
  removePhoneEmergencyContactAPI,
  editEmergencyContactAPI,
} from './getCurrentEmergencyContact'
import { useState } from 'react'
import type { EmergencyContactProps } from './emergencyContact.types'
import { useEvent } from 'hooks/useEvent'

export const useEmergencyContact = (idProfile: string) => {
  const [fetchCurrentEmergencyContactAPI, isLoading, isSuccess, isError] = useQuery(getCurrentEmergencyContact)
  const [emergencyContact, setEmergencyContact] = useState<EmergencyContactProps>()

  const fetchCurrentEmergencyContact = useEvent(() =>
    fetchCurrentEmergencyContactAPI(idProfile).then((response) => {
      setEmergencyContact(response)
      return response
    }),
  )

  const reFetchCurrentEmergencyContact = useEvent(() =>
    getCurrentEmergencyContact(idProfile).then((response) => {
      setEmergencyContact(response)
      return response
    }),
  )

  const addEmergencyContact = useEvent(() => addEmergencyContactAPI(idProfile))

  const editEmergencyContact = useEvent((id: string, name: string, person: string) =>
    editEmergencyContactAPI(idProfile, id, name, person),
  )

  const removeEmergencyContact = useEvent((id: string) => removeEmergencyContactAPI(idProfile, id))

  const addPhoneEmergencyContact = useEvent(async (id: string, data: string) => {
    try {
      const res = await addPhoneEmergencyContactAPI(idProfile, id, data)
      //@ts-ignore
      if (res && res.error) {
        //@ts-ignore
        throw new Error(String(res.error))
      }
      return res
    } catch (error: any) {
      throw new Error(String(error.message))
    }
  })

  const editPhoneEmergencyContact = useEvent(async (idContact: string, id: string, data: string) => {
    try {
      const res = await editPhoneEmergencyContactAPI(idProfile, idContact, id, data)
      //@ts-ignore
      if (res && res.error) {
        //@ts-ignore
        throw new Error(String(res.error))
      }
      return res
    } catch (error: any) {
      throw new Error(String(error.message))
    }
  })

  const removePhoneEmergencyContact = useEvent((idContact: string, id: string) =>
    removePhoneEmergencyContactAPI(idProfile, idContact, id),
  )

  return {
    fetchCurrentEmergencyContact,
    emergencyContact,
    reFetchCurrentEmergencyContact,
    addEmergencyContact,
    removeEmergencyContact,
    addPhoneEmergencyContact,
    editPhoneEmergencyContact,
    removePhoneEmergencyContact,
    editEmergencyContact,
    isLoading,
    isSuccess,
    isError,
  }
}
