import {useState} from "react";
import {CountryOptionsProps} from "./country.types";
import {useQuery} from "../../../../useQuery";
import {fetchCountryAPI} from "./country";
import {useEvent} from "hooks/useEvent";

export const useCountry = () => {
    const [country, setCountry] = useState<Array<CountryOptionsProps>>([]);
    const [getAllCountry, isLoading, isSuccess, isError] = useQuery(fetchCountryAPI);

    const fetchCountry = useEvent(async () => getAllCountry().then((response) => {
            setCountry(response);
            return response;
        }));

    return {
        country,
        fetchCountry,
        isLoading,
        isSuccess,
        isError,
    };
};
