import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../constants'
import { CreateEmployeeMGPHistoryPayload, EmployeeMGPHistoryData, EmployeeMGPHistoryQuery, UpdateEmployeeMGPHistoryPayload } from './types'

export const employeeMgpHistoryApi = createApi({
  reducerPath: 'employeeMgpHistoryApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}` }),
  tagTypes: ['employeeMGPHistory'],
  endpoints: (build) => ({
    getEmployeeMgpHistoryData: build.query<EmployeeMGPHistoryData, EmployeeMGPHistoryQuery>({
      query: ({ employeeId, ...params }: EmployeeMGPHistoryQuery) => ({
        url: `/employees/${employeeId}/mgp`,
        params,
      }),
      providesTags: [{ type: 'employeeMGPHistory', id: 'LIST' }],
    }),
    createEmployeeMgpHistory: build.mutation<string, CreateEmployeeMGPHistoryPayload>({
      query: (body) => ({
        url: '/mgp',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'employeeMGPHistory', id: 'LIST' }]
        }
        return []
      },
    }),
    updateEmployeeMgpHistory: build.mutation<string, UpdateEmployeeMGPHistoryPayload>({
      query: ({ id, ...body }) => ({
        url: `/mgp/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'employeeMGPHistory', id: 'LIST' }]
        }
        return []
      },
    }),
    deleteEmployeeMgpHistory: build.mutation<string, string>({
      query: (id) => ({
        url: `/mgp/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'employeeMGPHistory', id: 'LIST' }]
        }
        return []
      },
    }),
  }),
})

export const {
  useGetEmployeeMgpHistoryDataQuery,
  useCreateEmployeeMgpHistoryMutation,
  useUpdateEmployeeMgpHistoryMutation,
  useDeleteEmployeeMgpHistoryMutation,
} = employeeMgpHistoryApi
