import { useEffect, useRef, useMemo, MutableRefObject } from 'react'
import debounce from 'lodash/debounce'
import { UseDebounceCallbackType, DebouncedCallbackType } from './types'

export const useDebounce = <CallbackParamType>(
  callback: UseDebounceCallbackType<CallbackParamType>,
  delay: number = 500,
): DebouncedCallbackType<CallbackParamType> => {
  const ref: MutableRefObject<undefined | UseDebounceCallbackType<CallbackParamType>> = useRef()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback: DebouncedCallbackType<CallbackParamType> = useMemo(() => {
    const func = (value: CallbackParamType) => {
      ref.current?.(value)
    }

    return debounce(func, delay)
  }, [])

  return debouncedCallback
}
