import styled from 'styled-components'

export const DeleteInfoStyled = styled.div`
  .deleted-info-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0 0 12px;
    gap: 10px;

    .list-item {
      display: flex;

      .label,
      .value {
        display: inline-block;
        color: rgba(23, 43, 77, 1);
        line-height: 22px;
      }

      .label {
        min-width: 110px;
      }

      .value {
        width: calc(100% - 110px);
      }
    }
  }
`
