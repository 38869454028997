import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const EditAnnouncementModalBodyStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${token("space.100")};
  padding: 0 ${token("space.300")};

  .text-area {
    position: relative;

    .field-counter {
      position: absolute;
      right: 8px;
      bottom: 8px;
    }
  }
`
