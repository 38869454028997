import { useMemo, useState } from 'react'
import { useAppDispatch } from 'hooks'
import { Table, Input, Select } from 'antd'
import { Button } from 'components'
import type { GetProps, TableProps } from 'antd'
import { tableColumns } from './components'
import { NoResults } from 'components/UI/table'
import { SkeletonTable } from 'components/UI/skeleton-table'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { DropDownIcon } from './icons'
import { useGetCurrenciesQuery, useAddSystemCurrencyMutation, useGetSystemCurrenciesQuery } from 'services/api'
import { Currency } from 'models'
import { TablePaginationData } from 'types'
import { GetCurrenciesPayload, CurrenciesSortBy } from 'services/api/currencies/types'
import { defaultCurrenciesTableState, tableColumnsMap } from './constants'
import { orderDirectionEnumLabel } from 'shared'
import { triggerNotification } from 'store'
import { CurrenciesTableRowProps } from './types'
import isArray from 'lodash/isArray'
import clsx from 'clsx'
import { CurrenciesStyles } from './styles'

type SearchProps = GetProps<typeof Input.Search>
const { Search } = Input

export const Currencies = () => {
  const dispatch = useAppDispatch()
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false)
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null)
  const { data: currenciesList, isLoading: isCurrenciesListLoading } = useGetCurrenciesQuery()

  const currenciesSelectOptions = useMemo(
    () =>
      currenciesList?.map((item: Currency) => ({
        label: `${item.code} - ${item.name}`,
        value: item.id,
      })),
    [currenciesList],
  )

  const [tableQuery, setTableQuery] = useState<GetCurrenciesPayload>(defaultCurrenciesTableState)
  const { data: tableData = [], isFetching } = useGetSystemCurrenciesQuery(tableQuery)
  const [addSystemCurrency, { isLoading }] = useAddSystemCurrencyMutation()

  const onSearchHandler: SearchProps['onSearch'] = (value) => {
    setTableQuery({
      ...tableQuery,
      search: value.trim(),
    })
  }

  const onSearchChange: SearchProps['onChange'] = (e) => {
    if (!e.target.value) {
      setTableQuery(defaultCurrenciesTableState)
    }
  }

  const onTableChange: TableProps<CurrenciesTableRowProps>['onChange'] = (pagination, filters, sorter) => {
    setTableQuery({
      ...tableQuery,
      order:
        !isArray(sorter) && sorter.order ? orderDirectionEnumLabel[sorter.order] : defaultCurrenciesTableState.order,
      sort:
        !isArray(sorter) && sorter.field && sorter.order
          ? (sorter.field as TablePaginationData<CurrenciesSortBy>['sort'])
          : defaultCurrenciesTableState.sort,
    })
  }

  const onCurrencySelect = async (value: string) => {
    const newQuery: GetCurrenciesPayload = {
      currencyId: value,
    }
    try {
      await addSystemCurrency(newQuery).unwrap()
      setSelectedCurrency(null)
      setIsSelectOpen(false)
      dispatch(
        triggerNotification({
          type: 'success',
          message: "You've added a currency.",
        }),
      )
    } catch (error: any) {
      if (error.status === 422) {
        dispatch(
          triggerNotification({
            type: 'error',
            message: 'Currency already have been added.',
          }),
        )
      }
      //TODO: fix TS
      else throw new Error('Something went wrong!', error)
    }
  }

  const showSelectHandler = () => {
    setIsSelectOpen(true)
  }

  return (
    <CurrenciesStyles>
      {isCurrenciesListLoading ? (
        <SkeletonTable colCount={4} rowCount={10} isHeaderButton={false} isSubtitle />
      ) : (
        <>
          <h3 className="currencies-title">Currencies</h3>
          <Search
            allowClear
            className="currencies-search"
            placeholder="Search"
            onSearch={onSearchHandler}
            onChange={onSearchChange}
            onClear={() => setTableQuery(defaultCurrenciesTableState)}
            loading={isFetching}
          />

          {tableQuery.search && !tableData.length ? (
            <NoResults />
          ) : (
            <>
              <Table<CurrenciesTableRowProps>
                columns={tableColumns}
                dataSource={tableData}
                className="currencies-table"
                size="middle"
                pagination={false}
                onChange={onTableChange}
                rowKey={tableColumnsMap.id}
              />
              <div className="currencies-add">
                <div className={clsx('currencies-add__select', { 'is-open': isSelectOpen })}>
                  <Select
                    showSearch
                    value={selectedCurrency}
                    placeholder="Select currency"
                    options={currenciesSelectOptions}
                    optionFilterProp="label"
                    onSelect={onCurrencySelect}
                    suffixIcon={isLoading ? <LoadingOutlined className="currencies-add__loading" /> : <DropDownIcon />}
                  />
                </div>
                <div className="currencies-add__btn">
                  <Button icon={<PlusOutlined />} type="text" onClick={showSelectHandler}>
                    Add currency
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </CurrenciesStyles>
  )
}
