// TODO: fix eslint errors
/* eslint-disable */
import React, { useState, useEffect } from 'react'

type YearProps = {
  selectedYear: string
  onYearChange: (year: string) => void
}

const SubHeaderFilterBlock = ({ selectedYear, onYearChange }: YearProps) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear().toString())

  useEffect(() => {
    setCurrentYear(selectedYear)
  }, [selectedYear])

  const handleYearClick = (year: string) => {
    onYearChange(year)
    setCurrentYear(year)
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('year', year.toString())
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.pushState({}, '', newUrl)
  }

  return (
    <ul className="sub-header-filter-block align-items-center d-flex">
      <li
        className={selectedYear === (new Date().getFullYear() - 1).toString() ? 'active' : ''}
        onClick={() => handleYearClick((new Date().getFullYear() - 1).toString())}
      >
        {new Date().getFullYear() - 1}
      </li>
      <li
        className={selectedYear === new Date().getFullYear().toString() ? 'active' : ''}
        onClick={() => handleYearClick(new Date().getFullYear().toString())}
      >
        {new Date().getFullYear()}
      </li>
      <li
        className={selectedYear === (new Date().getFullYear() + 1).toString() ? 'active' : ''}
        onClick={() => handleYearClick((new Date().getFullYear() + 1).toString())}
      >
        {new Date().getFullYear() + 1}
      </li>
    </ul>
  )
}

export default SubHeaderFilterBlock
