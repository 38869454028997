// TODO: Make it better
import dayjs, { Dayjs } from 'dayjs'
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'
import { dateRangePresets } from './constants'

export const getMatchingPreset = (dates: string[]): string | null => {
  const [startDateStr, endDateStr] = dates
  const startDate = dayjs(startDateStr)
  const endDate = dayjs(endDateStr).endOf('day')

  for (const preset of dateRangePresets) {
    const datesArray = preset.value as RangeValueType<Dayjs>

    if (startDate.isSame(datesArray[0]) && endDate.isSame(datesArray[1])) {
      return preset.label as string
    }
  }

  return null
}
