// TODO: fix eslint errors
/* eslint-disable */
import React, { FC, useCallback, useEffect, useState } from 'react'
import './JobInfo.scss'
import { EmployeeFromApi } from '../../../../../../shared/Employee/data/EmployeeFromApi'
import { assertEmployee, useEmployee } from '../../../../../../contexts/EmployeeContext'
import { BlockInfoItem } from 'components/Profile/BlockInfo'
import Lozenge from '@atlaskit/lozenge'
import { hasAdminAccess } from 'utils/hasAdminAccess'
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit'
import './EmployeeStatus.scss'
import { EmployeeStatuses, SubmitResult } from 'interfaces/employee/statuses.interface'
import { SetStatusModal } from './Modal/SetStatusModal'
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove'
import { statusAPI } from 'API/Employees/status'
import { SectionMessageData } from 'interfaces/common/section-message.interface'
import { formatDateToDDMMYYY } from 'utils/helpers/DateTime/formatDate'
import { statusEmptyStateID } from 'interfaces/common/status.interface'
import { ProfileModalChangeDateWithPolicyAccrual } from '../../../../../../interfaces/timeOffPolicy/profileModalChangeDateWithPolicyAccrual.interface'
import ModalPolicyNotification from 'components/Profile/Modal/PolicyNotification/ModalPolicyNotification'
import { useAppDispatch } from 'hooks'
import { triggerNotification } from 'store'

interface EmployeeStatusProps {
  id?: string
  index: number
  handleStatusUpdate: () => void
  previousStatusStartDate: string
  previousStatusId: string
  employeeStatuses: EmployeeStatuses[]
  lastStatusName: string
  lastStatusStartDate: string
  lastStatusId: string
  lastOriginalStatusId: string
  employee: EmployeeFromApi
  triggerParentUpdate: () => void
}

const removeRespMessages: {
  [key in SubmitResult]: SectionMessageData
} = {
  success: {
    appearance: 'success',
    title: `You’ve deleted the status`,
    text: `If you need to perform additional actions in the employee's card or 
      elsewhere, don't forget to do so.`,
  },
  error: {
    appearance: 'error',
    title: `Failed to delete the status`,
    text: `If you need to perform additional actions in the employee's card or 
    elsewhere, don't forget to do so.`,
  },
}

export const EmployeeStatus: FC<EmployeeStatusProps> = (props) => {
  const { employee: employeeContext } = useEmployee()
  assertEmployee(employeeContext)
  const hasAccessRole = hasAdminAccess(employeeContext.roles)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false)
  const [policies, setPolicies] = useState<ProfileModalChangeDateWithPolicyAccrual[]>([])

  const handleEdit = () => () => {
    openModal()
  }

  const showMessage = (result: SubmitResult) => {
    dispatch(
      triggerNotification({
        type: removeRespMessages[result].appearance,
        message: removeRespMessages[result].title,
        description: removeRespMessages[result].text,
      }),
    )
  }

  const handleRemove = async () => {
    try {
      const response = await statusAPI.deleteUserStatus(props.employee.id, props.lastStatusId)
      if (response.ok) {
        props.handleStatusUpdate()
        return showMessage('success')
      }
      return showMessage('error')
    } catch (error) {
      return showMessage('error')
    }
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = useCallback(() => setIsModalOpen(false), [])
  const handleClosePolicyModal = () => {
    setIsPolicyModalOpen(false)
    setPolicies([])
  }
  const handleConfirmPolicyModal = () => {
    setIsPolicyModalOpen(false)
    setPolicies([])
  }

  useEffect(() => {
    if (policies.length > 0) {
      setIsPolicyModalOpen(true)
    }
  }, [policies])

  return (
    <>
      <div className="block">
        <div className="blockRight">
          <BlockInfoItem title="">
            <>
              <SetStatusModal
                dateFrom={props.previousStatusStartDate}
                employeeId={props.employee.id}
                onStatusUpdate={props.handleStatusUpdate}
                isOpen={isModalOpen}
                closeModal={closeModal}
                isSelectDisabled
                defaultDate={props.lastStatusStartDate}
                defaultStatus={props.lastStatusName}
                options={[]}
                lastStatusId={props.lastStatusId}
                lastOriginalStatusId={props.lastOriginalStatusId}
                mode="edit"
                setPolicies={setPolicies}
              />
              {props.employeeStatuses.map(
                (status, index) =>
                  !status.isArchived &&
                  status.statusId !== statusEmptyStateID && (
                    <div key={status.id}>
                      <div className="datestatus">
                        <Lozenge appearance={status.dateEnd ? 'default' : 'success'}>{status.statusName}</Lozenge>
                        {hasAccessRole && status.dateEnd === null && (
                          <div className="dateicon">
                            <span
                              className={`statuses ${hasAccessRole && status.dateEnd === null ? 'editable' : ''}`}
                              onClick={hasAccessRole && !status.dateEnd ? handleEdit() : undefined}
                            >
                              <EditorEditIcon label="Edit" />
                            </span>
                            <span
                              className={`statuses ${hasAccessRole && status.dateEnd === null ? 'editable' : ''}`}
                              onClick={hasAccessRole && !status.dateEnd ? handleRemove : undefined}
                            >
                              <EditorRemoveIcon label="remove" />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="dateblock">
                        <div className="date">
                          <h4 className="title">From:</h4>
                          <p>{formatDateToDDMMYYY(status.dateStart)}</p>
                        </div>
                        <div className="date">
                          <h4 className="title">To:</h4>
                          <p>{formatDateToDDMMYYY(status.dateEnd) || 'Now'}</p>
                        </div>
                      </div>
                    </div>
                  ),
              )}
            </>
          </BlockInfoItem>
        </div>
      </div>
      {isPolicyModalOpen && (
        <ModalPolicyNotification
          isOpen={isPolicyModalOpen}
          onClose={handleClosePolicyModal}
          onConfirm={handleConfirmPolicyModal}
          policies={policies}
        />
      )}
    </>
  )
}
