// TODO: fix eslint errors
/* eslint-disable */
import { EMPLOYEES } from '../../../../../../../utils/constants/api'
import ConfirmDeleteModal from '../../../../../Layer/Modal/ConfirmDeleteModal'
import { EmployeeFromApi } from '../../../../../../../shared/Employee/data/EmployeeFromApi'

type DeleteEmployee = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  employee: EmployeeFromApi
}

function DeleteEmployee(props: DeleteEmployee) {
  function deleteEmployee(): void {
    fetch(`${EMPLOYEES}/${props.employee.id}`, {
      method: 'DELETE',
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else {
        response.json().then()
      }
    })
  }

  return (
    <ConfirmDeleteModal
      opened={props.opened}
      modalTitle="Delete Employee"
      cancelText="Cancel"
      deleteText={`employee <span>&laquo;${props.employee.firstNameEn} ${props.employee.lastNameEn}&raquo;</span>`}
      okText="Delete"
      onCancel={props.onClose}
      onOk={deleteEmployee}
    />
  )
}

export default DeleteEmployee
