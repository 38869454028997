// TODO: fix eslint errors
/* eslint-disable */
import Textfield from '@atlaskit/textfield'
import SearchIcon from '@atlaskit/icon/glyph/search'
import { debounce } from 'lodash'
import { ReactElement, useEffect, useRef, useState } from 'react'

type SearchFieldType = {
  value: string
  onChange: (arg: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onreset?: number
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent) => void
  placeholder?: string
  onPressEnter?: () => void
  elemAfterInput?: ReactElement
}

export default function SearchField({
  value,
  onChange,
  onKeyDown,
  onFocus,
  onClick,
  onPressEnter,
  placeholder,
  onreset,
  elemAfterInput,
}: SearchFieldType) {
  const onChangeDebounced = useRef(
    debounce((value) => {
      onChange(value)
    }, 400),
  ).current

  const [searchValue, setSearchValue] = useState(value)

  useEffect(() => {
    onChangeDebounced(searchValue)
  }, [searchValue])

  useEffect(() => {
    setSearchValue('')
  }, [onreset])

  return (
    <Textfield
      placeholder={placeholder}
      appearance="standard"
      maxLength={60}
      value={searchValue}
      elemBeforeInput={<SearchIcon label="search" />}
      elemAfterInput={elemAfterInput}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
      }}
      onFocus={onFocus}
      onClick={onClick}
      onKeyDown={(e) => {
        if (onKeyDown) {
          onKeyDown(e)
        }
        if (e.key === 'Enter') {
          if (onPressEnter) {
            onPressEnter()
          }
        }
      }}
    />
  )
}
