import { useAppDispatch } from 'hooks'
import { FilterCheckboxGroup, FilterRangeCalendar, FilterRangeDates } from 'components'
import { employeeRequestStatusSelectOptions } from 'shared/constants'
import { setBonusPenaltyFilters } from 'store'
import { BonusesPenaltiesFiltersStyles } from './styles'

export const BonusesPenaltiesFilters = () => {
  const dispatch = useAppDispatch()

  const handleChangeEffectiveDates = (dates: string[]) => {
    dispatch(
      setBonusPenaltyFilters({
        effectiveDate: dates,
      }),
    )
  }

  const handleChangeCreationDates = (dates: string[]) => {
    dispatch(
      setBonusPenaltyFilters({
        creationDate: dates,
      }),
    )
  }

  const handleChangeStatus = (statuses: string[]) => {
    dispatch(
      setBonusPenaltyFilters({
        statuses,
      }),
    )
  }

  return (
    <BonusesPenaltiesFiltersStyles>
      <FilterRangeCalendar onChange={handleChangeEffectiveDates} value="thisMonth" />
      <div className="filter-body">
        <FilterCheckboxGroup
          label="Statuses"
          options={employeeRequestStatusSelectOptions}
          onChange={handleChangeStatus}
        />
        <FilterRangeDates placeholder="Creation date" onChange={handleChangeCreationDates} />
      </div>
    </BonusesPenaltiesFiltersStyles>
  )
}
