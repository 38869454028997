import { useMemo } from 'react'
import { EmployeeMgpHistory } from 'models'
import { TablePaginationData } from 'types'
import { EmployeeMGPHistorySortBy } from 'services/api'
import { defaultEmployeeMGPHistoryTableState, setEmployeeMgpHistoryTableState } from 'store'
import { useAppDispatch, useAppSelector } from 'hooks'
import { Table as CoreHRTable } from 'components'
import { getTableColumns } from './table-columns'
import { EmployeeMGPHistoryTableConfig, EmployeeMGPHistoryTableProps } from './types'

export const Table = (props: EmployeeMGPHistoryTableProps) => {
  const { isLoading, sourceData } = props

  const dispatch = useAppDispatch()

  const {
    tableState: { page, limit },
  } = useAppSelector((state) => state.employeeMgpHistoryTableFilters)

  const { isVisible: isVisibleAmountValue } = useAppSelector((state) => state.showEmployeeCompensation)

  // prettier-ignore
  const tableColumns: EmployeeMGPHistoryTableConfig = useMemo(
    () => getTableColumns({ isVisibleAmountValue }), [isVisibleAmountValue]
  )

  const onTableChange = (tableState: TablePaginationData<EmployeeMGPHistorySortBy>) => {
    dispatch(setEmployeeMgpHistoryTableState(tableState))
  }

  return (
    <CoreHRTable<EmployeeMgpHistory, EmployeeMGPHistorySortBy>
      loading={isLoading}
      columns={tableColumns}
      recordIdKey="id"
      defaultTableState={defaultEmployeeMGPHistoryTableState}
      dataSource={sourceData.items}
      onChange={onTableChange}
      pagination={{
        pageSize: limit,
        total: sourceData.total,
        current: page,
      }}
    />
  )
}
