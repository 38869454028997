import {useState} from "react";
import {DirectManagerProps} from "./directManager.types";
import {useQuery} from "../../../../useQuery";
import {fetchDirectManagerAPI} from "./directManager";
import {useEvent} from "hooks/useEvent";

export const useDirectManager = (idOpenProfile: string) => {
    const [directManager, setDirectManagers] = useState<Array<DirectManagerProps>>([]);
    const [getAllEmployees, isLoading, isSuccess, isError] = useQuery(fetchDirectManagerAPI);

    const fetchDirectManagers = useEvent(async () => getAllEmployees(idOpenProfile)
            .then((response) => {
                setDirectManagers(response);
                return response;
            }));

    return {
        directManager,
        fetchDirectManagers,
        isLoading,
        isSuccess,
        isError
    };
};
