// TODO: fix eslint errors
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Modal from '../../../../../Layer/Modal/Modal'
import TextField, { Type } from 'components/old-ui/Input/TextField/TextField'
import { ValidationError } from '../../../../../../../shared/Common/Error/ValidationError'
import { EMPLOYEES } from '../../../../../../../utils/constants/api'
import { VALIDATION_ERROR } from '../../../../../../../utils/constants/errors'

type BaseModal = {
  opened: boolean
  modalTitle: string
  okText: string
  onCancel: () => any
  onOk: (
    nickname: string | null,
    lastNameEn: string,
    firstNameEn: string,
    email: string,
    validate: (errors: ValidationError[]) => void,
  ) => void
  loading?: boolean
}

function BaseModal(props: BaseModal) {
  const [nickname, setNickname] = useState<string>('')
  const [nicknameError, setNicknameError] = useState<string | null>(null)
  const [firstNameEn, setFirstNameEn] = useState<string>('')
  const [firstNameEnError, setFirstNameEnError] = useState<string | null>(null)
  const [lastNameEn, setLastNameEn] = useState<string>('')
  const [lastNameEnError, setLastNameEnError] = useState<string | null>(null)
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string | null>(null)

  function validateField(field: string, value: string): void {
    fetch(`${EMPLOYEES}/employee/${field}/${value}`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-type': 'application/json' },
    }).then((response) => {
      if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors))
      }
    })
  }

  function resetAllValues(): void {
    setNickname('')
    setLastNameEn('')
    setFirstNameEn('')
    setEmail('')
  }

  function resetAllErrors(): void {
    setNicknameError(null)
    setLastNameEnError(null)
    setFirstNameEnError(null)
    setEmailError(null)
  }

  function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
      if (error.property === 'employee.email') {
        setEmailError(error.message)
      }
      if (error.property === 'employee.lastNameEn' || error.property === 'employee.last-name-en') {
        setLastNameEnError(error.message)
      }
      if (error.property === 'employee.firstNameEn' || error.property === 'employee.first-name-en') {
        setFirstNameEnError(error.message)
      }
      if (error.property === 'employee.nickname') {
        setNicknameError(error.message)
      }
    })
  }

  useEffect(() => {
    if (props.modalTitle === 'Create Employee') {
      resetAllValues()
      resetAllErrors()
    }
  }, [props.opened])

  return (
    <Modal
      opened={props.opened}
      modalTitle={props.modalTitle}
      cancelText="Cancel"
      okText={props.okText}
      onCancel={() => {
        props.onCancel()
        resetAllValues()
        resetAllErrors()
      }}
      onOk={() => props.onOk(nickname, lastNameEn, firstNameEn, email, validate)}
      loading={props.loading}
    >
      <div
        onBlur={() => {
          validateField('first-name-en', firstNameEn)
        }}
      >
        <TextField
          label="First Name (En)"
          placeholder="First Name..."
          required
          value={firstNameEn}
          type={Type.Textarea}
          horizontal
          error={firstNameEnError}
          onChange={(newFirstName) => {
            const isValid = /^([-'a-zA-Z\s]+)?$/.test(newFirstName)
            if (isValid) {
              setFirstNameEn(newFirstName)
            }
          }}
          onFocus={() => setFirstNameEnError(null)}
          maxLength={30}
        />
      </div>
      <div
        onBlur={() => {
          validateField('last-name-en', lastNameEn)
        }}
      >
        <TextField
          label="Last Name (En)"
          placeholder="Last Name..."
          required
          value={lastNameEn}
          type={Type.Textarea}
          horizontal
          error={lastNameEnError}
          onChange={(newLastName) => {
            const isValid = /^([-'a-zA-Z\s]+)?$/.test(newLastName)
            if (isValid) {
              setLastNameEn(newLastName)
            }
          }}
          onFocus={() => setLastNameEnError(null)}
          onBlur={(newLastName) => {
            validateField('last-name-en', newLastName)
          }}
          maxLength={30}
        />
      </div>
      <div
        onBlur={() => {
          validateField('email', email)
        }}
      >
        <TextField
          label="Email"
          placeholder="Email..."
          required
          value={email}
          type={Type.Textarea}
          horizontal
          error={emailError}
          onChange={(newEmail) => {
            const isValid = /^[a-zA-Z0-9@._-]*$/.test(newEmail)
            if (isValid) {
              setEmail(newEmail)
            }
          }}
          onFocus={() => setEmailError(null)}
          maxLength={50}
        />
      </div>
      <div
        onBlur={() => {
          validateField('nickname', nickname)
        }}
      >
        <TextField
          label="Nickname"
          placeholder="Nickname..."
          required={false}
          value={nickname}
          type={Type.Textarea}
          horizontal
          error={nicknameError}
          onChange={(newNickname) => {
            const isValid = /^([-_'a-zA-Z\s]+)?$/.test(newNickname)
            if (isValid) {
              setNickname(newNickname)
            }
          }}
          onFocus={() => setNicknameError(null)}
          maxLength={20}
        />
      </div>
    </Modal>
  )
}

export default BaseModal
