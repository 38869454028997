import type { TimeOffPolicyResponseProps, TimeOffPolicyProps } from './timeOffPolicy.types'

import { TIME_OFF_POLICIES } from '../../../../constants'

const transformToADSSelectOptions = (options: Array<TimeOffPolicyResponseProps>): Array<TimeOffPolicyProps> =>
  options.map((option) => ({
    ...option,
    dataValue: option,
    label: option.name,
    value: option.id,
  }))

export const fetchTimeOffPolicyAPI = async () => {
  try {
    const response = await fetch(TIME_OFF_POLICIES, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })

    const data = (await response.json()) as Array<TimeOffPolicyResponseProps>

    return transformToADSSelectOptions(data)
  } catch (error) {
    console.error(error)
    throw new Error()
  }
}
