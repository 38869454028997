// TODO: fix eslint errors
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Modal from '../../../../Layer/Modal/Modal'
import TextField, { Type } from 'components/old-ui/Input/TextField/TextField'
import SelectInput from 'components/old-ui/Input/SelectInput/SelectInput'
import { JobTitlesCategoryFromApi } from '../../../../../../shared/JobTitle/data/JobTitlesCategoryFromApi'
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError'

type BaseModal = {
  opened: boolean
  modalTitle: string
  name: string | null
  okText: string
  onCancel: () => any
  onOk: (text: string, categoryId: string | null, validate: (errors: ValidationError[]) => void) => void
  categories: JobTitlesCategoryFromApi[]
  category: CategorySelectValue | null
}

type CategorySelectValue = {
  label: string
  value: string
}

function BaseModal(props: BaseModal) {
  const categoryOptions = props.categories.map(
    (category) => ({ label: category.name, value: category.id }) as CategorySelectValue,
  )

  const [name, setName] = useState<string>(props.name ?? '')
  const [category, setCategory] = useState<CategorySelectValue | null>(props.category)
  const [textError, setTextError] = useState<string | null>(null)
  const [categoryError, setCategoryError] = useState<string | null>(null)

  function resetAll(): void {
    setName('')
    setCategory(null)
    setTextError(null)
    setCategoryError(null)
  }

  useEffect(() => {
    if (props.modalTitle === 'Create Job Title') {
      resetAll()
    }
  }, [props.opened])

  function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
      if (error.property === 'title.name') {
        setTextError(error.message)
      } else if (error.property === 'title.categoryId') {
        name ? setCategoryError(error.message) : setTextError('Name is required')
      }
    })
  }

  function handleNameChange(newName: string): void {
    setName(newName)
    setTextError(null)
  }

  function handleCategoryChange(newCategory: CategorySelectValue | null): void {
    setCategory(newCategory)
    setCategoryError(null)
  }

  return (
    <Modal
      opened={props.opened}
      modalTitle={props.modalTitle}
      cancelText="Cancel"
      okText={props.okText}
      onCancel={() => {
        props.onCancel()
        resetAll()
      }}
      onOk={() => props.onOk(name, category ? category.value : null, validate)}
    >
      <TextField
        label="Name"
        placeholder="Name..."
        required
        value={name}
        type={Type.Textarea}
        horizontal
        error={textError}
        onChange={(newName) => handleNameChange(newName)}
        maxLength={50}
      />
      <SelectInput
        label="Category"
        required
        isMulti={false}
        isSearchable={false}
        value={category}
        options={categoryOptions}
        error={categoryError}
        onChange={(newCategory) => handleCategoryChange(newCategory)}
      />
    </Modal>
  )
}

export default BaseModal
