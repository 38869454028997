import { AUTH } from '../../utils/constants/api'
import * as Sentry from '@sentry/react'

export const signIn = async (email: string, password: string) => {
  try {
    const response = await fetch(AUTH, {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
      }),
    })

    return {
      status: response.status,
      statusText: response.statusText,
      data: await response.json(),
    }
  } catch (error) {
    Sentry.captureException(error)
    return {
      status: 500,
      statusText: 'Internal Server Error',
      data: null,
    }
  }
}
