/**
 * The Date object's methods convert from UTC to the user's timezone
 * Set minutes to current minutes (UTC) + offset from user's local time
 * Now we can use methods on the Date object without timezone conversion
 *
 * @param date - String to create the object or null
 * @return Date|null - A Date object with a fixed timezone if a string was passed, null otherwise
 */
export const dateFromStringWithDefaultTimezone = (date: string | null): Date | null => {
  if (!date) {
    return null
  }

  const dateObject = new Date(date)
  dateObject.setMinutes(dateObject.getMinutes() + dateObject.getTimezoneOffset())

  return dateObject
}

export function formatDateToFriendly(date: string): string {
  const dateObject = dateFromStringWithDefaultTimezone(date) || new Date(date)
  const monthName = dateObject.toLocaleString('en-US', { month: 'long' })
  const dayNumber = dateObject.getDate()

  return `${monthName} ${String(dayNumber).padStart(2, '0')}`
}

export function formatDateToFriendlyShort(date: string): string {
  const dateObject = dateFromStringWithDefaultTimezone(date) || new Date(date)
  const monthName = dateObject.toLocaleString('en-US', { month: 'short' })
  const dayNumber = dateObject.getDate()

  return `${monthName} ${String(dayNumber).padStart(2, '0')}`
}

export function formatDateToFriendlyShortTimeZone(date: string): string {
  const dateObject = new Date(date)
  const monthName = dateObject.toLocaleString('en-US', { month: 'short' })
  const dayNumber = dateObject.getDate()

  return `${monthName} ${String(dayNumber).padStart(2, '0')}`
}

export function isOverlap(range1Start: Date, range1End: Date, range2Start: Date, range2End: Date): boolean {
  return range1Start <= range2End && range2Start <= range1End
}
