// TODO: fix eslint errors
/* eslint-disable */
import BaseModal from './BaseModal'
import { LOCATIONS } from '../../../../../utils/constants/api'
import { CountryFromApi } from '../../../../../shared/Location/data/CountryFromApi'
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError'
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors'

type CreateLocation = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  countries: CountryFromApi[]
}

type SelectValue = {
  value: string
  label: string
}
const emptySelectValue = { label: '', value: '' } as SelectValue

const country = emptySelectValue
const city = emptySelectValue
const timezone = emptySelectValue

function CreateLocation(props: CreateLocation) {
  function create(
    country: string,
    city: string,
    timezone: string,
    showOnMainPage: boolean,
    validate: (errors: ValidationError[]) => void,
  ): void {
    const formData = new FormData()
    formData.append(
      'location',
      JSON.stringify({
        countryCode: country,
        city,
        timezone,
        showOnMainPage,
      }),
    )

    fetch(LOCATIONS, { method: 'POST', body: formData }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors))
      }
    })
  }

  return (
    <BaseModal
      opened={props.opened}
      modalTitle="Create Location"
      country={country}
      city={city}
      timezone={timezone}
      countries={props.countries}
      showOnMainPage
      okText="Create"
      onCancel={props.onClose}
      onOk={create}
    />
  )
}

export default CreateLocation
