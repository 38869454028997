import type {ManagersOpenProfileProps} from './managersOpenProfile.types';
import {useQuery} from "../../useQuery";
import {getManagersOpenProfileAPI} from "./managersOpenProfile";
import {useState} from "react";
import {useEvent} from "hooks/useEvent";

export const useManagersOpenProfile = (idProfile: string) => {
    const [getManagersOpenProfileAPIs, isLoading, isSuccess, isError] = useQuery(getManagersOpenProfileAPI);
    const [managersOpenProfile, setManagersOpenProfile] = useState<ManagersOpenProfileProps | null>();

    const getManagersOpenProfile = useEvent(async () => {
        try {
            const response = await getManagersOpenProfileAPIs(idProfile);
            setManagersOpenProfile(response);
            return response;
        } catch (error) {
            throw new Error();
        }
    });

    return {
        getManagersOpenProfile,
        managersOpenProfile,
        isLoading,
        isSuccess,
        isError
    }
};
