import { AnnouncementPriorityEnum, announcementPriorityLabelMap } from 'shared'
import { getEnumValues } from 'utils/helpers/enum-values'
import { AnnouncementPriorityOptionsType, AnnouncementFormValues } from './types'

export const announcementPrioritySelectOptions: AnnouncementPriorityOptionsType = getEnumValues(
  AnnouncementPriorityEnum,
).map((type: AnnouncementPriorityEnum) => ({
  label: announcementPriorityLabelMap[type],
  value: type,
}))

export const announcementFormErrorsMsgMap: { [key in keyof AnnouncementFormValues]: { required: string } } = {
  startDate: {
    required: 'Please choose start date',
  },
  endDate: {
    required: 'Please choose end date',
  },
  priority: {
    required: 'Please choose priority',
  },
  locations: {
    required: 'Please select location',
  },
  text: {
    required: 'Please write announcement',
  },
}
