// TODO: fix eslint errors
/* eslint-disable */
import { FC } from 'react'
import styleBTN from 'components/UI/Button/Button.module.scss'
import { IconButton } from '@atlaskit/button/new'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

interface MoreExportProps {
  exportPDF: () => void
  exportCSV: () => void
  isDisabled: boolean
}

export const MoreExport: FC<MoreExportProps> = ({ exportPDF, exportCSV, isDisabled }) => (
  <DropdownMenu<HTMLButtonElement>
    placement="bottom-start"
    trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
      <span className={styleBTN.button}>
        <IconButton
          isDisabled={isDisabled}
          {...providedProps}
          isSelected={isSelected}
          ref={triggerRef}
          icon={ChevronDownIcon}
          label="More"
        />
      </span>
    )}
  >
    <DropdownItemGroup>
      <DropdownItem onClick={exportPDF}>PDF File</DropdownItem>
      <DropdownItem onClick={exportCSV}>CSV File</DropdownItem>
    </DropdownItemGroup>
  </DropdownMenu>
)
