import styled from 'styled-components'

export const StatisticsPanelStyles = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;

  .switcher {
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .ant-spin-nested-loading {
    height: 74px;
  }

  .statistics-list {
    list-style: none;
    padding: 0 2px;
    display: flex;
    gap: 24px;

    &-item {
      display: flex;
      padding: 8px 8px 10px 8px;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      background: #fff;
      margin: 0;
      box-shadow: 0px 2px 4px 0px rgba(0, 35, 11, 0.2);
    }

    .label {
      color: rgba(0, 0, 0, 0.45);
      line-height: 24px;
      display: flex;
      gap: 4px;
      align-items: center;

      .ant-btn-default.ant-btn-text {
        padding: 0;
        width: 16px;
        height: 16px;
      }
    }

    .value {
      color: rgba(0, 0, 0, 0.65);
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
  }
`
