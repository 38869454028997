// TODO: fix eslint errors
/* eslint-disable */
import { FC, ReactElement } from 'react'
import { token } from '@atlaskit/tokens'
import styled from 'styled-components'

export const StyledPolicySelect: FC<{
  children: ReactElement
  dropdownHorizontalDisplacement: string | undefined
  onClick: (arg: any) => void
}> = styled.div`
  transition: none;

  .dropdown {
    &-container {
      height: 32px;
      width: 160px;
      padding: ${token('space.050')} ${token('space.100')};
      gap: ${token('space.025')};
      border: 2px solid rgba(9, 30, 66, 0.14);
      background: ${token('color.background.input')};
      color: ${token('color.text.subtlest')};
      border-radius: 3px;
      cursor: pointer;

      &:focus-within {
        box-shadow: none;
      }

      &:hover {
        color: ${token('color.text')};
      }

      &[aria-expanded='true'] {
        border: 2px solid ${token('color.border.focused')};
        background: ${token('color.background.input.pressed')};
      }
    }

    &-heading {
      height: 100%;
      padding: 0;
      cursor: pointer;

      &-value {
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 400;

        & > span {
          width: 100%;
          height: 100%;
          color: inherit;
        }
      }
    }

    &-content {
      display: flex;
      width: 250px;
      padding: ${token('space.100')} 0px;
      margin-top: ${token('space.100')};
      flex-direction: column;
      align-items: center;
      border-radius: 3px;
      background: ${token('elevation.surface.overlay')};
      box-shadow:
        0px 0px 1px 0px rgba(9, 30, 66, 0.31),
        0px 8px 12px 0px rgba(9, 30, 66, 0.15);
      left: ${(props) => (props.dropdownHorizontalDisplacement ? props.dropdownHorizontalDisplacement : '0')};

      .panel-content {
        width: 100%;
        box-shadow: none;
      }

      .search {
        display: flex;
        height: 32px;
        width: calc(100% - (${token('space.050')} * 2));
        padding: ${token('space.050')} ${token('space.100')};
        margin: 0 auto;
        align-items: center;
        gap: ${token('space.025')};
        border-radius: 3px;
        border: 2px solid rgba(9, 30, 66, 0.14);
        background: ${token('color.background.input')};
        flex: 1 0 0;

        input {
          height: 28px;
          padding: ${token('space.050')} ${token('space.100')};
          background: transparent;
        }

        &-clear-button:not([hidden]) {
          height: 100%;
          display: flex;
          align-items: center;
        }

        &::before {
          height: 100%;
          content: url('/image/SearchIcon_.svg');
          display: inline-block;
        }

        &:focus-within {
          border: 2px solid ${token('color.border.focused')};
        }
      }

      .options {
        max-height: 256px;
        overflow-x: hidden;
      }

      .select-item {
        width: 100%;
        height: 32px;
        padding: 0;

        &:hover,
        &:active {
          box-shadow: inset 2px 0px 0px ${token('color.border.selected')};
          background: ${token('color.background.neutral.subtle.hovered')};
        }

        &.selected {
          background: none;
        }
      }

      .item-renderer {
        display: flex;
        height: 32px;
        padding: 0px ${token('space.150')} 0px ${token('space.200')};
        align-items: center;
        align-self: stretch;
        gap: ${token('space.050')};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }

      .item-textline {
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        gap: 0 5px;
        color: ${token('color.text')};

        .small-icon {
          pointer-events: none;
        }

        span {
          width: 177px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  // Overwrite default
  .clear-selected-button {
    display: none !important;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(102, 102, 102, 0.4);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const StyledSelectPlaceholder = styled.div`
  display: flex;
  align-items: center;
  gap: 0 ${token('space.050')};

  & > p {
    height: 20px;

    & > span {
      margin: 0;
    }
  }

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
