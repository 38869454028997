import {useQuery} from "../../useQuery";
import {fetchTimeOffBalanceAPI} from "./timeOffBalance";
import {useState} from "react";
import type {TimeOffBalanceProps} from "./timeOffBalance.types";
import {useEvent} from "hooks/useEvent";

export const useTimeOffBalance = (idProfile: string) => {
    const [getTimeOffBalance, isLoading, isSuccess, isError] = useQuery(fetchTimeOffBalanceAPI);
    const [timeOffBalance, setTimeOffBalance] = useState<Array<TimeOffBalanceProps>>();

    const fetchTimeOffBalance = useEvent(() => getTimeOffBalance(idProfile).then((response) => {
            setTimeOffBalance(response);
            return response;
        }));

    const reFetchTimeOffBalance = useEvent(() => fetchTimeOffBalanceAPI(idProfile).then((response) => {
            setTimeOffBalance(response);
            return response;
        }));

    return {
        fetchTimeOffBalance,
        reFetchTimeOffBalance,
        timeOffBalance,
        isLoading,
        isSuccess,
        isError
    }
};
