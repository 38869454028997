import React from 'react'
import { Label } from '@atlaskit/form'
import Textfield from '@atlaskit/textfield'
import TextArea from '@atlaskit/textarea'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import { token } from '@atlaskit/tokens'
import { Box } from '@atlaskit/primitives'
import {
  StyledWrapper,
  StyledLeftSide,
  StyledMessage,
  StyledTextAreaNumer,
  StyledErrorField,
  StyledPolicyDescript,
  StyledNotesText,
  StyledRightSide,
  StyledMessageError,
  StyledMessageErrorCustom,
  StyledSettingsPreview,
  StyledMessageEmpty,
} from '../StylesCreatePolicy'
import IconSelect from '../../../Select/IconSelect/IconSelect'
import { DetailsStepProps } from '../../../../../interfaces/timeOffPolicy/Steps/detailsStepProps.interface'
import { TypeIconsCollection } from '../../../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection'

const MAX_LENGTH_DESCRIPTION = 173
const MAX_LENGTH_NAME = 50

const StepDetails: React.FC<DetailsStepProps> = ({
  policyName,
  setPolicyName,
  policyNameError,
  setPolicyNameError,
  policyDescription,
  setPolicyDescription,
  selectedIcon,
  setSelectedIcon,
  policyIconError,
  setPolicyIconError,
  policyDescriptionError,
  setPolicyDescriptionError,
  nameValidate,
}) => {
  const handleChangeDiscription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    if (newValue.length <= MAX_LENGTH_DESCRIPTION) {
      setPolicyDescription(newValue)
    }
    setPolicyDescriptionError('')
  }

  const handleBlurName = () => {
    if (policyName !== '') {
      nameValidate()
    }
  }

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    if (newValue.length <= MAX_LENGTH_NAME) {
      setPolicyName(newValue)
    }
    setPolicyNameError('')
  }
  return (
    <StyledWrapper>
      <StyledLeftSide>
        <Box>
          <Label htmlFor="policyName">Name</Label>
          <Textfield
            name="policyName"
            placeholder="e. g. Vacation"
            value={policyName}
            onChange={handleChangeName}
            onBlur={handleBlurName}
          />
          <StyledErrorField>
            {policyNameError.length > 0 && (
              <StyledMessageError>
                <ErrorIcon size="small" primaryColor={token('color.icon.danger')} label="" />
                {policyNameError}
              </StyledMessageError>
            )}
            <StyledTextAreaNumer>
              {policyName.length} / {MAX_LENGTH_NAME}
            </StyledTextAreaNumer>
          </StyledErrorField>
        </Box>
        <Box>
          <IconSelect icon={selectedIcon} setIcon={setSelectedIcon} setPolicyIconError={setPolicyIconError} />
          {policyIconError.length > 0 && (
            <StyledMessageErrorCustom>
              <ErrorIcon size="small" primaryColor={token('color.icon.danger')} label="" />
              {policyIconError}
            </StyledMessageErrorCustom>
          )}
        </Box>
        <StyledPolicyDescript>
          <Label htmlFor="area">Policy description (optional)</Label>
          <StyledMessage>Additional notes, this field does not affect settings</StyledMessage>
          <StyledNotesText>
            <TextArea
              id="area"
              placeholder=""
              maxHeight="108px"
              name="area"
              rows={9}
              value={policyDescription}
              onChange={handleChangeDiscription}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          </StyledNotesText>
          <StyledErrorField>
            {policyDescriptionError.length > 0 && (
              <StyledMessageErrorCustom>
                <ErrorIcon size="small" primaryColor={token('color.icon.danger')} label="" />
                {policyDescriptionError}
              </StyledMessageErrorCustom>
            )}
            <StyledTextAreaNumer>
              {policyDescription.length} / {MAX_LENGTH_DESCRIPTION}
            </StyledTextAreaNumer>
          </StyledErrorField>
        </StyledPolicyDescript>
      </StyledLeftSide>
      <StyledRightSide>
        <h3>Policy settings preview</h3>
        {!policyName && !policyDescription && !selectedIcon && (
          <StyledMessageEmpty>The parameters you selected in the settings will be displayed here</StyledMessageEmpty>
        )}
        {policyName && (
          <StyledSettingsPreview>
            <h4>Name</h4>
            <span>{policyName}</span>
          </StyledSettingsPreview>
        )}
        {selectedIcon && (
          <StyledSettingsPreview>
            <h4>Icon</h4>
            {TypeIconsCollection[selectedIcon as keyof typeof TypeIconsCollection]('small-icon', 'withouthover')}
          </StyledSettingsPreview>
        )}
        {policyDescription && (
          <StyledSettingsPreview>
            <h4>Description</h4>
            <p>{policyDescription}</p>
          </StyledSettingsPreview>
        )}
      </StyledRightSide>
    </StyledWrapper>
  )
}

export default StepDetails
