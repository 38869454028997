import { Space } from 'antd'
import { useAppDispatch } from 'hooks'
import { openMGPModal } from 'store'
import { Button } from 'components'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { TableActionsProps } from './types'

export const TableAction = ({ record }: TableActionsProps) => {
  if (!record.editable) return null

  const dispatch = useAppDispatch()

  return (
    <Space size={8} align="center">
      <Button
        size="small"
        icon={<EditFilled />}
        onClick={() => dispatch(openMGPModal({ mode: 'edit', payload: record }))}
      />
      <Button
        size="small"
        icon={<DeleteFilled />}
        onClick={() => dispatch(openMGPModal({ mode: 'delete', payload: record }))}
      />
    </Space>
  )
}
