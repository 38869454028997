import dayjs from 'dayjs'
import { OrderEnum } from 'shared'
import { dateFormatDB } from 'utils/helpers/formatted-date'
import { BonusPenaltyTableFilters, BonusPenaltyTableState } from './types'

export const defaultBonusPenaltyTableState: BonusPenaltyTableState = {
  page: 1,
  limit: 10,
  sort: 'creationDate',
  order: OrderEnum.DESC,
}

export const defaultBonusPenaltyTableFilters: BonusPenaltyTableFilters = {
  effectiveDate: [dayjs().startOf('month').format(dateFormatDB), dayjs().endOf('month').format(dateFormatDB)],
}
