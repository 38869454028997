// TODO: fix eslint errors
/* eslint-disable */
import { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import style from '../../../Profile.module.scss'
import styleParent from '../../../Profile.module.scss'
import InlineEdit from '@atlaskit/inline-edit'
import { Input } from '../../../../UI/Input'
import { InputText } from '../../../ViewingModes/InputText'
import { BlockInfoItem } from '../../../BlockInfo'
import { useProfile } from '../../../context'
import { ContactTypeProps } from '../../../../../API/profile/contactInfo'
import { useSetField } from '../../../../../API/profile/setField/useSetField'
import { Button } from '../../../../UI/Button'
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add'
import { IconButton } from '@atlaskit/button/new'
import { Tooltip } from '../../../../UI/Tooltip'

import { ReactComponent as EditorRemoveIconNew } from '../../../../../assets/images/ProfileIcons/trash.svg'
import StarIcon from '@atlaskit/icon/glyph/star'
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled'

import { useContactInfo } from '../../../../../API/profile/fields/generalInfo/contactInfo'
import Spinner from '@atlaskit/spinner'
import * as yup from 'yup'
import { useEvent } from 'hooks/useEvent'

const emailSchema = yup.string().required("Email don't match").email('Email is invalid')

export const Email: FC = () => {
  const {
    contactInfo,
    isAccessEdit,
    isAccessEditRole,
    idOpenProfile,
    reFetchContactInfo,
    profile,
    setMainEmail,
    showErrorNotify,
  } = useProfile()

  const [mainErrorStateServ, setMainErrorStateServ] = useState<{
    clearError?: () => void | undefined
    error?: string | undefined
    valueBeforeError: string
  }>({
    clearError: undefined,
    error: undefined,
    valueBeforeError: '',
  })
  const [otherErrorStateServ, setOtherErrorStateServ] = useState<
    {
      clearError?: () => void | undefined
      error?: string | undefined
      valueBeforeError: string
    }[]
  >([])

  const [otherEmails, setOtherEmails] = useState<Array<ContactTypeProps>>([])

  const { setField } = useSetField(idOpenProfile, true)
  const { setField: setFavorite, isLoading: isFavoriteLoading } = useSetField(idOpenProfile, true)
  const { addContact, removeContact, isRemoveLoading } = useContactInfo(idOpenProfile)

  const addEmail = () => {
    const newEmails = [...otherEmails]
    const existingFields = newEmails.map((email) => parseInt(email.field))
    const missingFields = [1, 2, 3, 4].filter((field) => !existingFields.includes(field))

    const newField = missingFields[0]
    newEmails.push({ field: newField.toString(), value: '', isFavorite: '0' })
    setOtherEmails(newEmails)
  }

  useEffect(() => {
    const emails = contactInfo.find((element) => element.type === 'email')

    if (!emails) {
      setOtherEmails([])
      return
    }

    const emailsValues = [...emails.values]

    setOtherEmails(emailsValues)
  }, [contactInfo])

  return (
    <BlockInfoItem title="Email">
      <div>
        <div className={cn(style.inputWrapper, isAccessEdit && style.profileInputTextIsEditing)}>
          <InlineEdit
            {...(!isAccessEditRole && { isEditing: false })}
            {...(mainErrorStateServ.error && { isEditing: true })}
            isRequired
            defaultValue={mainErrorStateServ.valueBeforeError ? mainErrorStateServ.valueBeforeError : profile.email}
            editView={({ errorMessage, onChange, ...fieldProps }, ref) => (
              <Input
                {...fieldProps}
                onChange={(event) => {
                  onChange(event)
                }}
                ref={ref}
                autoFocus={!mainErrorStateServ.error}
                isInvalid={!!errorMessage || !!mainErrorStateServ.error}
                autoComplete="off"
                placeholder=""
                className={style.profileInput}
              />
            )}
            readView={() => <InputText value={profile.email} />}
            onConfirm={(valueInput: string) => {
              mainErrorStateServ.clearError && mainErrorStateServ.clearError()
              setMainErrorStateServ({
                error: undefined,
                valueBeforeError: '',
                clearError: undefined,
              })
              const value = valueInput

              if (!value || value === profile.email) {
                return
              }

              setField(['email', value].join('/'))
                .then(() => {
                  setMainEmail(value)
                })
                .catch((error) => {
                  setMainErrorStateServ({
                    clearError: showErrorNotify(String(error.message)),
                    error: String(error.message),
                    valueBeforeError: value,
                  })
                })
            }}
          />
        </div>
      </div>
      {otherEmails.length > 0 &&
        otherEmails.map(({ value, isFavorite, field }, index) => {
          const isFavoriteBoolean = Boolean(Number(isFavorite))

          return (
            <div className={style.otherPhones} key={field}>
              <div className={cn(style.inputWrapper, isAccessEdit && style.profileInputTextIsEditing)}>
                <InlineEdit
                  {...(!isAccessEdit && { isEditing: false })}
                  {...(otherErrorStateServ[Number(field)]?.error && { isEditing: true })}
                  defaultValue={
                    otherErrorStateServ[Number(field)]?.error
                      ? otherErrorStateServ[Number(field)]?.valueBeforeError
                      : value
                  }
                  editView={({ errorMessage, onChange, ...fieldProps }, ref) => (
                    <Input
                      {...fieldProps}
                      onChange={(event) => {
                        onChange(event)
                      }}
                      ref={ref}
                      isInvalid={!!errorMessage || !!otherErrorStateServ[Number(field)]?.error}
                      autoComplete="off"
                      autoFocus={!otherErrorStateServ[Number(field)]?.error}
                      placeholder=""
                      className={style.profileInput}
                    />
                  )}
                  readView={() => <InputText value={value} />}
                  onConfirm={(valueInput: string) => {
                    const newOtherErrorStateServ = [...otherErrorStateServ]
                    // @ts-ignore
                    newOtherErrorStateServ[Number(field)]?.clearError &&
                      // @ts-ignore
                      newOtherErrorStateServ[Number(field)].clearError()
                    newOtherErrorStateServ[Number(field)] = {
                      clearError: undefined,
                      error: undefined,
                      valueBeforeError: '',
                    }
                    setOtherErrorStateServ(newOtherErrorStateServ)

                    const value = valueInput

                    if (!value || value === '+' || value === otherEmails[index].value) {
                      return
                    }

                    if (!otherEmails[index].value) {
                      addContact(['contact-info/email', field, value || 'null'].join('/'))
                        .then(() => {
                          reFetchContactInfo()
                        })
                        .catch((error) => {
                          const newOtherErrorStateServ = [...otherErrorStateServ]
                          newOtherErrorStateServ[Number(field)] = {
                            clearError: showErrorNotify(String(error.message)),
                            error: String(error.message),
                            valueBeforeError: value,
                          }
                          setOtherErrorStateServ(newOtherErrorStateServ)
                        })
                    } else {
                      setField(['contact-info/email', field, value || 'null'].join('/'))
                        .then(() => {
                          reFetchContactInfo()
                        })
                        .catch((error) => {
                          const newOtherErrorStateServ = [...otherErrorStateServ]
                          newOtherErrorStateServ[Number(field)] = {
                            clearError: showErrorNotify(String(error.message)),
                            error: String(error.message),
                            valueBeforeError: value,
                          }
                          setOtherErrorStateServ(newOtherErrorStateServ)
                        })
                    }
                  }}
                />
              </div>
              {isAccessEdit && (
                <div className={style.contactBtnGroup}>
                  <div>
                    <Tooltip
                      position="bottom"
                      delay={0}
                      content={isFavoriteBoolean ? 'Hide in "Contact me"' : 'Show in "Contact me"'}
                    >
                      {(tooltipProps) => (
                        <div>
                          <IconButton
                            {...tooltipProps}
                            onClick={() => {
                              setFavorite(
                                ['contact-info/email', field, 'favorite', Number(!isFavoriteBoolean)].join('/'),
                              ).then(() => {
                                reFetchContactInfo()
                              })
                            }}
                            isSelected={isFavoriteBoolean}
                            appearance="subtle"
                            shape="default"
                            icon={isFavoriteBoolean ? StarFilledIcon : StarIcon}
                            label={isFavoriteBoolean ? 'Remove Favorite' : 'Add Favorite'}
                            overlay={isFavoriteLoading ? <Spinner size="medium" /> : null}
                          />
                        </div>
                      )}
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip position="bottom" delay={0} content="Delete">
                      {(tooltipProps) => (
                        <IconButton
                          {...tooltipProps}
                          onClick={() => {
                            const newOtherErrorStateServ = [...otherErrorStateServ]
                            // @ts-ignore
                            newOtherErrorStateServ[Number(field)]?.clearError &&
                              // @ts-ignore
                              newOtherErrorStateServ[Number(field)].clearError()
                            newOtherErrorStateServ[Number(field)] = {
                              clearError: undefined,
                              error: undefined,
                              valueBeforeError: '',
                            }
                            setOtherErrorStateServ(newOtherErrorStateServ)
                            removeContact(['contact-info/email', field].join('/')).then(() => {
                              reFetchContactInfo()
                            })
                          }}
                          appearance="subtle"
                          shape="default"
                          // @ts-ignore
                          icon={EditorRemoveIconNew}
                          overlay={isRemoveLoading ? <Spinner size="medium" /> : null}
                          label="Delete"
                        />
                      )}
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      {isAccessEdit && otherEmails.length < 4 && (
        <div className={style.addContactField}>
          <Button
            className={styleParent.btnAdd}
            iconBefore={<EditorAddIcon primaryColor="#758195" label="Add email" />}
            appearance="subtle"
            spacing="compact"
            onClick={addEmail}
          >
            Add email
          </Button>
        </div>
      )}
    </BlockInfoItem>
  )
}
