import {useQuery} from "../../useQuery";
import {fetchDirectManagerAPI} from "./directManager";
import {useState} from "react";
import type {DirectManagerProps} from "./directManager.types";
import {useEvent} from "hooks/useEvent";

export const useDirectManager = (idProfile: string) => {
    const [fetchDirectManagerAPIs, isLoading, isSuccess, isError] = useQuery(fetchDirectManagerAPI);
    const [directManager, setDirectManager] = useState<DirectManagerProps>();

    const fetchDirectManager = useEvent(async () => {
        try {
            const response = await fetchDirectManagerAPIs(idProfile);
            setDirectManager(response);
            return response;
        } catch (error) {
            throw new Error();
        }
    });

    return {
        fetchDirectManager,
        directManager,
        isLoading,
        isSuccess,
        isError
    }
}
