import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultEmployeeMGPHistoryTableFilters, defaultEmployeeMGPHistoryTableState } from './constants'
import { EmployeeMGPHistoryTableFilters, EmployeeMGPHistoryTableState } from './types'

export const employeeMgpHistoryTableFiltersSlice = createSlice({
  name: 'employeeMGPHistoryTableFilters',
  initialState: {
    tableState: defaultEmployeeMGPHistoryTableState,
    tableFilters: defaultEmployeeMGPHistoryTableFilters,
  },
  reducers: {
    setEmployeeMgpHistoryTableState: (state, action: PayloadAction<EmployeeMGPHistoryTableState>) => ({
      ...state,
      tableState: action.payload,
    }),
    setEmployeeMgpHistoryFilters: (state, action: PayloadAction<EmployeeMGPHistoryTableFilters>) => ({
      tableFilters: { ...state.tableFilters, ...action.payload },
      tableState: defaultEmployeeMGPHistoryTableState,
    }),
    resetEmployeeMgpHistoryTableState: (state) => ({
      ...state,
      tableState: defaultEmployeeMGPHistoryTableState,
    }),
    resetEmployeeMgpHistoryFilters: () => ({
      tableFilters: defaultEmployeeMGPHistoryTableFilters,
      tableState: defaultEmployeeMGPHistoryTableState,
    }),
  },
})

export const {
  setEmployeeMgpHistoryTableState,
  setEmployeeMgpHistoryFilters,
  resetEmployeeMgpHistoryTableState,
  resetEmployeeMgpHistoryFilters,
} = employeeMgpHistoryTableFiltersSlice.actions

export const employeeMgpHistoryTableFiltersReducer = employeeMgpHistoryTableFiltersSlice.reducer
