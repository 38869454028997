// TODO: fix eslint errors
/* eslint-disable */
import { useState } from 'react'
import { adminStatusAPI } from 'API/common/status'

interface UpdateStatusProps {
  id: string
  name: string
  isActive: boolean
  onSuccess: () => void
}

const useUpdateStatus = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const updateStatus = async ({ id, name, isActive, onSuccess }: UpdateStatusProps) => {
    try {
      const updateStatusResponse = await adminStatusAPI.updateStatus(id, name, isActive)
      if (!updateStatusResponse.ok) {
        throw new Error(`HTTP error! status: ${updateStatusResponse.status}`)
      }
      onSuccess()
    } catch (error) {
      setError((error as Error).message)
    } finally {
    }
  }

  return { updateStatus, error }
}

export default useUpdateStatus
