import { FILTERS } from "../../utils/constants/api";

function getEmployeesWithOrgUnit() {
  return fetch(`${FILTERS  }/attendance/org-units-by-employees`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  }).then((response) => response.json());
}

export default getEmployeesWithOrgUnit;
