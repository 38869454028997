import {useState} from "react";
import type {TimeOffPolicyProps} from "./timeOffPolicy.types";
import {useQuery} from "../../../../useQuery";
import {fetchTimeOffPolicyAPI} from "./timeOffPolicy";
import {useEvent} from "hooks/useEvent";

export const useTimeOffPolicy = () => {
    const [timeOffPolicy, setTimeOffPolicy] = useState<Array<TimeOffPolicyProps>>([]);
    const [getAllTimeOffPolicy, isLoading, isSuccess, isError] = useQuery(fetchTimeOffPolicyAPI);

    const fetchTimeOffPolicy = useEvent(async () => getAllTimeOffPolicy()
            .then((response) => {
                setTimeOffPolicy(response);
                return response;
            }));

    return {
        timeOffPolicy,
        fetchTimeOffPolicy,
        isLoading,
        isSuccess,
        isError
    }
};
