import {useState} from "react";
import {useQuery} from "../useQuery";
import {fetchTimeOffPolicyAPI} from "./timeOffPolicy";
import {useEvent} from "hooks/useEvent";
import {TimeOffPolicyResponseProps} from "./timeOffPolicy.types";

export const useTimeOffPolicy = (all = true, phrase = '') => {
    const [timeOffPolicy, setTimeOffPolicy] = useState<Array<TimeOffPolicyResponseProps>>([]);
    const [getAllTimeOffPolicy, isLoading, isSuccess, isError] = useQuery(() => fetchTimeOffPolicyAPI(all, phrase));

    const fetchTimeOffPolicy = useEvent(async () => getAllTimeOffPolicy()
            .then((response) => {
                setTimeOffPolicy(response);
                return response;
            }));

    return {
        timeOffPolicy,
        fetchTimeOffPolicy,
        isLoading,
        isSuccess,
        isError
    };
};
