// TODO: fix eslint errors
/* eslint-disable */
import React from 'react'
import '../index.scss'
import './Radio.scss'

type Radio = {
  label?: string
  required: boolean
  value: string
  name: string
  onChange: (value: any) => void
  radios: { label: any; value: string }[]
}

function Radio(props: Radio) {
  const id = Math.random()

  return (
    <div className="input horizontal-input">
      {props.label ? (
        <label>
          {props.label}
          {props.required ? <span>*</span> : ''}
        </label>
      ) : null}
      <div className="radios">
        {props.radios.map((radio) => (
          <div className="radio-container" key={id + radio.value}>
            <input
              type="radio"
              name={props.name}
              value={radio.value}
              onChange={() => props.onChange(radio.value)}
              defaultChecked={radio.value === props.value}
            />
            <span>{radio.label}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Radio
