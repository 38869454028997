import { ThemeAppearance } from '@atlaskit/lozenge'

export enum AnnouncementPriorityEnum {
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}
export enum AnnouncementGetListOrderByEnum {
  Priority = 'priority',
  StartDate = 'startDate',
}
export const announcementPriorityLabelMap: Record<AnnouncementPriorityEnum, string> = {
  [AnnouncementPriorityEnum.Critical]: 'Critical',
  [AnnouncementPriorityEnum.High]: 'High',
  [AnnouncementPriorityEnum.Medium]: 'Medium',
  [AnnouncementPriorityEnum.Low]: 'Low',
}
export const announcementPriorityColorMap: Record<AnnouncementPriorityEnum, ThemeAppearance> = {
  [AnnouncementPriorityEnum.Critical]: 'removed',
  [AnnouncementPriorityEnum.High]: 'moved',
  [AnnouncementPriorityEnum.Medium]: 'inprogress',
  [AnnouncementPriorityEnum.Low]: 'new',
}
