import React, { FC } from "react";
import style from "./Profile.module.scss";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import { LineSkeleton } from "../UI/LineSkeleton";
import cn from "classnames";
import { BlockInfo, BlockInfoSkeletonItem } from "./BlockInfo";
import { Skeleton } from "@atlaskit/avatar";

export const SkeletonProfile: FC = () => (
    <div className={style.profileWrapper}>
      <div className={style.profileLayout}>
        <div className={style.leftSideProfile}>
          <div className={style.leftSkeleton}>
            <div className={style.avatarSkeleton}>
              <Skeleton size="xxlarge" />
            </div>
            <LineSkeleton
              className={style.nameSkeleton}
              height="28px"
              width="80%"
            />
            <LineSkeleton
              className={style.nickSkeleton}
              height="20px"
              width="70%"
            />
            <LineSkeleton
              className={style.comSkeleton}
              height="20px"
              width="70%"
            />
            <LineSkeleton
              className={style.comSkeleton}
              height="24px"
              width="70%"
            />
          </div>
        </div>
        <div className={style.rightSideProfile}>
          <div>
            <Tabs id="profile_tabs">
              <div className={cn(style.tabList, style.tabListSkeleton)}>
                <TabList>
                  <Tab>
                    <div style={{ height: "26px" }}>
                      <LineSkeleton height="20px" width="150px" />
                    </div>
                  </Tab>
                  <Tab>
                    <div style={{ height: "26px" }}>
                      <LineSkeleton height="20px" width="150px" />
                    </div>
                  </Tab>
                </TabList>
              </div>
              <TabPanel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <BlockInfo title="">
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                  </BlockInfo>

                  <BlockInfo title="">
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                  </BlockInfo>
                </div>
              </TabPanel>
              <TabPanel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <BlockInfo title="">
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                  </BlockInfo>

                  <BlockInfo title="">
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                    <BlockInfoSkeletonItem />
                  </BlockInfo>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
