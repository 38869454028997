import { LocationType } from 'models'
import Tooltip from '@atlaskit/tooltip'
import { LocationsListStyles } from './styles'
import { maxVisibleLocationLength } from './constants'

export const LocationsList = ({ locations }: { locations: Array<LocationType> }) => (
  <LocationsListStyles>
    {locations.map(({ id, name }) => (
      <Tooltip content={name.length > maxVisibleLocationLength ? name : null} position="top" key={id}>
        <span className="tag">
          <span className="tag-body">{name}</span>
        </span>
      </Tooltip>
    ))}
  </LocationsListStyles>
)
