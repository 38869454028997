// TODO: fix eslint errors
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Modal from '../../../Layer/Modal/Modal'
import TextField, { Type } from 'components/old-ui/Input/TextField/TextField'
import Checkbox from 'components/old-ui/Input/Checkbox/Checkbox'
import SelectInput from 'components/old-ui/Input/SelectInput/SelectInput'
import { OrgUnitFromApi } from '../../../../../shared/OrgChart/data/OrgUnitFromApi'
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError'
import { EMPLOYEES } from '../../../../../utils/constants/api'
import { EmployeeFromApi } from '../../../../../shared/Employee/data/EmployeeFromApi'
import { SelectValueJSX } from '../../../../../shared/Common/data/SelectValueJSX'
import { Tooltip } from 'react-tooltip'

type BaseModal = {
  opened: boolean
  modalTitle: string
  orgUnitValue: {
    id: string
    name: string
    isCompany: boolean
  } | null
  orgUnits: OrgUnitFromApi[]
  parentOrgUnit: OrgUnit | null
  leaderOrgUnit: SelectValueJSX | null
  okText: string
  onCancel: () => any
  onOk: (
    name: string,
    parentId: string | null,
    leaderId: string | null,
    isCompany: boolean,
    validate: (errors: ValidationError[]) => void,
  ) => void
}

type OrgUnit = {
  label: string
  value: string
}

function BaseModal(props: BaseModal) {
  const [name, setName] = useState<string>(props.orgUnitValue ? props.orgUnitValue.name : '')
  const [nameError, setNameError] = useState<string | null>(null)
  const [isCompany, setIsCompany] = useState<boolean>(props.orgUnitValue ? props.orgUnitValue.isCompany : false)
  const [parentOrgUnit, setParentOrgUnit] = useState<OrgUnit | null>(props.parentOrgUnit)
  const [parentOrgUnitError, setParentOrgUnitError] = useState<string | null>(null)
  const [leaderOrgUnit, setLeaderOrgUnit] = useState<SelectValueJSX | null>(props.leaderOrgUnit)
  const [leaderOrgUnitError, setLeaderOrgUnitError] = useState<string | null>(null)
  const [leaderOptions, setLeaderOptions] = useState<SelectValueJSX[]>([])

  const childFilter = (orgUnits: any[], parentId?: any) => {
    if (!parentId) {
      return orgUnits.map(({ id, name }) => ({ label: name, value: id }))
    }

    const childIds = new Set<number>()
    const getChildIds = (parentId: any) => {
      orgUnits.forEach((orgUnit) => {
        if (orgUnit.parentId === parentId) {
          childIds.add(orgUnit.id)
          getChildIds(orgUnit.id)
        }
      })
    }
    getChildIds(parentId)

    const allowedOrgUnits = orgUnits.filter((orgUnit) => {
      if (orgUnit.id === parentId || orgUnit.parentId === parentId || childIds.has(orgUnit.id)) {
        return false
      }
      return true
    })

    return allowedOrgUnits.map(({ id, name }) => ({ label: name, value: id }))
  }

  const parentOrgUnitOptions = childFilter(props.orgUnits, props.orgUnitValue ? props.orgUnitValue.id : null)

  parentOrgUnitOptions.unshift({ label: 'not chosen', value: '' })

  function getLeaders() {
    return fetch(`${EMPLOYEES}/type/active`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((employees: EmployeeFromApi[]) => {
        let options = employees.map((employee) => ({
          value: employee.id,
          label: (
            <span data-tooltip-id={`tooltip-${employee.id}`}>
              {employee.firstNameEn} {employee.lastNameEn} {employee.nickname ? `(${employee.nickname})` : null}
              <Tooltip id={`tooltip-${employee.id}`} place="bottom" noArrow={false}>
                {employee.email}
              </Tooltip>
            </span>
          ),
        }))
        options = [{ label: <span>not chosen</span>, value: '' }].concat(options)
        setLeaderOptions(options)
      })
  }

  useEffect(() => {
    getLeaders()
    if (props.modalTitle === 'Create Org Unit') {
      resetAll()
    }
  }, [props.opened])

  function resetAll(): void {
    setName('')
    setNameError(null)
    setIsCompany(false)
    setParentOrgUnit(null)
    setParentOrgUnitError(null)
    setLeaderOrgUnit(null)
    setLeaderOrgUnitError(null)
  }

  function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
      if (error.property === 'orgUnit.name') {
        setNameError(error.message)
      } else if (error.property === 'orgUnit.leaderId') {
        setLeaderOrgUnitError(error.message)
      } else if (error.property === 'orgUnit.parentId') {
        setParentOrgUnitError(error.message)
      }
    })
  }

  return (
    <Modal
      opened={props.opened}
      modalTitle={props.modalTitle}
      cancelText="Cancel"
      okText={props.okText}
      onCancel={() => {
        props.onCancel()
        resetAll()
      }}
      onOk={() => {
        props.onOk(
          name,
          parentOrgUnit ? parentOrgUnit.value : null,
          leaderOrgUnit ? leaderOrgUnit.value : null,
          isCompany,
          validate,
        )
      }}
    >
      <TextField
        label="Org Unit name"
        placeholder="Org Unit name..."
        required
        value={name}
        type={Type.Textarea}
        horizontal
        error={nameError}
        onChange={(newName) => {
          setName(newName)
          setNameError(null)
        }}
        maxLength={50}
      />
      <SelectInput
        label="Parent Org Unit"
        required={false}
        isMulti={false}
        value={parentOrgUnit}
        options={parentOrgUnitOptions}
        error={parentOrgUnitError}
        onChange={(newParentOrgUnit) => {
          setParentOrgUnit(newParentOrgUnit)
          setParentOrgUnitError(null)
        }}
        isSearchable
      />
      <Checkbox
        disabled={false}
        label="Company"
        required={null}
        checked={isCompany}
        checkBoxLabel="Mark as a company"
        onChange={(newIsCompany) => setIsCompany(newIsCompany)}
        switch
      />
      <SelectInput
        label="Leader"
        required={false}
        isMulti={false}
        value={leaderOrgUnit}
        options={leaderOptions}
        error={leaderOrgUnitError}
        onChange={(newLeader) => {
          setLeaderOrgUnit(newLeader)
          setLeaderOrgUnitError(null)
        }}
        isSearchable
      />
    </Modal>
  )
}

export default BaseModal
