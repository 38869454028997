import { token } from '@atlaskit/tokens'
import styled from 'styled-components'

export const TimeOffLogModalStyles = styled.div`
  form {
    display: grid;
  }

  .ant-form-item {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .ant-input-number {
    width: 100%;

    &-input {
      padding-left: 60px;
    }
  }

  .ant-radio-group {
    position: absolute;
    inset: 4px auto auto 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 4px;
    height: 24px;
    z-index: 5;

    .ant-radio-button {
      & + span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 24px;
      }

      &-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        padding: 0;
        border-radius: 3px;
        border-inline-start-width: 1px;
        background-color: rgba(9, 30, 66, 0.06);
        color: #44546f;
        border-color: transparent;

        &.is-error {
          border-color: red;
        }

        &-checked {
          background-color: #e9f2ff;
          color: #0c66e4;
        }

        &::before {
          content: none;
        }

        .radio-icon {
          font-size: 10px;
        }
      }
    }
  }

  .amount-item {
    position: absolute;
    inset: 30px auto auto 0;
    width: 60px;

    &-wrap {
      position: relative;
    }
  }

  textarea {
    resize: none;
  }

  .modal-footer {
    padding: 12px 0 0;

    .ant-form-item-control-input-content {
      display: flex;
      gap: 0 8px;
    }
  }
`

export const PolicyItemStyles = styled.div`
  display: flex;
  align-items: center;
  gap: 0 ${token('space.100')};

  & p {
    width: 286px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    color: ${token('color.text')};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  & > span {
    flex-shrink: 0;
    margin-left: auto;
    color: ${token('color.text.subtlest')};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`
