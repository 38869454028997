import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../constants'
import {
  CreateEmployeeRateHistoryPayload,
  DeleteEmployeeRateHistoryPayload,
  EmployeeRateHistoryData,
  EmployeeRateHistoryQuery,
  UpdateEmployeeRateHistoryPayload,
} from './types'

export const employeeRateHistoryApi = createApi({
  reducerPath: 'employeeRateHistoryApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/employees` }),
  tagTypes: ['employeeRateHistory'],
  endpoints: (build) => ({
    getEmployeeRateHistoryData: build.query<EmployeeRateHistoryData, EmployeeRateHistoryQuery>({
      query: ({ employeeId, ...params }: EmployeeRateHistoryQuery) => ({
        url: `/${employeeId}/rates`,
        params,
      }),
      providesTags: [{ type: 'employeeRateHistory', id: 'LIST' }],
    }),
    createEmployeeRateHistory: build.mutation<string, CreateEmployeeRateHistoryPayload>({
      query: ({ employeeId, ...body }) => ({
        url: `/${employeeId}/rates`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'employeeRateHistory', id: 'LIST' }]
        }
        return []
      },
    }),
    updateEmployeeRateHistory: build.mutation<string, UpdateEmployeeRateHistoryPayload>({
      query: ({ id, employeeId, ...body }) => ({
        url: `/${employeeId}/rates/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'employeeRateHistory', id: 'LIST' }]
        }
        return []
      },
    }),
    deleteEmployeeRateHistory: build.mutation<string, DeleteEmployeeRateHistoryPayload>({
      query: ({ employeeId, id }) => ({
        url: `/${employeeId}/rates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'employeeRateHistory', id: 'LIST' }]
        }
        return []
      },
    }),
  }),
})

export const {
  useGetEmployeeRateHistoryDataQuery,
  useCreateEmployeeRateHistoryMutation,
  useUpdateEmployeeRateHistoryMutation,
  useDeleteEmployeeRateHistoryMutation,
} = employeeRateHistoryApi
