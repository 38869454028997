import { Flex, Skeleton, Space, Col, Row } from 'antd'
import { SkeletonTableProps } from './types'
import { SkeletonTableStyles } from './styles'

export const SkeletonTable = ({
  isSubtitle,
  isCalendar,
  isHeaderButton = true,
  isTableOnly = false,
  filtersCount,
  colCount,
  rowCount,
}: SkeletonTableProps) => {
  const itemsRowRender = (itemsCount: number, className: string, isTableRow: boolean) =>
    new Array(itemsCount).fill(0).map((_, index) =>
      isTableRow ? (
        <Col key={`col-${index}`} flex={1} className={className}>
          <Skeleton.Node active className="skeleton-table-node" />
        </Col>
      ) : (
        <Skeleton.Node key={`filter-${index}`} active className={className} />
      ),
    )

  return (
    <SkeletonTableStyles>
      {!isTableOnly && (
        <Flex vertical gap={16} className="skeleton-top">
          <Space>
            <Skeleton.Node active className="skeleton-top-title" />
          </Space>

          {isSubtitle && (
            <Space>
              <Skeleton.Node active className="skeleton-top-title" />
            </Space>
          )}

          {isCalendar && (
            <Space>
              <Skeleton.Node active className="skeleton-top-calendar" />
            </Space>
          )}

          <Flex gap={16}>
            {filtersCount && itemsRowRender(filtersCount, 'skeleton-top-filter', false)}
            {isHeaderButton && <Skeleton.Node active className="skeleton-top-button" />}
          </Flex>
        </Flex>
      )}

      <div className="skeleton-table">
        <div className="skeleton-table-grid">
          <Row className="skeleton-table-row">{itemsRowRender(colCount, 'skeleton-table-header-cell', true)}</Row>
          {new Array(rowCount).fill(0).map((_, index) => (
            <Row key={`row-${index}`} className="skeleton-table-row">
              {itemsRowRender(colCount, 'skeleton-table-cell', true)}
            </Row>
          ))}
        </div>
      </div>
    </SkeletonTableStyles>
  )
}
