import { notification } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useEffect } from 'react'
import { resetNotifications, TriggerNotificationDataType } from 'store'
import { NotificationInstanceType } from './types'
import { coreHRNotificationTypeMapping } from './constants'

export const NotificationsHandler = () => {
  const dispatch = useAppDispatch()
  const notificationsHandlers = useAppSelector((state) => state.notificationsHandler)
  const [api, contextHolder] = notification.useNotification()

  useEffect(() => {
    if (notificationsHandlers.length) {
      notificationsHandlers.forEach(({ type, ...rest }: TriggerNotificationDataType) => {
        const mappedType: NotificationInstanceType = coreHRNotificationTypeMapping[type]

        api[mappedType]({
          ...rest,
          className: 'core-hr-notification',
          placement: 'bottomLeft',
          duration: 10,
        })
      })

      dispatch(resetNotifications())
    }
  }, [notificationsHandlers])

  return <>{contextHolder}</>
}
