import { Form, Modal, Space } from 'antd'
import { useEffect, useMemo } from 'react'
import { Button, FormInputAmount, FormDatepicker, FormSelect } from 'components'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from 'hooks'
import { useProfile } from '../../../../../../context'
import {
  useGetSystemCurrencyQuery,
  useCreateEmployeeRateHistoryMutation,
  useUpdateEmployeeRateHistoryMutation,
  useDeleteEmployeeRateHistoryMutation,
  CreateEmployeeRateHistoryPayload,
  UpdateEmployeeRateHistoryPayload,
  employeeCompensationApi,
} from 'services/api'
import { closeRateModal, triggerNotification } from 'store'
import { mergeDataBySourceFields } from 'utils/helpers/merge-data-by-source-fields'
import { DeleteInfo } from './components'
import { schema } from './schema'
import { RateFormModalTitle, RateFormStyles } from './styles'
import { RateFormModalProps, RateFormValues } from './types'
import {
  defaultValues,
  rateModalTitleMap,
  RateModalTypeEnum,
  rateModalNotificationMap,
  rateModalBtnMap,
} from './constants'

export const RateFormModal = ({ mode, payload }: RateFormModalProps) => {
  const dispatch = useAppDispatch()

  const { profile } = useProfile()

  const { data: currencyList, isLoading: isLoadingSystemCurrencies } = useGetSystemCurrencyQuery(undefined, {
    skip: mode === RateModalTypeEnum.Delete,
  })

  const [createRate, { isLoading: isCreatingRate }] = useCreateEmployeeRateHistoryMutation()
  const [updateRate, { isLoading: isUpdatingRate }] = useUpdateEmployeeRateHistoryMutation()
  const [deleteRate, { isLoading: isDeletingRate }] = useDeleteEmployeeRateHistoryMutation()
  const isLoading = isCreatingRate || isUpdatingRate || isDeletingRate

  const { handleSubmit, control, reset } = useForm<RateFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const currencySelectOptions = useMemo(() => {
    if (!currencyList) return []

    return currencyList.map(({ currencyId, code, name }) => ({
      label: `${code} - ${name}`,
      value: currencyId,
    }))
  }, [currencyList])

  const handleClose = () => {
    reset()
    dispatch(closeRateModal())
  }

  const onSubmit: SubmitHandler<RateFormValues> = async (data) => {
    try {
      // TODO - Fix the type of data
      if (mode === RateModalTypeEnum.Create) {
        await createRate({ ...data, employeeId: profile.id } as CreateEmployeeRateHistoryPayload).unwrap()
      }

      if (mode === RateModalTypeEnum.Edit && payload) {
        await updateRate({
          ...data,
          id: payload.id,
          employeeId: profile.id,
        } as UpdateEmployeeRateHistoryPayload).unwrap()
      }

      dispatch(employeeCompensationApi.util.invalidateTags(['employeeCompensation']))
      dispatch(triggerNotification({ type: 'success', message: rateModalNotificationMap[mode] }))
      handleClose()
    } catch (error) {
      console.error('Error creating incentive', error)
    }
  }

  const handleDelete = async () => {
    if (payload) {
      try {
        await deleteRate({ id: payload.id, employeeId: profile.id }).unwrap()

        dispatch(employeeCompensationApi.util.invalidateTags(['employeeCompensation']))
        dispatch(triggerNotification({ type: 'success', message: rateModalNotificationMap[mode] }))
        handleClose()
      } catch (error) {
        console.error('Error creating incentive', error)
      }
    } else {
      console.error({ payload })
    }
  }

  useEffect(() => {
    if (mode === RateModalTypeEnum.Edit && payload) {
      const formResetData = mergeDataBySourceFields(defaultValues, {
        ...payload,
        currencyId: payload.currency.id,
      })
      reset(formResetData)
    }
  }, [])

  return (
    <Modal
      title={<RateFormModalTitle>{rateModalTitleMap[mode]}</RateFormModalTitle>}
      open
      footer={null}
      onCancel={handleClose}
      destroyOnClose
      width={400}
      maskClosable={false}
    >
      <RateFormStyles>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          {mode === RateModalTypeEnum.Delete && payload ? (
            <DeleteInfo
              rate={payload.rate}
              startDate={payload.startDate}
              currency={payload.currency}
              employeeFullName={`${profile.firstNameEn} ${profile.lastNameEn} ${profile.nickname ? `(${profile.nickname})` : ''}`}
            />
          ) : (
            <>
              <FormSelect<RateFormValues>
                showSearch
                control={control}
                name="currencyId"
                loading={isLoadingSystemCurrencies}
                label="Currency"
                placeholder="Select currency"
                optionFilterProp="label"
                options={currencySelectOptions}
              />
              <FormInputAmount<RateFormValues> control={control} label="Rate" name="rate" />
              <FormDatepicker<RateFormValues>
                control={control}
                name="startDate"
                placeholder="Select date"
                label="Start date"
              />
            </>
          )}
          <Space className="modal-footer" size={8}>
            <Button type="default" onClick={handleClose}>
              Close
            </Button>
            <Button
              type="primary"
              danger={mode === RateModalTypeEnum.Delete}
              htmlType={mode === RateModalTypeEnum.Delete ? 'button' : 'submit'}
              loading={isLoading}
              onClick={mode === RateModalTypeEnum.Delete ? handleDelete : undefined}
            >
              {rateModalBtnMap[mode]}
            </Button>
          </Space>
        </Form>
      </RateFormStyles>
    </Modal>
  )
}
