import { PresetStatusColorType } from 'antd/es/_util/colors'
import { PresetColorKey } from 'antd/es/theme/interface'

export enum EmployeeRequestStatusEnum {
  New = 'new',
  Canceled = 'canceled',
  Approved = 'approved',
  Rejected = 'rejected',
}
export const employeeRequestStatusLabelMap: Record<EmployeeRequestStatusEnum, string> = {
  [EmployeeRequestStatusEnum.New]: 'New',
  [EmployeeRequestStatusEnum.Canceled]: 'Canceled',
  [EmployeeRequestStatusEnum.Approved]: 'Approved',
  [EmployeeRequestStatusEnum.Rejected]: 'Rejected',
}
export const employeeRequestStatusColorMap: Record<EmployeeRequestStatusEnum, PresetColorKey | PresetStatusColorType> =
  {
    [EmployeeRequestStatusEnum.New]: 'blue',
    [EmployeeRequestStatusEnum.Canceled]: 'default',
    [EmployeeRequestStatusEnum.Approved]: 'green',
    [EmployeeRequestStatusEnum.Rejected]: 'red',
  }
