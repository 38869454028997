import { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import { captureException } from '@sentry/react'
import { SentryReportingError } from './components'

export const SentryReporting = () => {
  const routeError = useRouteError()

  useEffect(() => {
    captureException(routeError, { level: 'fatal' })
  }, [routeError])

  return <SentryReportingError />
}
