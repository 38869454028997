import { object, string, ObjectSchema, number, mixed } from 'yup'
import { BonusPenaltyFormValues } from './types'
import { BonusPenaltyEnum } from 'shared/constants/employee-bonus-penalty'

export const schema: ObjectSchema<BonusPenaltyFormValues> = object({
  employeeId: string().required('Please select employee'),
  type: mixed<BonusPenaltyEnum>().oneOf(Object.values(BonusPenaltyEnum)).required('Please choose incentive'),
  currencyId: string().required('Please choose currency'),
  amount: number()
    .min(0.01, 'Min amount is 0.01')
    .max(9999999.99, 'Max amount is 9,999,999.99')
    .required('Please enter an amount'),
  effectiveDate: string().required('Please select effective date'),
}).required()
