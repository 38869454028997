import styled from "styled-components";
import { token } from "@atlaskit/tokens";

// prettier-ignore
export const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${token("space.300")} ${token("space.400")} ${token("space.600")} ${token("space.300")};

  @media (min-width: 1920px) {
    padding: ${token("space.300")} ${token("space.800")} ${token("space.600")};
  }

  @media (max-width: 1280px) {
    padding: ${token("space.300")} ${token("space.400")} ${token("space.600")} ${token("space.200")};
  }

  @media (max-width: 1024px) {
    width: 100vw;
    padding: ${token("space.300")} ${token("space.400")} ${token("space.600")};
  }

  @media (max-width: 768px) {
    padding: ${token("space.300")} ${token("space.200")} ${token("space.600")};
  }

  @media (max-width: 480px) {
    width: 100vw;
  }

  h3 {
    color: ${token("color.text")};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 24px;
  }
`;

export const StyledHeadline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  //search bar
  & > [role="presentation"] {
    display: flex;
    align-items: center;
    color: ${token("color.text.subtlest")};
    border-radius: 3px;
    border: 2px solid rgba(9, 30, 66, 0.14);
    background: ${token("color.background.input")};
    padding: 0 ${token("space.100")};
    flex-shrink: 0;
    max-width: 240px;
    height: 32px;

    [role="img"] {
      width: 20px;
      height: 20px;
    }

    input {
      height: 100%;
      padding: 0 0 0 ${token("space.100")};
      line-height: 28px;
    }

    &:focus-within:not([data-disabled]) {
      border: 2px solid ${token("color.border.focused")};
      box-shadow: none;
    }
  }

  button {
    &:first-child {
      min-width: 152px;
      gap: 4px;
      padding: 0 12px;

      span {
        margin: 0;
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
      border-radius: 3px;
      padding: 0 12px 0 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      gap: 4px;
      border: 1px solid ${token("color.border")};
      background: ${token("color.background.accent.blue.subtlest")};
      min-width: 125px;

      span {
        color: ${token("color.text")};
        margin: 0;

        span {
          color: ${token("color.icon")};
          margin: 0;
        }
      }

      &:hover {
        background: ${token("color.background.accent.blue.subtlest.hovered")};
      }
    }
  }
`;

export const StyledDynamicTable = styled.div`
  /* max-width: 1144px; */
  margin-top: 15px;

  table {
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;

    img {
      display: flex;
      align-items: center;
    }
    tr {
      cursor: auto;
    }
  }

  table th {
    padding: 0 8px !important;
    height: 30px;
    vertical-align: middle;
  }

  table td {
    padding: 0 8px !important;
    height: 40px;
    vertical-align: middle;
    color: ${token("color.text.subtlest")};
  }

  table th:nth-child(5),
  table td:nth-child(5) {
    width: 105px !important;
  }

  table td:nth-child(5) {
    padding: 0 2px !important;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 110px !important;
  }
  table th:nth-child(7),
  table td:nth-child(7) {
    width: 80px !important;
  }

  table th:nth-child(1),
  table th:nth-child(2),
  table th:nth-child(3),
  table th:nth-child(4),
  table td:nth-child(1),
  table td:nth-child(2),
  table td:nth-child(3),
  table td:nth-child(4) {
    width: 212.8px !important;
  }
`;

export const StyledNoData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 72px;

  h2 {
    color: ${token("color.text")};
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin: 0 0 16px;
  }

  button {
    display: flex;
    align-items: center;
    border-radius: 3px;
    border: 1px solid ${token("color.border")};
    background: ${token("color.background.accent.blue.subtlest")};
    min-width: 138px;

    span {
      color: ${token("color.text")};

      span {
        color: ${token("color.icon")};
      }
    }

    &:hover {
      background: ${token("color.background.accent.blue.subtlest.hovered")};
    }
  }
`;

export const StyledPopupText = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  padding: ${token("space.200")};
  color: ${token("color.text")};
  width: 277px;
  white-space: pre-wrap;
`;

export const StyledPopupTextPolicy = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  padding: ${token("space.200")};
  color: ${token("color.text")};
  width: 338px;
`;

export const StyledIcons = styled.div`
  span {
    color: ${token("color.icon.accent.gray")};
  }

  button {
    &:hover,
    &:focus,
    &:active {
      background: ${token("color.background.selected")};

      span {
        color: ${token("color.icon.selected")};
      }
    }
  }
`;
