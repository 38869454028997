// TODO: fix eslint errors
/* eslint-disable */
import React, { useState, useEffect, FC } from 'react'
import './Levels.scss'
import CreateLevel from './Levels/Modal/CreateLevel'
import DeleteLevel from './Levels/Modal/DeleteLevel'
import EditLevel from './Levels/Modal/EditLevel'
import { LEVELS } from '../../../../../utils/constants/api'
import Button, { Size, Type } from 'components/old-ui/Button/Button'
import { LevelFromApi } from '../../../../../shared/Employee/Level/data/LevelFromApi'

export const Levels: FC = () => {
  const [createLevelModal, setCreateLevelModal] = useState<boolean>(false)
  const [editLevelModal, setEditLevelModal] = useState<boolean>(false)
  const [deleteLevelModal, setDeleteLevelModal] = useState<boolean>(false)

  const [levels, setLevels] = useState<LevelFromApi[]>([])
  const [levelToChange, setLevelToChange] = useState<LevelFromApi | null>(null)
  const [levelToDelete, deleteLevel] = useState<LevelFromApi | null>(null)

  useEffect(() => {
    getLevels()
  }, [])

  function getLevels() {
    fetch(LEVELS, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((levels: LevelFromApi[]) => setLevels(levels))
  }

  function editLevelModalVisibility(level: LevelFromApi): void {
    setLevelToChange(level)
    setEditLevelModal(true)
  }

  function confirmDeleteLevelModalVisibility(level: LevelFromApi): void {
    deleteLevel(level)
    setDeleteLevelModal(true)
  }

  return (
    <div className="admin-panel-board">
      <div className="sub-content-block d-flex">
        <div className="sub-content-header d-flex">
          <div className="sub-title-block d-flex">
            <h3>Job levels</h3>
          </div>

          <div className="add-button-block">
            <Button
              text="<i className='material-icons add-item'>add</i> Add level"
              size={Size.Medium}
              type={Type.Accent}
              onClick={() => setCreateLevelModal(true)}
            />
          </div>
        </div>
        <div className="sub-content-main">
          <table className="levels-table">
            <thead>
              <tr>
                <th>Job level</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {levels.map((level) => (
                <tr key={level.id}>
                  <td title={level.name}>{level.name}</td>
                  <td>
                    <i className="material-icons" onClick={() => editLevelModalVisibility(level)}>
                      edit
                    </i>
                    <i className="material-icons" onClick={() => confirmDeleteLevelModalVisibility(level)}>
                      delete
                    </i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <CreateLevel
        opened={createLevelModal}
        onOk={() => {
          getLevels()
          setCreateLevelModal(false)
        }}
        onClose={() => setCreateLevelModal(false)}
      />
      {levelToDelete ? (
        <DeleteLevel
          opened={deleteLevelModal}
          onOk={() => {
            getLevels()
            setDeleteLevelModal(false)
            deleteLevel(null)
          }}
          onClose={() => {
            setDeleteLevelModal(false)
            deleteLevel(null)
          }}
          level={levelToDelete}
        />
      ) : null}
      {levelToChange ? (
        <EditLevel
          opened={editLevelModal}
          onOk={() => {
            getLevels()
            setEditLevelModal(false)
            setLevelToChange(null)
          }}
          onClose={() => {
            setEditLevelModal(false)
            setLevelToChange(null)
          }}
          level={levelToChange}
        />
      ) : null}
    </div>
  )
}
