// TODO: fix eslint error
/* eslint-disable */
import { EMPLOYEES } from '../../../../constants'

export const addContact = async (id: string, data: string) => {
  try {
    const response = await fetch(`${EMPLOYEES}/employee/${id}/${data}`, {
      method: 'POST',
    })

    if (!response.ok || response.status !== 200) {
      const error = await response.json()
      throw new Error(error[0].message)
    }
  } catch (error: any) {
    console.error(error)
    // @ts-ignore
    return {
      error: error.message,
    }
  }
}

export const removeContact = async (id: string, data: string) => {
  try {
    const response = await fetch(`${EMPLOYEES}/employee/${id}/${data}`, {
      method: 'DELETE',
    })

    if (response.status === 422) {
      throw new Error(await response.json())
    }

    return response
  } catch (error) {
    console.error(error)
    throw new Error()
  }
}
