import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants'
import {
  PoliciesDatatype,
  PoliciesPayloadType,
  PoliciesQueryType,
  TimeOffLogDatatype,
  TimeOffLogQueryType,
} from './types'

export const timeOffLogAPI = createApi({
  reducerPath: 'time-off-log-api',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}` }),
  tagTypes: ['time-off-log'],
  endpoints: (build) => ({
    getTimeOffLog: build.query<TimeOffLogDatatype, TimeOffLogQueryType>({
      query: (params: TimeOffLogQueryType) => ({
        url: `/employee/${params.employeeId}/time-off-logs`,
        params,
      }),
      providesTags: [{ type: 'time-off-log', id: 'LIST' }],
    }),
    getPolicies: build.query<PoliciesDatatype, PoliciesQueryType>({
      query: (params: PoliciesQueryType) => ({
        url: `/employees/${params.employeeId}/policies`,
        params,
      }),
      providesTags: [{ type: 'time-off-log', id: 'LIST' }],
    }),
    updatePolicyBalance: build.mutation<string, PoliciesPayloadType>({
      query: (body) => ({
        url: `/employee/${body.employeeId}/time-off-accrued-days`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'time-off-log', id: 'LIST' }]
        }
        return []
      },
    }),
  }),
})

export const { useGetTimeOffLogQuery, useGetPoliciesQuery, useUpdatePolicyBalanceMutation } = timeOffLogAPI
