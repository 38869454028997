// TODO: fix eslint errors
/* eslint-disable */
import React from 'react'
import BaseModal from './BaseModal'
import { JOB_TITLES_CATEGORIES } from '../../../../../../utils/constants/api'
import { JobTitlesCategoryFromApi } from '../../../../../../shared/JobTitle/data/JobTitlesCategoryFromApi'
import { VALIDATION_ERROR } from '../../../../../../utils/constants/errors'
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError'

type EditCategory = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  category: JobTitlesCategoryFromApi
  categories: JobTitlesCategoryFromApi[]
}

function EditCategory(props: EditCategory) {
  function edit(name: string, validate: (errors: ValidationError[]) => void): void {
    fetch(`${JOB_TITLES_CATEGORIES}/${props.category.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        name,
      }),
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors))
      }
    })
  }

  return (
    <BaseModal
      opened={props.opened}
      modalTitle="Edit Category"
      name={props.category.name}
      okText="Edit"
      onCancel={props.onClose}
      onOk={edit}
      categories={props.categories}
    />
  )
}

export default EditCategory
