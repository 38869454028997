import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultBonusPenaltyTableState, defaultBonusPenaltyTableFilters } from './constants'
import { BonusPenaltyTableFilters, BonusPenaltyTableState } from './types'

export const bonusPenaltyTableSlice = createSlice({
  name: 'bonusPenaltyTable',
  initialState: {
    tableState: defaultBonusPenaltyTableState,
    tableFilters: defaultBonusPenaltyTableFilters,
  },
  reducers: {
    setBonusPenaltyTableState: (state, action: PayloadAction<BonusPenaltyTableState>) => ({
      ...state,
      tableState: action.payload,
    }),
    setBonusPenaltyFilters: (state, action: PayloadAction<BonusPenaltyTableFilters>) => ({
      tableFilters: { ...state.tableFilters, ...action.payload },
      tableState: defaultBonusPenaltyTableState,
    }),
  },
})

export const { setBonusPenaltyTableState, setBonusPenaltyFilters } = bonusPenaltyTableSlice.actions
export const bonusPenaltyTableReducer = bonusPenaltyTableSlice.reducer
