export const getAbbr = (name: string | null | void): string => {
  if (!name) {
    return ''
  }
  return name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .toUpperCase()
}
