import { useMemo, useState } from 'react'
import MultiSelectCustom from 'components/UI/MultiSelectCustom/MultiSelectCustom'
import { type ValueType as Value } from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { useGetLocationsQuery } from 'services/api'
import { useDebounce, DebouncedCallbackType } from 'hooks'
import { announcementPrioritySelectOptions } from '../constants'
import { IPriorityOption, Option } from '../types'
import { SearchIcon } from './components'
import { TableFiltersStyles } from './styles'
import { defaultTableFilters } from './constants'
import { IAnnouncementsTableFiltersProps, AnnouncementsTableFiltersType } from './types'

export const AnnouncementsTableFilters = (props: IAnnouncementsTableFiltersProps) => {
  const { onUpdateTableFilters } = props

  const [tableFilters, setTableFilters] = useState<AnnouncementsTableFiltersType>(defaultTableFilters)

  const { data: locationsList, isLoading: isLoadingLocations } = useGetLocationsQuery()

  const onSearch: DebouncedCallbackType<string> = useDebounce<string>((value: string) => {
    const values = {
      ...tableFilters,
      search: value,
    }

    setTableFilters(values)
    onUpdateTableFilters(values)
  })

  const onChangePriority = (priority: Array<IPriorityOption>) => {
    const values = {
      ...tableFilters,
      priority,
    }

    setTableFilters(values)
    onUpdateTableFilters(values)
  }

  const onChangeLocation = (locations: Array<Option>) => {
    const values = {
      ...tableFilters,
      locations,
    }

    setTableFilters(values)
    onUpdateTableFilters(values)
  }

  const locationSelectOptions: Value<Option[]> = useMemo(() => {
    if (!locationsList) return []

    return locationsList.map(({ name: label, id: value }) => ({
      label,
      value,
    }))
  }, [locationsList])

  return (
    <TableFiltersStyles>
      <div className="filter-body">
        <div className="text-field">
          <Textfield
            className="field"
            isCompact
            placeholder="Search"
            elemBeforeInput={<SearchIcon />}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onSearch(e.target.value)
            }}
          />
        </div>
        <MultiSelectCustom
          selectWidth={160}
          hasSelectAll={false}
          options={announcementPrioritySelectOptions}
          onChange={onChangePriority}
          value={tableFilters.priority}
          placeholder="Priority"
          labelledBy="Priority select"
          hideSearch
        />
        <MultiSelectCustom
          selectWidth={160}
          hasSelectAll={false}
          options={locationSelectOptions}
          onChange={onChangeLocation}
          value={tableFilters.locations}
          placeholder="Location"
          labelledBy="Location select"
          isLoading={isLoadingLocations}
        />
      </div>
    </TableFiltersStyles>
  )
}
