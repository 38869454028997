import type {WorkLocationProps} from './workLocation.types';
import {useState} from "react";
import {useQuery} from "../../../../useQuery";
import {fetchWorkLocationAPI} from "./workLocation";
import {useEvent} from "hooks/useEvent";

export const useWorkLocation = () => {
    const [workLocations, setWorkLocations] = useState<Array<WorkLocationProps>>([]);
    const [getAllWorkLocations, isLoading, isSuccess, isError] = useQuery(fetchWorkLocationAPI);

    const fetchWorkLocations = useEvent(async () => getAllWorkLocations()
            .then((response) => {
                setWorkLocations(response);
                return response;
            }));

    return {
        workLocations,
        fetchWorkLocations,
        isLoading,
        isSuccess,
        isError
    };
};
