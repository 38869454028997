// TODO: fix eslint errors
/* eslint-disable */
import { type StatusesType } from './checkInOut.types'
import { useEffect, useState } from 'react'
import { useEvent } from 'hooks/useEvent'
import { checkStatus as checkStatusAPI } from './checkStatus'
import { checkIn as checkInAPI } from './checkIn'
import { pause as pauseAPI } from './pause'
import { unpause as unpauseAPI } from './unpause'
import { checkOut as checkOutAPI } from './checkOut'

export const useCheckInOut = (employeeId: string) => {
  const [status, setStatus] = useState<StatusesType>('')
  const [isInitLoading, setInitLoading] = useState(true)
  const [isInitError, setInitError] = useState(false)
  const [isSyncLoading, setSyncLoading] = useState(false)

  const [isCheckInLoading, setCheckInLoading] = useState(false)

  const [isPauseLoading, setPauseLoading] = useState(false)

  const [isUnpauseLoading, setUnpauseLoading] = useState(false)

  const [isCheckOutLoading, setCheckOutLoading] = useState(false)

  const isSwitchStatusLoading = isCheckInLoading || isPauseLoading || isUnpauseLoading || isCheckOutLoading

  const [onWork, setOnWork] = useState<string>('')
  const [onBreak, setOnBreak] = useState<string>('')

  const setTime = (time: string) => {
    if (time === '00:00') {
      return time
    }
    const timeArr = time.split(':')

    return parseInt(timeArr[0]) || parseInt(timeArr[1]) ? time : ''
  }

  const checkIn = useEvent(async () => {
    setCheckInLoading(true)
    checkInAPI(employeeId)
      .then(() => {
        setStatus('check_in')
        initSynchronizationServer()
      })
      .finally(() => {
        setCheckInLoading(false)
      })
  })

  const pause = useEvent(async () => {
    setPauseLoading(true)
    pauseAPI(employeeId)
      .then(() => {
        setStatus('pause')
        initSynchronizationServer()
      })
      .finally(() => {
        setPauseLoading(false)
      })
  })

  const unpause = useEvent(async () => {
    setUnpauseLoading(true)
    unpauseAPI(employeeId)
      .then(() => {
        setStatus('unpause')
        initSynchronizationServer()
      })
      .finally(() => {
        setUnpauseLoading(false)
      })
  })

  const checkOut = useEvent(async () => {
    setCheckOutLoading(true)
    checkOutAPI(employeeId)
      .then(() => {
        setStatus('check_out')
        initSynchronizationServer()
      })
      .finally(() => {
        setCheckOutLoading(false)
      })
  })

  const initSynchronizationServer = () => {
    setInitLoading(true)
    checkStatusAPI(employeeId)
      .then((data) => {
        setStatus(data.status)
        setOnWork(data.onWork || '')
        setOnBreak(data.onBreak || '')
      })
      .catch(() => {
        setInitError(true)
      })
      .finally(() => {
        setInitLoading(false)
      })
  }

  const synchronizationServer = () => {
    setSyncLoading(true)
    checkStatusAPI(employeeId)
      .then((data) => {
        setStatus(data.status || 'init')
        setOnWork(data.onWork || '')
        setOnBreak(data.onBreak || '')
      })
      .finally(() => {
        setSyncLoading(false)
      })
  }

  useEffect(() => {
    initSynchronizationServer()
  }, [])

  useEffect(() => {
    if (status === 'check_in' || status === 'pause' || status === 'unpause') {
      const timerID = setInterval(() => {
        !isSwitchStatusLoading && synchronizationServer()
      }, 61000)

      return () => {
        clearInterval(timerID)
      }
    }
  }, [status])

  return {
    status,
    isInitLoading,
    isInitError,
    isSyncLoading,
    onWork,
    onBreak,
    checkIn,
    isCheckInLoading,
    pause,
    isPauseLoading,
    unpause,
    isUnpauseLoading,
    checkOut,
    isCheckOutLoading,
  }
}
