import styled from 'styled-components'
import { token } from '@atlaskit/tokens'

export const CurrenciesStyles = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: ${token('space.300')} ${token('space.400')} ${token('space.600')} ${token('space.300')};

  @media (min-width: 1920px) {
    margin: ${token('space.300')} ${token('space.800')} ${token('space.600')};
  }

  @media (max-width: 1280px) {
    margin: ${token('space.300')} ${token('space.400')} ${token('space.600')} ${token('space.200')};
  }

  @media (max-width: 1024px) {
    width: 100vw;
    margin: ${token('space.300')} ${token('space.400')} ${token('space.600')};
  }

  @media (max-width: 768px) {
    margin: ${token('space.300')} ${token('space.200')} ${token('space.600')};
  }

  @media (max-width: 480px) {
    width: 100vw;
  }

  .currencies {
    &-title {
      color: var(--Colors-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
      font-size: var(--Typography-Font-Size-fontSizeHeading3, 24px);
      font-style: normal;
      font-weight: var(--Typography-Font-Weight-fontWeightStrong, 500);
      line-height: var(--Typography-Line-Height-lineHeightHeading3, 32px);
    }

    &-search {
      width: 463px;
      margin-top: 16px;
    }

    &-table {
      margin-top: 24px;
    }

    &-add {
      &__select {
        height: 0;
        display: flex;
        align-items: center;
        overflow: hidden;
        transition: height 0.25s ease-in-out;

        &.is-open {
          height: 56px;
          border-bottom: var(--Components-Table-Global-lineWidth, 1px) solid
            var(--Components-Table-Component-borderColor, #f0f0f0);
        }

        .ant-select {
          width: 322px;
        }
      }

      &__btn {
        height: 56px;
        display: flex;
        align-items: center;
        border-bottom: var(--Components-Table-Global-lineWidth, 1px) solid
          var(--Components-Table-Component-borderColor, #f0f0f0);
      }

      &__loading {
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
`
