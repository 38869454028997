// TODO: fix eslint errors
/* eslint-disable */
import React from 'react'
import { HeaderBlockStats } from '../HeaderBlockStats'
import style from '../AttendanceLog.module.scss'
import { DayRange } from 'components/UI/FilterCalendar'
import useEmployeeSummary from './useEmployeeSummary'
import { EmployeeData } from 'interfaces/attendance/employees.interface'

interface EmployeeSummaryProps {
  name?: string
  show: boolean
  dateRange: DayRange
  employee: EmployeeData
}

export const EmployeeSummary: React.FC<EmployeeSummaryProps> = ({ show, dateRange, employee }) => {
  const summary = useEmployeeSummary(dateRange, employee)
  return (
    <>
      {show && (
        <div className={style.headerStats}>
          <HeaderBlockStats
            title="Expected hours"
            hours={summary?.expectedHours.hours ? summary.expectedHours.hours : '0'}
            minutes={summary?.expectedHours.minutes ? summary.expectedHours.minutes : '00'}
          />
          <HeaderBlockStats
            title="Worked hours"
            hours={summary?.workedHours.hours ? summary.workedHours.hours : '0'}
            minutes={summary?.workedHours.minutes ? summary.workedHours.minutes : '00'}
          />
          <HeaderBlockStats
            title="Remaining hours"
            hours={summary?.remainingHours.hours ? summary.remainingHours.hours : '0'}
            minutes={summary?.remainingHours.minutes ? summary.remainingHours.minutes : '00'}
          />
        </div>
      )}
    </>
  )
}
