import { Button, Flex } from 'antd'
import { ArrowLeftOutlined, CloseCircleFilled } from '@ant-design/icons'
import { InactiveUserViewStyles } from './styles'
import { IInactiveUserViewProps } from './types'

export const InactiveUserView = ({ onClose }: IInactiveUserViewProps) => (
  <InactiveUserViewStyles>
    <Flex gap={8} vertical>
      <CloseCircleFilled className="container__icon" />
      <h3 className="container__title">Account Inactive</h3>
      <p className="container__text">Your account is currently inactive. Please contact your manager for assistance.</p>
    </Flex>

    <Button color="default" variant="text" icon={<ArrowLeftOutlined />} className="container__link" onClick={onClose}>
      Return to login
    </Button>
  </InactiveUserViewStyles>
)
