import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ILocation } from 'interfaces/common/location.interface'
import { BASE_URL } from './constants'

export const locationsAPI = createApi({
  reducerPath: 'locations-api',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/locations` }),
  tagTypes: ['locations'],
  endpoints: (build) => ({
    getLocations: build.query<ILocation[], void>({
      query: () => ({
        url: '',
      }),
      providesTags: (_result) => ['locations'],
    }),
  }),
})

export const { useGetLocationsQuery } = locationsAPI
