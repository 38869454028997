// TODO: fix eslint errors
/* eslint-disable */
import { useState } from 'react'
import { useQuery } from '../../useQuery'
import { fetchCurrentAvatarAPI, uploadNewAvatarAPI, deleteAvatarAPI } from './avatar'
import { useEvent } from 'hooks/useEvent'
import { assertEmployee, useEmployee } from '../../../contexts/EmployeeContext'

export const useAvatar = (id: string) => {
  const [avatar, setAvatar] = useState<string>()
  const [getCurrentAvatar, isLoading, isSuccess, isError] = useQuery(fetchCurrentAvatarAPI)
  const [uploadNewAvatarA, isULoading, isUSuccess, isUError] = useQuery(uploadNewAvatarAPI)
  const [deleteAvatarA, isDLoading, isDSuccess, isDError] = useQuery(deleteAvatarAPI)

  const { employee: employeeAuth, setAvatar: setAvatarAuth } = useEmployee()
  assertEmployee(employeeAuth)

  const fetchAvatar = useEvent(async () =>
    getCurrentAvatar(id).then((response) => {
      setAvatar(response?.cropped || '')
      return response
    }),
  )

  const uploadNewAvatar = useEvent(async (data: string) =>
    uploadNewAvatarA(id, data).then((response) => {
      setAvatar(data)
      if (employeeAuth.id === id) {
        setAvatarAuth(data)
      }
      return response
    }),
  )

  const deleteAvatar = useEvent(async () =>
    deleteAvatarA(id).then((response) => {
      setAvatar('')
      if (employeeAuth.id === id) {
        setAvatarAuth('')
      }
      return response
    }),
  )

  return {
    avatar,
    fetchAvatar,
    uploadNewAvatar,
    deleteAvatar,
    isLoading,
    isSuccess,
    isError,
  }
}
