// TODO: Fix TS and make refactor
import { CheckboxOptionType } from 'antd'

export const getSelectedFormattedDate = (
  filterValues: string[],
  options: CheckboxOptionType[],
): { selectedCount: number; selectedLabel: string | null } => {
  const arr = options.reduce((acc, currency) => {
    if (filterValues.includes(currency.value)) {
      //@ts-ignore
      acc.push(currency)
    }
    return acc
  }, [])

  const selectedLabel = arr.reduce((acc, item) => {
    //@ts-ignore
    const itemString = `${item.title}`
    if (`${acc}, ${itemString}`.length <= 150) {
      return acc ? `${acc}, ${itemString}` : itemString
    }
    return acc
  }, '')

  return {
    selectedCount: arr.length,
    selectedLabel,
  }
}
