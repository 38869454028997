import {type JobInfoProps} from './jobInfo.types';
import {EMPLOYEE} from "../../constants";

export const getCurrentJobInfo = async (idProfile: string) => {
    try {
        const response = await fetch(`${EMPLOYEE}/${idProfile}/current-job-info`, {
            method: 'GET',
            headers: {'Accept': 'application/json' }
        });

        return await response.json() as JobInfoProps;
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};
