// TODO: fix eslint errors
/* eslint-disable */
import { useState } from 'react'
import { useEvent } from 'hooks/useEvent'
import { loginWithGoogle as fetchLoginWithGoogle } from './loginWithGoogle'
import { useGoogleLogin } from '@react-oauth/google'
import { useEmployee } from '../../contexts/EmployeeContext'
import { useNavigate } from 'react-router-dom'
import { MAIN } from 'navigation'
import * as Sentry from '@sentry/react'

export const useLoginWithGoogle = () => {
  const navigate = useNavigate()
  const toMain = () => {
    navigate(MAIN, { replace: true })
  }

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const [isError, setError] = useState<boolean>(false)

  const { signIn: signInEmployee } = useEmployee()

  const resetStatuses = useEvent(() => {
    setLoading(false)
    setSuccess(false)
    setError(false)
  })

  const loginGoogle = useEvent((data: any) =>
    fetchLoginWithGoogle(data)
      .then((data) => {
        setSuccess(true)
        signInEmployee(data)
        toMain()
        return data
      })
      .catch((error) => {
        Sentry.captureException(error)
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      }),
  )

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => loginGoogle(codeResponse),
    onError: () => {
      setLoading(false)
      setError(true)
    },
    flow: 'auth-code',
  })

  const loginWithGoogle = () => {
    setLoading(true)
    login()
  }

  return {
    isLoading,
    isSuccess,
    isError,
    loginWithGoogle,
    resetStatuses,
  }
}
