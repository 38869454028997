import { useCallback, useMemo } from 'react'
import { useProfile } from '../../../../context'
import { useGetBonusPenaltyDataByEmployeeIdQuery } from 'services/api'
import { BonusPenaltyFormModal } from 'components'
import { openBonusPenaltyModal } from 'store'
import { useAppDispatch, useAppSelector } from 'hooks'
import { TableBox } from '../table-box'
import { Table, Filters } from './compoenents'

export const BonusPenaltyTable = () => {
  const { idOpenProfile, profile } = useProfile()

  const dispatch = useAppDispatch()

  const { tableState, tableFilters } = useAppSelector((state) => state.employeeBonusPenaltyTable)
  const { isOpen } = useAppSelector((state) => state.bonusPenaltyModal)

  const {
    data: bonusesPenaltiesDate = { items: [], total: 0 },
    isLoading,
    isFetching,
  } = useGetBonusPenaltyDataByEmployeeIdQuery({ ...tableState, ...tableFilters, employeeId: idOpenProfile })

  const onOpenCreateRequestModal = useCallback(() => dispatch(openBonusPenaltyModal()), [])

  // TODO: Implement hasActiveFiltersFn
  const hasActiveFilters = useMemo(() => {
    const { effectiveDate, statuses } = tableFilters

    return !!(effectiveDate?.length || statuses)
  }, [tableFilters.effectiveDate, tableFilters.statuses])

  return (
    <>
      <TableBox
        isLoadingTable={isLoading}
        tableTitle="Bonuses&Penalties history"
        skeleton={{ filtersCount: 2, colCount: 5, rowCount: 6 }}
        onClickAddBtn={onOpenCreateRequestModal}
        showTable={Boolean(bonusesPenaltiesDate.total)}
        tableComponent={<Table isLoading={isFetching} sourceData={bonusesPenaltiesDate} />}
        tableFiltersComponent={<Filters />}
        showTableFilters={hasActiveFilters}
      />
      {isOpen && <BonusPenaltyFormModal createFromProfile employee={profile} />}
    </>
  )
}
