import styled from 'styled-components'

export const FormInputNumberStyles = styled.div`
  .input-number {
    width: 100%;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 0 11px;
    border-radius: 6px;
    height: 32px;

    &::placeholder {
      color: #d9d9d9;
    }

    &:hover {
      border: 1px solid #4096ff;
    }

    &:focus {
      border: 1px solid #4096ff;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    }
  }

  .input-number.invalid {
    border: 1px solid #ff4d4f;

    &:focus {
      box-shadow: none;
    }
  }
`
