// TODO: fix eslint errors
/* eslint-disable */
import BaseModal from './BaseModal'
import { RANDOM_PHRASES } from '../../../../../utils/constants/api'
import { RandomPhraseFromApi } from '../../../../../shared/RandomPhrase/data/RandomPhraseFromApi'
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors'
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError'
import { LocationFromApi } from '../../Locations/Locations'
import { getGMTOffsetByTimezoneName } from '../../../../Common/Timezones/Services'

type EditRandomPhrase = {
  opened: boolean
  onOk: () => void
  onClose: () => any
  randomPhrase: RandomPhraseFromApi
  locations: LocationFromApi[]
}

function EditRandomPhrase(props: EditRandomPhrase) {
  function edit(
    text: string,
    locations: string[],
    visible: boolean,
    validate: (errors: ValidationError[]) => void,
  ): void {
    fetch(`${RANDOM_PHRASES}/${props.randomPhrase.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        text,
        locations,
        visible,
      }),
    }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors))
      }
    })
  }

  return (
    <BaseModal
      opened={props.opened}
      modalTitle="Edit Random Phrase"
      text={props.randomPhrase.text}
      selectedLocations={props.locations}
      locations={props.randomPhrase.locations.map((location) => {
        let locationName = ''
        props.locations.map((locationValue) => {
          if (locationValue.id === location) {
            locationName = `${locationValue.name} ${getGMTOffsetByTimezoneName(locationValue.value.timezone)}`
          }
        })
        return {
          label: locationName,
          value: location,
        }
      })}
      visible={props.randomPhrase.visible}
      okText="Edit"
      onCancel={props.onClose}
      onOk={edit}
    />
  )
}

export default EditRandomPhrase
