import { PresetColorKey } from 'antd/es/theme/internal'

export enum BonusPenaltyEnum {
  Bonus = 'bonus',
  Penalty = 'penalty',
}
export const bonusPenaltyLabelMap: Record<BonusPenaltyEnum, string> = {
  [BonusPenaltyEnum.Bonus]: 'Bonus',
  [BonusPenaltyEnum.Penalty]: 'Penalty',
}
export const bonusPenaltyColorMap: Record<BonusPenaltyEnum, PresetColorKey> = {
  [BonusPenaltyEnum.Bonus]: 'green',
  [BonusPenaltyEnum.Penalty]: 'volcano',
}
