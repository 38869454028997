// TODO: fix eslint errors
/* eslint-disable */
import { FC, useEffect } from 'react'
import { TabContainer } from '../TabContainer'
import { BlockInfo, BlockInfoSkeletonItem } from '../../BlockInfo'

import { ReactComponent as PersonalIcon } from '../../BlockInfo/Icons/person_outline.svg'
import { ReactComponent as ContactsIcon } from '../../BlockInfo/Icons/profile_outline.svg'
import { ReactComponent as ShieldIcon } from '../../BlockInfo/Icons/shield_outline.svg'

import { FirstNameEN } from '../../Fields/generalInfo/FirstNameEN'
import { OtherNameEN } from '../../Fields/generalInfo/OtherNameEN'
import { LastNameEN } from '../../Fields/generalInfo/LastNameEN'
import { FirstName } from '../../Fields/generalInfo/FirstName'
import { OtherName } from '../../Fields/generalInfo/OtherName'
import { LastName } from '../../Fields/generalInfo/LastName'
import { Nickname } from '../../Fields/generalInfo/Nickname'
import { GenderPronounce } from '../../Fields/generalInfo/GenderPronounce'
import { DateOfBirth } from '../../Fields/generalInfo/DateOfBirth'

import { Country } from '../../Fields/generalInfo/Country'
import { City } from '../../Fields/generalInfo/City'
import { PhoneNumber } from '../../Fields/generalInfo/PhoneNumber'
import { Email } from '../../Fields/generalInfo/Email'
import { SocialNetwork } from '../../Fields/generalInfo/SocialNetwork'

import { AccessLevel } from '../../Fields/generalInfo/AccessLevel'
import { useProfile } from '../../context'

export const GeneralInfoTab: FC = () => {
  const { contactInfo } = useProfile()

  return (
    <TabContainer>
      <BlockInfo title="Personal Info">
        <FirstNameEN />
        <OtherNameEN />
        <LastNameEN />
        <FirstName />
        <OtherName />
        <LastName />
        <Nickname />
        <GenderPronounce />
        <DateOfBirth />
      </BlockInfo>

      <BlockInfo title="Contact Info">
        <Country />
        <City />
        {contactInfo ? <PhoneNumber /> : <BlockInfoSkeletonItem />}
        {contactInfo ? <Email /> : <BlockInfoSkeletonItem />}
        {contactInfo ? <SocialNetwork /> : <BlockInfoSkeletonItem />}
      </BlockInfo>

      <BlockInfo title="Other Info">
        <AccessLevel />
      </BlockInfo>
    </TabContainer>
  )
}
