import { Button, Modal } from 'antd'
import { DeleteModalProps } from './types'
import { CloseCircleFilled } from '@ant-design/icons'
import { DeleteModalStyles } from './styles'

export const DeleteModal = ({ data, isLoading, isOpen, onCancel, onSubmit }: DeleteModalProps) => (
  <Modal open={isOpen} onCancel={onCancel} destroyOnClose width={400} footer={null}>
    <DeleteModalStyles>
      <p className="title">
        <CloseCircleFilled className="title__icon" /> Delete currency
      </p>
      <p className="currency">
        {data.code} - {data.name}
      </p>
      <div className="footer">
        <Button type="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="solid" loading={isLoading} onClick={onSubmit} color="danger">
          Delete
        </Button>
      </div>
    </DeleteModalStyles>
  </Modal>
)
