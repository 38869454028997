import { useMemo } from 'react'
import { EmployeeRateHistory } from 'models'
import { EmployeeRateHistorySortBy } from 'services/api'
import { defaultEmployeeRateHistoryTableState, setEmployeeRateHistoryTableState } from 'store'
import { useAppDispatch, useAppSelector } from 'hooks'
import { Table as CoreHRTable } from 'components'
import { getTableColumns } from './table-columns'
import { EmployeeRateHistoryTableConfig, EmployeeRateHistoryTableProps } from './types'
import { TablePaginationData } from 'types'

export const Table = (props: EmployeeRateHistoryTableProps) => {
  const { isLoading, sourceData } = props

  const dispatch = useAppDispatch()

  const {
    tableState: { page, limit },
  } = useAppSelector((state) => state.employeeRateHistoryTableFilters)

  const { isVisible: isVisibleAmountValue } = useAppSelector((state) => state.showEmployeeCompensation)

  // prettier-ignore
  const tableColumns: EmployeeRateHistoryTableConfig = useMemo(
    () => getTableColumns({ isVisibleAmountValue }), [isVisibleAmountValue]
  )

  const onTableChange = (tableState: TablePaginationData<EmployeeRateHistorySortBy>) => {
    dispatch(setEmployeeRateHistoryTableState(tableState))
  }

  return (
    <CoreHRTable<EmployeeRateHistory, EmployeeRateHistorySortBy>
      loading={isLoading}
      columns={tableColumns}
      recordIdKey="id"
      defaultTableState={defaultEmployeeRateHistoryTableState}
      dataSource={sourceData.items}
      onChange={onTableChange}
      pagination={{
        pageSize: limit,
        total: sourceData.total,
        current: page,
      }}
    />
  )
}
