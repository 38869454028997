import styled from 'styled-components'

export const FilterCheckboxTreeStyles = styled.div`
  .ant-tree {
    .ant-tree-treenode {
      padding: 0;
      width: 100%;
    }

    .ant-tree-checkbox + span {
      width: 100%;

      &:hover {
        background: #e6f4ff;
      }
    }
  }
`
