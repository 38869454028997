// TODO: fix eslint errors
/* eslint-disable */
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button/new'
import { xcss, Box } from '@atlaskit/primitives'
import { token } from '@atlaskit/tokens'

export default function ModalContent1({
  setContent,
  setIsOpen,
  interviesID,
}: {
  setContent: (arg: number) => void
  setIsOpen: (arg: boolean) => void
  interviesID: string
}) {
  return (
    <div>
      <ModalHeader>
        <ModalTitle>
          {' '}
          <Box
            xcss={xcss({
              color: 'color.text',
              fontFamily: 'inherit',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            })}
          >
            Personality analysis by AI
          </Box>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Box
          xcss={xcss({
            color: 'color.text',
            fontFamily: 'inherit',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          })}
        >
          Привіт! Цей аналіз допоможе вам краще зрозуміти ваші сильні й слабкі сторони та шляхи професійного розвитку.
          Також Ваш менеджер і колеги отримують поради щодо того, як покращити співпрацю з вами.
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button
          appearance="subtle"
          onClick={() => {
            setIsOpen(false)
            setContent(1)
          }}
        >
          Відмінити
        </Button>
        <Button
          appearance="primary"
          onClick={() => {
            setContent(2)
          }}
        >
          Продовжити
        </Button>
      </ModalFooter>
    </div>
  )
}
