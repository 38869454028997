// TODO: fix eslint errors
/* eslint-disable */
import React, { FC, useEffect, useState } from 'react'
import style from './ChangeTotal.module.scss'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button/new'
import { Input } from 'components/UI/Input'
import { useAttendanceLogEditTotal } from '../../../API/attendanceLog'
import { useEmployee, assertEmployee } from '../../../contexts/EmployeeContext'

interface ChangeTotalProps {
  isOpen: boolean
  closeModal: () => void
  dataToChangeTotal: Record<string, any> | undefined
  forceUpdate: number
  setForceUpdate: React.Dispatch<React.SetStateAction<number>>
}

const convertH2M = (timeInHour: string) => {
  const timeParts = timeInHour.split(':')
  return Number(timeParts[0]) * 60 + Number(timeParts[1])
}

const m2hm = (min: number) => {
  const m = min % 60
  const h = (min - m) / 60

  return {
    h,
    m,
  }
}

const timeFormatStrToTime = (timeExpr: string): string => {
  const regex = /(\d+)([hm])/g
  let hours = 0
  let minutes = 0
  let match

  while ((match = regex.exec(timeExpr))) {
    if (match[2] === 'h') {
      hours = parseInt(match[1], 10)
    } else if (match[2] === 'm') {
      minutes = parseInt(match[1], 10)
    }
  }

  if (minutes >= 60) {
    hours += Math.floor(minutes / 60)
    minutes %= 60
  }

  const hoursStr = hours.toString().padStart(2, '0')
  const minutesStr = minutes.toString().padStart(2, '0')

  return `${hoursStr}:${minutesStr}`
}

const isValidTimeFormat = (timeExpr: string): boolean => {
  const regex = /^(\d+h\s\d+m|\d+h|\d+m)$/
  if (!regex.test(timeExpr)) {
    return false
  }

  const matches = timeExpr.match(/(\d+)[hm]/g)
  if (!matches) {
    return false
  }

  let hours = 0
  let minutes = 0

  matches.forEach((match) => {
    if (match.includes('h')) {
      hours = parseInt(match)
    }
    if (match.includes('m')) {
      minutes = parseInt(match)
    }
  })

  if ((hours === 0 && minutes === 0) || minutes >= 60) {
    return false
  }

  return true
}

export const ChangeTotal: FC<ChangeTotalProps> = ({
  dataToChangeTotal,
  isOpen,
  closeModal,
  forceUpdate,
  setForceUpdate,
}) => {
  const { employee } = useEmployee()
  assertEmployee(employee)

  const { correctionTotalTime, isLoading, isSuccess, isError } = useAttendanceLogEditTotal()
  const [sign, setSign] = useState<'' | '-'>('')
  const [timeStr, setTimeStr] = useState('00:00')
  const [timeOriginalValue, setTimeOriginalValue] = useState('')
  const [isValidInput, setIsValidInput] = useState(false)
  const [isResultValid, setIsResultValid] = useState(true)

  const OriginalMin = dataToChangeTotal?.originalTime ? convertH2M(dataToChangeTotal?.originalTime) : 0
  const OriginalMinHM = OriginalMin ? m2hm(OriginalMin) : undefined

  const validateInput = (value: string, newSign: '' | '-') => {
    const formattedTime = timeFormatStrToTime(value)
    const isValid = isValidTimeFormat(value)
    setIsValidInput(isValid)

    if (isValid) {
      const addMin = convertH2M(formattedTime)
      const totalMin = newSign === '-' ? OriginalMin - addMin : OriginalMin + addMin
      setIsResultValid(totalMin >= 0)
    } else {
      setIsResultValid(true)
    }
  }

  const onClose = () => {
    setSign('')
    setTimeStr('00:00')
    closeModal()
  }

  useEffect(() => {
    if (isSuccess) {
      onClose()
      setForceUpdate(forceUpdate + 1)
    }
  }, [isSuccess])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    setTimeStr(timeFormatStrToTime(value))
    setTimeOriginalValue(value)
    validateInput(value, sign)
  }

  const handleSignChange = (newSign: '' | '-') => {
    setSign(newSign)
    validateInput(timeOriginalValue, newSign)
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="small">
          <ModalHeader>
            <ModalTitle>Change total hours</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className={style.originTime}>
              Original Time{' '}
              <span>
                {OriginalMinHM?.h} hours
                {OriginalMinHM?.m ? ` ${OriginalMinHM.m} minutes` : ''}
              </span>
            </div>
            <div className={style.inputWrapper}>
              <div className={style.btnWrapper}>
                <Button
                  autoFocus={false}
                  onClick={() => handleSignChange('')}
                  isSelected={sign === ''}
                  spacing="compact"
                >
                  +
                </Button>
                <Button onClick={() => handleSignChange('-')} isSelected={sign === '-'} spacing="compact">
                  -
                </Button>
              </div>
              <Input autoFocus onChange={handleInputChange} className={style.input} />
            </div>
            <div className={style.timeFormat}>
              <div className={style.timeFormatTitle}>Use the format: 6h 45m</div>
              <ul className={style.timeFormatList}>
                <li>h = hours</li>
                <li>m = minutes</li>
              </ul>
            </div>
            <div className={style.originTimeTotal}>
              Total{' '}
              {isValidInput && (
                <span>
                  {m2hm(sign === '-' ? OriginalMin - convertH2M(timeStr) : OriginalMin + convertH2M(timeStr)).h} hours
                  {m2hm(sign === '-' ? OriginalMin - convertH2M(timeStr) : OriginalMin + convertH2M(timeStr)).m
                    ? ` ${m2hm(sign === '-' ? OriginalMin - convertH2M(timeStr) : OriginalMin + convertH2M(timeStr)).m} minutes`
                    : ''}
                </span>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button
              appearance="primary"
              isDisabled={!isValidInput || !isResultValid}
              onClick={() => {
                correctionTotalTime({
                  employeeId: dataToChangeTotal?.idEmployee,
                  actionEmployeeId: employee?.id,
                  date: dataToChangeTotal?.date,
                  timeCorrection: sign + timeStr,
                })
              }}
            >
              Change
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}
