// TODO: fix eslint errors
/* eslint-disable */
import BaseModal from './BaseModal'
import { STATE_HOLIDAYS } from '../../../../../../utils/constants/api'
import { VALIDATION_ERROR } from '../../../../../../utils/constants/errors'
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError'
import { OrgUnitFromApi } from '../../../../../../shared/OrgChart/data/OrgUnitFromApi'
import { SelectorData } from 'components/old-ui/Input/DateSelector/data/SelectorData'
import { LocationFromApi } from '../../../Locations/Locations'
import { SelectValue } from '../../../../../../shared/Common/data/SelectValue'

type CreateStateHoliday = {
  orgUnits: OrgUnitFromApi[]
  locations: LocationFromApi[]
  opened: boolean
  onOk: () => void
  onClose: () => any
}

function CreateStateHoliday(props: CreateStateHoliday) {
  function create(
    name: string,
    locations: string[],
    orgUnits: string[],
    type: string,
    movingType: SelectorData,
    date: Date | null,
    repeatAnnually: boolean,
    dayOff: boolean,
    paidTimeOff: boolean,
    additionalPaidTimeOff: SelectValue,
    additionalPaidTimeDate: Date | null,
    text: string | null,
    visible: boolean,
    validate: (errors: ValidationError[]) => void,
  ): void {
    const formData = new FormData()
    formData.append(
      'stateHoliday',
      JSON.stringify({
        name,
        orgUnits,
        locations,
        type,
        movingType: type === 'moving' ? movingType : null,
        date: date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : null,
        repeatAnnually,
        dayOff,
        paidTimeOff,
        additionalPaidTimeOff: additionalPaidTimeOff ? additionalPaidTimeOff.value : null,
        additionalPaidTimeDate: additionalPaidTimeDate
          ? `${additionalPaidTimeDate.getFullYear()}-${additionalPaidTimeDate.getMonth() + 1}-${additionalPaidTimeDate.getDate()}`
          : null,
        text,
        visible,
      }),
    )

    fetch(STATE_HOLIDAYS, { method: 'POST', body: formData }).then((response) => {
      if (response.ok) {
        props.onOk()
      } else if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors))
      }
    })
  }

  return (
    <BaseModal
      opened={props.opened}
      modalTitle="Create State Holiday"
      name=""
      orgUnitValues={props.orgUnits}
      orgUnits={[]}
      locationValues={props.locations}
      locations={[]}
      date={null}
      repeatAnnually
      dayOff="false"
      paidTimeOff="true"
      additionalPaidTimeOff={{ label: 'Yes, next working day', value: '1' }}
      additionalPaidTimeDate={null}
      type="fixed"
      movingType={{ month: '1', weekNumber: '1', dayOfWeek: 'Monday' } as SelectorData}
      text={null}
      visible
      okText="Create"
      onCancel={props.onClose}
      onOk={create}
    />
  )
}

export default CreateStateHoliday
