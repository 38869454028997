import styled from 'styled-components'

export const FilterRangeDatesStyles = styled.div`
  display: flex;
  gap: 4px;
  height: 32px;
  align-items: center;
  width: 232px;
  position: relative;

  .ant-input-affix-wrapper > input.ant-input {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-input-affix-wrapper {
    .anticon svg {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .ant-picker {
    position: absolute;
    z-index: 1;
  }
`
