import * as Sentry from '@sentry/react';

export const changeUserPassword = async (resetCode: string, newPassword: string, confirmedPassword: string) => {
    try {
        const response = await fetch(`/api/employees/password?reset_code=${resetCode}`, {
            method: 'PUT',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                newPassword,
                confirmedPassword
            })
        });

        if (!response.ok || response.status !== 200) {
            throw new Error();
        }
    } catch (error) {
        Sentry.captureException(error);
        throw new Error();
    }
};
