import { getFormattedDate } from 'utils/helpers/formatted-date'
import { currencyFormatter } from 'utils/helpers/currency-formatter'
import { EmployeeRateHistory } from 'models'
import { tableColumnsMap } from './constants'
import { EmployeeRateHistoryTableConfig, GetTableColumnsProps } from './types'
import { TableAction } from './components'
import { SmileOutlined } from '@ant-design/icons'

export const getTableColumns = ({ isVisibleAmountValue }: GetTableColumnsProps): EmployeeRateHistoryTableConfig => {
  const config: EmployeeRateHistoryTableConfig = [
    {
      title: 'Start date',
      width: '40%',
      dataIndex: tableColumnsMap.startDate,
      render: (startDate: EmployeeRateHistory['startDate']) => (
        <span>{getFormattedDate(startDate, 'coreHRDateFormat')}</span>
      ),
      sorter: true,
    },
    {
      title: 'Amount',
      width: '40%',
      dataIndex: tableColumnsMap.rate,
      render: (amount: EmployeeRateHistory['rate'], { currency }) => (
        <>{isVisibleAmountValue ? `${currency.symbol} ${currencyFormatter(amount)}` : <SmileOutlined />}</>
      ),
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: unknown, record: EmployeeRateHistory) => <TableAction record={record} />,
    },
  ]
  return config
}
