// TODO: fix eslint errors
/* eslint-disable */
import React, { FC, useEffect } from 'react'
import style from './LeaveStatus.module.scss'
import { ProfileAvatar } from '../../ProfileAvatar'
import { Link } from 'react-router-dom'

import { useLeaveStatus } from '../../../../API/profile/leaveStatus'
import { useProfile } from '../../context'
import { TypeIconsCollection } from '../../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection'
import { useAvatar } from '../../../../API/profile/avatar/useAvatar'

const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

export const LeaveStatus: FC = () => {
  const { idOpenProfile, setIcon } = useProfile()
  const { getLeaveStatus, leaveStatus } = useLeaveStatus(idOpenProfile)
  const { avatar, fetchAvatar } = useAvatar(leaveStatus?.substituteId || '')

  const [fName, lName, nicName] = leaveStatus && leaveStatus.substituteName ? leaveStatus.substituteName.split(' ') : []

  const sDate = new Date(leaveStatus?.startDate || '')
  const eDate = new Date(leaveStatus?.endDate || '')

  useEffect(() => {
    getLeaveStatus()
  }, [])

  useEffect(() => {
    leaveStatus && setIcon(leaveStatus.typeIcon)
  }, [leaveStatus])

  useEffect(() => {
    if (leaveStatus?.substituteId) {
      fetchAvatar()
    }
  }, [leaveStatus?.substituteId])

  if (!leaveStatus) {
    return null
  }

  return (
    <div className={style.wrapper}>
      <div className={style.status}>
        <div className={style.statusIcon}>
          {TypeIconsCollection[leaveStatus?.typeIcon as keyof typeof TypeIconsCollection]('large-icon', 'withouthover')}
        </div>
        <div className={style.statusText}>On {leaveStatus.typeName}</div>
      </div>
      <div className={style.period}>
        {mS[sDate.getMonth()]} {sDate.getDate()}, {sDate.getFullYear()} - {mS[eDate.getMonth()]} {eDate.getDate()},{' '}
        {eDate.getFullYear()}{' '}
      </div>

      {leaveStatus.substituteId && (
        <>
          <div className={style.titleReplacing}>Replacing:</div>
          <Link to={`/employee/${leaveStatus.substituteId}`} className={style.directManagerLink}>
            <div className={style.directManager}>
              <div className={style.avatar}>
                <ProfileAvatar imgAvatar={avatar || ''} size={32} firstName={fName} lastName={lName} />
              </div>
              <div>
                <div className={style.name}>
                  {fName} {lName}
                </div>
                {nicName && <div className={style.nickname}>{nicName.replace(/^[()]+|[()]+$/g, '')}</div>}
              </div>
            </div>
          </Link>
        </>
      )}
    </div>
  )
}
