import { useMemo } from 'react'
import { SkeletonTable } from 'components/UI/skeleton-table'
import { useEmployee } from 'contexts/EmployeeContext'
import { BonusesPenaltiesTable, BonusesPenaltiesFilters } from './components'
import { useGetBonusPenaltyDataQuery } from 'services/api'
import { AddIcon, BonusPenaltyFormModal, Button } from 'components'
import { NoData, NoResults } from 'components/UI/table'
import { openBonusPenaltyModal } from 'store'
import { useAppDispatch, useAppSelector } from 'hooks'
import { BonusesPenaltiesStyles } from './styles'

export const BonusesPenalties = () => {
  const { employee } = useEmployee()

  const dispatch = useAppDispatch()
  const { tableState, tableFilters } = useAppSelector((state) => state.bonusPenaltyTable)
  const { isOpen } = useAppSelector((state) => state.bonusPenaltyModal)

  const {
    data: bonusesPenaltiesDate = { items: [], total: 0 },
    isLoading,
    isFetching,
  } = useGetBonusPenaltyDataQuery({ ...tableState, ...tableFilters })

  const onOpenCreateRequestModal = () => dispatch(openBonusPenaltyModal())

  // TODO: Implement hasActiveFiltersFn
  const hasActiveFilters = useMemo(() => {
    const { creationDate, effectiveDate, statuses } = tableFilters

    return !!(creationDate?.length || effectiveDate?.length || statuses)
  }, [tableFilters.creationDate, tableFilters.effectiveDate, tableFilters.statuses])

  return (
    <BonusesPenaltiesStyles>
      {isLoading && <SkeletonTable filtersCount={2} colCount={7} rowCount={10} />}
      <h3 className="table-header-title">Requests: Bonuses & penalties</h3>
      <div className="filters">
        <BonusesPenaltiesFilters />
        <Button customType="core-hr-secondary" onClick={onOpenCreateRequestModal}>
          <AddIcon />
          Add
        </Button>
      </div>
      {bonusesPenaltiesDate.total ? (
        <BonusesPenaltiesTable isLoading={isFetching} sourceData={bonusesPenaltiesDate} />
      ) : (
        <>{hasActiveFilters ? <NoResults /> : <NoData onClick={onOpenCreateRequestModal} btnText="Add" />}</>
      )}
      {isOpen && <BonusPenaltyFormModal employee={employee} />}
    </BonusesPenaltiesStyles>
  )
}
