// TODO: fix eslint errors
/* eslint-disable */
import React, { useState, useEffect, FC } from 'react'
import CreateLocation from './Modal/CreateLocation'
import DeleteLocation from './Modal/DeleteLocation'
import EditLocation from './Modal/EditLocation'
import Button, { Size, Type } from 'components/old-ui/Button/Button'

import { CountryFromApi } from '../../../../shared/Location/data/CountryFromApi'
import { getGMTOffsetByTimezoneName } from '../../../Common/Timezones/Services'

import { COUNTRIES, LOCATIONS } from '../../../../utils/constants/api'
import { ROLE_ADMIN, ROLE_HR } from '../../../../shared/Security/constants/AccessLevels'

import './Locations.scss'
import { assertEmployee, useEmployee } from '../../../../contexts/EmployeeContext'

export type LocationFromApi = {
  id: string
  value: {
    countryCode: string
    city: string
    timezone: string
    showOnMainPage: boolean
  }
  name: string
}

export const Locations: FC = () => {
  const { employee } = useEmployee()
  assertEmployee(employee)
  const [createLocationModal, setCreateLocationModal] = useState<boolean>(false)
  const [editLocationModal, setEditLocationModal] = useState<boolean>(false)
  const [deleteLocationModal, setDeleteLocationModal] = useState<boolean>(false)

  const [locations, setLocations] = useState<LocationFromApi[]>([])
  const [locationToChange, setLocationToChange] = useState<LocationFromApi | null>(null)
  const [locationToDelete, deleteLocation] = useState<LocationFromApi | null>(null)

  const [countries, setCountries] = useState<CountryFromApi[]>([])

  useEffect(getLocations, [])
  useEffect(getCountries, [])

  function getLocations() {
    fetch(LOCATIONS, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((locations: LocationFromApi[]) => setLocations(locations))
  }

  function getCountries() {
    fetch(COUNTRIES, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((countries: CountryFromApi[]) => setCountries(countries))
  }

  function editLocationModalVisibility(location: LocationFromApi): void {
    setLocationToChange(location)
    setEditLocationModal(true)
  }

  function confirmDeleteLocationModalVisibility(location: LocationFromApi): void {
    deleteLocation(location)
    setDeleteLocationModal(true)
  }

  function getCountryNameByCountryCode(countryCode: string): string {
    return countries.find((country) => country.code === countryCode)?.name || ''
  }

  const roles = [ROLE_ADMIN, ROLE_HR] as typeof employee.roles
  const hasRole = roles.some((role) => employee.roles.includes(role))

  return (
    <div className="admin-panel-board">
      <div className="sub-content-block d-flex">
        <div className="sub-content-header d-flex">
          <div className="sub-title-block d-flex">
            <h3>Work locations</h3>
          </div>

          {hasRole && (
            <div className="add-button-block">
              <Button
                text="<i className='material-icons add-item'>add</i> Add location"
                size={Size.Medium}
                type={Type.Accent}
                onClick={() => setCreateLocationModal(true)}
              />
            </div>
          )}
        </div>
        {locations.length > 0 && (
          <div className="sub-content-main">
            <table className="locations-table">
              <thead>
                <tr>
                  <th>Location</th>
                  <th className="show-on-main-page">Show on main page</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {locations.map((location) => (
                  <tr key={location.id}>
                    <td>
                      {location.value.city
                        ? `${location.value.city}, ${getCountryNameByCountryCode(location.value.countryCode)} ${getGMTOffsetByTimezoneName(location.value.timezone)}`
                        : `${getCountryNameByCountryCode(location.value.countryCode)} ${getGMTOffsetByTimezoneName(location.value.timezone)}`}
                    </td>
                    <td className="show-on-main-page">
                      <i className={location.value.showOnMainPage ? 'material-icons check' : 'material-icons'}>
                        {location.value.showOnMainPage ? 'check' : 'remove'}
                      </i>
                    </td>
                    <td>
                      {hasRole && (
                        <>
                          <i className="material-icons" onClick={() => editLocationModalVisibility(location)}>
                            edit
                          </i>
                          <i className="material-icons" onClick={() => confirmDeleteLocationModalVisibility(location)}>
                            delete
                          </i>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <CreateLocation
        opened={createLocationModal}
        onOk={() => {
          getLocations()
          setCreateLocationModal(false)
        }}
        onClose={() => setCreateLocationModal(false)}
        countries={countries}
      />
      {locationToDelete ? (
        <DeleteLocation
          opened={deleteLocationModal}
          onOk={() => {
            getLocations()
            setDeleteLocationModal(false)
            deleteLocation(null)
          }}
          onClose={() => {
            setDeleteLocationModal(false)
            deleteLocation(null)
          }}
          location={locationToDelete}
          countries={countries}
        />
      ) : null}
      {locationToChange ? (
        <EditLocation
          opened={editLocationModal}
          onOk={() => {
            getLocations()
            setEditLocationModal(false)
            setLocationToChange(null)
          }}
          onClose={() => {
            setEditLocationModal(false)
            setLocationToChange(null)
          }}
          locationToChange={locationToChange}
          countries={countries}
        />
      ) : null}
    </div>
  )
}
