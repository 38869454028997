import clsx from 'clsx'
import { StyledButton } from './styles'
import { ButtonProps } from './types'
import { btnCustomTypeCssClass } from './constants'

export const Button = (props: ButtonProps) => {
  const { type = 'default', customType, className, capitalize, ...buttonProps } = props
  const customTypeCssClass: string | undefined = customType && btnCustomTypeCssClass[customType]

  return (
    <StyledButton
      {...buttonProps}
      type={type}
      className={clsx(className, customTypeCssClass, {
        'capitalize-text': capitalize,
      })}
    />
  )
}
