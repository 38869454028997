import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../constants'
import { EmployeeCompensationData } from './types'

export const employeeCompensationApi = createApi({
  reducerPath: 'employeeCompensationApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/employees` }),
  tagTypes: ['employeeCompensation'],
  endpoints: (build) => ({
    getEmployeeCompensationData: build.query<EmployeeCompensationData, string>({
      query: (id: string) => ({
        url: `${id}/compensations-summary`,
      }),
      providesTags: ['employeeCompensation'],
    }),
  }),
})

export const { useGetEmployeeCompensationDataQuery } = employeeCompensationApi
