// TODO: fix eslint errors
/* eslint-disable */
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button/new'
import { Link } from 'react-router-dom'
import { Checkbox } from '@atlaskit/checkbox'
import { xcss, Box } from '@atlaskit/primitives'

export default function ModalContent5({
  setContent,
  setIsOpen,
  interviesID,
}: {
  setContent: (arg: number) => void
  setIsOpen: (arg: boolean) => void
  interviesID: string
}) {
  return (
    <div>
      <ModalHeader>
        <ModalTitle>
          <Box
            xcss={xcss({
              color: 'color.text',
              fontFamily: 'inherit',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            })}
          >
            Personality analysis by AI
          </Box>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Box
          xcss={xcss({
            color: 'color.text',
            fontFamily: 'inherit',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          })}
        >
          Незабаром Ваш результат буде готовий. Ви знайдете його у своєму профілі у вкладці Personality analysis by AI.
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button
          appearance="primary"
          onClick={() => {
            setIsOpen(false)
            setContent(1)
          }}
        >
          Зрозуміло
        </Button>
      </ModalFooter>
    </div>
  )
}
