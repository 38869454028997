// TODO: Create a component for employee item
import { Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { EMPLOYEE_PROFILE } from 'utils/constants/routes'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { getAbbr } from 'utils/helpers/get-abbr'
import { EmployeeItemPropsType } from './types'
import { EmployeeItemStyles } from './styles'

export const EmployeeItem = (props: EmployeeItemPropsType) => {
  const { employeeFullName, employeeAvatar, employeeNickname, employeeId } = props

  return (
    <EmployeeItemStyles>
      <Avatar
        size={32}
        alt="avatar"
        className="employee-avatar"
        src={employeeAvatar ? <LazyLoadImage src={employeeAvatar} effect="blur" width={32} height={32} /> : ''}
      >
        {getAbbr(`${employeeFullName}`)}
      </Avatar>
      <div className="employee">
        <span className="employee-name">
          <Link to={`${EMPLOYEE_PROFILE}/${employeeId}`} target="_blank">{`${employeeFullName}`}</Link>
        </span>
        <span className="employee-nickname">{employeeNickname}</span>
      </div>
    </EmployeeItemStyles>
  )
}
