import { token } from '@atlaskit/tokens'
import styled from 'styled-components'

export const TimeOffLogStyles = styled.div`
  padding: 0 64px 64px 24px;
  position: relative;

  .content {
    position: relative;
    width: 100%;

    &__title {
      color: ${token('color.text')};
      font-size: 29px;
      font-weight: 500;
      line-height: 32px;
    }

    &-tabs {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: ${token('space.200')};

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 ${token('space.100')};

        &.isActive {
          background-color: #e9f2ff;
          color: #0c66e4;

          &:hover,
          &:not(.ant-btn-disabled) {
            background-color: #e9f2ff !important;
          }
        }
      }
    }
  }

  .skeleton-wrap {
    position: relative;
  }
`
