import { token } from '@atlaskit/tokens'
import styled from 'styled-components'

export const SkeletonTableStyles = styled.div`
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: #fff;

  .skeleton-top {
    &-title {
      width: 364px;
      height: 16px;
    }

    &-calendar {
      width: 172px;
      height: 32px;
    }

    &-filter {
      width: 172px;
      height: 32px;
    }

    &-button {
      width: 184px;
      height: 32px;
      margin-left: auto;
    }
  }

  .skeleton-table {
    margin-top: 26px;

    &-header {
      &-cell {
        position: relative;
        height: 16px;
        margin: 13px 16px;

        &::after {
          content: '';
          position: absolute;
          inset: 0 -16px 0 auto;
          margin: auto;
          width: 1px;
          height: 16px;
          background-color: ${token('color.border')};
        }

        &:last-of-type {
          &::after {
            content: none;
          }
        }
      }
    }

    &-grid {
      display: grid;
    }

    &-row {
      border-bottom: 2px solid ${token('color.border')};
    }

    &-cell {
      height: 16px;
      margin: 20px 12px;
    }

    &-node {
      width: 100% !important;
      height: 16px !important;

      .ant-skeleton-image {
        vertical-align: baseline;
      }
    }
  }

  .ant-skeleton-image {
    width: 100% !important;
    height: 100% !important;
  }
`
