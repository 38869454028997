import { Button, AddIcon, NoDataIcon } from 'components'
import { EmptyTableTemplate } from '../empty-table-tamplate'
import { NoDataProps } from './types'

export const NoData = (props: NoDataProps) => {
  const { onClick, title = 'There is no information here yet', btnText, size = 'medium' } = props

  return (
    <EmptyTableTemplate size={size}>
      <>
        <NoDataIcon />
        <h4 className="title">{title}</h4>
        {onClick && (
          <Button customType="core-hr-secondary" onClick={onClick} icon={<AddIcon />}>
            {btnText || 'Create new'}
          </Button>
        )}
      </>
    </EmptyTableTemplate>
  )
}
