import styled from 'styled-components'

export const DropdownRenderStyles = styled.div<{ $dropdownHeight: number; $hasSearch: boolean }>`
  padding: 4px;
  background: #fff;
  max-height: ${({ $dropdownHeight }) => `${$dropdownHeight}px`};
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  box-shadow:
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  .dropdown-content {
    overflow-y: auto;
    max-height: ${({ $dropdownHeight, $hasSearch }) => `calc(${$dropdownHeight}px - ${$hasSearch ? '40px' : '0px'})`};
    padding-bottom: 10px;
  }
`

export const DropdownViewStyles = styled.div`
  .dropdown-label-content {
    cursor: pointer;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 0 12px;
    height: 32px;
    width: 172px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    transition: 0.3s;
    background: #fff;

    &:hover {
      border: 1px solid #4096ff;
    }

    .clear-icon-wrap {
      display: flex;
      align-items: center;
    }

    .label {
      font-size: 14px;
      line-height: 22px;
      margin-right: 4px;
      color: rgba(0, 0, 0, 0.25);
    }

    .anticon svg {
      color: rgba(0, 0, 0, 0.25);
    }

    .selected-label {
      display: flex;
      gap: 4px;
      align-items: center;
      overflow: hidden;

      .ant-tag {
        background: rgba(0, 0, 0, 0.06);
        margin-inline-end: 0;
      }

      .label {
        color: rgba(0, 0, 0, 0.88);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
      }
    }
  }
  .dropdown-label-content.ant-dropdown-open {
    border: 1px solid #1677ff;
    box-shadow: 0px 0px 0px 2px rgba(5, 145, 255, 0.1);
  }
`
