import { HeadType } from '@atlaskit/dynamic-table/dist/types/types'
import { tableColumnsMap } from './constants'

export const tableHead: HeadType = {
  cells: [
    {
      key: tableColumnsMap.date,
      content: 'Date',
      isSortable: true,
      width: 10,
    },
    {
      key: tableColumnsMap.quantity,
      content: 'Amount',
      isSortable: true,
      width: 10,
    },
    {
      key: tableColumnsMap.changedByEmployeeId,
      content: 'Changed by',
      shouldTruncate: true,
      isSortable: true,
      width: 12,
    },
    {
      key: tableColumnsMap.balance,
      content: 'Balance',
      isSortable: true,
      width: 10,
    },
    {
      key: tableColumnsMap.comment,
      content: 'Comment',
      isSortable: false,
      width: 20,
    },
  ],
}
