import styled from 'styled-components'

export const TableBoxStyles = styled.div`
  position: relative;

  .table-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding-bottom: 4px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 24px;
    border-bottom: 1px solid #d9d9d9;
  }

  .table-filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .core-hr-secondary-btn {
      margin-left: auto;
    }
  }
`
