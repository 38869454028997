import { useState, useEffect } from 'react'
import { INTERVIEWS, EMPLOYEES } from '../../../../utils/constants/api'
import { useEmployee } from '../../../../contexts/EmployeeContext'
import AIModal from '../../../Profile/ShortInformationCard/TimeOffBalance/AIModal/AIModal'
import ModalContentWithAIButton from './ModalContentWithAIButton'
import ModalContentNoAIButton from './ModalContentNoAIButton'
import { aiButtonSlice } from 'store'
import { useAppDispatch } from 'hooks'
import { Button, Popover, Tooltip } from 'antd'
import { AIBtnIcon } from './icons'
import { TDirectManager } from './types'
import { AIPopupStyles, AIStatusButtonStyles } from './styles'

export const AIStatusButton = () => {
  const { increment } = aiButtonSlice.actions
  const dispatch = useAppDispatch()
  const { employee } = useEmployee()
  const [isOpen, setIsOpen] = useState(false)
  const [interviewsID, setInterviewsID] = useState('')
  const [modalContent, setModalContent] = useState(1)
  const [AIOpen, setAIOpen] = useState(false)
  const [lastInterview, setLastInterview] = useState(null)
  const [directManager, setDirectManager] = useState<TDirectManager | null>(null)

  useEffect(() => {
    fetch(`${INTERVIEWS}/last`)
      .then((res) => {
        if (res) {
          return res.json()
        }
      })
      .then((data) => {
        if (data) {
          setLastInterview(data)
        }
      })
  }, [isOpen])

  useEffect(() => {
    if (employee) {
      fetch(`${EMPLOYEES}/${employee.id}/interviews/open-request`)
        .then((res) => res.json())
        .then((data) => {
          if (typeof data === 'string') {
            setInterviewsID(data)
          } else {
            setInterviewsID('')
          }
        })
    }
  }, [employee, modalContent])

  useEffect(() => {
    if (employee) {
      fetch(`${EMPLOYEES}/employee/${employee.id}/direct-manager`)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }
        })
        .then((data) => {
          setDirectManager(data)
        })
    }
  }, [employee])

  return (
    <AIStatusButtonStyles>
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <AIPopupStyles>
            <p className="ai-popup-title">Personality analysis by AI</p>

            {/* eslint-disable-next-line max-len */}
            {directManager && !interviewsID && !lastInterview && <ModalContentNoAIButton directManager={directManager} />}

            {lastInterview && (
              <ModalContentWithAIButton
                directManager={directManager}
                employee={employee}
                AIOpen={AIOpen}
                interviewsID={interviewsID}
                setAIOpen={(val: boolean) => {
                  setAIOpen(val)
                  setIsOpen(val)
                }}
                lastInterview={lastInterview}
              />
            )}
          </AIPopupStyles>
        }
      >
        <Tooltip placement="left" title="AI personality analysis">
          <Button icon={<AIBtnIcon />} className="ai-btn" />
        </Tooltip>
      </Popover>

      {employee && (
        <AIModal
          interviesID={interviewsID}
          setContent={setModalContent}
          content={modalContent}
          isOpen={AIOpen}
          setIsOpen={(val) => {
            setAIOpen(val)
            setIsOpen(val)
            dispatch(increment())
          }}
        />
      )}
    </AIStatusButtonStyles>
  )
}
