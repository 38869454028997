// TODO: fix eslint errors
/* eslint-disable */
import { useState } from 'react'
import { useEvent } from 'hooks/useEvent'

type callbackType = (...args: Array<any>) => Promise<any>

export const useQuery = <T extends callbackType>(query: T) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const [isError, setError] = useState<boolean>(false)

  // @ts-ignore TODO: make batter type (without @ts-ignore)
  const queryFn = useEvent<T>(async (...args) => {
    try {
      setSuccess(false)
      setError(false)
      setLoading(true)

      const response = await query(...args)
      setSuccess(true)
      return response
    } catch (error) {
      setError(true)
      setSuccess(false)
      throw new Error()
    } finally {
      setLoading(false)
    }
  })

  return [queryFn, isLoading, isSuccess, isError] as const
}
