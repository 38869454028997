// TODO: fix eslint errors
/* eslint-disable */
import { FC, useState } from 'react'
import { useProfile } from '../../../context'
import { BlockInfoItem } from '../../../BlockInfo'
import cn from 'classnames'
import style from '../../../Profile.module.scss'
import InlineEdit from '@atlaskit/inline-edit'
import { Input } from '../../../../UI/Input'
import { InputText } from '../../../ViewingModes/InputText'
import { useSetField } from '../../../../../API/profile/setField/useSetField'

interface RelationshipProps {
  id: string
  employeeId: string
  person: string | null
  name: string | null
  editContactPerson: (id: string, name: string, person: string) => void
}

export const Relationship: FC<RelationshipProps> = ({ name, id, person, employeeId, editContactPerson }) => {
  const { isAccessEdit, emergencyContact, idOpenProfile } = useProfile()

  return (
    <BlockInfoItem title="Relationship">
      <div className={cn(style.inputWrapper, isAccessEdit && style.profileInputTextIsEditing)}>
        <InlineEdit
          {...(!isAccessEdit && { isEditing: false })}
          defaultValue={person}
          editView={({ errorMessage, onChange, ...fieldProps }, ref) => (
            <Input
              {...fieldProps}
              onChange={(event) => {
                onChange(event)
              }}
              ref={ref}
              autoFocus
              placeholder=""
              className={style.profileInput}
            />
          )}
          readView={() => <InputText value={person} />}
          onConfirm={(value) => {
            editContactPerson(id, name || '', value)
          }}
        />
      </div>
    </BlockInfoItem>
  )
}
