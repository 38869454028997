import type {AvatarResponseProps, DeleteAvatarResponseProps, UploadAvatarResponseProps} from './avatar.type';
import {EMPLOYEES} from '../../constants';

export const fetchCurrentAvatarAPI = async (id: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${id}/images`);

        if (!response.ok || response.status !== 200) {
            return null; 
        }

        return await response.json() as AvatarResponseProps;
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};

export const uploadNewAvatarAPI = async (id: string, data: string) => {
    try {
        const blobData = await (await fetch(data)).blob();

        const formData = new FormData();
        formData.append("croppedImage", blobData, "cropped.jpg");

        const response = await fetch(`${EMPLOYEES}/${id}/images`, {
            method: "POST",
            body: formData,
        });

        if(!response.ok || response.status !== 200) {
            throw new Error();
        }

        return await response.json() as UploadAvatarResponseProps;
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};

export const deleteAvatarAPI = async (id: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${id}/images`, {
            method: "DELETE",
            headers: { 'Accept': 'application/json' }
        });

        if(!response.ok || response.status !== 200) {
            throw new Error();
        }

        return await response.json() as DeleteAvatarResponseProps;
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};
